import React, { PureComponent } from "react";
import "react-d3-treemap/dist/react.d3.treemap.css";
import {
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Select,
  Form,
  Button,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Space,
  message,
} from "antd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import { Link, useParams } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import axios from "axios";
import jwt from "jwt-decode"; // import dependency
import { useCubeQuery } from "@cubejs-client/react";
import cookie from "js-cookie";
import moment from "moment";
import logo from "../img/logo.png";
import { Redirect } from "react-router";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  baseUrlCube,
  homeUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";
import ReactToPrint from "react-to-print";

import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.mySubmitHandler = this.mySubmitHandler.bind(this);

    this.state = {
      loginUser: "",
      display: "none",
      dateUrl: "",
      collapsed: true,
      subject: "",
      message: "",
      schoolList: [],
      classList: [],
      groupList: [],
      studentList: [],
      assignmentList: [],
      individualSchool: [props?.match?.params?.schoolId],
      individualClass: [props?.match?.params?.standardId],
      individualGroup: [props?.match?.params?.groupIdName],
      individualStudent: [props?.match?.params?.studentId],
      individualDateRange: props?.match?.params?.dateRange,
      individualAssignment: [props?.match?.params?.assignment],
      individualAggregated: [props?.match?.params?.aggregatedBy],
      individualviewBy: [props?.match?.params?.viewBy],
      selectedRowKeys: [],
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ], //undefined, // ["2021-04-05", "2021-04-20"],
      timezone: "America/Los_Angeles",
      school: [""],
      processDateRangeList: [
        moment().subtract(5, "months"),
        moment().startOf("second"),
      ],
      loader: false,
      selectedSchool: [""],
      selectedSchoolID: "",
      selectedClassID: "",
      selectedGroupID: "",
      selectedStudentID: "",
      selectedSchoolValue: "",
      selectedClassValue: "",
      selectedDuration: "0",
      selectedGroupValue: "",
      selectedStudentValue: "",
      selectedDateRange: ["", ""],
      student: [""],
      selectedStudent: [""],
      class: [""],
      selectedClass: [""],
      selectedAssignment: [""],
      group: [""],
      selectedGroup: [""],
      assignmentsAll: [],
      assignments: [],
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      prevButton: "",
      nextButton: "disabled",
      ifAdaptive: 0,
      dateRangeArr: [this.props.match.params.sdateRange?.toString(),this.props.match.params.edateRange?.toString()],
    };
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  onSubmit(values) {
    this.props.reset();
  }

  onChangeViweBy = (value) => {
    this.setState({ individualviewBy: "" });

    this.setState({
      viewBy: [value.value],
    });
    this.state.viewBy = [value.value];
    if (value.value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (this.state.viewBy[0] == "skill") {
      this.state.pivotConfig = {
        x: ["TmStudenthomeworkquestions.tmSthwqtChapterName"],
        y: ["TmStudenthomeworkquestions.tmSthwqtSkillName", "measures"],
      };
    }
  };

  onChangeDuration = (value, option) => {
    this.setState({ selectedDuration: option.value.toString() });
  };

  onChangeNextButton = () => {
    var currentDate = "";
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD");
    var futureMonth = currentDate.add(3, "M").format("YYYY-MM-DD");
    var StateDate = [
      moment(futureMonth, "YYYY-MM-DD").add(-5, "months"),
      futureMonth,
    ];

    var currentDateLive = moment().format("YYYY-MM-DD");
    if (futureMonth > currentDateLive) {
      this.setState({
        nextButton: "disabled",
      });
    } else if (futureMonth <= currentDateLive) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: "",
      });
    }
  };

  onChangePrevButton = () => {
    var currentDate = "";
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD");
    var futureMonth = moment().add(3, "M").format("YYYY-MM-DD");
    var prevMonth = currentDate.add(-3, "M").format("YYYY-MM-DD");
    var fifthYear = moment().add(-3, "Y").format("YYYY-MM-DD");
    var StateDate = [
      moment(prevMonth, "YYYY-MM-DD").add(-5, "months"),
      prevMonth,
    ];
    this.setState({
      nextButton: "",
      processDateRangeList: StateDate,
      nextButton: "",
    });

    if (prevMonth > fifthYear) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: "",
      });
    } else if (prevMonth <= fifthYear) {
      this.setState({
        prevButton: "disable",
      });
    }
  };

  onChangeAggregateBy = (value) => {
    this.setState({ individualAggregated: "" });
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value.toString()],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
  };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value.value == "0") {
      this.setState({
        selectedAssignment: ["0"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
      });
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }
    }
  };

  onDateRangeChange = (value, dateString) => {
    this.setState({ dateRangeList: value });
  };

  myChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  mySubmitHandler = (event) => {
    let subject = this.state.subject;
    let message = this.state.message;
    if (subject === "" || message === "" || this.state.selectedRowKeys == 0) {
      return false;
    } else {
    }
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  CheckQuery = () => {
    let query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: {
        "TmSchoolGroups.classCount": "desc",
      },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    const renderProps = useCubeQuery(query);
    if (renderProps.resultSet) {
    }
  };

  componentDidMount() {
    let access_token = cookie?.get("token");
    this.setState({ loginUser: localStorage.getItem("username") });
    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isStudentTrue = false;
    let isAssignmentTrue = false;

    if (this.props?.match?.params?.sdateRange) {
      if (this.props?.match?.params?.sdateRange.toString() == "Invalid date") {
        this.setState({ dateRangeList: ["", ""] });
      } else {
        var inputstartdate = moment(this.props?.match?.params?.sdateRange);
        var inputenddate = moment(this.props?.match?.params?.edateRange);

        this.state.dateRangeList = [inputstartdate, inputenddate];
      }
    }
    this.setState({ selectedSchool: [this.props?.match?.params?.schoolId] });
    this.setState({ selectedClass: [this.props?.match?.params?.standardId] });
    this.setState({ selectedGroup: [this.props?.match?.params?.groupIdName] });
    this.setState({ selectedStudent: [this.props?.match?.params?.studentId] });

    let groupQuery = "";

    if (this.props?.match?.params?.groupIdName.toString() != "0") {
      groupQuery = `cubejs-api/v1/load?query={"dimensions":["TmStudentMock.tmStmktkSchoolName","TmStudentMock.tmStmktkStandardName","TmStudentMock.tmStmktkGroupName","TmStudentMock.tmStmktkStudentName"],"order":{"TmStudentMock.tmStmktkSchoolName":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkStudentId","operator":"equals","values":["${this.props?.match?.params?.studentId}"]},{"member":"TmStudentMock.tmStmktkStandardId","operator":"equals","values":["${this.props?.match?.params?.standardId}"]},{"member":"TmStudentMock.tmStmktkGroupId","operator":"equals","values":["${this.props?.match?.params?.groupIdName}"]}]}`;
    }
    else {
      groupQuery = `cubejs-api/v1/load?query={"dimensions":["TmStudentMock.tmStmktkSchoolName","TmStudentMock.tmStmktkStandardName","TmStudentMock.tmStmktkGroupName","TmStudentMock.tmStmktkStudentName"],"order":{"TmStudentMock.tmStmktkSchoolName":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkStudentId","operator":"equals","values":["${this.props?.match?.params?.studentId}"]},{"member":"TmStudentMock.tmStmktkStandardId","operator":"equals","values":["${this.props?.match?.params?.standardId}"]}]}`;
    }
    

    axios
      .get(
        `${homeUrlCube + groupQuery}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          selectedSchoolValue:
            res.data.data[0]["TmStudentMock.tmStmktkSchoolName"],
        });
        this.setState({
          selectedClassValue:
            res.data.data[0]["TmStudentMock.tmStmktkStandardName"],
        });
        this.setState({
          selectedGroupValue:
            res.data.data[0]["TmStudentMock.tmStmktkGroupName"],
        });
        this.setState({
          selectedStudentValue:
            res.data.data[0]["TmStudentMock.tmStmktkStudentName"],
        });
      })
      .catch((error) => {});
  }

  render() {
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: this.state.dateRangeList,
      });
    }

    let dateRangeArrayQuestion = [];
    let dateRangeArrayQuestionReport = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesDomain = [];
    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptiveScore = [];

    //if (this.state.selectedDuration[0]?.toString() != "0") {
      if (this.state.dateRangeArr[0] == null) {
        /* dateRangeArrayQuestion.push({
          dimension: "TmStudentMock.tmStmktkStartedon",
        }); */
        dateRangeArrayQuestion.push({
          dimension: "TmStudentMock.tmStmktkCompletedon",
        });
        dateRangeArrayQuestionReport.push({
          dimension: "TmStudenthomeworkquestions.tmSthwqtUpdatedon",
        });
        dateRangeArrayQuestionAdaptive.push({
          dimension: "IndividualStudentReport.Publish_Date",
        });
        dateRangeArrayBoxes = [];
        dateRangeArrayBoxes.push({
          dimension: "TmSchoolHomework.publishDate",
        });

        dateRangeArrayBoxesDomain = [];
        dateRangeArrayBoxesDomain.push({
          dimension: "TmSingleHwstudStatistics.Publish_Date",
        });
        dateRangeArrayBoxesAdaptiveScore = [];
        dateRangeArrayBoxesAdaptiveScore.push({
          dimension: "classScore.Publish_Date",
        });
      } else {
        /* dateRangeArrayQuestion.push({
          dimension: "TmStudentMock.tmStmktkStartedon",
          dateRange: [
            this.state.dateRangeArr[0],
            this.state.dateRangeArr[1],
          ],
        }); */
        dateRangeArrayQuestion.push({
          dimension: "TmStudentMock.tmStmktkCompletedon",
          dateRange: [
            this.state.dateRangeArr[0],
            this.state.dateRangeArr[1],
          ],
        });
        dateRangeArrayQuestionReport.push({
          dimension: "TmStudenthomeworkquestions.tmSthwqtUpdatedon",
          dateRange: [
            this.state.dateRangeArr[0],
            this.state.dateRangeArr[1],
          ],
        });

        dateRangeArrayQuestionAdaptive.push({
          dimension: "IndividualStudentReport.Publish_Date",
          dateRange: [
            this.state.dateRangeList[0].format("YYYY-MM-DD"),
            this.state.dateRangeList[1].format("YYYY-MM-DD"),
          ],
        });

        dateRangeArrayBoxes = [];
        dateRangeArrayBoxes.push({
          dimension: "TmSchoolHomework.publishDate",
          dateRange: [
            this.state.dateRangeList[0].format("YYYY-MM-DD"),
            this.state.dateRangeList[1].format("YYYY-MM-DD"),
          ],
        });

        dateRangeArrayBoxesDomain = [];
        dateRangeArrayBoxesDomain.push({
          dimension: "TmSingleHwstudStatistics.Publish_Date",
          dateRange: [
            this.state.dateRangeList[0].format("YYYY-MM-DD"),
            this.state.dateRangeList[1].format("YYYY-MM-DD"),
          ],
        });
        dateRangeArrayBoxesAdaptiveScore = [];
        dateRangeArrayBoxesAdaptiveScore.push({
          dimension: "classScore.Publish_Date",
          dateRange: [
            this.state.dateRangeList[0].format("YYYY-MM-DD"),
            this.state.dateRangeList[1].format("YYYY-MM-DD"),
          ],
        });
      }
    //}

    let dimensionArray = [];

    if (this.state.aggregateBy == "domain") {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArray.push(
        "TmStudentMockTask.tmStmktkCompletedon",
        "TmStudentMockTask.tmStmktkCategoryName",
        "TmStudentMockTask.tmStmktkTaskName",
        "TmStudentMockTask.tmStmktkLevelName",
        "TmStudentMockTask.tmRemediationCompleted"
      );
    } else if (this.state.aggregateBy == "topic") {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    } else {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionArrayReport = [];

    if (this.state.aggregateBy == "domain") {
      dimensionArrayReport.push(
        "TmStudenthomeworkquestions.tmSthwqtDomainName"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArrayReport.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName"
      );
    } else if (this.state.aggregateBy == "topic") {
      dimensionArrayReport.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName"
      );
    } else {
      dimensionArrayReport.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName"
      );
    }

    let dateRangeArrayQuestionReportOrder = {};
    if (this.state.aggregateBy == "domain") {
      dateRangeArrayQuestionReportOrder = {
        "TmStudenthomeworkquestions.tmSthwqtSkillId": "asc",
      };
    } else if (this.state.aggregateBy == "chapter") {
      dateRangeArrayQuestionReportOrder = {
        "TmStudenthomeworkquestions.tmSthwqtChapterName": "asc",
      };
    } else if (this.state.aggregateBy == "topic") {
      dateRangeArrayQuestionReportOrder = {
        "TmStudenthomeworkquestions.tmSthwqtSkillId": "asc",
      };
    } else {
      dateRangeArrayQuestionReportOrder = {
        "TmStudenthomeworkquestions.tmSthwqtSkillId": "asc",
      };
    }

    let dimensionArrayReportSkill = [];

    if (this.state.aggregateBy == "domain") {
      dimensionArrayReportSkill.push(
        "TmStudenthomeworkquestions.tmSthwqtDomainName",
        "TmStudenthomeworkquestions.tmSthwqtSkillName",
        "TmStudenthomeworkquestions.tmSthwqtSkillId"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArrayReportSkill.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtSkillName",
        "TmStudenthomeworkquestions.tmSthwqtSkillId"
      );
    } else if (this.state.aggregateBy == "topic") {
      dimensionArrayReportSkill.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName",
        "TmStudenthomeworkquestions.tmSthwqtSkillName",
        "TmStudenthomeworkquestions.tmSthwqtSkillId"
      );
    } else {
      dimensionArrayReportSkill.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName",
        "TmStudenthomeworkquestions.tmSthwqtSkillName"
      );
    }

    let dateRangeArrayQuestionReportOrderSkill = {};
    if (this.state.aggregateBy == "domain") {
      dateRangeArrayQuestionReportOrderSkill = {
        "TmStudenthomeworkquestions.tmSthwqtDomainName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    } else if (this.state.aggregateBy == "chapter") {
      dateRangeArrayQuestionReportOrderSkill = {
        "TmStudenthomeworkquestions.tmSthwqtChapterName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    } else if (this.state.aggregateBy == "topic") {
      dateRangeArrayQuestionReportOrderSkill = {
        "TmStudenthomeworkquestions.tmSthwqtChapterName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtTopicName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    } else {
      dateRangeArrayQuestionReportOrderSkill = {
        "TmStudenthomeworkquestions.tmSthwqtChapterName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtTopicName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    }

    let dimensionArrayReportProficiency = [];

    if (this.state.aggregateBy == "domain") {
      dimensionArrayReportProficiency.push(
        "TmStudenthomeworkquestions.tmSthwqtDomainName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArrayReportProficiency.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
      );
    } else if (this.state.aggregateBy == "topic") {
      dimensionArrayReportProficiency.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
      );
    } else {
      dimensionArrayReportProficiency.push(
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyName",
        "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
      );
    }

    let dateRangeArrayQuestionReportOrderProficiency = {};
    if (this.state.aggregateBy == "domain") {
      dateRangeArrayQuestionReportOrderProficiency = {
        "TmStudenthomeworkquestions.tmSthwqtDomainName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    } else if (this.state.aggregateBy == "chapter") {
      dateRangeArrayQuestionReportOrderProficiency = {
        "TmStudenthomeworkquestions.tmSthwqtProficiencyId": "desc",
      };
    } else if (this.state.aggregateBy == "topic") {
      dateRangeArrayQuestionReportOrderProficiency = {
        "TmStudenthomeworkquestions.tmSthwqtChapterName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtTopicName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    } else {
      dateRangeArrayQuestionReportOrderProficiency = {
        "TmStudenthomeworkquestions.tmSthwqtChapterName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtTopicName": "asc",
        "TmStudenthomeworkquestions.tmSthwqtSkillName": "asc",
      };
    }
    if (this.state.viewBy == "proficiency") {
      this.state.orderBy = [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
        ["TmHwQnAnsStat.proficiencyName", "asc"],
      ];

      this.state.pivotConfig = {
        x: [
          "TmHwQnAnsStat.domain",
          "TmHwQnAnsStat.chapterName",
          "TmHwQnAnsStat.topicName",
        ],
        y: ["TmHwQnAnsStat.proficiencyName", "measures"],
        fillMissingDates: true,
        joinDateRange: false,
      };
    }

    if (this.state.viewBy == "skill") {
      this.state.orderBy = [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
        ["TmHwQnAnsStat.skillName", "asc"],
      ];

      this.state.pivotConfig = {
        x: [
          "TmHwQnAnsStat.domain",
          "TmHwQnAnsStat.chapterName",
          "TmHwQnAnsStat.topicName",
        ],
        y: ["TmHwQnAnsStat.skillName", "measures"],
        fillMissingDates: true,
        joinDateRange: false,
      };
    }

    if (this.state.viewBy == "skill") {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy == "proficiency") {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");
    } else {
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assigId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let filterChapter = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filterChapter.push({
        member: "TmStudentTask.tmStmktkSchoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filterChapter.push({
        member: "TmStudentTask.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup[0]?.toString() != "0") {
      filterChapter.push({
        member: "TmStudentTask.tmStmktkGroupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterChapter.push({
        member: "TmStudentTask.studentid",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    let filterLevel = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filterLevel.push({
        member: "TmTaskLevel.tmStmktkSchoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filterLevel.push({
        member: "TmTaskLevel.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup[0]?.toString() != "0") {
      filterLevel.push({
        member: "TmTaskLevel.tmStmktkGroupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterLevel.push({
        member: "TmTaskLevel.studentid",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    let filterLevelPending = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filterLevelPending.push({
        member: "TmLevelPending.schoolid",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filterLevelPending.push({
        member: "TmLevelPending.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup[0]?.toString() != "0") {
      filterLevelPending.push({
        member: "TmLevelPending.groupid",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterLevelPending.push({
        member: "TmLevelPending.studentid",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    /* ****************** Student Progress Summary ******************* */
    let filterStudentSummary = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filterStudentSummary.push({
        member: "TmStudentProgress.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filterStudentSummary.push({
        member: "TmStudentProgress.gradeId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterStudentSummary.push({
        member: "TmStudentProgress.studentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    let dateRangeArrayStudentSummary = [];
    //if (this.state.selectedDuration[0]?.toString() != "0") {
      //console.log("sss daterng",this.state.dateRangeList[0])
      if (this.state.dateRangeArr[0] != null) {
       /*  dateRangeArrayStudentSummary.push({
          dimension: "TmStudentProgress.completedDate",
        });
      } else { */
        dateRangeArrayStudentSummary.push({
          dimension: "TmStudentProgress.completedDate",
          dateRange: [
            this.state.dateRangeArr[0],
            this.state.dateRangeArr[1],
          ],
        });
      }
    //}

    /* ****************** Student Progress Summary ******************* */

    let filterTaskPending = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filterTaskPending.push({
        member: "TmTaskPending.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filterTaskPending.push({
        member: "TmTaskPending.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup[0]?.toString() != "0") {
      filterTaskPending.push({
        member: "TmTaskPending.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterTaskPending.push({
        member: "TmTaskPending.studentid",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    let filter = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filter.push({
        member: "TmStudentMock.tmStmktkSchoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filter.push({
        member: "TmStudentMock.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup[0]?.toString() != "0") {
      filter.push({
        member: "TmStudentMock.tmStmktkGroupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filter.push({
        member: "TmStudentMock.tmStmktkStudentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    let filterTask = [];
    if (this.state.selectedSchool[0]?.toString() != "0") {
      filterTask.push({
        member: "TmStudentMock.tmStmktkSchoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass[0]?.toString() != "0") {
      filterTask.push({
        member: "TmStudentMock.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup[0]?.toString() != "0") {
      filterTask.push({
        member: "TmStudentMock.tmStmktkGroupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterTask.push({
        member: "TmStudentMock.tmStmktkStudentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    filterTask.push(
      {
        member: "TmStudentMock.tkAttempted",
        operator: "equals",
        values: ["1"],
      },
      {
        member: "TmStudentMock.tkCategoryStatus",
        operator: "equals",
        values: ["1"],
      }
    );

    /* *************** students Filter ************** */
    let studentPointsFilter = [];

    if (this.props?.match?.params?.studentId.toString() != "0") {
      studentPointsFilter.push({
        member: "TmStudentPoints.tmStmktkStudentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.props?.match?.params?.standardId.toString() != "0") {
      studentPointsFilter.push({
        member: "TmStudentPoints.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }


    let studentFilter = [];

    if (this.props?.match?.params?.studentId.toString() != "0") {
      studentFilter.push({
        member: "TmStudentDetails.studentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }
    /* *************** students Filter ************** */

    let filterReport = [];

    if (this.state.selectedStudent[0]?.toString() != "0") {
      filterReport.push({
        member: "TmStudenthomeworkquestions.tmSthwqtStudentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      filterReport.push({
        member: "TmStudenthomeworkquestions.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    let studentFilterDig = [];

    if (this.state.selectedStudent[0]?.toString() != "0") {
      studentFilterDig.push({
        member: "TmSingleHwstudStatistics.studentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      studentFilterDig.push({
        member: "TmSchoolHomework.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let studentFilterAdaptive = [];

    if (this.state.selectedStudent[0]?.toString() != "0") {
      studentFilterAdaptive.push({
        member: "IndividualStudentReport.Student_Id",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      studentFilterAdaptive.push({
        member: "IndividualStudentReport.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dimensionDynamic = [
      "TmHwQnAnsStat.proficiency",
      "TmDifficulty.proficiency",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamic.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamic.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamic.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicSkill = [
      "TmHwQnAnsStat.skillName",
      "TmHwQnAnsStat.skillStd",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicSkill.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicAdaptive = [];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicAdaptive.push(
        "IndividualStudentReport.Domain_Name",
        "IndividualStudentReport.Domain_result"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.skillName",
        "TmHwQnAnsStat.skillStd",
        "TmHwQnAnsStat.chapterName"
      );
    } else {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.skillName",
        "TmHwQnAnsStat.skillStd",
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    /* *************** Student Points & name  ************** */
    const DashboardStudentPoints = [
      {
        id: 1,
        name: "Student",
        vizState: {
          query: {
            order: { "TmStudentPoints.totalPoints": "asc" },
            measures: ["TmStudentPoints.totalPoints"],
            filters: studentPointsFilter,
          },
          chartType: "number",
          parameters: [],
        },
      },
    ];

    const DashboardStudentDetails = [
      {
        id: 1,
        name: "Student",
        vizState: {
          query: {
            order: { "TmStudentDetails.studentName": "asc" },
            dimensions: ["TmStudentDetails.studentName"],
            filters: studentFilter,
          },
          chartType: "student_name",
          parameters: [],
        },
      },
    ];
    /* *************** Student Points & name  ************** */

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Chapter Levels Attempted",
        vizState: {
          query: {
            measures: ["TmLevelPending.level_completed"],
            filters: filterLevel,
          },
          chartType: "number",
        },
      },
      {
        id: 2,
        name: "Levels Pending",
        vizState: {
          query: {
            measures: ["TmLevelPending.level_pending"],

            filters: filterLevelPending,
          },
          chartType: "number",
        },
      },
      {
        id: 3,
        name: "Tasks Attempted",
        vizState: {
          query: {
            measures: ["TmStudentMock.StmktkCompleted"],

            // "timeDimensions": dateRangeArrayQuestion,
            filters: filter,
          },
          chartType: "number",
        },
      },
      {
        id: 4,
        name: "Summary",
        desc: "This section shows the tasks that have been attempted by the student and their performance at each proficiency level.",
        /* vizState: {
          query: {
            "measures": [
              "TmTaskPending.task_pending",
            ],
         
            "filters": filterTaskPending
          },
          chartType: "number",
        } */

        vizState: {
          query: {
            filters: filterStudentSummary,
            timeDimensions: dateRangeArrayStudentSummary,
            dimensions: [
              "TmStudentProgress.categoryId",
              "TmStudentProgress.categoryName",
            ],
            order: [["TmStudentProgress.introExpectationText", "desc"]],
            measures: [
              "TmStudentProgress.introExpectationText",
              "TmStudentProgress.beginerExpectationText",
              "TmStudentProgress.intermediateExpectationText",
              "TmStudentProgress.advancedExpectationText",
            ],
            // "measures": ["TmStudentMock.StmktkScore"]
          },
          chartType: "student_summary_report",
          parameters: [
            { School: this.state.selectedSchool },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Datebetween: this.state.dateRangeArr },
          ],
        },
      },
      {
        id: 5,
        name: "Details of Tasks Attempted",
        vizState: {
          query: {
            filters: filterTask,
            dimensions: [
              "TmStudentMock.tmStmktkCompletedon",
              "TmStudentMock.tmStmktkCategoryName",
              "TmStudentMock.tmStmktkTaskName",
              "TmStudentMock.tmStmktkLevelName",
              "TmStudentMock.Score",
              "TmStudentMock.tkexpect",
              "TmStudentMock.tmRemediationCompleted",
              "TmStudentMock.StmktkAttemptAvg",
            ],
            timeDimensions: dateRangeArrayQuestion,
            order: [["TmStudentMock.tmStmktkStudentId", "desc"]],

            // "measures": ["TmStudentMock.StmktkScore"]
          },
          chartType: "table_pivot_task_detail",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 6,
        name: "Analysis",
        vizState: {
          query: {
            filters: filterReport,
            dimensions: dimensionArrayReport,
            timeDimensions: dateRangeArrayQuestionReport,
            order: dateRangeArrayQuestionReportOrderSkill,
            measures: [
              "TmStudenthomeworkquestions.averageScore",
              "TmStudenthomeworkquestions.distCount",
            ],
          },
          chartType: "table_pivot_topic",
          pivotConfig: this.state.pivotConfig,
          parameters: [
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 6,
        name: "Analysis",
        vizState: {
          query: {
            filters: filterReport,
            dimensions: dimensionArrayReportSkill,
            timeDimensions: dateRangeArrayQuestionReport,
            order: dateRangeArrayQuestionReportOrder,

            measures: [
              "TmStudenthomeworkquestions.averageScore",
              "TmStudenthomeworkquestions.distCount",
            ],
          },
          chartType: "table_pivot_html_skill_progress",
          pivotConfig: this.state.pivotConfig,
          parameters: [
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 6,
        name: "Analysis",
        vizState: {
          query: {
            filters: filterReport,
            dimensions: dimensionArrayReportProficiency,
            timeDimensions: dateRangeArrayQuestionReport,
            order: dateRangeArrayQuestionReportOrderProficiency,
            measures: [
              "TmStudenthomeworkquestions.averageScore",
              "TmStudenthomeworkquestions.distCount",
            ],
          },
          chartType: "table_pivot_html_proficiency_progress",
          pivotConfig: this.state.pivotConfig,
          parameters: [
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 7,
        name: "Points",
        vizState: {
          query: {
            measures: ["TmStudentMock.StmktkPoints"],

            // "timeDimensions": dateRangeArrayQuestion,
            filters: filter,
          },
          chartType: "number",
        },
      },
      {
        id: 8,
        name: "Total Tasks Completed",
        vizState: {
          query: {
            measures: ["TmStudentMock.StmktkTotal"],

            // "timeDimensions": dateRangeArrayQuestion,
            filters: filter,
          },
          chartType: "number_progress_report",
        },
      },
      {
        id: 9,
        name: "Total Chapter Completed",
        vizState: {
          query: {
            measures: ["TmStudentMock.StmktkChapterTotal"],

            // "timeDimensions": dateRangeArrayQuestion,
            filters: filter,
          },
          chartType: "number_progress_report",
        },
      },
    ];

    const DashboardItems = [
      {
        id: 1,
        name: "Number Chart",
        vizState: {
          query: {
            measures: ["TmHWQs.distCount"],
            timeDimensions: [],
            order: {},
            dimensions: [],
            filters: [],
            limit: 10,
          },
          chartType: "number",
        },
      },
    ];
    const dashboardItem = (item) => (
      <React.Fragment>
        {item.isVisible && (
          <Col span={24} lg={12} key={item.id} style={{ marginBottom: "24px" }}>
            <DashboardItem title={item.name}>
              <ChartRenderer vizState={item.vizState} />
            </DashboardItem>
          </Col>
        )}
      </React.Fragment>
    );
    const dashboardItemTop = (item) => (
      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "24px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>
    );

    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let selectedSchoolID = this.state.selectedSchool[0];
    let selectedClassID = this.state.selectedClass[0];
    let selectedStudentID = this.state.selectedStudent[0];

    var classOption = [];
    const { collapsed } = this.state;
    const dateFormat = "DD-MM-YYYY";

    let allGroupList = [];
    let groupChildren = [];
    let classChildren = [];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    const cubejsApi = cubejs(cookie?.get("token"), {
      apiUrl: `${homeUrlCube}cubejs-api/v1`,
    });

    return DashboardItems.length ? (
      <React.Fragment>
        <CubeProvider cubejsApi={cubejsApi}>
          <Layout className="print_width" style={{ minHeight: "100vh" }}>
            <Sider
              width={230}
              collapsible
              collapsed={collapsed}
              onCollapse={this.onCollapse}
              className="side_menu site-layout-background site-layout-background-spl"
            >
              {/* <div className="logo">
            </div> */}

              <Menu theme="dark" mode="inline" className="nav_panel">
                <Menu.Item icon={<SolutionOutlined />} key="3">
                  <Link
                    to="/teacher-usage"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Teachers Usage
                  </Link>
                </Menu.Item>
                {/* <Menu.Item icon={<FileDoneOutlined />} key="13">
                    <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                </Menu.Item> */}
                <Menu.Item icon={<TeamOutlined />} key="4">
                  <Link to="/" className="sub-menu ant-menu-submenu-active1">
                    Class Usage
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<UserOutlined />}
                  key="2"
                  className="ant-menu-submenu "
                >
                  <Link
                    to="/student"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Individual Student Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FormOutlined />} key="5">
                  <Link
                    to="/assignment"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Assignment Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<MailOutlined />} key="6">
                  <Link
                    to="/send-email"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Send Email
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FundProjectionScreenOutlined />} key="1">
                  <Link
                    to="/home-module"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Self Prep
                  </Link>
                </Menu.Item>
                {localStorage.getItem("userType") == 1 && (
                  <>
                    <Menu.Item icon={<LoginOutlined />} key="7">
                      <Link
                        to="/login-report"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Login Report
                      </Link>
                    </Menu.Item>
                    {/* <Menu.Item icon={<DatabaseOutlined />} key="8">
                      <Link
                        to="/task-data"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Task Report
                      </Link>
                    </Menu.Item> */}
                    <Menu.Item icon={<LineChartOutlined />} key="9">
                      <Link
                        to="/task-analysis"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Task Analysis
                      </Link>
                    </Menu.Item>
                    <Menu.Item icon={<FundOutlined />} key="10">
                      <Link
                        to="/question-analysis"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Question Analysis
                      </Link>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>
            <Layout style={{ padding: "0 0px 0px" }}>
              <Grid
                xs={12}
                sm={12}
                lg={12}
                className="header header-customized"
              >
                <nav className="custom-nav">
                  <button
                    className="nav-toggl"
                    onClick={() => {
                      this.setState({ mobNav: !this.state.mobNav });
                      console.log("The button is clicked", this.state.mobNav);
                    }}
                  >
                    <MenuOutlinedIcon
                      className="icon-style-custom"
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "auto",
                        display: "flex",
                      }}
                    />
                  </button>
                  {this.state.mobNav && (
                    <ul className="custom-ul">
                      <Menu theme="dark" mode="inline" className="nav_panel">
                        <Menu.Item icon={<SolutionOutlined />} key="3">
                          <Link
                            to="/teacher-usage"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Teachers Usage
                          </Link>
                        </Menu.Item>
                        {/* <Menu.Item icon={<FileDoneOutlined />} key="16">
                            <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                        </Menu.Item> */}
                        <Menu.Item icon={<TeamOutlined />} key="4">
                          <Link
                            to="/"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Class Usage
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          icon={<UserOutlined />}
                          key="2"
                          className="ant-menu-submenu "
                        >
                          <Link
                            to="/student"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Individual Student Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<FormOutlined />} key="5">
                          <Link
                            to="/assignment"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Assignment Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<MailOutlined />} key="6">
                          <Link
                            to="/send-email"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Send Email
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          icon={<FundProjectionScreenOutlined />}
                          key="1"
                        >
                          <Link
                            to="/home-module"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Self Prep
                          </Link>
                        </Menu.Item>
                        {localStorage.getItem("userType") == 1 && (
                          <>
                            <Menu.Item icon={<LoginOutlined />} key="7">
                              <Link
                                to="/login-report"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Login Report
                              </Link>
                            </Menu.Item>
                            {/* <Menu.Item icon={<DatabaseOutlined />} key="8">
                              <Link
                                to="/task-data"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Task Report
                              </Link>
                            </Menu.Item> */}
                            <Menu.Item icon={<LineChartOutlined />} key="9">
                              <Link
                                to="/task-analysis"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Task Analysis
                              </Link>
                            </Menu.Item>
                            <Menu.Item icon={<FundOutlined />} key="10">
                              <Link
                                to="/question-analysis"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Question Analysis
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                      </Menu>
                    </ul>
                  )}
                </nav>
                <Grid
                  xs={24}
                  lg={24}
                  container
                  spacing={0}
                  style={{ padding: 0 }}
                  justify="left"
                  alignItems="flex-start"
                >
                  <Col span={8} className="logo_header">
                    <img class="logoWidth" src={logo} />
                  </Col>
                  <Col span={8} className="breadcrumb">
                    <center>
                      <h2>
                        <b>Student Overview</b>
                      </h2>
                    </center>
                  </Col>
                  <Col span={8} className="user_detail">
                    <h5>
                      <p>
                        <span className="c1">Hello!,</span>{" "}
                        <span className="c2">
                          {this.state.loginUser}
                          {/* {localStorage.getItem('user')}  */}
                          {/* ABC Teacher */}{" "}
                        </span>
                        <br />
                        {this.state.selectedSchoolValue} <br />
                        <Link
                          className="logout_btn"
                          onClick={(event) => {
                            event.preventDefault();
                            signout(() => <Redirect to="/home" />);
                          }}
                        >
                          {" "}
                          Home{" "}
                        </Link>
                      </p>
                    </h5>
                  </Col>
                </Grid>
              </Grid>

              {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Students Usage</Breadcrumb.Item>
              </Breadcrumb> */}
              <center>
                <h2>
                  <b>
                    <div
                      style={{
                        display: this.state.display,
                        border: "2px solid black",
                        width: "30%",
                        height: "100px",
                        marginTop: "30px",
                        paddingTop: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      One of the Data not provided
                    </div>
                  </b>
                </h2>
              </center>
              <Content
                id="printme"
                className="site-layout-background site-layout-background-spl"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {/* Content */}

                {/* {!this.state.loader && <Spin />} */}
                {/* {this.state.display=="none"?!this.state.loader && <Spin />:this.setState({display:"block"})} */}
                {!this.state.loader && (
                  <Dashboard>
                    <Grid
                      xs={10}
                      sm={10}
                      lg={10}
                      className="header printme-extra-content"
                    >
                      <Grid
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col span={8} className="logo_header_print">
                          <img class="logoWidth" src={logo} style={{paddingLeft:"40px"}} />
                        </Col>
                        <Col span={8} className="breadcrumb_print">
                          <center>
                            <h2>
                              <b>Student Overview</b>
                            </h2>
                          </center>
                        </Col>
                        <Col span={8} className="user_detail_print">
                          <h5>
                            <p>
                              <span className="c1">School:</span>{" "}
                              <span className="c2">
                                {this.state.selectedSchoolValue}
                              </span>
                              <br />
                              <span className="c1">Student Name:</span>{" "}
                              <span className="c2">
                                {this.state.selectedStudentValue}
                              </span>
                              <br />
                              <span className="c1">Class:</span>{" "}
                              <span className="c2">
                                {this.state.selectedClassValue}
                              </span>
                              <br />
                             { (this.props?.match?.params?.groupIdName.toString() != "0") &&
                             <>
                                <span className="c1">Group:</span>{" "}
                                      <span className="c2">
                                        {this.state.selectedGroupValue == "0"
                                          ? "All"
                                          : this.state.selectedGroupValue}
                                      </span>
                                  <br />
                             </> 
                            }
                             

                              {/* <span className="c1">Date Range:</span>{" "}
                              <span className="c2">
                                {this.state.dateRangeList[0].format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                To{" "}
                                {this.state.dateRangeList[1].format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                              <br /> */}
                            </p>
                          </h5>
                        </Col>
                      </Grid>
                    </Grid>
                    <Grid xs={10} sm={10} lg={10}>
                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col span={24} lg={24}>
                          <div class="title-container student-overview">
                            <div class="student-points">
                              <div class="g-progress">
                                <div class="sub-ui-widget">
                                  <center style={{ fontSize: 7 }}>
                                    <div class="ui-values">
                                      <ChartRendererCustomised
                                        vizState={
                                          DashboardStudentPoints[0].vizState
                                        }
                                      />
                                    </div>
                                    <div class="ui-labels">BEE POINTS</div>
                                  </center>
                                </div>
                              </div>
                            </div>
                            <div
                              class="div-left"
                              className="custom-student-name"
                            >
                              <ChartRendererCustomised
                                vizState={DashboardStudentDetails[0].vizState}
                              />
                            </div>
                            <div class="div-right">
                              <div class="print_btn_print">
                                <Link to={`/home-module`}>
                                  <button class="print_btn_web print_btn_web-stdAtmp">
                                    Back
                                  </button>
                                </Link>
                                </div>
                            </div>
                          </div>
                        </Col>

                          {/*  <Col span={8} key={DashboardItemsTop[0].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                            <DashboardItem title={DashboardItemsTop[0].name}>
                              <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                            </DashboardItem>
                          </Col> */}
                              {/* <Col span={5} key={DashboardItemsTop[1].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                            <DashboardItem title={DashboardItemsTop[1].name}>
                              <ChartRendererCustomised vizState={DashboardItemsTop[1].vizState} />
                            </DashboardItem>
                          </Col> */}
                              {/*      <Col span={8} key={DashboardItemsTop[2].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                            <DashboardItem title={DashboardItemsTop[2].name}>
                              <ChartRendererCustomised vizState={DashboardItemsTop[2].vizState} />
                            </DashboardItem>
                          </Col> */}
                              {/*  <Col span={5} key={DashboardItemsTop[3].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                            <DashboardItem title={DashboardItemsTop[3].name}>
                              <ChartRendererCustomised vizState={DashboardItemsTop[3].vizState} />
                            </DashboardItem>
                          </Col> */}
                              {/*  <Col span={8} key={DashboardItemsTop[8].id} className="topWidget" id="">
                          <DashboardItem title={DashboardItemsTop[8].name}>
                            <ChartRendererCustomised vizState={DashboardItemsTop[8].vizState} />
                          </DashboardItem>
                        </Col> */}
                      </Grid>
                      <br />

                      {/* New student summary report starts here */}
                      <Grid
                        className="top-widgets mt400"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[3].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[3].name}>
                          <p>{DashboardItemsTop[3].desc}</p>
                          <div className="labels-wrapr">
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#b4d3b2" }}>
                                    EE
                                  </span>
                                  Exceeds Expectation (71% - 100%)
                                </p>
                                <p>
                                  <span style={{ background: "#daf0f7" }}>
                                    ME
                                  </span>
                                  Meets Expectation (51% - 70%)
                                </p>
                              </div>
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#ece5c7" }}>
                                    PME
                                  </span>
                                  Partially Meets Expectation (33% - 50%)
                                </p>
                                <p>
                                  <span style={{ background: "#ffe3e1" }}>
                                    NI
                                  </span>
                                  Needs Improvement (0% - 32%)
                                </p>
                              </div>
                            </div>
                           
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[3].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      </Grid>
                      <br />
                      {/* New student summary report starts here */}

                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[4].id}
                          
                          id=""
                          onClick={this.onClickDashboardTop}  className={"task-details"}
                        >
                          <DashboardItem title={DashboardItemsTop[4].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[4].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      </Grid>
                      <br />
                      {this.state.viewBy[0] == "all" ? (
                        <Grid
                          className="top-widgets"
                          xs={24}
                          lg={24}
                          container
                          spacing={0}
                          style={{ padding: 0 }}
                          justify="left"
                          alignItems="flex-start"
                        >
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[5].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[5].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[5].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        </Grid>
                      ) : this.state.viewBy[0] == "skill" ? (
                        <Grid
                          className="top-widgets"
                          xs={24}
                          lg={24}
                          container
                          spacing={0}
                          style={{ padding: 0 }}
                          justify="left"
                          alignItems="flex-start"
                        >
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[6].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[6].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[6].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        </Grid>
                      ) : (
                        <Grid
                          className="top-widgets"
                          xs={24}
                          lg={24}
                          container
                          spacing={0}
                          style={{ padding: 0 }}
                          justify="left"
                          alignItems="flex-start"
                        >
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[7].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[7].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[7].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        </Grid>
                      )}
                      <br />
                    </Grid>
                    <Grid
                      xs={2}
                      sm={2}
                      lg={2}
                      className="print_width_filter print_filters filter-align-student-progess"
                    >
                      <Grid
                        container
                        justify="left"
                        alignItems="flex-start"
                        id="filterContainer-title"
                      >
                        <div
                          className="filter-bg white-bg print_btn_print"
                          style={{ float: "left" }}
                        >
                          <ReactToPrint
                            trigger={() => (
                              <button className="print_btn_web">
                                <PrinterFilled />
                                &nbsp;&nbsp;Print
                              </button>
                            )}
                            content={() => window.print()}
                          />
                        </div>
                        <DashboardItem
                          title="Filters"
                          className="ant-card-body"
                        >
                          {/* <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Duration </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedDuration?.toString(),
                                }}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select Date"
                                optionFilterProp="children"
                                onChange={this.onChangeDuration}
                              >
                                <Option value="0">All</Option>
                                <Option value="1">Date Range</Option>
                              </Select>
                            </div>
                          </Col>

                          {this.state.selectedDuration != 0 && (
                            <Col
                              span={24}
                              lg={24}
                              xs={24}
                              sm={24}
                              md={24}
                              className="filterContainer"
                            >
                              <div className="filter-bg color-1 white-bg">
                                <label>
                                  {" "}
                                  <strong> Date Range </strong>{" "}
                                </label>
                                <RangePicker
                                  style={{ top: 5 }}
                                  onChange={this.onDateRangeChange}
                                  value={
                                    this.state.dateRangeList[0] != "0"
                                      ? [
                                          moment(
                                            this.state.dateRangeList[0],
                                            dateFormat
                                          ),
                                          moment(
                                            this.state.dateRangeList[1],
                                            dateFormat
                                          ),
                                        ]
                                      : ""
                                  }
                                  format={dateFormat}
                                />
                              </div>
                            </Col>
                          )} */}

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Aggregate by</strong>{" "}
                              </label>
                              <Select
                                showSearch
                                labelInValue
                                defaultValue={{
                                  value: this.state.viewBy.toString(),
                                }}
                                style={{ width: "100%" }}
                                placeholder="Select View by"
                                optionFilterProp="children"
                                onChange={this.onChangeViweBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="all">Overall</Option>
                                <Option value="skill">Skill</Option>
                                <Option value="proficiency">Proficiency</Option>
                              </Select>
                            </div>
                          </Col>
                          {this.state.ifAdaptive == 0 ||
                          this.state.ifAdaptive == 2 ||
                          this.props?.match?.params?.viewBy != "domain" ? (
                            <Col
                              span={24}
                              lg={24}
                              xs={24}
                              sm={24}
                              md={24}
                              style={{ paddingBottom: 6 }}
                              className="filterContainer"
                            >
                              <div className="filter-bg color-1 white-bg">
                                <label>
                                  {" "}
                                  <strong> View by </strong>{" "}
                                </label>
                                <Select
                                  showSearch
                                  value={this.state.aggregateBy}
                                  style={{ width: "100%", paddingTop: 5 }}
                                  placeholder="Select Aggregate by"
                                  optionFilterProp="children"
                                  onChange={this.onChangeAggregateBy}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                >
                                  {/* <Option value="domain">Domain</Option> */}
                                  <Option value="chapter">Chapter</Option>
                                  <Option value="topic">Topic</Option>
                                </Select>
                              </div>
                            </Col>
                          ) : (
                            <Col
                              span={24}
                              lg={24}
                              xs={24}
                              sm={24}
                              md={24}
                              style={{ paddingBottom: 6 }}
                              className="filterContainer"
                            >
                              <div className="filter-bg color-1 white-bg">
                                <label>
                                  {" "}
                                  <strong> View by </strong>{" "}
                                </label>
                                <Select
                                  showSearch
                                  value={this.state.aggregateByAdaptive}
                                  style={{ width: "100%", paddingTop: 5 }}
                                  placeholder="Select Aggregate by"
                                  optionFilterProp="children"
                                  onChange={this.onChangeAggregateBy}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                >
                                  <Option value="domain">Domain</Option>
                                  <Option value="chapter">Chapter</Option>
                                  <Option value="topic">Topic</Option>
                                </Select>
                              </div>
                            </Col>
                          )}
                        </DashboardItem>
                      </Grid>
                    </Grid>
                  </Dashboard>
                )}
              </Content>
            </Layout>
          </Layout>
        </CubeProvider>
      </React.Fragment>
    ) : (
      <div style={{ textAlign: "center", padding: 12 }}>
        {" "}
        <Typography variant="h5" color="inherit">
          {" "}
          There are no charts on this dashboard. Use Playground Build to add
          one.{" "}
        </Typography>{" "}
      </div>
    );
  }
}

export default CustomerDashboardPage;
