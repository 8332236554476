import React, { PureComponent } from "react";
// import TreeMap from "react-d3-treemap";
// import "react-d3-treemap/dist/react.d3.treemap.css"; // Breadcrumb, // Button, Radio, Checkbox, Card, // , Space
import { Spin, Row, Col, Layout, Menu, Select, DatePicker } from "antd";
import Grid from "@material-ui/core/Grid";
//import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartRendererTabbie";
import ChartRendererCustomised from "../components/ChartRendererTabbie";
import { Table, Divider, Input } from "antd";
// import Link from 'umi/link';
import { Link } from "react-router-dom";
import logo from "../img/logo.jpg";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import cookie from "js-cookie";
import moment from "moment";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import schoolValue from "../pages/Student";
import ReactToPrint from "react-to-print";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import SinglePage from "../components/ChartRendererTabbie";
// import PrintButton from "../components/PrintButton";

import { Redirect } from "react-router";
//import { authenticate, isAuth } from '../components/helpers';
import {
  baseUrlCube,
  homeUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";

import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;

const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      studentReport: [],
      isRedirect403: false,
      collapsed: true,
      schoolList: [],
      classList: [],
      groupList: [],
      assignmentList: [],
      //dateRangeList:["2020-11-25","2021-05-25"], //undefined, // ["2021-04-05", "2021-04-20"],
      // dateRangeList: [moment().subtract(4, 'months'), moment().startOf('second')], //undefined, // ["2021-04-05", "2021-04-20"],
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ], //undefined, // ["2021-04-05", "2021-04-20"],
      school: [],
      loader: false,
      selectedSchool: [],
      schoolName: [],
      selectedSchoolID: "",
      selectedSchoolValue: "",
      selectedSchoolDetails:
        JSON.parse(sessionStorage.getItem("selectedSchoolDetails")) || [],
      selectedClassValue: sessionStorage.getItem("selectedClassID") || "",
      selectedGroupValue: "",
      selectedClassID: "",
      selectedGroupID: "",
      selectedUserPlan: sessionStorage.getItem("selectedUserPlan") || "0",
      selectedUserPlanValue: "",
      class: [],
      selectedClass: [""],
      selectedClassDetails:
        JSON.parse(sessionStorage.getItem("selectedClassDetails")) || [],
      group: [],
      selectedGroup: [""],
      selectedDateRange: ["", ""],
      selectedAssignment: [""],
      selectedAssignmentLabel: "",
      viewBy: ["all"],
    /*   studentPlan: [], */
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      ifAdaptive: 0,
    };
  }

  printOrder = () => {
    let orderHTML = "";
    const printableElements = document.getElementById("printme").innerHTML;
    orderHTML =
      '<html><head><title></title></head><body style="padding:20px">' +
      printableElements +
      "</body></html>";
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage;
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onChangeUserPlan = (value, option) => {
    this.setState({
      selectedUserPlan: option.value.toString(),
    });



    if(option.value.toString() != "0") {
      this.setState({
        selectedGroupValue: 0,
      });
      this.setState({
        selectedGroup: ["0"],
      });

      
    }


    sessionStorage.setItem("selectedUserPlan", option.value.toString());
  };

  ChangeUserPlanOnLoading = (value, option) => {
    this.setState({
      selectedUserPlan: option,
    });
  };

  onChangeViweBy = (value) => {
    this.setState({
      viewBy: [value],
    });

    if (value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (value == "skill") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.skillName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.skillName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }
  };

  onChangeAggregateBy = (value) => {
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value],
      });
    }
  };

  onChangeSchool = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedSchool: [option.value.toString()],
      selectedSchoolID: option.value.toString(),
      selectedSchoolValue: option.children.toString(),
    });

    let valuesToRemove = [
      "selectedSchoolID",
      "selectedSchoolValue",
      "selectedSchoolDetails",
      "selectedSchool",
      "selectedClassID",
      "selectedClass",
      "selectedClassDetails",
      "selectedGroup",
      "selectedGroupValue",
      "selectedGroupDetails",
    ];
    valuesToRemove.forEach((k) => sessionStorage.removeItem(k));

    sessionStorage.setItem(
      "selectedSchool",
      JSON.stringify([option.value.toString()])
    );
    sessionStorage.setItem("selectedSchoolID", option.value.toString());
    sessionStorage.setItem("selectedSchoolValue", option.children.toString());
    sessionStorage.setItem("selectedSchoolDetails", JSON.stringify(option));

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmSchool.schoolName","asc"]],"dimensions":["TmSchool.schoolId","TmSchool.schoolName"],"segments":[],"filters":[{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  changeSchoolOnLoading = (selectedSchoolDetails) => {
    this.state.ifAdaptive = 0;

    if (selectedSchoolDetails) {
      this.setState({
        selectedSchool: [selectedSchoolDetails?.value?.toString()],
        selectedSchoolID: selectedSchoolDetails?.value?.toString(),
        selectedSchoolValue: selectedSchoolDetails?.children?.toString(),
      });
    }

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmSchool.schoolName","asc"]],"dimensions":["TmSchool.schoolId","TmSchool.schoolName"],"segments":[],"filters":[{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${selectedSchoolDetails.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    let access_token = cookie?.get("token");

    /*************** Class Section  *************/

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStandard.standardId","asc"]],"dimensions":["TmStandard.standardId","TmStandard.className"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStandard.standardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStandard.className"];
        let className = classList[0]["TmStandard.className"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });
        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStandard.standardId"] != undefined) {
              classListOnLoad.push(x["TmStandard.standardId"]?.toString());
            }
          });
        this.setState({
          selectedClass: JSON.parse(
            sessionStorage.getItem("selectedClass")
          ) || [classListOnLoad[0]],
        });

        /*************** Group Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const groupList = res.data.data;
            let groupId = groupList[0]["TmStudentCurrent.groupId"];
            let groupName = groupList[0]["TmStudentCurrent.groupName"];
            this.setState({ groupList: groupList });
            this.setState({
              selectedGroupID: ["0"],
              selectedGroupValue: ["All"],
              selectedGroup: ["0"],
            });
            const groupListOnLoad = [];
            groupList &&
              groupList.map((x) => {
                groupListOnLoad.push(x["TmStudentCurrent.groupId"].toString());
              });

            // this.state.selectedGroup= ['0'];

            /*************** Group Section  *************/
          })
          .catch((error) => {});
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };

  onChangeClass = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedClass: [option.value.toString()],
      selectedClassValue: option.label.toString(),
    });

    // storing to session storage
    sessionStorage.setItem(
      "selectedClass",
      JSON.stringify([option.value.toString()])
    );
    sessionStorage.setItem("selectedClassDetails", JSON.stringify(option));
    sessionStorage.setItem("selectedClassID", option.value);
    sessionStorage.removeItem("selectedGroup");
    sessionStorage.removeItem("selectedGroupValue");

    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let groupQuery = "";
    groupQuery = `cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${option.value}"]}]}`;
    axios
      .get(`${baseUrlCube + groupQuery}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["All"],
          selectedAssignment: ["0"],
        });
      })
      .catch((error) => {});
  };

  changeClassOnLoading = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedClass: [option.value.toString()],
      selectedClassValue: option.label.toString(),
    });

    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let groupQuery = "";
    groupQuery = `cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${option.value}"]}]}`;
    axios
      .get(`${baseUrlCube + groupQuery}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["All"],
          selectedAssignment: ["0"],
        });
      })
      .catch((error) => {});
  };

  onChangeGroup = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedGroup: [option.value.toString()],
      selectedGroupValue: option.label.toString(),
    });

    sessionStorage.setItem(
      "selectedGroup",
      JSON.stringify([option.value.toString()])
    );
    sessionStorage.setItem("selectedGroupValue", option.label.toString());
    sessionStorage.setItem("selectedGroupDetails", JSON.stringify(option));
  };

  changeGroupOnLoading = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedGroup: [option.value.toString()],
      selectedGroupValue: option.label.toString(),
    });
  };

  onDateRangeChange = (value, dateString) => {
    this.state.ifAdaptive = 0;

    sessionStorage.setItem("dateValue", [JSON.stringify(value)]);
    // sessionStorage.setItem("dateValue[0]", [JSON.stringify(value[0])]);
    // sessionStorage.setItem("dateValue[1]", [JSON.stringify(value[1])]);
    sessionStorage.setItem("dateString", JSON.stringify(dateString));

    dateString = dateString?.toString().split(",");
    this.setState({ dateRangeList: value }); //[dateString.toString()]
    if (dateString[0] != "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        this.state.selectedGroup
      }"]}]}`;
      console.log("dateRangeGroupAssignment", dateRangeGroupAssignment);
    } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`;
    } else {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    }
    let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        console.log("assignmentList", assignmentList);
        this.setState({ assignmentList: assignmentList });
        if (assignmentList.length > 0) {
          console.log("inside1 if");
          if (this.state.selectedGroup[0].toString() == "0") {
            console.log("inside 2 if");
            this.setState({ selectedAssignment: ["0"] });
          } else {
            console.log("inside else");
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            console.log("selectedAsignment");
            this.setState({ selectedAssignment: ["0"] });
          }
        } else {
          console.log("inside else laast");
          this.setState({ selectedAssignment: ["0"], assignmentList: null });
        }
      })
      .catch((error) => {});
  };

  // changeDateRangeOnLoading = (value, dateString) => {
  //   console.log("inside changeDateRangeOnLoading", value);

  //   this.state.ifAdaptive = 0;
  //   dateString = dateString?.toString().split(",");
  //   this.setState({ dateRangeList: value }); //[dateString.toString()]

  //   console.log("dateRangeList", this.state.dateRangeList);

  //   // let value = [sessionStorage.getItem("dateOptimized[[0]"),sessionStorage.getItem("dateOptimized[[1]")]
  //   // this.setState({ dateRangeList: value }); //[dateString.toString()]

  //   // console.log("dateRangeList", this.state.dateRangeList);
  //   // console.log("dateRangeList", value);

  //   if (dateString[0] != "" && this.state.selectedGroup != "0") {
  //     // console.log("inside if states", [
  //     //   this.state.selectedSchool,
  //     //   this.state.selectedClass,
  //     //   this.state.selectedGroup,
  //     // ]);

  //     var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${
  //       value[0]
  //     }","${
  //       value[1]
  //     }"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedSchool")) ||
  //       this.state.selectedSchool
  //     }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedClass")) ||
  //       this.state.selectedClass
  //     }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedGroup")) ||
  //       this.state.selectedGroup
  //     }"]}]}`;
  //     console.log("dateRangeGroupAssignment", dateRangeGroupAssignment);
  //   } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
  //     var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${
  //       value[0]
  //     }","${
  //       value[1]
  //     }"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedSchool")) ||
  //       this.state.selectedSchool
  //     }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedClass")) ||
  //       this.state.selectedClass
  //     }"]}]}`;
  //   } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
  //     var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedSchool")) ||
  //       this.state.selectedSchool
  //     }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedClass")) ||
  //       this.state.selectedClass
  //     }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedGroup")) ||
  //       this.state.selectedGroup
  //     }"]}]}`;
  //   } else {
  //     var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedSchool")) ||
  //       this.state.selectedSchool
  //     }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
  //       JSON.parse(sessionStorage.getItem("selectedClass")) ||
  //       this.state.selectedClass
  //     }"]}]}`;
  //   }
  //   let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
  //   axios
  //     .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
  //       headers: {
  //         Authorization: `${access_token}`,
  //       },
  //     })
  //     .then((res) => {
  //       const assignmentList = res.data.data;
  //       console.log("assignmentList", assignmentList);
  //       this.setState({ assignmentList: assignmentList });
  //       if (assignmentList.length > 0) {
  //         console.log("inside1 if");
  //         if (this.state.selectedGroup[0].toString() == "0") {
  //           console.log("inside 2 if");
  //           this.setState({ selectedAssignment: ["0"] });
  //         } else {
  //           console.log("inside else");
  //           const assignmentListOnLoad = [];
  //           assignmentList &&
  //             assignmentList.map((x) => {
  //               assignmentListOnLoad.push(
  //                 x["TmSchoolHomework.assignmentId"].toString()
  //               );
  //             });
  //           console.log("selectedAsignment");
  //           this.setState({ selectedAssignment: ["0"] });
  //         }
  //       } else {
  //         console.log("inside else last");
  //         this.setState({ selectedAssignment: ["0"], assignmentList: null });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value == "0") {
      this.setState({
        selectedAssignment: ["0"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
      });
      this.state.selectedAssignment = [value.value.toString()];
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }

      this.state.selectedAssignmentLabel = value.label;
    }
  };
  renderRedirect = () => {
    return <Redirect to="/home" />;
  };

  componentDidMount() {
    let selectedSchoolDetails = JSON.parse(
      sessionStorage.getItem("selectedSchoolDetails")
    );
    let selectedClassDetails = JSON.parse(
      sessionStorage.getItem("selectedClassDetails")
    );
    let selectedGroupDetails = JSON.parse(
      sessionStorage.getItem("selectedGroupDetails")
    );
    let selectedUserPlan = sessionStorage.getItem("selectedUserPlan");
    let value = null;

/*     this.setState({studentPlan:""}); */

    // let dateValue = JSON.parse(sessionStorage.getItem("dateValue"));
    // let cutDate = sessionStorage.getItem("dateValue[0]");
    // this.setState({
    //   dateRangeList: sessionStorage.getItem("dateValue[1]")
    // })

    // cutDate &&
    //   sessionStorage.setItem(
    //     "dateOptimized[0]",
    //     JSON.parse(sessionStorage.getItem("dateValue[0]")).split("T").shift()
    //   );
    // cutDate &&
    //   sessionStorage.setItem(
    //     "dateOptimized[1]",
    //     JSON.parse(sessionStorage.getItem("dateValue[1]")).split("T").shift()
    //   );
    // let dateString = JSON.parse(sessionStorage.getItem("dateString"));
    // let dateValArray = dateValue.map((item) => {
    //   return item.split("T").shift();
    // });
    // sessionStorage.setItem("dateValArray[0]", dateValArray[0]);
    // sessionStorage.setItem("dateValArray[1]", dateValArray[1]);
    // // let valueTwo = dateValue[1].split("T").shift() || "";
    // // let dateValArray = [valueOne, valueTwo];
    // console.log("dateValue", dateValArray);

    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isAssignmentTrue = false;
    this.setState({ loginUser: localStorage.getItem("username") });
    let loginUserName = localStorage.getItem("user");
    let loginUserId = "";
    loginUserId = loginUserName;

    let access_token = cookie?.get("token");
    {
      selectedSchoolDetails &&
        this.changeSchoolOnLoading(selectedSchoolDetails);
    }
    {
      selectedClassDetails && this.changeClassOnLoading(selectedClassDetails);
    }
    {
      selectedGroupDetails && this.changeGroupOnLoading(selectedGroupDetails);
    }
    {
      selectedUserPlan && this.ChangeUserPlanOnLoading(value, selectedUserPlan);
    }
    // {
    //   dateValArray &&
    //     dateString &&
    //     this.changeDateRangeOnLoading(dateValArray, dateString);
    // }


    /* ************************* Added to set username when teacher admin logs in **************************** */
    axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmProfiles.shortname"],"timeDimensions":[],"order":{"TmProfiles.shortname":"asc"},"filters":[{"member":"TmProfiles.userId","operator":"equals","values":["${loginUserId}"]}]}`,{
      headers: {
        'Authorization': `${access_token}`
      }
    })
      .then(res => {
        isSchoolTrue=true;
        const loginUser=res.data.data[0]["TmProfiles.shortname"];
        this.setState({loginUser:loginUser})
        localStorage.setItem('username',loginUser)
        
    }).
    catch(error => {
    });
    /* ******************************************************************************************************** */

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmSchool.schoolName","asc"]],"dimensions":["TmSchool.schoolId","TmSchool.schoolName"],"segments":[],"filters":[]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        isSchoolTrue = true;
        const schoolList = res.data.data;
        let schoolId = "";
        let schoolName = "";
        let schoolNameArray = [];
        if (this.props?.match?.params?.schoolId) {
          schoolId = this.props?.match?.params?.schoolId;
          schoolList &&
            schoolList.map((x) => {
              if (x["TmSchool.schoolId"] == schoolId) {
                schoolNameArray.push(x["TmSchool.schoolName"]);
              }
            });
          schoolName = schoolNameArray.toString();
        } else {
          schoolId =
            sessionStorage.getItem("selectedSchoolID") ||
            schoolList[0]["TmSchool.schoolId"];
          schoolName =
            sessionStorage.getItem("selectedSchoolValue") ||
            schoolList[0]["TmSchool.schoolName"];
        }

        // Assign School list
        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });
        if (this.props?.match?.params?.schoolId) {
          this.setState({
            selectedSchoolID: this.props?.match?.params?.schoolId.toString(),
            selectedSchoolValue: schoolName.toString(),
          });
        } else {
          this.setState({
            selectedSchoolID: schoolId.toString(),
            selectedSchoolValue: schoolName.toString(),
          });
        }
        /*************** Class Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query=
        {"measures":[],"timeDimensions":[],"order":[["TmStandard.standardId","asc"]],"dimensions":["TmStandard.standardId","TmStandard.className"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${schoolId}"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            isClassTrue = true;
            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStandard.standardId"] != null) {
                  classList.push(x);
                }
              });

            this.setState({ classList: classList });

            let classListOnLoad = [];
            let classValue = [];
            classList &&
              classList.map((x) => {
                if (x["TmStandard.standardId"] != undefined) {
                  classListOnLoad.push(x["TmStandard.standardId"]?.toString());
                }
              });
            classList &&
              classList.map((x) => {
                if (
                  x["TmStandard.standardId"] == classListOnLoad[0].toString()
                ) {
                  classValue.push(x["TmStandard.className"]?.toString());
                }
              });
            this.setState({
              selectedClassValue:
                JSON.parse(sessionStorage.getItem("selectedClass")) ||
                classValue[0].toString(),
            });
            //changed above this

            // if (this.props?.match?.params?.standardId) {
            //   let classId=this.props?.match?.params?.standardId.toString()
            // let className=[];
            // let classValue=[];
            // classList && classList.map((x) => {
            //   if ((x)["TmStandard.standardId"] == this.props?.match?.params?.standardId.toString()) {
            //     classValue.push((x)["TmStandard.className"]?.toString());
            //   }
            // })
            // this.setState({
            //   selectedClassID: classId.toString(),
            //   selectedClassValue:classValue[0].toString()
            // });
            // classList && classList.map((x) => {
            //   if ((x)["TmStandard.standardId"] == classId) {
            //     className.push((x)["TmStandard.standardId"]?.toString());
            //   }

            // })
            // classListOnLoad[0]=className;
            // if (this.props?.match?.params?.standardId == "0"){
            // this.state.selectedClass= classId;
            // }
            // else{
            //   this.state.selectedClass= className;

            // }
            // }
            // else {
            this.setState({
              selectedClass: JSON.parse(
                sessionStorage.getItem("selectedClass")
              ) || [classListOnLoad[0]?.toString()],
            });
            // }
            //changed above this

            /*************** Group Section  *************/
            let studentListUrl = "";

            if (
              this.props?.match?.params?.studentId &&
              this.props?.match?.params?.groupIdName == "0"
            ) {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"timeDimensions":[],"order":[["TmStudentCurrent.groupId","asc"]],"measures":[],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.userId","operator":"equals","values":["${this.props?.match?.params?.studentId}"]}]}`;
            } else {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
            }
            axios
              .get(`${studentListUrl}`, {
                headers: {
                  Authorization: `${access_token}`,
                },
              })

              .then((res) => {
                this.setState({
                  loader: true,
                });
                isGroupTrue = true;
                const groupList = res.data.data;

                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList &&
                  groupList.map((x) => {
                    groupListOnLoad.push(
                      x["TmStudentCurrent.groupId"].toString()
                    );
                  });
                this.setState({
                  selectedGroup: JSON.parse(
                    sessionStorage.getItem("selectedGroup")
                  ) || ["0"],
                  selectedGroupID: sessionStorage.getItem("selectedGroup") || [
                    "0",
                  ],
                  selectedGroupValue: sessionStorage.getItem(
                    "selectedGroupValue"
                  ) || ["All"],
                });
                // changed above this
              })
              .catch((error) => {});

            /*************** Assignment Section  *************/
          })
          .catch((error) => {
            this.setState({
              loader: true,
            });
          });

        /*************** Class Section  *************/
      })
      .catch((error) => {
        if (error && error == "Error: Request failed with status code 403") {
          this.setState({ isRedirect403: true, loader: false });
        }
      });
  }

  render() {
    if (this.state.isRedirect403) {
      return <Redirect to="/home" />;
    }
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: this.state.dateRangeList,
      });
    }

    let dateRangeArrayBoxesHome = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayBoxesHome.push({
        dimension: "TmStudentMock.tmStmktkCompletedon",
      });
    } else {
      dateRangeArrayBoxesHome.push({
        dimension: "TmStudentMock.tmStmktkCompletedon",
        dateRange: [this.state.dateRangeList[0], this.state.dateRangeList[1]],
      });
    }

    // sessionStorage.getItem("dateValArray[0]") || this.state.dateRangeList[0].format("YYYY-MM-DD"),
    // sessionStorage.getItem("dateValArray[1]") || this.state.dateRangeList[1].format("YYYY-MM-DD"),

    let dateRangeArrayQuestion = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesAdaptiveScore = [];
    let dateRangeArrayBoxesDigScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestion = [];
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
      });

      dateRangeArrayBoxesDigScore = [];
      dateRangeArrayBoxesDigScore.push({
        dimension: "DiagnosticReport.diag_Publish_Date",
      });
    } else {
      dateRangeArrayQuestion = [];
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesDigScore = [];
      dateRangeArrayBoxesDigScore.push({
        dimension: "DiagnosticReport.diag_Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptive = [];
    let dateRangeArrayBoxesAdaptiveReport = [];
    let dateRangeArrayBoxesDigReport = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });

      dateRangeArrayBoxesAdaptive = [];
      dateRangeArrayBoxesAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });

      dateRangeArrayBoxesAdaptiveReport = [];
      dateRangeArrayBoxesAdaptiveReport.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
      });
      dateRangeArrayBoxesDigReport = [];
      dateRangeArrayBoxesDigReport.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesAdaptive = [];
      dateRangeArrayBoxesAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesAdaptiveReport = [];
      dateRangeArrayBoxesAdaptiveReport.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesDigReport = [];
      dateRangeArrayBoxesDigReport.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dimensionArray = [];
    if (this.state.aggregateBy[0] == "domain") {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy[0] == "chapter") {
      dimensionArray.push("TmHwQnAnsStat.chapterName");
    } else if (this.state.aggregateBy[0] == "topic") {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    } else {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicAdaptive = [];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicAdaptive.push(
        "AdaptiveReport.Domain_Name",
        "AdaptiveReport.Domain_result"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    if (this.state.viewBy[0] == "skill") {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy[0] == "proficiency") {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");
    } else {
    }

    let filter = [];
    if (this.state.selectedSchool.length >= 1) {
      filter.push({
        member: "TmSchool.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass.length > 0) {
      filter.push({
        member: "TmStandard.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup.length > 0) {
      filter.push({
        member: "TmSchoolGroups.group",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    let chartHomeFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartHomeFilter.push({
        member: "TmStudentMock.tmStmktkSchoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartHomeFilter.push({
        member: "TmStudentMock.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartHomeFilter.push({
        member: "TmStudentMock.tmStmktkGroupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    /* *************** Chapter Summary Report ************** */
    let dateRangeArrayChapterSummary = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      if (this.state.selectedUserPlan[0]?.toString() != "0") {
        if (this.state.dateRangeList[0] == "") {
          dateRangeArrayChapterSummary.push({
            dimension: "TmChapterSummary.completedDate",
          });
        } else {
          dateRangeArrayChapterSummary.push({
            dimension: "TmChapterSummary.completedDate",
            dateRange: [
              this.state.dateRangeList[0].format("YYYY-MM-DD"),
              this.state.dateRangeList[1].format("YYYY-MM-DD"),
            ],
          });
        }
      }
    } else {
      if (this.state.selectedUserPlan[0]?.toString() != "0") {
        if (this.state.dateRangeList[0] == "") {
          dateRangeArrayChapterSummary.push({
            dimension: "TmChapterSummaryNoGrp.completedDate",
          });
        } else {
          dateRangeArrayChapterSummary.push({
            dimension: "TmChapterSummaryNoGrp.completedDate",
            dateRange: [
              this.state.dateRangeList[0].format("YYYY-MM-DD"),
              this.state.dateRangeList[1].format("YYYY-MM-DD"),
            ],
          });
        }
      }
    }

    let chapterSummaryReportFilter = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      if (this.state.selectedSchool[0]?.toString() != "0") {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummary.schoolId",
          operator: "equals",
          values: [this.state.selectedSchool[0]?.toString()],
        });
      }

      if (this.state.selectedClass[0]?.toString() != "0") {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummary.gradeId",
          operator: "equals",
          values: this.state.selectedClass,
        });
      }
      if (this.state.selectedGroup[0]?.toString() != "0" ) {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummary.groupId",
          operator: "equals",
          values: this.state.selectedGroup,
        });
      }
      if (this.state.selectedUserPlan[0]?.toString() == "0" ) {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummary.planStatus",
          operator: "equals",
          values: [this.state.selectedUserPlan[0]?.toString()],
        });
      }
      chapterSummaryReportFilter.push({
        member: "TmChapterSummary.attemptedReportCount",
        operator: "gt",
        values: ["0"],
      });
    } else {
      if (this.state.selectedSchool[0]?.toString() != "0") {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummaryNoGrp.schoolId",
          operator: "equals",
          values: [this.state.selectedSchool[0]?.toString()],
        });
      }

      if (this.state.selectedClass[0]?.toString() != "0") {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummaryNoGrp.gradeId",
          operator: "equals",
          values: this.state.selectedClass,
        });
      }
     /*  if (this.state.selectedGroup[0]?.toString() != "0") {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummaryNoGrp.groupId",
          operator: "equals",
          values: this.state.selectedGroup,
        });
      } */
      if (this.state.selectedUserPlan[0]?.toString() == "0") {
        chapterSummaryReportFilter.push({
          member: "TmChapterSummaryNoGrp.planStatus",
          operator: "equals",
          values: [this.state.selectedUserPlan[0]?.toString()],
        });
      }
      chapterSummaryReportFilter.push({
        member: "TmChapterSummaryNoGrp.attemptedReportCount",
        operator: "gt",
        values: ["0"],
      });
    }

    /* *************** Chapter Summary Report ************** */

    /* *************** Chapter Report ************** */
    let dateRangeArrayChapterReport = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      if (this.state.selectedUserPlan[0]?.toString() != "0") {
        if (this.state.dateRangeList[0] == "") {
          dateRangeArrayChapterReport.push({
            dimension: "TmAllChapterList.completedDate",
          });
        } else {
          dateRangeArrayChapterReport.push({
            dimension: "TmAllChapterList.completedDate",
            dateRange: [
              this.state.dateRangeList[0].format("YYYY-MM-DD"),
              this.state.dateRangeList[1].format("YYYY-MM-DD"),
            ],
          });
        }
      }
    } else {
      if (this.state.selectedUserPlan[0]?.toString() != "0") {
        if (this.state.dateRangeList[0] == "") {
          dateRangeArrayChapterReport.push({
            dimension: "TmAllChapterListNoGroup.completedDate",
          });
        } else {
          dateRangeArrayChapterReport.push({
            dimension: "TmAllChapterListNoGroup.completedDate",
            dateRange: [
              this.state.dateRangeList[0].format("YYYY-MM-DD"),
              this.state.dateRangeList[1].format("YYYY-MM-DD"),
            ],
          });
        }
      }
    }

    let chapterReportFilter = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      if (this.state.selectedSchool[0]?.toString() != "0") {
        chapterReportFilter.push({
          member: "TmAllChapterList.schoolId",
          operator: "equals",
          values: [this.state.selectedSchool[0]?.toString()],
        });
      }

      if (this.state.selectedClass[0]?.toString() != "0") {
        chapterReportFilter.push({
          member: "TmAllChapterList.gradeId",
          operator: "equals",
          values: this.state.selectedClass,
        });
      }
      if (this.state.selectedGroup[0]?.toString() != "0") {
        chapterReportFilter.push({
          member: "TmAllChapterList.groupId",
          operator: "equals",
          values: this.state.selectedGroup,
        });
      }
      if (this.state.selectedUserPlan[0]?.toString() == "0") {
        chapterReportFilter.push({
          member: "TmAllChapterList.planStatus",
          operator: "equals",
          values: [this.state.selectedUserPlan[0]?.toString()],
        });
      }
    } else {
      if (this.state.selectedSchool[0]?.toString() != "0") {
        chapterReportFilter.push({
          member: "TmAllChapterListNoGroup.schoolId",
          operator: "equals",
          values: [this.state.selectedSchool[0]?.toString()],
        });
      }

      if (this.state.selectedClass[0]?.toString() != "0" && this.state.selectedClass[0]?.toString() != "") {
        chapterReportFilter.push({
          member: "TmAllChapterListNoGroup.gradeId",
          operator: "equals",
          values: this.state.selectedClass,
        });
      }
      /*   if (this.state.selectedGroup[0]?.toString() != "0") {
        chapterReportFilter.push({
          member: "TmAllChapterListNoGroup.groupId",
          operator: "equals",
          values: this.state.selectedGroup,
        });
      } */
      if (this.state.selectedUserPlan[0]?.toString() == "0") {
        chapterReportFilter.push({
          member: "TmAllChapterListNoGroup.planStatus",
          operator: "equals",
          values: [this.state.selectedUserPlan[0]?.toString()],
        });
      }
    }

    /* *************** Chapter Report ************** */

    /* *************** Student Overview Filter ************** */
    let dateRangeArrayStudentOverview = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      if (this.state.selectedUserPlan[0]?.toString() != "0") {
        if (this.state.dateRangeList[0] == "") {
          dateRangeArrayStudentOverview.push({
            dimension: "TmStudentOverview.completedDate",
          });
        } else {
          dateRangeArrayStudentOverview.push({
            dimension: "TmStudentOverview.completedDate",
            dateRange: [
              this.state.dateRangeList[0].format("YYYY-MM-DD"),
              this.state.dateRangeList[1].format("YYYY-MM-DD"),
            ],
          });
        }
      }
    } else {
      if (this.state.selectedUserPlan[0]?.toString() != "0") {
        if (this.state.dateRangeList[0] == "") {
          dateRangeArrayStudentOverview.push({
            dimension: "TmStudentOverviewNoGroup.completedDate",
          });
        } else {
          dateRangeArrayStudentOverview.push({
            dimension: "TmStudentOverviewNoGroup.completedDate",
            dateRange: [
              this.state.dateRangeList[0].format("YYYY-MM-DD"),
              this.state.dateRangeList[1].format("YYYY-MM-DD"),
            ],
          });
        }
      }
    }

    let studentOverviewReportFilter = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      if (this.state.selectedSchool[0]?.toString() != "0") {
        studentOverviewReportFilter.push({
          member: "TmStudentOverview.tmStmktkSchoolId",
          operator: "equals",
          values: [this.state.selectedSchool[0]?.toString()],
        });
      }

      if (this.state.selectedClass[0]?.toString() != "0") {
        studentOverviewReportFilter.push({
          member: "TmStudentOverview.tmStmktkStandardId",
          operator: "equals",
          values: this.state.selectedClass,
        });
      }

      if (this.state.selectedGroup[0]?.toString() != "0") {
        studentOverviewReportFilter.push({
          member: "TmStudentOverview.groupId",
          operator: "equals",
          values: this.state.selectedGroup,
        });
      }

      if (this.state.selectedUserPlan[0]?.toString() == "0" ) {
        studentOverviewReportFilter.push({
          member: "TmStudentOverview.planStatus",
          operator: "equals",
          values: [this.state.selectedUserPlan[0]?.toString()],
        });
      }
    } else {
      if (this.state.selectedSchool[0]?.toString() != "0") {
        studentOverviewReportFilter.push({
          member: "TmStudentOverviewNoGroup.tmStmktkSchoolId",
          operator: "equals",
          values: [this.state.selectedSchool[0]?.toString()],
        });
      }

      if (this.state.selectedClass[0]?.toString() != "0" && this.state.selectedClass[0]?.toString() != "") {
        studentOverviewReportFilter.push({
          member: "TmStudentOverviewNoGroup.tmStmktkStandardId",
          operator: "equals",
          values: this.state.selectedClass,
        });
      }

     /*  if (this.state.selectedGroup[0]?.toString() != "0") {
        studentOverviewReportFilter.push({
          member: "TmStudentOverviewNoGroup.groupId",
          operator: "equals",
          values: this.state.selectedGroup,
        });
      } */

      if (this.state.selectedUserPlan[0]?.toString() == "0" ) {
        studentOverviewReportFilter.push({
          member: "TmStudentOverviewNoGroup.planStatus",
          operator: "equals",
          values: [this.state.selectedUserPlan[0]?.toString()],
        });
      }


      studentOverviewReportFilter.push({
        member: "TmStudentOverviewNoGroup.moduleId",
        operator: "gt",
        values: ["0"],
      });

    }

    /* *************** Student Overview Filter ************** */

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptive = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptiveReport = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterDigscore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dimensionDynamic = [
      "TmHwQnAnsStat.proficiency",
      "TmDifficulty.proficiency",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamic.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamic.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamic.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicSkill = [
      "TmHwQnAnsStat.skillName",
      "TmHwQnAnsStat.skillStd",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicSkill.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    /* *************** Chapter Summary ************** */
    let DashboardChapterSummaryItemsTop = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      DashboardChapterSummaryItemsTop = [
        {
          id: 1,
          name: "Summary",
          desc: "This section shows for each proficiency level, how many students have attempted that level, and the % of students who have scored EE, ME, PME & NI performance grades.",
          vizState: {
            query: {
              dimensions: [
                "TmChapterSummary.levelId",
                "TmChapterSummary.level",
              ],
              order: { "TmChapterSummary.levelId": "asc" },
              filters: chapterSummaryReportFilter,
              timeDimensions: dateRangeArrayChapterSummary,
              measures: [
                "TmChapterSummary.userDistCount",
                "TmChapterSummary.exceedReportPercent",
                "TmChapterSummary.meetReportPercent",
                "TmChapterSummary.partialReportPercent",
                "TmChapterSummary.improvementReportPercent",
              ],
            },

            chartType: "chapter_summary_report",
            parameters: [
              { School: this.state.selectedSchoolID },
              { Class: this.state.selectedClass },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
            ],
          },
        },
      ];
    } else {
      DashboardChapterSummaryItemsTop = [
        {
          id: 1,
          name: "Summary",
          desc: "This section shows for each proficiency level, how many students have attempted that level, and the % of students who have scored EE, ME, PME & NI performance grades.",
          vizState: {
            query: {
              dimensions: [
                "TmChapterSummaryNoGrp.levelId",
                "TmChapterSummaryNoGrp.level",
              ],
              order: { "TmChapterSummaryNoGrp.levelId": "asc" },
              filters: chapterSummaryReportFilter,
              timeDimensions: dateRangeArrayChapterSummary,
              measures: [
                "TmChapterSummaryNoGrp.userDistCount",
                "TmChapterSummaryNoGrp.exceedReportPercent",
                "TmChapterSummaryNoGrp.meetReportPercent",
                "TmChapterSummaryNoGrp.partialReportPercent",
                "TmChapterSummaryNoGrp.improvementReportPercent",
              ],
            },

            chartType: "chapter_summary_report",
            parameters: [
              { School: this.state.selectedSchoolID },
              { Class: this.state.selectedClass },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
            ],
          },
        },
      ];
    }

    /* *************** Chapter Summary ************** */
    /* *************** Chapter Report ************** */
    let DashboardChapterItemsTop = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      DashboardChapterItemsTop = [
        {
          id: 1,
          name: "Task Overview",
          desc: "This section shows the number of tasks that are completed by students in each level.",
          vizState: {
            query: {
              dimensions: [
                "TmAllChapterList.categoryId",
                "TmAllChapterList.categoryName",
              ],
              order: { "TmAllChapterList.beginerCompletedCount": "desc" },
              filters: chapterReportFilter,
              timeDimensions: dateRangeArrayChapterReport,
              measures: [
                "TmAllChapterList.introCompletedCount",
                "TmAllChapterList.beginerCompletedCount",
                "TmAllChapterList.intermediateCompletedCount",
                "TmAllChapterList.advancedCompletedCount",
                "TmAllChapterList.averagePerformanceCount",
              ],
            },

            chartType: "chapter_report",
            parameters: [
              { School: this.state.selectedSchoolID },
              { Class: this.state.selectedClass },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
              { StudentPlan: this.state.selectedUserPlan },
            ],
          },
        },
      ];
    } else {

      DashboardChapterItemsTop = [
        {
          id: 1,
          name: "Task Overview",
          desc: "This section shows the number of tasks that are completed by students in each level.",
          vizState: {
            query: {
              dimensions: [
                "TmAllChapterListNoGroup.categoryId",
                "TmAllChapterListNoGroup.categoryName",
              ],
              order: {
                "TmAllChapterListNoGroup.beginerCompletedCount": "desc",
              },
              filters: chapterReportFilter,
              timeDimensions: dateRangeArrayChapterReport,
              measures: [
                "TmAllChapterListNoGroup.introCompletedCount",
                "TmAllChapterListNoGroup.beginerCompletedCount",
                "TmAllChapterListNoGroup.intermediateCompletedCount",
                "TmAllChapterListNoGroup.advancedCompletedCount",
                "TmAllChapterListNoGroup.averagePerformanceCount",
              ],
            },

            chartType: "chapter_report",
            parameters: [
              { School: this.state.selectedSchoolID },
              { Class: this.state.selectedClass },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
              { StudentPlan: this.state.selectedUserPlan },
            ],
          },
        },
      ];
    }

    /* *************** Chapter Report ************** */

    /* *************** Student Overview starts ************** */
    let DashboardStudentItems = [];
    if (this.state.selectedGroup[0]?.toString() != "0" && this.state.selectedGroup[0]?.toString() != "") {
      DashboardStudentItems = [
        {
          id: 1,
          name: "Student Overview",
          desc: "For every student, this section shows the number of tasks that are completed by the student in each level.",
          vizState: {
            query: {
              dimensions: [
                "TmStudentOverview.tmStmktkStudentName",
                "TmStudentOverview.tmStmktkStudentId",
                "TmStudentOverview.tmStmktkStandardId",
              ],
              order: { "TmStudentOverview.introCompletedCount": "desc" },
              filters: studentOverviewReportFilter,
              timeDimensions: dateRangeArrayStudentOverview,
              measures: [
                "TmStudentOverview.introCompletedCount",
                "TmStudentOverview.beginerCompletedCount",
                "TmStudentOverview.intermediateCompletedCount",
                "TmStudentOverview.advancedCompletedCount",
                "TmStudentOverview.averagePerformanceCount",
                "TmStudentOverview.totalPoints",
              ],
            },

            chartType: "student_overview_report",
            parameters: [
              { School: this.state.selectedSchoolID },
              { Class: this.state.selectedClass },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
              { StudentPlan: this.state.selectedUserPlan },
            ],
          },
        },
      ];
    } else {
      DashboardStudentItems = [
        {
          id: 1,
          name: "Student Overview",
          desc: "For every student, this section shows the number of tasks that are completed by the student in each level.",
          vizState: {
            query: {
              dimensions: [
                "TmStudentOverviewNoGroup.tmStmktkStudentName",
                "TmStudentOverviewNoGroup.tmStmktkStudentId",
                "TmStudentOverviewNoGroup.tmStmktkStandardId",
              ],
              order: { "TmStudentOverviewNoGroup.introCompletedCount": "desc" },
              filters: studentOverviewReportFilter,
              timeDimensions: dateRangeArrayStudentOverview,
              measures: [
                "TmStudentOverviewNoGroup.introCompletedCount",
                "TmStudentOverviewNoGroup.beginerCompletedCount",
                "TmStudentOverviewNoGroup.intermediateCompletedCount",
                "TmStudentOverviewNoGroup.advancedCompletedCount",
                "TmStudentOverviewNoGroup.averagePerformanceCount",
                "TmStudentOverviewNoGroup.totalPoints",
              ],
            },

            chartType: "student_overview_report",
            parameters: [
              { School: this.state.selectedSchoolID },
              { Class: this.state.selectedClass },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
              { StudentPlan: this.state.selectedUserPlan },
            ],
          },
        },
      ];
    }

    /* *************** Student Overview ends ************** */

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Task Report",
        vizState: {
          query: {
            dimensions: [
              "TmStudentMock.tmStmktkStudentId",
              "TmStudentMock.tmStmktkStudentName",
              "TmStudentMock.task_points",
            ],
            timeDimensions: dateRangeArrayBoxesHome,
            order: [["TmStudentMock.StmktkCompleted", "desc"]],
            measures: [
              "TmStudentMock.StmktkCompleted",
              "TmStudentMock.taskPending",
            ],
            filters: chartHomeFilter, // filter, //chartFilter,
          },

          chartType: "task_report",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
    ];

    const { collapsed } = this.state;
    const dateFormat = "DD-MM-YYYY";

    let allGroupList = [];
    let groupChildren = [];
    let classChildren = [];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    const cubejsApi = cubejs(cookie?.get("token"), {
      apiUrl: `${homeUrlCube}cubejs-api/v1`,
    });

    return (
      <React.Fragment>
        <CubeProvider cubejsApi={cubejsApi}>
          <Layout className="print_width" style={{ minHeight: "100vh" }}>
            <Sider
              width={230}
              collapsible
              collapsed={collapsed}
              onCollapse={this.onCollapse}
              className="side_menu site-layout-background"
            >
              <Menu theme="dark" mode="inline" className="nav_panel">
                <Menu.Item icon={<SolutionOutlined />} key="2">
                  <Link
                    to="/teacher-usage"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Teachers Usage
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<TeamOutlined />} key="1">
                  <Link to="/" className="sub-menu ant-menu-submenu-active1">
                    Class Usage
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<UserOutlined />}
                  key="3"
                  className="ant-menu-submenu "
                >
                  <Link
                    to="/student"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Individual Student Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FormOutlined />} key="4">
                  <Link
                    to="/assignment"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Assignment Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<MailOutlined />} key="5">
                  <Link
                    to="/send-email"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Send Email
                  </Link>
                </Menu.Item>
     {/*            <Menu.Item icon={<FundProjectionScreenOutlined />} key="6">

                  <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                </Menu.Item> */}
                {localStorage.getItem("userType") == 1 && (
                  <>
                    <Menu.Item icon={<LoginOutlined />} key="7">
                      <Link
                        to="/login-report"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Login Report
                      </Link>
                    </Menu.Item>
                    {/* <Menu.Item icon={<DatabaseOutlined />} key="8">
                      <Link
                        to="/task-data"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Task Report
                      </Link>
                    </Menu.Item> */}
                    <Menu.Item icon={<LineChartOutlined />} key="9">
                      <Link
                        to="/task-analysis"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Task Analysis
                      </Link>
                    </Menu.Item>
                    <Menu.Item icon={<FundOutlined />} key="10">
                      <Link
                        to="/question-analysis"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Question Analysis
                      </Link>
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            <Layout style={{ padding: "0 0px 0px" }}>
              <Grid
                xs={12}
                sm={12}
                lg={12}
                className="header header-customized"
              >
                <nav className="custom-nav">
                  <button
                    className="nav-toggl"
                    onClick={() => {
                      this.setState({ mobNav: !this.state.mobNav });
                      console.log("The button is clicked", this.state.mobNav);
                    }}
                  >
                    <MenuOutlinedIcon
                      className="icon-style-custom"
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "auto",
                        display: "flex",
                      }}
                    />
                  </button>
                  {this.state.mobNav && (
                    <ul className="custom-ul">
                      <Menu theme="dark" mode="inline" className="nav_panel">
                        <Menu.Item icon={<SolutionOutlined />} key="3">
                          <Link
                            to="/teacher-usage"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Teachers Usage
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<TeamOutlined />} key="4">
                          <Link
                            to="/"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Class Usage
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          icon={<UserOutlined />}
                          key="2"
                          className="ant-menu-submenu "
                        >
                          <Link
                            to="/student"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Individual Student Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<FormOutlined />} key="5">
                          <Link
                            to="/assignment"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Assignment Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<MailOutlined />} key="6">
                          <Link
                            to="/send-email"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Send Email
                          </Link>
                        </Menu.Item>
                        {/* <Menu.Item
                          icon={<FundProjectionScreenOutlined />}
                          key="1"
                        >
        
                          <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                        </Menu.Item> */}
                        {localStorage.getItem("userType") == 1 && (
                          <>
                            <Menu.Item icon={<LoginOutlined />} key="7">
                              <Link
                                to="/login-report"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Login Report
                              </Link>
                            </Menu.Item>
                            <Menu.Item icon={<DatabaseOutlined />} key="8">
                              <Link
                                to="/task-data"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Task Report
                              </Link>
                            </Menu.Item>
                            <Menu.Item icon={<LineChartOutlined />} key="9">
                              <Link
                                to="/task-analysis"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Task Analysis
                              </Link>
                            </Menu.Item>
                            <Menu.Item icon={<FundOutlined />} key="10">
                              <Link
                                to="/question-analysis"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Question Analysis
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                      </Menu>
                    </ul>
                  )}
                </nav>
                <Grid
                  xs={24}
                  lg={24}
                  container
                  spacing={0}
                  style={{ padding: 0 }}
                  justify="left"
                  alignItems="flex-start"
                >
                  <Col span={8} className="logo_header" >
                    <img class="logoWidth" src={logo}  />
                  </Col>
                  <Col span={8} className="breadcrumb">
                    <center>
                      <h2>
                        <b>Self Prep Overview</b>
                      </h2>
                    </center>
                  </Col>
                  <Col span={8} className="user_detail">
                    <h5>
                      <p>
                        <span className="c1">Hello!,</span>{" "}
                        <span className="c2">{this.state.loginUser}</span>
                        <br />
                        <div className="none">
                          {this.state.selectedSchoolValue}
                        </div>
                        <Link
                          className="logout_btn"
                          onClick={(event) => {
                            event.preventDefault();
                            signout(() => <Redirect to="/home" />);
                          }}
                        >
                          {" "}
                          Home{" "}
                        </Link>
                      </p>
                    </h5>
                  </Col>
                </Grid>
              </Grid>
              <center>
                <h2>
                  <b>
                    <div
                      style={{
                        display: this.state.display,
                        border: "2px solid black",
                        width: "30%",
                        height: "100px",
                        marginTop: "30px",
                        paddingTop: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      No Records Found
                    </div>
                  </b>
                </h2>
              </center>
              <Content
                className="ant-layout-content site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {this.state.display == "none"
                  ? !this.state.loader && <Spin />
                  : this.setState({ display: "block" })}

                {this.state.loader && (
                  <Dashboard>
                    <Grid
                      xs={10}
                      sm={10}
                      lg={10}
                      className="header printme-extra-content"
                    >
                      <Grid
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col span={8} className="logo_header_print">
                          <img class="logoWidth" src={logo} style={{paddingLeft:"40px"}} />
                        </Col>
                        <Col span={8} className="breadcrumb_print">
                          <center>
                            <h2>
                              <b>Self Prep Overview</b>
                            </h2>
                          </center>
                        </Col>
                        <Col span={8} className="user_detail_print">
                          <h5>
                            <p>
                              <span className="c1">School:</span>{" "}
                              <span className="c2">
                                {this.state.selectedSchoolValue}
                              </span>
                              <br />
                              <span className="c1">Class:</span>{" "}
                              <span className="c2">
                                {this.state.selectedClassValue}
                              </span>
                              <br />
                              <span className="c1">Group:</span>{" "}
                              <span className="c2">
                                {this.state.selectedGroupValue == "0"
                                  ? "All"
                                  : this.state.selectedGroupValue}
                              </span>
                              <br />
                              {this.state.selectedUserPlan == 0 ? (
                                <span>
                                  <span className="c1">
                                    Current Students/Date Range:
                                  </span>
                                  <span className="c2">Current Students</span>
                                  <br />
                                </span>
                              ) : (
                                ""
                              )}
                              {this.state.selectedUserPlan != 0 && (
                                <div>
                                  <span className="c1">Date Range:</span>{" "}
                                  <span className="c2">
                                    {this.state.dateRangeList[0].format(
                                      "DD-MM-YYYY"
                                    )}{" "}
                                    To{" "}
                                    {this.state.dateRangeList[1].format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                  <br />
                                </div>
                              )}
                            </p>
                          </h5>
                        </Col>
                      </Grid>
                    </Grid>

                    {/* *************************** New Chapter report starts here**************************** */}
                    <Grid
                      xs={10}
                      sm={10}
                      lg={10}
                      className="mt430"
                      //className="grid-wrapper-cust"
                    >
                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardChapterSummaryItemsTop[0].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem
                            title={DashboardChapterSummaryItemsTop[0].name}
                          >
                            <p>{DashboardChapterSummaryItemsTop[0].desc}</p>
                            <div className="labels-wrapr">
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#b4d3b2" }}>
                                    EE
                                  </span>
                                  Exceeds Expectation (71% - 100%)
                                </p>
                                <p>
                                  <span style={{ background: "#daf0f7" }}>
                                    ME
                                  </span>
                                  Meets Expectation (51% - 70%)
                                </p>
                              </div>
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#ece5c7" }}>
                                    PME
                                  </span>
                                  Partially Meets Expectation (33% - 50%)
                                </p>
                                <p>
                                  <span style={{ background: "#ffe3e1" }}>
                                    NI
                                  </span>
                                  Needs Improvement (0% - 32%)
                                </p>
                              </div>
                            </div>
                            <div
                              className="table-designed"
                              style={{ width: "70%", margin: "auto" }}
                            >
                              <ChartRendererCustomised
                                vizState={
                                  DashboardChapterSummaryItemsTop[0].vizState
                                }
                              />
                            </div>
                          </DashboardItem>
                        </Col>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={2}
                      sm={2}
                      lg={2}
                      className="print_width_filter print_filters"
                    >
                      <Grid
                        container
                        justify="left"
                        alignItems="flex-start"
                        id="filterContainer-title"
                      >
                        <div
                          className="filter-bg white-bg print_btn_print"
                          style={{ float: "center" }}
                        >
                          <ReactToPrint
                            trigger={() => (
                              <button className="print_btn_web">
                                <PrinterFilled />
                                &nbsp;&nbsp;Print
                              </button>
                            )}
                             content={() => window.print()}
                          />
                        </div>

                        
                        <Link to={`/task-data`}  target="_blank" >
                            <button className="export_btn_web">Export to CSV</button>
                        </Link>
                        <DashboardItem
                          title="Filters"
                          className="ant-card-body "
                        >
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> School Name </strong>{" "}
                              </label>
                              <schoolValue
                                data={this.state.selectedSchool}
                              ></schoolValue>
                              <Select
                                showSearch
                                defaultActiveFirstOption={true}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select school name"
                                optionFilterProp="children"
                                onChange={(value, option) =>
                                  this.onChangeSchool(option)
                                }
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedSchoolID?.toString(),
                                }}
                              >
                                {this.state.schoolList.map((x) => {
                                  return (
                                    <Option
                                      key={x["TmSchool.schoolId"]}
                                      value={x["TmSchool.schoolId"]?.toString()}
                                    >
                                      {x["TmSchool.schoolName"]}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Class </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                style={{ width: "100%", paddingTop: 5 }}
                                labelInValue
                                value={{
                                  value:
                                    typeof this.state.selectedClass == "string"
                                      ? this.state.selectedClass
                                      : this.state.selectedClass?.toString(),
                                }}
                                placeholder="Select Class"
                                optionFilterProp="children"
                                onChange={this.onChangeClass}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                if({this.state.selectedClass.length > 0})
                                {this.state.classList &&
                                  this.state.classList.map((x) => {
                                    classChildren.push(
                                      <Option
                                        value={x[
                                          "TmStandard.standardId"
                                        ]?.toString()}
                                      >
                                        {x["TmStandard.className"]}
                                      </Option>
                                    );
                                    allClassList.push(
                                      x["TmStandard.standardId"]
                                    );
                                  })}
                                <Option value="0">All</Option>
                                {classChildren}
                              </Select>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Group </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedGroupValue?.toString(),
                                }}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select Group"
                                optionFilterProp="children"

                                disabled={this.state.selectedUserPlan == 1}
                                onChange={this.onChangeGroup}
                              >
                                if({this.state.selectedGroup.length > 0}){" "}
                                {this.state.groupList &&
                                  this.state.groupList.map((x) => {
                                    groupChildren.push(
                                      <Option
                                        key={x["TmStudentCurrent.groupId"]}
                                      >
                                        {x["TmStudentCurrent.groupName"]}
                                      </Option>
                                    );
                                    allGroupList.push(
                                      x["TmStudentCurrent.groupId"]
                                    );
                                  })}
                                <Option value="0"> All </Option>
                                {groupChildren}
                              </Select>
                            </div>
                          </Col>

                        {/*   {this.state.selectedGroupValue}==== {this.state.selectedUserPlan} */}
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong>
                                  {" "}
                                  Current Students/Date Range{" "}
                                </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedUserPlan?.toString(),
                                }}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select User Plan"
                                optionFilterProp="children"
                                onChange={this.onChangeUserPlan}
                              >
                                <Option value="0">Current Students</Option>
                                <Option value="1">Date Range</Option>
                              </Select>
                            </div>
                          </Col>

                          {this.state.selectedUserPlan != 0 && (
                            <Col
                              span={24}
                              lg={24}
                              xs={24}
                              sm={24}
                              md={24}
                              className="filterContainer"
                            >
                              <div className="filter-bg color-1 white-bg">
                                <label>
                                  {" "}
                                  <strong> Date Range </strong>{" "}
                                </label>
                                <RangePicker
                                  style={{ top: 5 }}
                                  onChange={this.onDateRangeChange}
                                  value={[
                                    moment(
                                      this.state.dateRangeList[0],
                                      dateFormat
                                    ),
                                    moment(
                                      this.state.dateRangeList[1],
                                      dateFormat
                                    ),
                                  ]}
                                  format={dateFormat}
                                />
                              </div>
                            </Col>
                          )}
                        </DashboardItem>
                      </Grid>
                    </Grid>

                    <Grid xs={10} sm={10} lg={10}>
                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardChapterItemsTop[0].id}
                          id=""
                        >
                          <DashboardItem
                            title={DashboardChapterItemsTop[0].name}
                          >
                            <p>{DashboardChapterItemsTop[0].desc}</p>
                            <ChartRendererCustomised
                              vizState={DashboardChapterItemsTop[0].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={2}
                      sm={2}
                      lg={2}
                      className="print_width_filter print_filters"
                    ></Grid>

                    {/* *************************** New Chapter report ends here**************************** */}

                    {/*   <Grid xs={10} sm={10} lg={10}>
                      <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                        <Col span={24} lg={24} key={DashboardItemsTop[0].id} className={this.state.active === "" ? 'active' : ''} id="" >
                        <DashboardItem title={DashboardItemsTop[0].name}>
                          <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                        </DashboardItem>
                      </Col>
                      </Grid>
                    </Grid> */}

                    {/* ****************************** Student listing ************************************* */}

                    <Grid xs={10} sm={10} lg={10}>
                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardStudentItems[0].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardStudentItems[0].name}>
                            <p>{DashboardStudentItems[0].desc}</p>
                            <ChartRendererCustomised
                              vizState={DashboardStudentItems[0].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      </Grid>
                    </Grid>

                    <Grid
                      xs={2}
                      sm={2}
                      lg={2}
                      className="print_width_filter print_filters"
                    ></Grid>
                  </Dashboard>
                )}
              </Content>
            </Layout>
          </Layout>
        </CubeProvider>
      </React.Fragment>
    );
  }
}

export default CustomerDashboardPage;
