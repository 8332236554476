import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router, Route } from "react-router-dom";
import studentUsage from "./pages/StudentUsage";
import Student from "./pages/Student";
import StudentDetail from "./pages/student-detail";
import TeacherUsage from './pages/TeacherUsage';
import Assignment from './pages/Assignment';
import SendMail from './pages/TabbiemathSendEmail';
import Homemodule from './pages/HomeModule'
import StudentProgressReport from './pages/Student-progress-report'
import PreAuth from './pages/PreAuth';
import Reports from './pages/Report';

import AssignmentTeacher from './pages/AssignmentWithTeacher'
import LoginReport from './pages/admin/LoginReport'
import TaskData from './pages/admin/TaskData'
import TaskAnalysis from './pages/admin/TaskAnalysis'
import QuestionAnalysis from './pages/admin/QuestionAnalysis'
import StudentAttempts from './pages/StudentAttempts';
import progressReport from './pages/admin/progressReport'




ReactDOM.render(

  <Router>
    <App>
      <Route key="index" exact path="/reports/:token?" 
      component={Reports} />
      <Route key="index" exact path="/" component={studentUsage} />
      <Route key="index" exact path="/student/:studentId?/:schoolId?/:standardId?/:groupIdName?/:sdateRange?/:edateRange?/:assignment?/:viewBy?/:aggregatedBy?/:adaptive?" component={Student} />
      <Route key="index" exact path="/student-detail/:jwt" component={StudentDetail} />
      <Route key="index" exact path="/teacher-usage" component={TeacherUsage} />
      <Route key="index" exact path="/send-email" component={SendMail} />
      <Route key="index" exact path="/assignment/:assignmentId?/:schoolId?/:standardId?/:groupIdName?/:teacherId?/:sdateRange?/:edateRange?/:viewBy?/:aggregatedBy?/:adaptive?" component={AssignmentTeacher} />
      <Route key="index" exact path="/individual-assignment/:assignmentId?/:schoolId?/:standardId?/:groupIdName?/:teacherId?/:sdateRange?/:edateRange?/:viewBy?/:aggregatedBy?/:adaptive?" component={AssignmentTeacher} />
      <Route key="index" exact path="/individual-teacher/:assignmentId?/:schoolId?/:standardId?/:groupIdName?/:teacherId?/:sdateRange?/:edateRange?/:viewBy?/:aggregatedBy?/:adaptive?" component={AssignmentTeacher} />
      <Route key="index" exact path="/home" component={PreAuth} />
      <Route key="index" exact path="/home-module" component={Homemodule} />
      <Route key="index" exact path="/progress-report/:studentId?/:schoolId?/:standardId?/:groupIdName?/:sdateRange?/:edateRange?" component={StudentProgressReport} />
      <Route key="index" exact path="/login-report" component={LoginReport} />
      <Route key="index" exact path="/task-data" component={TaskData} />
      <Route key="index" exact path="/task-analysis" component={TaskAnalysis} />
      <Route key="index" exact path="/question-analysis" component={QuestionAnalysis} />
      <Route key="index" exact path="/student-attempts/:categoryId?/:schoolId?/:standardId?/:groupId?/:sdateRange?/:edateRange?" component={StudentAttempts} />
      <Route key="index" exact path="/progress/:jwt/:standardId" component={progressReport} />
    </App>
  </Router>,
  document.getElementById("root")
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
