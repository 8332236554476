import React, { PureComponent } from "react";
import {
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Select,
  Button,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Space,
} from "antd";
import Grid from "@material-ui/core/Grid";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Input } from "antd";
import { Link, useHistory, navigate } from "react-router-dom";
import jwt from "jwt-decode"; // import dependency
import axios from "axios";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  authenticate,
  baseUrlCube,
  baseUrlApp,
  isAuth,
} from "../components/helpers";
import { Redirect } from "react-router";
import {
  UserOutlined,
  LaptopOutlined,
  AppstoreFilled,
  DoubleLeftOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;

const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;

class Report extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateRangeList: ["", ""],
      pageRedirect: false,
      isLoader: true,
    };
  }

  componentDidMount() {

    localStorage.setItem("self-prep", this.props.location.search);
    this.setState({ isLoader: true });
    if (this.props.match.params.token === undefined) {
      this.setState({
        pageRedirect: false,
      });
    } else {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmSchool.count"],"timeDimensions": [],"order": [["TmSchool.schoolName","asc"]],"dimensions": ["TmSchool.schoolId","TmSchool.schoolName"]}`,
          {
            headers: {
              Authorization: `${this.props.match.params.token}`,
            },
          }
        )
        .then((response) => {
          console.log("SIGNIN SUCCESS", response);
          authenticate(this.props.match.params.token, () => {});

          this.setState({
            pageRedirect: true,
            isLoader: false,
          });

      //    window.location.href = `${baseUrlApp}/#/`; // for preprod
        window.location.href = "/#/"; // for dev and live

          let selfPrep = localStorage.getItem("self-prep");
          if (selfPrep) {
            window.location.href = "/#/home-module/";
          }

        })
        .catch((error) => {
          this.setState({
            pageRedirect: false,
          });
          window.location.href = "/";
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoader && (
          <Layout className="contanerSpin">
            <Spin />
          </Layout>
        )}
      </React.Fragment>
    );
  }
}

export default Report;
