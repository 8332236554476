import React, { PureComponent } from "react";
import TreeMap from "react-d3-treemap";
import "react-d3-treemap/dist/react.d3.treemap.css";
import {
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Select,
  Button,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Space,
} from "antd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from "antd";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import { useCubeQuery } from "@cubejs-client/react";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import { Redirect } from "react-router";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  baseUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";
import moment from "moment";
import axios from "axios";
import cookie from "js-cookie";
import ReactToPrint from "react-to-print";
import "antd/dist/antd.css";
import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
  AreaChartOutlined,FilePdfOutlined,   
} from "@ant-design/icons";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;

class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      collapsed: true,
      loader: false,
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ],
      timezone: "America/Los_Angeles",
      schoolList: [],
      selectedSchool: [],
      selectedSchoolID: "",
      selectedClassValue: "",
      selectedClassID: "",
      school: ["19"],
      class: ["7"],
      selectedClass: [""],
      pivotConfig: {},
    };
  }

  printOrder = () => {
    const printableElements = document.getElementById("printme").innerHTML;
    const orderHTML =
      '<html><head><title></title></head><body class="PrintSection">' +
      printableElements +
      "</body></html>";
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage;
  };

  onChangeSchool = (option) => {
    this.setState({
      selectedSchool: [option.value.toString()],
      selectedSchoolID: option.value.toString(),
      selectedSchoolValue: option.children.toString(),
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    let access_token = cookie?.get("token");

    /*************** Class Section  *************/
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStandard.standardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStandard.className"];
        let className = classList[0]["TmStandard.className"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });

        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStandard.standardId"] != undefined) {
              classListOnLoad.push(x["TmStandard.standardId"]?.toString());
            }
          });
        this.setState({ selectedClass: [classListOnLoad[0]] });
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onChangeClass = (option) => {
    this.setState({
      selectedClass: [option.value.toString()],
      selectedClassValue: option.label.toString(),
    });
  };

  onDateRangeChange = (value, dateString) => {
    if (value == null) {
      this.setState({ dateRangeList: value }); //[dateString.toString()]
    } else {
      this.setState({ dateRangeList: value });
    }
  };

  componentDidMount() {
    let access_token = cookie?.get("token");
    this.setState({ loginUser: localStorage.getItem("username") });

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmSchool.count"],"timeDimensions": [],"order": [["TmSchool.schoolName","asc"]],"dimensions": ["TmSchool.schoolId","TmSchool.schoolName"]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const schoolList = res.data.data;
        let schoolId = schoolList[0]["TmSchool.schoolId"];
        let schoolName = schoolList[0]["TmSchool.schoolName"];

        // Assign School list
        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });

        this.setState({
          selectedSchoolID: schoolId.toString(),
          selectedSchoolValue: schoolName.toString(),
        });

        /*************** Class Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${schoolId}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            this.setState({
              loader: true,
            });

            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStandard.standardId"] != null) {
                  classList.push(x);
                }
              });
            let classId = classList[0]["TmStandard.standardId"];
            let className = classList[0]["TmStandard.className"];
            this.setState({ classList: classList });
            this.setState({
              selectedClassID: classId.toString(),
              selectedClassValue: className.toString(),
            });

            let classListOnLoad = [];
            classList &&
              classList.map((x) => {
                if (x["TmStandard.standardId"] != undefined) {
                  classListOnLoad.push(x["TmStandard.standardId"]?.toString());
                }
              });

            this.setState({ selectedClass: [classListOnLoad[0]] });
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  }

  render() {
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }
    let dimensionArray = [];
    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    const DashboardItemsTop = [
      {
        id: 1,
        name: "No of Teachers",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.distinctTeachers"],
            timeDimensions: dateRangeArray,
            order: {},
            filters: chartFilter,
            dimensions: [],
          },
          chartType: "number",
        },
      },
      {
        id: 2,
        name: "Total Assignments",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.count"],
            timeDimensions: dateRangeArray,
            order: {},
            filters: chartFilter,
            dimensions: [],
          },
          chartType: "number",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 3,
        name: "Completed",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.totalTuCompleted"],
            timeDimensions: dateRangeArray,
            order: {},
            filters: chartFilter,
          },
          chartType: "number",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 4,
        name: "Open",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.totalTuOpen"],
            timeDimensions: dateRangeArray,
            order: {},
            filters: chartFilter,
            dimensions: [],
          },
          chartType: "number",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 5,
        name: "Not Marked",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.totalTuNotMarked"],
            timeDimensions: dateRangeArray,
            order: {},
            filters: [
              {
                member: "TmSchoolHomework.schoolId",
                operator: "equals",
                values: this.state.selectedSchool,
              },
              {
                member: "TmSchoolHomework.standardId",
                operator: "equals",
                values: this.state.selectedClass,
              },
            ],
            dimensions: [],
          },
          chartType: "number",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 6,
        name: "Teacher Usage",
        vizState: {
          query: {
            measures: [
              "TmSchoolHomework.totalTuCompleted",
              "TmSchoolHomework.totalTuOpen",
              "TmSchoolHomework.totalTuNotMarked",
            ],
            timeDimensions: dateRangeArray,
            order: [["TmSchoolHomework.teacher", "asc"]],
            dimensions: ["TmSchoolHomework.teacher"],
            filters: chartFilter,
          },
          chartType: "bar",
        },
      },
      {
        id: 7,
        name: "Teacher Usage Report",
        vizState: {
          query: {
            measures: [
              "TmSchoolHomework.totalTuCompleted",
              "TmSchoolHomework.totalTuNotMarked",
              "TmSchoolHomework.totalTuOpen",
              "TmSchoolHomework.count",
            ],
            timeDimensions: dateRangeArray,
            order: [["TmSchoolHomework.teacher", "asc"]],
            dimensions: [
              "TmSchoolHomework.teacherId",
              "TmSchoolHomework.teacher",
            ],
            filters: chartFilter,
          },
          chartType: "table_teacher",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Daterange: this.state.dateRangeList },
          ],
          pivotConfig: this.state.pivotConfig,
        },
      },
    ];

    const dashboardItemTop = (item) => (
      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "24px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>
    );
    const { collapsed } = this.state;
    let selectedSchoolID = this.state.selectedSchool[0];
    let selectedClassID = this.state.selectedClass[0];
    var classOption = [];
    const dateFormat = "DD-MM-YYYY";
    let classChildren = [];
    let allClassList = [];

    return (
      <React.Fragment>
        <Layout className="print_width" style={{ minHeight: "100vh" }}>
          <Sider
            width={230}
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            className="side_menu site-layout-background"
          >
            <Menu theme="dark" mode="inline" className="nav_panel">
         
                        {localStorage.getItem("reportType") == 1 && (
                                <>
                                    <Menu.Item icon={<SolutionOutlined />} key="1">
                                            <Link
                                              to="/teacher-usage"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Teachers Usage
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<TeamOutlined />} key="2">
                                            <Link to="/" className="sub-menu ant-menu-submenu-active1">
                                              Class Usage
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item
                                            icon={<UserOutlined />}
                                            key="3"
                                            className="ant-menu-submenu "
                                          >
                                            <Link
                                              to="/student"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Individual Student Report
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<FormOutlined />} key="4">
                                            <Link
                                              to="/assignment"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Assignment Report
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<MailOutlined />} key="5">
                                            <Link
                                              to="/send-email"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Send Email
                                            </Link>
                                          </Menu.Item>
                                {/*           <Menu.Item icon={<FundProjectionScreenOutlined />} key="6">
                                            <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                                          </Menu.Item> */}
                                          
                              </>
                        )}


      

          {localStorage.getItem("reportType") == 3 && (
              <>
                    <Menu.Item icon={<DatabaseOutlined   />} key="1">
                      <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                    </Menu.Item>

                    <Menu.Item icon={<FileDoneOutlined   />} key="2">
                      <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                    </Menu.Item>

                    <Menu.Item icon={<AreaChartOutlined />} key="3">
                      <a href={`${newReportUrl}/progress-report`}>Progress</a>
                    </Menu.Item>

                    <Menu.Item icon={<SolutionOutlined  />} key="4">
                      <Link
                        to="/teacher-usage"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Teacher Usage
                      </Link>
                    </Menu.Item>



                    <Menu.Item icon={<MailOutlined   />} key="5">
                      <Link
                        to="/send-email"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Mail
                      </Link>
                    </Menu.Item>

                    
                    <Menu.Item
                      icon={<FundProjectionScreenOutlined />}
                      key="6"
                    >
                      <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                    </Menu.Item>

                    {localStorage.getItem("userType") == 1 && (
                        <>
                          <Menu.Item icon={<LoginOutlined />} key="7">
                            <Link
                              to="/login-report"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Login Report
                            </Link>
                          </Menu.Item>
                          <Menu.Item icon={<LineChartOutlined />} key="9">
                            <Link
                              to="/task-analysis"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Task Analysis
                            </Link>
                          </Menu.Item>
                          <Menu.Item icon={<FundOutlined />} key="10">
                            <Link
                              to="/question-analysis"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Question Analysis
                            </Link>
                          </Menu.Item>
                        </>
                      )}
              </>
          )}


            </Menu>
          </Sider>
          <Layout style={{ padding: "0 0px 0px" }}>
            <Grid xs={12} sm={12} lg={12} className="header header-customized">
              <nav className="custom-nav">
                <button
                  className="nav-toggl"
                  onClick={() => {
                    this.setState({ mobNav: !this.state.mobNav });
                    console.log("The button is clicked", this.state.mobNav);
                  }}
                >
                  <MenuOutlinedIcon
                    className="icon-style-custom"
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "auto",
                      display: "flex",
                    }}
                  />
                </button>
                {this.state.mobNav && (
                  <ul className="custom-ul">
                    <Menu theme="dark" mode="inline" className="nav_panel">
                    {localStorage.getItem("reportType") == 1 && (
                          <>
                              <Menu.Item icon={<SolutionOutlined />} key="3">
                                <Link
                                  to="/teacher-usage"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Teachers Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<TeamOutlined />} key="4">
                                <Link
                                  to="/"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Class Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<UserOutlined />}
                                key="2"
                                className="ant-menu-submenu "
                              >
                                <Link
                                  to="/student"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Individual Student Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<FormOutlined />} key="5">
                                <Link
                                  to="/assignment"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Assignment Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<MailOutlined />} key="6">
                                <Link
                                  to="/send-email"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Send Email
                                </Link>
                              </Menu.Item>

                          </>
                      )}

                      {localStorage.getItem("reportType") == 3 && (
                              <>

                                  <Menu.Item icon={<DatabaseOutlined   />} key="1">
                                    <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<FileDoneOutlined   />} key="2">
                                    <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<AreaChartOutlined />} key="3">
                                    <a href={`${newReportUrl}/progress-report`}>Progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<SolutionOutlined  />} key="4">
                                    <Link
                                      to="/teacher-usage"
                                      className="sub-menu ant-menu-submenu-active1"
                                    >
                                      Teacher Usage
                                    </Link>
                                  </Menu.Item>



                                  <Menu.Item icon={<MailOutlined   />} key="5">
                                    <Link
                                      to="/send-email"
                                      className="sub-menu ant-menu-submenu-active1"
                                    >
                                      Mail
                                    </Link>
                                  </Menu.Item>


                                  <Menu.Item
                                    icon={<FundProjectionScreenOutlined />}
                                    key="6"
                                  >
                                    <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                                  </Menu.Item>


                                      {localStorage.getItem("userType") == 1 && (
                                        <>
                                          <Menu.Item icon={<LoginOutlined />} key="7">
                                            <Link
                                              to="/login-report"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Login Report
                                            </Link>
                                          </Menu.Item>
                      
                                          <Menu.Item icon={<LineChartOutlined />} key="9">
                                            <Link
                                              to="/task-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Task Analysis
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<FundOutlined />} key="10">
                                            <Link
                                              to="/question-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Question Analysis
                                            </Link>
                                          </Menu.Item>
                                        </>
                                      )}
                            </>
                        )}
                    </Menu>
                  </ul>
                )}
              </nav>
              <Grid
                xs={24}
                lg={24}
                container
                spacing={0}
                style={{ padding: 0 }}
                justify="left"
                alignItems="flex-start"
              >
                <Col span={8} className="logo_header">
                  <img class="logoWidth" src={logo} />
                </Col>
                <Col span={8} className="breadcrumb">
                  <center>
                    <h2>
                      <b>Teachers Usage Report</b>
                    </h2>
                  </center>
                </Col>
                <Col span={8} className="user_detail">
                  <h5>
                    <p>
                      <span className="c1">Hello!,</span>{" "}
                      <span className="c2">{this.state.loginUser} </span>
                      <br />
                      {this.state.selectedSchoolValue} <br />
                      <Link
                        className="logout_btn"
                        onClick={(event) => {
                          event.preventDefault();
                          signout(() => <Redirect to="/home" />);
                        }}
                      >
                        {" "}
                        Home{" "}
                      </Link>
                    </p>
                  </h5>
                </Col>
              </Grid>
            </Grid>

            <center>
              <h2>
                <b>
                  <div
                    style={{
                      display: this.state.display,
                      border: "2px solid black",
                      width: "30%",
                      height: "100px",
                      marginTop: "30px",
                      paddingTop: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    One of the Data not provided
                  </div>
                </b>
              </h2>
            </center>
            <Content
              className="ant-layout-content site-layout-background"
              id="printme"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {this.state.display == "none"
                ? !this.state.loader && <Spin />
                : this.setState({ display: "block" })}
              {this.state.loader && (
                <Dashboard>
                  <Grid
                    xs={10}
                    sm={10}
                    lg={10}
                    className="header printme-extra-content"
                  >
                    <Grid
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col span={8} className="logo_header_print">
                        <img class="logoWidth" src={logo} />
                      </Col>
                      <Col span={8} className="breadcrumb_print">
                        <center>
                          <h2>
                            <b>Teachers Usage Report</b>
                          </h2>
                        </center>
                      </Col>
                      <Col span={8} className="user_detail_print">
                        <h5>
                          <p>
                            <span className="c1">School:</span>{" "}
                            <span className="c2">
                              {this.state.selectedSchoolValue}
                            </span>
                            <br />
                            <span className="c1">Class:</span>{" "}
                            <span className="c2">
                              {this.state.selectedClassValue}
                            </span>
                            <br />
                            <span className="c1">Date Range:</span>{" "}
                            <span className="c2">
                              {this.state.dateRangeList[0].format("DD-MM-YYYY")}{" "}
                              To{" "}
                              {this.state.dateRangeList[1].format("DD-MM-YYYY")}
                            </span>
                            <br />
                          </p>
                        </h5>
                      </Col>
                    </Grid>
                  </Grid>
                  <Grid xs={10} sm={10} lg={10}>
                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={5}
                        key={DashboardItemsTop[0].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[0].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[0].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      <Col
                        span={5}
                        key={DashboardItemsTop[1].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[1].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[1].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      <Col
                        span={5}
                        key={DashboardItemsTop[2].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[2].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[2].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      <Col
                        span={5}
                        key={DashboardItemsTop[3].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[3].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[3].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      <Col
                        span={4}
                        key={DashboardItemsTop[4].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[4].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[4].vizState}
                          />
                        </DashboardItem>
                      </Col>
                    </Grid>
                    <br />
                    <Grid
                      className="top-widgets top-widgets2"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={24}
                        lg={24}
                        key={DashboardItemsTop[5].id}
                        className={this.state.active === "" ? "active" : ""}
                        id=""
                        onClick={this.onClickDashboardTop}
                      >
                        <DashboardItem title={DashboardItemsTop[5].name}>
                          <ChartRenderer
                            id="charts"
                            ref={(chart) => (this.chartInstance = chart)}
                            vizState={DashboardItemsTop[5].vizState}
                          />
                        </DashboardItem>
                      </Col>
                    </Grid>
                    <br />
                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={24}
                        lg={24}
                        key={DashboardItemsTop[6].id}
                        className={this.state.active === "" ? "active" : ""}
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[6].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[6].vizState}
                          />
                        </DashboardItem>
                      </Col>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={2}
                    sm={2}
                    lg={2}
                    className="print_width_filter print_filters print_width_filter-cust2"
                  >
                    <Grid
                      container
                      justify="left"
                      alignItems="flex-start"
                      id="filterContainer-title"
                    >
                      <div
                        className="filter-bg white-bg print_btn_print"
                        style={{ float: "center" }}
                      >
                        <ReactToPrint
                          trigger={() => (
                            <button className="print_btn_web">
                              <PrinterFilled />
                              &nbsp;&nbsp;Print
                            </button>
                          )}
                          content={() => window.print()}
                        />
                      </div>
                      <DashboardItem title="Filters" className="ant-card-body">
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ paddingBottom: 6 }}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> School Name </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select school name"
                              optionFilterProp="children"
                              onChange={(value, option) =>
                                this.onChangeSchool(option)
                              }
                              labelInValue
                              value={{
                                value: this.state.selectedSchoolID?.toString(),
                              }}
                            >
                              {this.state.schoolList.map((x) => {
                                return (
                                  <Option
                                    key={x["TmSchool.schoolId"]}
                                    value={x["TmSchool.schoolId"]?.toString()}
                                  >
                                    {x["TmSchool.schoolName"]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Class </strong>{" "}
                            </label>

                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value: this.state.selectedClass[0]?.toString(),
                              }}
                              placeholder="Select Class"
                              optionFilterProp="children"
                              onChange={this.onChangeClass}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              if({this.state.selectedClass.length > 0})
                              {this.state.classList &&
                                this.state.classList.map((x) => {
                                  classChildren.push(
                                    <Option
                                      value={x[
                                        "TmStandard.standardId"
                                      ]?.toString()}
                                    >
                                      {x["TmStandard.className"]}
                                    </Option>
                                  );
                                  allClassList.push(x["TmStandard.standardId"]);
                                })}
                              <Option value="0">All</Option>
                              {classChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Date Range </strong>{" "}
                            </label>
                            <br />
                            <RangePicker
                              style={{ top: 5 }}
                              onChange={this.onDateRangeChange}
                              value={[
                                moment(this.state.dateRangeList[0], dateFormat),
                                moment(this.state.dateRangeList[1], dateFormat),
                              ]}
                              format={dateFormat}
                            />
                          </div>
                        </Col>
                      </DashboardItem>
                    </Grid>
                  </Grid>
                </Dashboard>
              )}
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

export default CustomerDashboardPage;
