import React, { PureComponent } from "react";
import { Spin, Row, Col, Layout, Menu, Select, DatePicker, Button } from "antd";
import Grid from "@material-ui/core/Grid";
import { CSVLink } from "react-csv";
import { Table, Divider, Input } from "antd";
import { Link } from "react-router-dom";
import logo from "../../img/logo.png";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import cookie from "js-cookie";
import moment from "moment";
import Dashboard from "../../components/Dashboard";
import DashboardItem from "../../components/DashboardItem";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import "antd/dist/antd.css";
import axios from "axios";
import { Redirect } from "react-router";
import {
  baseUrlCube,
  homeUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../../components/helpers";
import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
  AreaChartOutlined, OrderedListOutlined, BarChartOutlined, 
} from "@ant-design/icons";
const { Header, Content, Sider } = Layout;
let access_token = cookie?.get("token");

class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      studentReport: [],
      isRedirect403: false,
      collapsed: true,
      schoolList: [],
      classList: [],
      groupList: [],
      assignmentList: [],
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ], //undefined, // ["2021-04-05", "2021-04-20"],
      fromDate: moment().subtract(3, "months"),
      toDate: moment().startOf("second"),
      school: [],
      loader: false,
      selectedSchool: [],
      schoolName: [],
      selectedSchoolID: "",
      selectedSchoolValue: "",
      selectedClassValue: "",
      selectedGroupValue: "",
      selectedClassID: "",
      selectedGroupID: "",
      class: [],
      selectedClass: [""],
      group: [],
      selectedGroup: [""],
      selectedDateRange: ["", ""],
      selectedAssignment: [""],
      selectedAssignmentLabel: "",
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      ifAdaptive: 0,
      dataInCSV: [],
      downloaded: 0,
    };
    this.csvLink = React.createRef();
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  componentDidMount = () => {
    let loginUserName = localStorage.getItem("user");
    let loginUserId = "";
    loginUserId = loginUserName;

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmProfiles.shortname"],"timeDimensions":[],"order":{"TmProfiles.shortname":"asc"},"filters":[{"member":"TmProfiles.userId","operator":"equals","values":["${loginUserId}"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const loginUser = res.data.data[0]["TmProfiles.shortname"];
        this.setState({ loginUser: loginUser });
        localStorage.setItem("username", loginUser);
      })
      .catch((error) => {
        console.log("username not loaded", error);
      });
  };

  fetchUser = (fromDate, toDate) => {
    debugger;
    axios
      .get(
        `${homeUrlCube}cubejs-api/v1/load?query=
    {"dimensions":["TmStudentLogin.date","TmStudentLogin.username","TmStudentLogin.student_type","TmStudentLogin.login_time"],"order":{"TmStudentLogin.login_time":"asc"},"timeDimensions":[{"dimension":"TmStudentLogin.createdon","dateRange":["${moment(
      fromDate
    ).format("YYYY-MM-DD")}","${moment(toDate).format("YYYY-MM-DD")}"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({ dataInCSV: [] });
        let arrayData = [];
        res.data.data.map((dataa, i) => {
          arrayData.push({
            "Serial No": i + 1,
            Date: dataa["TmStudentLogin.date"],
            "Student Username": dataa["TmStudentLogin.username"],
            "Student Type(School = 1/Home = 0)":
              dataa["TmStudentLogin.student_type"],
            "Login Time": dataa["TmStudentLogin.login_time"],
          });
        });
        this.setState({ dataInCSV: arrayData });
        this.csvLink.current.link.click();
        this.setState({ downloaded: 1 });
      })
      .catch((error) => {
        console.log("Download api not loaded", error);
      });
  };

  renderRedirect = () => {
    return <Redirect to="/home" />;
  };

  onChangeStart = (date, dateString) => {
    this.setState({ fromDate: date });
  };
  onChangeEnd = (date, dateString) => {
    this.setState({ toDate: date });
  };

  render() {
    if (this.state.isRedirect403) {
      return <Redirect to="/home" />;
    }
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    const { collapsed } = this.state;
    const dateFormat = "DD-MM-YYYY";

    const cubejsApi = cubejs(cookie?.get("token"), {
      apiUrl: `${homeUrlCube}cubejs-api/v1`,
    });

    return (
      <React.Fragment>
        <CubeProvider cubejsApi={cubejsApi}>
          <Layout
            className="print_width adminModule"
            style={{ minHeight: "100vh" }}
          >
            <Sider
              width={230}
              collapsible
              collapsed={collapsed}
              onCollapse={this.onCollapse}
              className="side_menu site-layout-background"
            >
              <Menu theme="dark" mode="inline" className="nav_panel">

              {localStorage.getItem("reportType") == 1 && (
                                <>
                <Menu.Item icon={<SolutionOutlined />} key="2">
                  <Link
                    to="/teacher-usage"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Teachers Usage
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<TeamOutlined />} key="1">
                  <Link to="/" className="sub-menu ant-menu-submenu-active1">
                    Class Usage
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<UserOutlined />}
                  key="3"
                  className="ant-menu-submenu "
                >
                  <Link
                    to="/student"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Individual Student Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FormOutlined />} key="4">
                  <Link
                    to="/assignment"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Assignment Report
                  </Link>
                </Menu.Item>
                {/* <Menu.Item icon={<MailOutlined />} key="5">
                  <Link
                    to="/send-email"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Send Email
                  </Link>
                </Menu.Item> */}
                </>
            )}

{localStorage.getItem("reportType") == 3 && (
              <>
                    <Menu.Item icon={<DatabaseOutlined   />} key="1">
                      <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                    </Menu.Item>

                    <Menu.Item icon={<FileDoneOutlined   />} key="2">
                      <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                    </Menu.Item>

                    <Menu.Item icon={<AreaChartOutlined />} key="3">
                      <a href={`${newReportUrl}/progress-report`}>Progress</a>
                    </Menu.Item>

                    <Menu.Item icon={<SolutionOutlined />} key="3">
                        <a href={`${newReportUrl}/teachers-usage`}>Teacher Usage</a>
                    </Menu.Item>



                    {/* <Menu.Item icon={<MailOutlined   />} key="5">
                      <Link
                        to="/send-email"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Mail
                      </Link>
                    </Menu.Item> */}

                    
                    <Menu.Item
                      icon={<FundProjectionScreenOutlined />}
                      key="6"
                    >
                      <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                    </Menu.Item>

                    <Menu.Item
                      icon={<BarChartOutlined />}
                      key="6"
                    >
                      <a href={`${newReportUrl}/self-prep-progress`}>Self Prep Progress</a>
                    </Menu.Item>



                      {localStorage.getItem("userType") == 1 && (
                        <>
                          <Menu.Item icon={<LoginOutlined />} key="7">
                            <Link
                              to="/login-report"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Login Report
                            </Link>
                          </Menu.Item>

                          <Menu.Item icon={<LineChartOutlined />} key="9">
                            <Link
                              to="/task-analysis"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Task Analysis
                            </Link>
                          </Menu.Item>
                          <Menu.Item icon={<FundOutlined />} key="10">
                            <Link
                              to="/question-analysis"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Question Analysis
                            </Link>
                          </Menu.Item>
                        </>
                      )}

              <Menu.Item
                icon={<OrderedListOutlined />}
                key="6"
              >
                <a href={`${newReportUrl}/group-school`}>Group School</a>
              </Menu.Item>

                  </>
          )}
              </Menu>
            </Sider>

            <Layout style={{ padding: "0 0px 0px" }}>
              <Grid
                xs={12}
                sm={12}
                lg={12}
                className="header header-customized"
              >
                <nav className="custom-nav">
                  <button
                    className="nav-toggl"
                    onClick={() => {
                      this.setState({ mobNav: !this.state.mobNav });
                      console.log("The button is clicked", this.state.mobNav);
                    }}
                  >
                    <MenuOutlinedIcon
                      className="icon-style-custom"
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "auto",
                        display: "flex",
                      }}
                    />
                  </button>
                  {this.state.mobNav && (
                    <ul className="custom-ul">
                      <Menu theme="dark" mode="inline" className="nav_panel">
                      {localStorage.getItem("reportType") == 1 && (
                          <>
                              <Menu.Item icon={<SolutionOutlined />} key="3">
                                <Link
                                  to="/teacher-usage"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Teachers Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<TeamOutlined />} key="4">
                                <Link
                                  to="/"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Class Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<UserOutlined />}
                                key="2"
                                className="ant-menu-submenu "
                              >
                                <Link
                                  to="/student"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Individual Student Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<FormOutlined />} key="5">
                                <Link
                                  to="/assignment"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Assignment Report
                                </Link>
                              </Menu.Item>
                              {/* <Menu.Item icon={<MailOutlined />} key="6">
                                <Link
                                  to="/send-email"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Send Email
                                </Link>
                              </Menu.Item> */}

                          </>
                      )}

                      {localStorage.getItem("reportType") == 3 && (
                              <>

                                  <Menu.Item icon={<DatabaseOutlined   />} key="1">
                                    <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<FileDoneOutlined   />} key="2">
                                    <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<AreaChartOutlined />} key="3">
                                    <a href={`${newReportUrl}/progress-report`}>Progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<SolutionOutlined />} key="3">
                                      <a href={`${newReportUrl}/teachers-usage`}>Teacher Usage</a>
                                  </Menu.Item>



                                  {/* <Menu.Item icon={<MailOutlined   />} key="5">
                                    <Link
                                      to="/send-email"
                                      className="sub-menu ant-menu-submenu-active1"
                                    >
                                      Mail
                                    </Link>
                                  </Menu.Item> */}


                                  <Menu.Item
                                    icon={<FundProjectionScreenOutlined />}
                                    key="6"
                                  >
                                    <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                                  </Menu.Item>


                                      {localStorage.getItem("userType") == 1 && (
                                        <>
                                          <Menu.Item icon={<LoginOutlined />} key="7">
                                            <Link
                                              to="/login-report"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Login Report
                                            </Link>
                                          </Menu.Item>
                      
                                          <Menu.Item icon={<LineChartOutlined />} key="9">
                                            <Link
                                              to="/task-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Task Analysis
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<FundOutlined />} key="10">
                                            <Link
                                              to="/question-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Question Analysis
                                            </Link>
                                          </Menu.Item>
                                        </>
                                      )}
                            </>
                        )}
                      </Menu>
                    </ul>
                  )}
                </nav>
                <Grid
                  xs={24}
                  lg={24}
                  container
                  spacing={0}
                  style={{ padding: 0 }}
                  justify="left"
                  alignItems="flex-start"
                >
                  <Col span={8} className="logo_header">
                    <img class="logoWidth" src={logo} />
                  </Col>
                  <Col span={8} className="breadcrumb">
                    <center>
                      <h2>
                        <b>Login Report</b>
                      </h2>
                    </center>
                  </Col>
                  <Col span={8} className="user_detail">
                    <h5>
                      <p>
                        <span className="c1">Hello!,</span>{" "}
                        <span className="c2">{this.state.loginUser}</span>
                        <br />
                        <div className="none">
                          {this.state.selectedSchoolValue}
                        </div>
                        <Link
                          className="logout_btn"
                          onClick={(event) => {
                            event.preventDefault();
                            signout(() => <Redirect to="/home" />);
                          }}
                        >
                          {" "}
                          Home{" "}
                        </Link>
                      </p>
                    </h5>
                  </Col>
                </Grid>
              </Grid>
              <center>
                <h2>
                  <b>
                    <div
                      style={{
                        display: this.state.display,
                        border: "2px solid black",
                        width: "30%",
                        height: "100px",
                        marginTop: "30px",
                        paddingTop: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      No Records Found
                    </div>
                  </b>
                </h2>
              </center>
              <Content
                className="ant-layout-content site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {!this.state.loader && (
                  <Dashboard>
                    <Grid
                      xs={10}
                      sm={10}
                      lg={10}
                      className="header printme-extra-content"
                    >
                      <Grid
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col span={8} className="logo_header_print">
                          <img class="logoWidth" src={logo} />
                        </Col>
                        <Col span={8} className="breadcrumb_print">
                          <center>
                            <h2>
                              <b>Login Report</b>
                            </h2>
                          </center>
                        </Col>
                        <Col span={8} className="user_detail_print">
                          <h5>
                            <p>
                              <span className="c1">School:</span>{" "}
                              <span className="c2">
                                {this.state.selectedSchoolValue}
                              </span>
                              <br />
                              <span className="c1">Class:</span>{" "}
                              <span className="c2">
                                {this.state.selectedClassValue}
                              </span>
                              <br />
                              <span className="c1">Group:</span>{" "}
                              <span className="c2">
                                {this.state.selectedGroupValue == "0"
                                  ? "All"
                                  : this.state.selectedGroupValue}
                              </span>
                              <br />
                              <span className="c1">Date Range:</span>{" "}
                              <span className="c2">
                                {this.state.dateRangeList[0].toString(
                                  "DD-MM-YYYY"
                                )}{" "}
                                To{" "}
                                {this.state.dateRangeList[1].toString(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                              <br />
                            </p>
                          </h5>
                        </Col>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={24}
                      sm={24}
                      lg={24}
                      style={{ width: "100%" }}
                      className="print_width_filter print_filters adminModule ant-card-body-cust"
                    >
                      <Grid
                        container
                        justify="left"
                        alignItems="flex-start"
                        id="filterContainer-title"
                      >
                        <DashboardItem
                          title="Filters"
                          className="ant-card-body"
                        >
                          <Col
                            span={8}
                            lg={8}
                            xs={8}
                            sm={8}
                            md={8}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Start Date </strong>{" "}
                              </label>
                              <br />
                              <DatePicker
                                style={{ top: 5, width: "60%" }}
                                onChange={this.onChangeStart}
                                defaultValue={this.state.dateRangeList[0]}
                                format={dateFormat}
                              />
                            </div>
                          </Col>

                          <Col
                            span={8}
                            lg={8}
                            xs={8}
                            sm={8}
                            md={8}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> End Date </strong>{" "}
                              </label>
                              <br />
                              <DatePicker
                                style={{ top: 5, width: "60%" }}
                                onChange={this.onChangeEnd}
                                defaultValue={this.state.dateRangeList[1]}
                                format={dateFormat}
                              />
                            </div>
                          </Col>

                          <Col
                            span={8}
                            lg={8}
                            xs={8}
                            sm={8}
                            md={8}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Download as CSV </strong>{" "}
                              </label>
                              <br />
                              <button
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.fetchUser(
                                    this.state.fromDate,
                                    this.state.toDate
                                  )
                                }
                              >
                                Go
                              </button>
                              <CSVLink
                                data={this.state.dataInCSV}
                                filename={"Login Report.csv"}
                                className="hidden"
                                ref={this.csvLink}
                                target="_blank"
                              />
                            </div>
                          </Col>
                        </DashboardItem>
                      </Grid>
                    </Grid>
                  </Dashboard>
                )}
              </Content>
            </Layout>
          </Layout>
        </CubeProvider>
      </React.Fragment>
    );
  }
}

export default CustomerDashboardPage;
