import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useCubeQuery } from "@cubejs-client/react";
import { Spin, Row, Col, Statistic, Table, Progress } from "antd";
import DashboardItem from "../components/DashboardItem";
import Wrapper from "../components/Wrapper";
import CenterWrapper from "../components/CenterWrapper";
import { useHistory } from "react-router-dom";
import cookie from 'js-cookie';
import axios from 'axios';
import { baseUrlCube, getUser, authenticate, isAuth, signout } from "../components/helpers"


import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  Label,
  LabelList,
  Text
} from "recharts";
import { Link } from "react-router-dom";
import moment from "moment";
var createReactClass = require('create-react-class');

var rowUniques=[];
var columnUniques=[];
var uniqPro=["Student Score","Distinct Questions"];
var uniqProStudent=["Student Score","Class Score","Distinct Questions"];
var uniqProStudentNotClass=["Student Score","Distinct Questions"];
var dataProficiency=[];
var dataOrderName=[];
var viewby=[];
var result=[];
var domainData=[];
var rowSpan={};

const dateFormatter = item => moment(item).format("MMM 'YY");
const dateDayFormatter = item => moment(item).format("MMM DD 'YY");
const dateTimeFormatter = item => moment(item).format("MMM DD 'YY hh:mm:ss a");

const rowSelection = {

  onChange: (selectedRowKeys, selectedRows) => {
    localStorage.removeItem('studentArray')
    let studentArrayString=selectedRows
    localStorage.setItem("studentArray",JSON.stringify(studentArrayString))
  }
};
const convertToFormalHours = function (totalSeconds) {
  let negative;
  // Find a Negative number
  if (totalSeconds < 0) { negative = true } else { negative = false }
  if (negative) { totalSeconds = totalSeconds * -1; }
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  var seconds = totalSeconds - (hours * 3600) - (minutes * 60);
  // round seconds
  seconds = Math.round(seconds * 100) / 100
  var result = (hours < 10 ? "0" + hours : hours);
  result += ":" + (minutes < 10 ? "0" + minutes : minutes);
  result += ":" + (seconds < 10 ? "0" + seconds : seconds);
  if (negative) { return result = '-' + result } else { return result };
}

const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={650}>
    <ChartComponent data={resultSet.chartPivot()} cursor="pointer">
      {/* <XAxis dataKey="x" /> */}
      {(() => {
        return (
            <XAxis dataKey="x" fontSize="10px" angle={-45} sclaeToFit="true" verticalAnchor= "start" interval={0}
            textAnchor="end"/>
            // <YAxis axisLine={false} />
               )
      })()}
      {(() => {
        if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'hour') {
          return (
            <XAxis dataKey="x" tickFormatter={dateTimeFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'day') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'month') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else {
          return (
            <XAxis dataKey="x" />
          )
        }
      })()}
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend verticalAlign="bottom" wrapperStyle={{fontSize:"17px",bottom:"0px"}}/>
      <Tooltip cursor={false} />
    </ChartComponent>
  </ResponsiveContainer>
);

const CartesianChartOverall = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={350} className="overall_chart">
    <ChartComponent data={resultSet.chartPivot()} cursor="pointer">
      {/* <XAxis dataKey="x" /> */}
      {/* {(() => {
        return (
            <XAxis dataKey="x" fontSize="10px" angle={-45}
            textAnchor="end" />
               )
      })()} */}
      {(() => {
        if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'hour') {
          return (
            <XAxis dataKey="x" tickFormatter={dateTimeFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'day') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'month') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else {
          return (
            <XAxis dataKey="x" />
          )
        }
      })()}
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend verticalAlign="bottom" wrapperStyle={{fontSize:"17px"}}/>
      <Tooltip cursor={false} />
    </ChartComponent>
  </ResponsiveContainer>
);

const colors = ["#F9C098", "#E18C93", "#E2E3A1", "#9ED5EC", "#a929a5", '#9A97A7', '#2A334A', "#6DAC4FFF", "#006B38FF", "#97BC62FF", "#2C5F2D", "#214a32", '#8cc63f', "#17703c", "#d5e4c0"];
const backgroundColor = ['rgba(91, 143, 249, 0.9)', 'rgba(93, 216, 166, 0.9)', 'rgba(93, 112, 146, 0.9)', 'rgba(246, 190, 46, 0.9)']
const borderColor = ["#D58C56", "#C94041", "#BDC069"]

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;
};


const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};



const stackedChartData = resultSet => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m)
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
}

const CustomizedLabel = createReactClass({

  render() {
    const { x, y, fill, value } = this.props;
    return <text
      x={x}
      y={y}

      fontSize='16'
      fontFamily='sans-serif'
      fill={fill}
      textAnchor="start">{value}%</text>
  }
});
const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="10" fill="#000000" fontWeight="Bold" />;
};
const renderCustomizedLabelTop = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#000000" fontWeight="Bold" />;
};
const TypeToChartComponent = {
  bar_horizontal: ({ resultSet }) => (

    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (

        <BarChart
          data={resultSet.seriesNames()}
          layout="vertical" barCategoryGap={1}
          margin={{ top: 0, right: 50, left: 0, bottom: 0 }}>
          <XAxis type="number" hide />
          <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" />

          <Bar
            dataKey="value"
            fill="#323232"
            label={<CustomizedLabel />}
          />

        </BarChart>
      ))}
    </CartesianChart>
  ),
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          strokeWidth={6}
          dataKey={series.key}
          name={series.title. split(",",1).toString()}
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={BarChart} >
      {localStorage.setItem("teacherName",resultSet.loadResponse.data[0]?.["TmSchoolHomework.teacher"])}
      {resultSet.seriesNames().map((series, i) => (
        <Bar
        
          layout="vertical"
          barSize={20}
          stroke={borderColor[i]}
          strokeWidth={1}
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title.replace(/^Work Order|^S Order/g, '')}
          fill={colors[i]}
        >
          <LabelList position="top" content={renderCustomizedLabelTop} />
        </Bar>
      ))}
    </CartesianChart>

  ),
  bar_overall: ({ resultSet }) => (
    <CartesianChartOverall resultSet={resultSet} ChartComponent={BarChart} >
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          layout="vertical"
          scroll={{ x: 100 }}
          barSize={20}
          stroke={borderColor[i]}
          strokeWidth={1}
          key={series.key}
          dataKey={series.key}
          name={series.title. split(",",1).toString()}
          fill={colors[i]}

        >
         
          <LabelList position="top" content={renderCustomizedLabelTop} />
        </Bar>
      ))}
    </CartesianChartOverall>
  ),

  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => (
    <ResponsiveContainer width="100%" height={350}>
      {resultSet.seriesNames()[0] != undefined ? (<PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey="x"
          dataKey={resultSet.seriesNames()[0] == undefined ? (0) : (resultSet.seriesNames()[0].key)}
          // label
          label={(entry) => entry.name + ' :  ' + entry.value}
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {/* <Legend /> */}
        <Tooltip />
      </PieChart>) : (<Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100%"
        }}
      >
        <Col> <Statistic value={0} /> </Col> </Row>)}
    </ResponsiveContainer>
  ),
  circleProgress: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            //<Statistic value={resultSet.totalRow()[s.key]} />
            <Progress type="circle" percent={Math.round(resultSet.totalRow()[s.key])} />
          ))}
        </Col>
      </Row>
    );
  },
  strightProgress: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Progress type="circle" className="ant-progress-circle1" percent={Math.round(resultSet.totalRow()[s.key])} />
          ))}
        </Col>
      </Row>
    );
  },
  number: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            value={resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  number_progress_report: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{margin:"7px 0 0 10px"}}>
          {resultSet.seriesNames().map((s) => (
            <span>(Total : {resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]})</span>
          ))}
        </Col>
      </Row>
    );
  },
  domain_text: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            value={resultSet.loadResponse.data[0]["classScore.overall_result"]}  
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text_istudent: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            value={resultSet.loadResponse.data[0]["TmSingleHwstudStatistics.Result"]}  
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text_dig: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            value={resultSet.loadResponse.data[0]["TmSingleHwstudStatistics.Diag_Student_Result"]}  
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text_dign: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            value={resultSet.loadResponse.data[0]["TmSchoolHomework.Diag_ClassScore"]}  
            // value={(resultSet.totalRow()["TmSingleHwstudStatistics.averageScore"]||resultSet.totalRow()["AssessmentStatistics.totalAverage"] ||resultSet.totalRow()["Class.average"] ? resultSet.totalRow()[s.key]+'%':resultSet.totalRow()[s.key])} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  table_pivot_custom: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={false}
      scroll={{ x: 1200 }}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig)))}
      bordered />
  ), table: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig), resultSet.loadResponse.query.filters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  box_table: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={resultSet.tablePivot(pivotConfig)}
      bordered />
  ),
  table_individual: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.studentId")}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  table_individual_adaptive: ({ resultSet, pivotConfig, parameters }) => {
    return (
      <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.studentId")}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
    )
  },
  table_assignment: ({ resultSet, pivotConfig, parameters }) => (
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidthHideColumn(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.shortTitle!==" " && ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId" && ea.dataIndex !== "TmSchoolHomework.teacherId")} //
      dataSource={addPercentage(dateFormat(individualStudent(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions)))}
      bordered />
  ),
    table_assignment_all: ({ resultSet, pivotConfig,parameters, renderProps }) => {
      return <Table
        pagination={false}
        scroll={{ x: 500 }}
        className={reduceWidth(resultSet.tableColumns(pivotConfig))}
        columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.shortTitle!==" " && ea.dataIndex !== "TmSchoolHomework.groupId" && ea.dataIndex !== "TmSchoolHomework.assignmentId" && ea.dataIndex !== "TmSchoolHomework.teacherId")}
        dataSource={individualAssignAll(resultSet.tablePivot(pivotConfig), parameters,"TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain")}
        bordered
      />
    },
  table_assignment_adaptive: ({ resultSet, pivotConfig, parameters }) => {
    return(
    // "adaptive - assignments"
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidthHideColumnAdaptive(resultSet.tableColumns(pivotConfig))}
      columns={ascending(resultSet.tableColumns(pivotConfig).filter(ea => ea.shortTitle!==" " && ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" ))} //
      dataSource={addPercentage(dateFormat(individualStudent(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions)))}

      bordered />
  )},
  table_assignment_mail_page: ({ resultSet, pivotConfig, parameters }) => (
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidth(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  table_assignment_adaptive_mail_page: ({ resultSet, pivotConfig, parameters }) => (
    // "adaptive - assignments"
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidth(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  table_assignment_mail: ({ resultSet, pivotConfig, parameters }) => (
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidth(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  table_teacher: ({ resultSet, pivotConfig, parameters }) => {
    return(
    <Table
      pagination={false}
      scroll={{ x: 1200 }}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSchoolHomework.teacherId")}
      dataSource={individualAssignment(resultSet.tablePivot(pivotConfig),parameters)}
      bordered />
    )
  },
  table_selection: ({ resultSet, pivotConfig }) => (
    <Table
      rowSelection={{
        ...rowSelection,
      }}
      pagination={true}
      scroll={{ x: 1200 }}
      columns={changeColumn(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSchoolHomework.standardId" ))}
      dataSource1={[
        {
          "key": 741,
          "TmStudentHomeworks.studentId": 741,
          "TmSingleHwstudStatistics.studentId": "1651",
          "TmStudentHomeworks.grpStudName": "1651 1651",
          "TmStudentHomeworks.studEmail": "1651"
        },
        {
          "key": 40552,
          "TmStudentHomeworks.studentId": 40552,
          "TmSingleHwstudStatistics.studentId": "78046",
          "TmStudentHomeworks.grpStudName": "78046 78046",
          "TmStudentHomeworks.studEmail": "78046"
        },
        {
          "key": 106105,
          "TmStudentHomeworks.studentId": 106105,
          "TmSingleHwstudStatistics.studentId": "NA",
          "TmStudentHomeworks.grpStudName": "NA",
          "TmStudentHomeworks.studEmail": "NA"
        }
      ]}
      dataSource={addKey(resultSet.tablePivot(pivotConfig))}
      bordered />
  ),
  table_pivot: ({ resultSet, pivotConfig, renderProps }) => {
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceWidth(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={individualStudentAdaptive(removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain"))}
      bordered
    />
  },
  table_pivot_topic: ({ resultSet, pivotConfig, parameters }) => {
    if (resultSet.loadResponse.data.length === 1) {
      return (
        <Table
          pagination={false}
          scroll={{ x: 500 }}
          className={reduceWidth(resultSet.tableColumns(pivotConfig))}
          columns={manipulateInput(
            resultSet.tableColumns(pivotConfig),
            resultSet.tablePivot(pivotConfig),
            resultSet
          )}
          dataSource={individualStudentAdaptive(
            removeRepeatt(
              resultSet.tablePivot(pivotConfig),
              "TmHwQnAnsStat.chapterName",
              "TmHwQnAnsStat.domain"
            )
          )}
          bordered
        />
      );
    } else {
      columnUniques = [];
      rowUniques = [];
      dataProficiency = [];
      viewby = [];
      result = [];
      var oldChapt = "";

      var viewByParam = parameters[1].AggregatedBy;
      var aggregatedByParam = parameters[0].ViewBy;
      let strokeColor = "";
      removeRepeatProficiencyProgress(
        resultSet.loadResponse.data,
        "TmStudenthomeworkquestions.tmSthwqtChapterName",
        "TmStudenthomeworkquestions.tmSthwqtDomainName",
        "TmStudenthomeworkquestions.tmSthwqtTopicName",
        aggregatedByParam
      );
      return (
        <table id="customers">
          <tr>
            {viewby.map((view, i) => (
              <th rowspan="2">
                {view}
                {console.log("viewview", view)}
              </th>
            ))}
            {columnUniques[0]?.[0] !== undefined &&
              columnUniques.map((cuniq, i) => (
                <th colspan="2">
                  {console.log("columnUniques", columnUniques)}
                  {cuniq[1]}
                  {console.log("viewview cuniq[1]", cuniq[1])}
                </th>
              ))}
          </tr>
          <tr className="subHead">
            {columnUniques.map((cuniq, i) =>
              uniqPro.map((uni, i) => (
                <td>
                  {uni}
                  {console.log("viewview uni", uni)}
                </td>
              ))
            )}
          </tr>
          {aggregatedByParam[0] === "chapter"
            ? rowUniques.map((runiq, i) => {
                return (
                  <tr>
                    <td>
                      {runiq}
                      {console.log("viewview runiq", runiq)}
                    </td>
                    {columnUniques.map((cuniq, i) => {
                      console.log("viewview cuniq", cuniq);
                      var score = "";
                      var distQuestion = "";
                      dataProficiency.map((dataa, i) => {
                        dataa["TmStudenthomeworkquestions.averageScore"] >= 71
                          ? (strokeColor = "#92a055")
                          : dataa["TmStudenthomeworkquestions.averageScore"] >=
                            50
                          ? (strokeColor = "#dc8147")
                          : (strokeColor = "#9e3433");
                        if (
                          runiq ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtChapterName"
                            ] &&
                          cuniq[0] ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
                            ]
                        ) {
                          score = (
                        /*     <Progress
                              type="circle"
                              percent={Math.round(
                                dataa["TmStudenthomeworkquestions.averageScore"]
                              )}
                              format={(percent) => `${percent}  %`}
                              strokeColor={strokeColor}
                            /> */

                            <Progress percent={Math.round(
                              dataa["TmStudenthomeworkquestions.averageScore"]
                            )}   /* strokeColor={strokeColor} */  format={(percent) => `${percent}  %`} />
                          );
                          distQuestion =
                            dataa["TmStudenthomeworkquestions.distCount"];
                        }
                      });
                      if (score && distQuestion) {
                        return (
                          <>
                            <td>{score}</td>
                            <td>{distQuestion}</td>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        );
                      }
                    })}
                  </tr>
                );
              })
            : aggregatedByParam == "domain"
            ? rowUniques.map((runiq, i) => {
                return (
                  <tr>
                    <td>{runiq}</td>
                    {columnUniques.map((cuniq, i) => {
                      var dscore = "";
                      var ddistQuestion = "";
                      dataProficiency.map((dataa, i) => {
                        dataa["TmStudenthomeworkquestions.averageScore"] >= 71
                          ? (strokeColor = "#92a055")
                          : dataa["TmStudenthomeworkquestions.averageScore"] >=
                            50
                          ? (strokeColor = "#dc8147")
                          : (strokeColor = "#9e3433");
                        if (
                          runiq ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtDomainName"
                            ] &&
                          cuniq[0] ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
                            ]
                        ) {
                          dscore = (
                            <Progress
                              type="circle"
                              percent={Math.round(
                                dataa["TmStudenthomeworkquestions.averageScore"]
                              )}
                              format={(percent) => `${percent}  %`}
                              strokeColor={strokeColor}
                            />
                          );
                          ddistQuestion =
                            dataa["TmStudenthomeworkquestions.distCount"];
                        }
                      });
                      if (dscore && ddistQuestion) {
                        return (
                          <>
                            <td>{dscore}</td>
                            <td>{ddistQuestion}</td>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        );
                      }
                    })}
                  </tr>
                );
              })
            : rowUniques.map((runiq, i) => {
                return (
                  <tr>
                    {oldChapt !== runiq[0] ? (
                      <>
                        <td rowspan={rowSpan[runiq[0]]}>
                          {runiq[0]}
                          {console.log("viewview rujniq[0]", runiq[0])}
                        </td>
                        <td>{runiq[1]}</td>
                      </>
                    ) : (
                      <>

                        <td>
                          {runiq[1]}
                          {console.log("viewview runiq[1]", runiq[1])}
                        </td>
                      </>
                    )}
                    {columnUniques.map((cuniq, i) => {
                      oldChapt = runiq[0];
                      var tscore = "";
                      var tdistQuestion = "";
                      dataProficiency.map((dataa, i) => {
                        dataa["TmStudenthomeworkquestions.averageScore"] >= 71
                          ? (strokeColor = "#92a055")
                          : dataa["TmStudenthomeworkquestions.averageScore"] >=
                            50
                          ? (strokeColor = "#dc8147")
                          : (strokeColor = "#9e3433");
                        if (
                          runiq[0] ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtChapterName"
                            ] &&
                          runiq[1] ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtTopicName"
                            ] &&
                          cuniq[0] ==
                            dataa[
                              "TmStudenthomeworkquestions.tmSthwqtProficiencyId"
                            ]
                        ) {
                          tscore = (
                            <Progress
                              type="circle"
                              percent={Math.round(
                                dataa["TmStudenthomeworkquestions.averageScore"]
                              )}
                              format={(percent) => `${percent}  %`}
                             /*  strokeColor={strokeColor} */
                            />
                          );
                          tdistQuestion =
                            dataa["TmStudenthomeworkquestions.distCount"];
                        }
                      });
                      if (tscore && tdistQuestion) {
                        return (
                          <>
                            <td>{tscore}</td>
                            <td>{tdistQuestion}</td>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <td></td>
                            <td></td>
                          </>
                        );
                      }
                    })}
                  </tr>
                );
              })}

     
        </table>
      );
    }
  },
  table_pivot_progress_topic: ({ resultSet, pivotConfig, parameters }) => {
    if (parameters[0].ViewBy[0] === "topic") {
      columnUniques = [];
      rowUniques = [];
      dataProficiency = [];
      viewby = [];
      result = [];
      var oldChapt = "";

      var aggregatedByParam = parameters[0].ViewBy;
      let strokeColor = "";
      console.log("resultSet.loadResponse.data", resultSet.loadResponse.data);
      removeRepeatTopicProgress(
        resultSet.loadResponse.data,
        "TmStudenthomeworkquestionTest.tmSthwqtChapterName",
        "TmStudenthomeworkquestionTest.tmSthwqtDomainName",
        "TmStudenthomeworkquestionTest.tmSthwqtTopicName",
        aggregatedByParam
      );
      return (
        <table id="customers">
          <tr>
            {viewby.map((view, i) => (
              <th rowspan="2">
                {view}
                {console.log("viewview", view)}
              </th>
            ))}
            {columnUniques[0]?.[0] !== undefined &&
              columnUniques.map((cuniq, i) => (
                <th colspan="2">
                  {console.log("viewview columnUniques", columnUniques)}
                  {cuniq[1]}
                  {console.log("viewview cuniq[1]", cuniq[1])}
                </th>
              ))}
          </tr>
          <tr className="subHead">
            {columnUniques.map((cuniq, i) =>
              uniqPro.map((uni, i) => (
                <td>
                  {uni}
                  {console.log("viewview uni", uni)}
                </td>
              ))
            )}
          </tr>
          {rowUniques.map((runiq, i) => {
            return (
              <tr>
                {oldChapt !== runiq[0] ? (
                  <>
                    <td rowspan={rowSpan[runiq[0]]}>
                      {runiq[0]}
                      {console.log("viewview rujniq[0]", runiq[0])}
                    </td>
                    <td>{runiq[1]}</td>
                  </>
                ) : (
                  <>
                    <td>
                      {runiq[1]}
                      {console.log("viewview runiq[1]", runiq[1])}
                    </td>
                  </>
                )}
                {columnUniques.map((cuniq, i) => {
                  oldChapt = runiq[0];
                  var tscore = "";
                  var tdistQuestion = "";
                  dataProficiency.map((dataa, i) => {
                    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71
                      ? (strokeColor = "#92a055")
                      : dataa["TmStudenthomeworkquestionTest.averageScore"] >=
                        50
                      ? (strokeColor = "#dc8147")
                      : (strokeColor = "#9e3433");
                    if (
                      runiq[0] ===
                        dataa[
                          "TmStudenthomeworkquestionTest.tmSthwqtChapterName"
                        ] &&
                      runiq[1] ===
                        dataa[
                          "TmStudenthomeworkquestionTest.tmSthwqtTopicName"
                        ] &&
                      cuniq[0] ===
                        dataa[
                          "TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"
                        ]
                    ) {
                      tscore = (
                        <Progress
                          percent={Math.round(
                            dataa["TmStudenthomeworkquestionTest.averageScore"]
                          )}
                          format={(percent) => `${percent}  %`}
                        />
                      );
                      tdistQuestion =
                        dataa["TmStudenthomeworkquestionTest.distCount"];
                    }
                    return null;
                  });
                  if (tscore && tdistQuestion) {
                    return (
                      <>
                        <td>{tscore}</td>
                        <td>{tdistQuestion}</td>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    );
                  }
                })}
              </tr>
            );
          })}
        </table>
      );
    } else {
      return (
        <Table
          pagination={false}
          scroll={{ x: 500 }}
          className={reduceWidth(resultSet.tableColumns(pivotConfig))}
          columns={manipulateInput(
            resultSet.tableColumns(pivotConfig),
            resultSet.tablePivot(pivotConfig),
            resultSet
          )}
          dataSource={individualStudentAdaptive(
            removeRepeatt(
              resultSet.tablePivot(pivotConfig),
              "TmHwQnAnsStat.chapterName",
              "TmHwQnAnsStat.domain"
            )
          )}
          bordered
        />
      );
    }
  },
  student_name: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
       
          {resultSet.seriesNames().map((s) => (
            <div>Student : {resultSet.loadResponse.data[0]["TmStudentDetails.studentName"]}</div> 
          
          ))}
       
      </Row>
    );
  },
  frontend_student_name: ({ resultSet }) => {
    return (
      <span>
          {resultSet.seriesNames().map((s) => (
            <span style={{fontWeight:"bold"}}>{resultSet.loadResponse.data[0]["TmStudentDetails.studentName"]}</span> 
          ))}
      </span>
    );
  },
  student_summary_report: ({ resultSet, pivotConfig, renderProps, parameters }) => {
    console.log("ppppp", parameters);
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceWidth(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmStudentProgress.categoryId"), resultSet.tablePivot(pivotConfig), resultSet)}
    
      dataSource={summaryData(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)}
      bordered
    />
  },
  table_pivot_task_detail: ({ resultSet, pivotConfig, renderProps }) => {
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceWidth(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInputProgress(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={individualStudentAdaptive(removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain"))}
      bordered
    />
  },
  table_pivot_adaptive: ({ resultSet, pivotConfig, renderProps,parameters }) => {
    return (
    <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceWidth(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain")}
      bordered
    />
    )
  },
  table_pivot_individual: ({ resultSet, pivotConfig, renderProps }) => {
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
  },
  table_pivot_individual_adaptive: ({ resultSet, pivotConfig, renderProps }) => {
    return (
    // "individual adaptive"
    <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
    )
  },
     table_pivot_html: ({ resultSet,parameters,pivotConfig }) => {
      if(resultSet.loadResponse.data.length==0){
        return(
        <Table
        pagination={false}
        scroll={{ x: 500 }}
        className={reduceColumn(resultSet.tableColumns(pivotConfig))}
        columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmHwQnAnsStat.proficiency"), resultSet.tablePivot(pivotConfig), resultSet)}
        dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
        bordered
      />
        )
      }
      else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy
    // alert(viewByParam)

    // alert(aggregatedByParam)
    let strokeColor = '';
    
    removeRepeat(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
            // alert("chapt")
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        // alert("true")
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        // alert("false")
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
      }
  },

  table_pivot_html_skill: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy[0]
    let strokeColor = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },
  table_pivot_html_skill_domain: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    let strokeColor = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_skill_progress: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmStudenthomeworkquestions.tmSthwqtChapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    let strokeColor = '';
    
    removeRepeatSkillProgress(resultSet.loadResponse.data, "TmStudenthomeworkquestions.tmSthwqtChapterName", "TmStudenthomeworkquestions.tmSthwqtDomainName", "TmStudenthomeworkquestions.tmSthwqtTopicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmStudenthomeworkquestions.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestions.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"] && cuniq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmStudenthomeworkquestions.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmStudenthomeworkquestions.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestions.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmStudenthomeworkquestions.tmSthwqtDomainName"] && cuniq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmStudenthomeworkquestions.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmStudenthomeworkquestions.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestions.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"] && runiq[1]==dataa["TmStudenthomeworkquestions.tmSthwqtTopicName"] && cuniq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmStudenthomeworkquestions.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_proficiency_progress: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmStudenthomeworkquestions.tmSthwqtChapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    let strokeColor = '';
    
    removeRepeatProficiencyProgress(resultSet.loadResponse.data, "TmStudenthomeworkquestions.tmSthwqtChapterName", "TmStudenthomeworkquestions.tmSthwqtDomainName", "TmStudenthomeworkquestions.tmSthwqtTopicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmStudenthomeworkquestions.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestions.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"] && cuniq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmStudenthomeworkquestions.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmStudenthomeworkquestions.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestions.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmStudenthomeworkquestions.tmSthwqtDomainName"] && cuniq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmStudenthomeworkquestions.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmStudenthomeworkquestions.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestions.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"] && runiq[1]==dataa["TmStudenthomeworkquestions.tmSthwqtTopicName"] && cuniq[0]==dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmStudenthomeworkquestions.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_skill_progress_student: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmStudenthomeworkquestionTest.tmSthwqtChapterName")}
      console={console.log('resultSet.tableColumnss', resultSet.tableColumns(pivotConfig))}
      console={console.log('resultSet.tablePivot', resultSet.tablePivot(pivotConfig))}
      console={console.log('--resultSet--', resultSet)}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    console.log('resultSet.tablePivot.Student', resultSet)
    console.log('parameter viewby', parameters)
    let strokeColor = '';
    
    removeRepeatSkillProgressStudent(resultSet.loadResponse.data, "TmStudenthomeworkquestionTest.tmSthwqtChapterName", "TmStudenthomeworkquestionTest.tmSthwqtDomainName", "TmStudenthomeworkquestionTest.tmSthwqtTopicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"] && cuniq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestionTest.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmStudenthomeworkquestionTest.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"] && cuniq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestionTest.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmStudenthomeworkquestionTest.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"] && runiq[1]==dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"] && cuniq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestionTest.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmStudenthomeworkquestionTest.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_proficiency_progress_student: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmStudenthomeworkquestionTest.tmSthwqtChapterName")}
      console={console.log('resultSet.tableColumnss', resultSet.tableColumns(pivotConfig))}
      console={console.log('resultSet.tablePivot', resultSet.tablePivot(pivotConfig))}
      console={console.log('--resultSet--', resultSet)}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    console.log('resultSet.tablePivot.Student', resultSet)
    console.log('parameter viewby', parameters)
    let strokeColor = '';
    
    removeRepeatProficiencyProgressStudent(resultSet.loadResponse.data, "TmStudenthomeworkquestionTest.tmSthwqtChapterName", "TmStudenthomeworkquestionTest.tmSthwqtDomainName", "TmStudenthomeworkquestionTest.tmSthwqtTopicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"] && cuniq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestionTest.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmStudenthomeworkquestionTest.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"] && cuniq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestionTest.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmStudenthomeworkquestionTest.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmStudenthomeworkquestionTest.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmStudenthomeworkquestionTest.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"] && runiq[1]==dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"] && cuniq[0]==dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmStudenthomeworkquestionTest.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmStudenthomeworkquestionTest.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_skill_adap: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    let strokeColor = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_student: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmHwQnAnsStat.proficiency"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""
    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy[0]
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeat(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      var classScore=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },


  table_pivot_html_skill_student: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy[0]
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr>
        <td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var classScore=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },

  table_pivot_html_skill_student_domain: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd" && ea.dataIndex !== "TmHwQnAnsStat.proficiency"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr>
        <td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var classScore=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },

  table_pivot_html_student_mail: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    var isClassScore=parameters[2].isClassScore
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeat(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {isClassScore==false?
    columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )
    :
    columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )
  }
  </tr>
  <tr className="subHead">
  {isClassScore==false?
columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )
  :
  columnUniques.map((cuniq,i)=>
  uniqProStudentNotClass.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
        {isClassScore==false?
    columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      var classScore=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })
  :
  columnUniques.map((cuniq,i)=>{
      
    var score=""
    var distQuestion=""
    // var classScore=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        //  classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         distQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(score && distQuestion){
      return(
      <><td>{score}</td><td>{distQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })
  }
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
      {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
  var dscore=""
  // var dclassScore=""
  var ddistQuestion=""
  dataProficiency.map((dataa,i)=>{
    dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'

    if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
       dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
      //  dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
       ddistQuestion=dataa["TmHWQs.distCount"];
    }
  })
  if(dscore && ddistQuestion){
    return(
    <><td>{dscore}</td><td>{ddistQuestion}</td></>
    )
  }
  else{
    return(
    <><td></td><td></td></>
    )
  }
})}
  </tr>
  )
})
:

rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
            {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
        oldChapt=runiq[0]
        
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
  oldChapt=runiq[0]
  
var tscore=""
// var tclassScore=""
var tdistQuestion=""

dataProficiency.map((dataa,i)=>{

dataa["TmHwQnAnsStat.averageScore"] >= 71?
strokeColor = '#92a055'
:
dataa["TmHwQnAnsStat.averageScore"] >= 50?
strokeColor = '#dc8147'
:
strokeColor = '#9e3433'

dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
strokeColor1 = '#92a055'
:
dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
strokeColor1 = '#dc8147'
:
strokeColor1 = '#9e3433'

if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
   tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
  //  tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
   tdistQuestion=dataa["TmHWQs.distCount"];
}
})
if(tscore && tdistQuestion){
return(
<><td>{tscore}</td><td>{tdistQuestion}</td></>
)
}
else{
return(
<><td></td><td></td></>
)
}
})}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },

  table_pivot_progres: ({ resultSet, pivotConfig, renderProps }) => {
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
  },

  table_pivot_html_skill_student_mail: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    var isClassScore=parameters[2].isClassScore

    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {isClassScore==false?
    columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )
  :
  columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">

  {isClassScore==false?
columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )
  :
  columnUniques.map((cuniq,i)=>
  uniqProStudentNotClass.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
        {isClassScore==false?
    columnUniques.map((cuniq,i)=>{
      
      var score=""
      var classScore=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })
  :
  columnUniques.map((cuniq,i)=>{
      
    var score=""
    // var classScore=""
    var distQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        //  classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         distQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(score && distQuestion){
      return(
      <><td>{score}</td><td>{distQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
            {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
  var dscore=""
  // var dclassScore=""
  var ddistQuestion=""
  dataProficiency.map((dataa,i)=>{
    dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'

    if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
       dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
      //  dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
       ddistQuestion=dataa["TmHWQs.distCount"];
    }
  })
  if(dscore && ddistQuestion){
    return(
    <><td>{dscore}</td><td>{ddistQuestion}</td></>
    )
  }
  else{
    return(
    <><td></td><td></td></>
    )
  }
})}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
                  {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
   
  oldChapt=runiq[0]
var tscore=""
// var tclassScore=""
var tdistQuestion=""
dataProficiency.map((dataa,i)=>{

dataa["TmHwQnAnsStat.averageScore"] >= 71?
strokeColor = '#92a055'
:
dataa["TmHwQnAnsStat.averageScore"] >= 50?
strokeColor = '#dc8147'
:
strokeColor = '#9e3433'

dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
strokeColor1 = '#92a055'
:
dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
strokeColor1 = '#dc8147'
:
strokeColor1 = '#9e3433'

if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
   tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
  //  tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
   tdistQuestion=dataa["TmHWQs.distCount"];
}
})
if(tscore && tdistQuestion){
return(
<><td>{tscore}</td><td>{tdistQuestion}</td></>
)
}
else{
return(
<><td></td><td></td></>
)
}
})}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },
}

function dateFormat(comDate) {
  var completion = [];
  comDate.map((data, i) => {
    if (data["TmStudentHomeworks.completionDate"] != null) {
     var date= moment(data["TmStudentHomeworks.completionDate"]).format("DD-MM-YYYY")
      // alert("ananti")
      // var date=data["TmStudentHomeworks.completionDate"];
      data["TmStudentHomeworks.completionDate"] = date;
    }
    completion.push(data)
  })
  return completion;
}

function addKey(studentMailList) {
  //var percentData = [];
  studentMailList.map((data, i) => {
    data['key'] = i
  });
  return studentMailList;
}


  function addPercentage(percent) {
  var percentData = [];

  percent.map((data, i) => {
    if (data["TmHwQnAnsStat.averageScore"] != null) {
      data["TmHwQnAnsStat.averageScore"] = data["TmHwQnAnsStat.averageScore"] + "%"
    }
    if (data["Class.average"] != null) {
      data["Class.average"] = data["Class.average"] + "%"
    }
    if (data["TmSingleHwstudStatistics.percentageString2"] != null) {
      var dataValue = data["TmSingleHwstudStatistics.percentageString2"];
      if (dataValue >= 71) {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='green'> {data["TmSingleHwstudStatistics.percentageString2"] + "%"} </Wrapper>
      }
      else if (dataValue >= 50) {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='orange'> {data["TmSingleHwstudStatistics.percentageString2"] + "%"} </Wrapper>
      }

      else if (data['TmSingleHwstudStatistics.statusOrder'] == '0') {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='grey'> NA  {/* data["TmStudentHomeworks.percentage"] + "%" */} </Wrapper>
      }


      else {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='yellow'> {data["TmSingleHwstudStatistics.percentageString2"] + "%"} </Wrapper>

      }
    }
    percentData.push(data)
  })
  return percentData;
}

// function assignDetail(individual,parameter,pivotConfig){
//   var res=[];
//   individual.map((ind, i) => {
//     pivotConfig.map((pivot, i) => {
//       if (ind["TmStudentCurrent.userId"]==pivot["TmStudentCurrent.userId"]) {
//         ind["TmStudentCurrent.completedAsg"]=pivot["TmStudentCurrent.completedAsg"]
//         ind["TmStudentCurrent.unattemptedAsg"]=pivot["TmStudentCurrent.unattemptedAsg"]
//         ind["TmStudentCurrent.studentAvg"]=pivot["TmStudentCurrent.studentAvg"]

//       }
//   res.push(ind)
//     })
// })
// return res;
// }

function individualAssignment(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    if (data["TmSchoolHomework.teacher"] != null) {
      data["TmSchoolHomework.totalTuCompleted"] == 0 || parameter[1].Class[0] == 0?
        data["TmSchoolHomework.teacher"] = data["TmSchoolHomework.teacher"]
        :
       // data["TmSchoolHomework.teacher"] = <Link to={`/assignment/0/${parameter[0].School}/${parameter[1].Class[0]}/0/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[2].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[2].Daterange[1]).format("YYYY-MM-DD")}/all/chapter/${data["TmSchoolHomework.teacherId"]}`}>{data["TmSchoolHomework.teacher"]}</Link>
       data["TmSchoolHomework.teacher"] =  data["TmSchoolHomework.teacher"]
    }
    linkData.push(data)
  })
  return linkData;
  }

function individualAssignAll(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    if(data["TmSchoolHomework.teacher"] != null){
      data["TmSchoolHomework.teacher"] = <Link to={`/individual-teacher/0/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.teacher"]}</Link>
    }
    if (data["TmSchoolHomework.assignmentName"] != null) {
      // data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/
      // ${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].aterange[1]).format("YYYY-MM-DD")}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      if (data["AssessmentStatistics.average_Score"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/individual-assignment/${data['TmSchoolHomework.assignmentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/individual-assignment/${data['TmSchoolHomework.assignmentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      else{
        data["TmSchoolHomework.assignmentName"] = <Link to={`/individual-assignment/${data['TmSchoolHomework.assignmentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      
    }
    linkData.push(data)
  })
  return linkData;
  }

function individualStudent(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    // alert(groupId)
    if (data["TmSingleHwstudStatistics.studentName"] != null) {
      if (data["TmSingleHwstudStatistics.Result"]) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/1`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
      }
      if (data["TmSingleHwstudStatistics.Diag_Student_Result"]) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/2`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
      }
      else{
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
    }
  }
    if (data["AdaptiveStudentReport.Student_Name"] != null) {
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["AdaptiveStudentReport.Student_Name"] = <Link to={`/student/${data['AdaptiveStudentReport.Student_Id']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>
        {data["AdaptiveStudentReport.Student_Name"]} </Link>
      :
      data["AdaptiveStudentReport.Student_Name"] = <Link to={`/student/${data['AdaptiveStudentReport.Student_Id']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/1`}>
      {data["AdaptiveStudentReport.Student_Name"]} </Link>
    }
   
    if (data["TmStudent.fullName"] != null) {
     //  data["TmStudent.fullName"] = <Link to={`/student/${data['TmStudent.fullName']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group[0]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}`}>{data["TmStudent.fullName"]}</Link>
    }

    if (data["TmSchoolHomework.assignmentName"] != null) {
      // data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/
      // ${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].aterange[1]).format("YYYY-MM-DD")}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      if (data["TmSingleHwstudStatistics.Result"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      if (data["TmSingleHwstudStatistics.Overall_Diag_Result"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      if (data["TmSchoolHomework.Diag_ClassScore"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        //data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        data["TmSchoolHomework.assignmentName"] = data["TmSchoolHomework.assignmentName"]
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      else{
          //data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/${data["TmSchoolHomework.teacherId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
          data["TmSchoolHomework.assignmentName"] = data["TmSchoolHomework.assignmentName"]
      }

    }

    linkData.push(data)
  })
  return linkData;
}

function individualStudentAdaptive(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    if(data["TmStudentMockTest.tmStmktkCompletedon"] !=null){
      data["TmStudentMockTest.tmStmktkCompletedon"] = moment(data["TmStudentMockTest.tmStmktkCompletedon"]).format("DD-MM-YYYY")
    }
    if(data["TmStudentMock.tmStmktkCompletedon"] !=null){
      data["TmStudentMock.tmStmktkCompletedon"] = moment(data["TmStudentMock.tmStmktkCompletedon"]).format("DD-MM-YYYY")
    }
    if (data["TmSingleHwstudStatistics.studentName"] != null) {
      if (data["TmSingleHwstudStatistics.Result"]) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/1`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
      }
      else{
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
    }
  }

  


    linkData.push(data)
  })
  return linkData;
}


function summaryData(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
   

  /* ***************************** Student summary changes ***************************** */
  if(data["TmStudentProgress.categoryName"] !=null){
    //console.log("ddddd", parameter[3]?.Datebetween[0])
    if(!parameter[0]?.Student) {
   
      if(parameter[3].Datebetween[0] != undefined) {
       // console.log(parameter[3]?.Datebetween[0]);
        data["TmStudentProgress.categoryName"] = <Link to={`/student-attempts/${data['TmStudentProgress.categoryId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2].Group}/${parameter[3]?.Datebetween[0]}/${parameter[3]?.Datebetween[1]}`}>
        {data["TmStudentProgress.categoryName"]}</Link>
      }
      else {
        data["TmStudentProgress.categoryName"] = <Link to={`/student-attempts/${data['TmStudentProgress.categoryId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2].Group}`}>
        {data["TmStudentProgress.categoryName"]}</Link>
      }
      
    }
    
  }



  if(data["TmStudentProgress.introExpectationText"] !=null){
    if(data["TmStudentProgress.introExpectationText"] == "1111") {
      data["TmStudentProgress.introExpectationText"] = <Col style={{background:"#B4D3B2",textAlign:"center",fontWeight:"bold"}}>EE</Col>
    }
    else if(data["TmStudentProgress.introExpectationText"] == "2222") {
      data["TmStudentProgress.introExpectationText"] = <Col style={{background:"#DAF0F7",textAlign:"center",fontWeight:"bold"}}>ME</Col>
    }
    else if(data["TmStudentProgress.introExpectationText"] == "3333") {
      data["TmStudentProgress.introExpectationText"] = <Col style={{background:"#ECE5C7",textAlign:"center",fontWeight:"bold"}}>PME</Col>
    }
    else if(data["TmStudentProgress.introExpectationText"] == "4444") {
      data["TmStudentProgress.introExpectationText"] = <Col style={{background:"#FFE3E1",textAlign:"center",fontWeight:"bold"}}>NI</Col>
    }
    else {
      data["TmStudentProgress.introExpectationText"] = "";
    }
  }

  if(data["TmStudentProgress.beginerExpectationText"] !=null){
    if(data["TmStudentProgress.beginerExpectationText"] == "1111") {
      data["TmStudentProgress.beginerExpectationText"] = <Col style={{background:"#B4D3B2",textAlign:"center",fontWeight:"bold"}}>EE</Col>
    }
    else if(data["TmStudentProgress.beginerExpectationText"] == "2222") {
      data["TmStudentProgress.beginerExpectationText"] = <Col style={{background:"#DAF0F7",textAlign:"center",fontWeight:"bold"}}>ME</Col>
    }
    else if(data["TmStudentProgress.beginerExpectationText"] == "3333") {
      data["TmStudentProgress.beginerExpectationText"] = <Col style={{background:"#ECE5C7",textAlign:"center",fontWeight:"bold"}}>PME</Col>
    }
    else if(data["TmStudentProgress.beginerExpectationText"] == "4444") {
      data["TmStudentProgress.beginerExpectationText"] = <Col style={{background:"#FFE3E1",textAlign:"center",fontWeight:"bold"}}>NI</Col>
    }
    else {
      data["TmStudentProgress.beginerExpectationText"] = "";
    }
  }

  if(data["TmStudentProgress.intermediateExpectationText"] !=null){
    if(data["TmStudentProgress.intermediateExpectationText"] == "1111") {
      data["TmStudentProgress.intermediateExpectationText"] = <Col style={{background:"#B4D3B2",textAlign:"center",fontWeight:"bold"}}>EE</Col>
    }
    else if(data["TmStudentProgress.intermediateExpectationText"] == "2222") {
      data["TmStudentProgress.intermediateExpectationText"] = <Col style={{background:"#DAF0F7",textAlign:"center",fontWeight:"bold"}}>ME</Col>
    }
    else if(data["TmStudentProgress.intermediateExpectationText"] == "3333") {
      data["TmStudentProgress.intermediateExpectationText"] = <Col style={{background:"#ECE5C7",textAlign:"center",fontWeight:"bold"}}>PME</Col>
    }
    else if(data["TmStudentProgress.intermediateExpectationText"] == "4444") {
      data["TmStudentProgress.intermediateExpectationText"] = <Col style={{background:"#FFE3E1",textAlign:"center",fontWeight:"bold"}}>NI</Col>
    }
    else {
      data["TmStudentProgress.intermediateExpectationText"] = "";
    }
  }


  if(data["TmStudentProgress.advancedExpectationText"] !=null){
    if(data["TmStudentProgress.advancedExpectationText"] == "1111") {
      data["TmStudentProgress.advancedExpectationText"] = <Col style={{background:"#B4D3B2",textAlign:"center",fontWeight:"bold"}}>EE</Col>
    }
    else if(data["TmStudentProgress.advancedExpectationText"] == "2222") {
      data["TmStudentProgress.advancedExpectationText"] = <Col style={{background:"#DAF0F7",textAlign:"center",fontWeight:"bold"}}>ME</Col>
    }
    else if(data["TmStudentProgress.advancedExpectationText"] == "3333") {
      data["TmStudentProgress.advancedExpectationText"] = <Col style={{background:"#ECE5C7",textAlign:"center",fontWeight:"bold"}}>PME</Col>
    }
    else if(data["TmStudentProgress.advancedExpectationText"] == "4444") {
      data["TmStudentProgress.advancedExpectationText"] = <Col style={{background:"#FFE3E1",textAlign:"center",fontWeight:"bold"}}>NI</Col>
    }
    else {
      data["TmStudentProgress.advancedExpectationText"] = "";
    }
  }
  /* ***************************** Student summary changes ***************************** */


    linkData.push(data)
  })
  return linkData;
}


// function getGroup(){

//   const groupListOnLoad = [];
//   let access_token = cookie?.get('token'); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    
//     axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentCurrent.groupId"],"timeDimensions":[],"order":[["TmStudentCurrent.groupId","asc"]],"measures":[],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${schoolId}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${classId}"]},{
//       "member": "TmStudentCurrent.userId","operator": "equals","values": ["${studentId}"]}]} `,
//       { 
//       headers: {
//           'Authorization': `${access_token}`
//         }
//       })
//         .then(res => {
          
//           const groupList = res.data.data;
//           groupList && groupList.map((x) => {
//             groupListOnLoad.push((x)["TmStudentCurrent.groupId"]);
//           })
//           return groupListOnLoad[0];
//       }).
//       catch(error => {
//       });
        
//     }
// }

// function hideLastColumn() {
//   return "tbl"
// }

function increaseWidth(column) {
  if (column.length >= 10) {
    return "assign_name"
  }
  if(column[1]["key"]=="TmSingleHwstudStatistics.percentageString2"){
    return "percentage_column_width"
  }
}

function increaseWidthHideColumn(column) {
  if (column.length >= 10) {
    return "assign_name"
  }
  if(column[1]["key"]=="TmSingleHwstudStatistics.percentageString2"){
    return "percentage_column_width"
  }

}

function increaseWidthHideColumnAdaptive(column) {
  return "tbl_adaptive"

}

function ascending(data) {
  let dataResult = [];
    data.map((dat,i)=>{
      if(dat['title'].includes('Qn ')){
        dataResult.push(dat['title'])
      }
    })  
  let result = dataResult.sort()
  let tableResult = [data[0],data[1]];
  result.map((res,j) => {
    data.map((dataa,i)=>{
      if(res == dataa["title"]){
        tableResult.push(dataa)
      }
    })
  })
  return tableResult
}

function reduceWidth(column) {
  //column.map((col, i) => {
  if (column.length === 3) {
    return "column_overall_chap"
  }
  if (column.length === 4) {
    return "column_overall_topic"
  }
  if (column.length === 5) {
    return "column_prof_chap"
  }
  if (column.length === 6) {
    return "column_prof_topic"
  }
  if (column.length === 8) {
    return "progress_report"
  }
}
function reduceColumn(column) {
  //column.map((col, i) => {
  if (column.length === 4) {
    return "indi_column_chap"
  }
  if (column.length === 5) {
    return "indi_column_topic"
  }
}

var rowData = [];
// function removeRepeat(data, input) {
//   var chapt = [];
//   data.map((dataa, i) => {   
//     var check = (chapt.indexOf(dataa[input]) > -1);
//     if (check) {
//       dataa[input] = "";
//     } else {
//       chapt.push(dataa[input]);
//     }
//     rowData.push(dataa);
//   })
//   return rowData;
// }




// function removeRepeat(data, input) {
//   rowData = [];
//   var chapt = [];
//   data.map((dataa, i) => {
//     // dataa[input1]=dataa[input1].append(dataa[input2])
//     // dataa[input2]=""
//     var check = (chapt.indexOf(dataa[input]) > -1);
//     if (check) {
//       dataa[input] = "";
//     } else {
//       chapt.push(dataa[input]);
//     }
//     rowData.push(dataa);
//   })
//   return rowData;
// }



function removeRepeat(data, input, domain, topic, parameter) {
  let data1=data;
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.domain"]])
          column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
          column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmHwQnAnsStat.chapterName"]])
          column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.domain"]])
              column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
              column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmHwQnAnsStat.chapterName"]])
              column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
            })
            viewby.push("Chapter")
          }
        // }

        // if(parameter=="skill"){
        //   if(data[0]?.hasOwnProperty(domain)){
        //         // alert("domain")
        //         viewby.push("Domain","Student Score","Distinct Questions")
        //         data.map((dataa,i)=>{
        //           row.push([dataa["TmHwQnAnsStat.domain"]])
        //           column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        //         })
        //       }
        //       else if(data[0]?.hasOwnProperty(topic)){
        //           // alert("topic")
        //         data.map((dataa,i)=>{
        //           row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
        //           column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        //         })
        //         // alert("true")
        //         viewby.push("Chapter","Topic","Student Score","Distinct Questions")
        //       }
        //       else{
        //         data.map((dataa,i)=>{
        //           // alert("chapter")
        //           row.push([dataa["TmHwQnAnsStat.chapterName"]])
        //           column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        //         })
        //         viewby.push("Chapter","Student Score","Distinct Questions")
        //       }
        //     }



          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });

      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
  
   /*  columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  }); */
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    domainData.push(data[i])
    for(var j = 0, ln = columnUniques.length; j < ln; j++) {
      // alert("2st")
      if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
        // alert("3rd")
        // data.push(data["order"] = uniques1[j][3])
        data[i].order=columnUniques[j][2]

      }
    }
  }

  dataProficiency=[];

dataProficiency = data;



  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));





  //   rowData = [];
  // let rowDataChapt = [];
  // var dmain = [];
  // var chapt = [];
  // data.map((dataa, i) => {
  //   // dataa[input1]=dataa[input1].append(dataa[input2])
  //   // dataa[input2]=""
  //   var check = (dmain.indexOf(dataa[domain]) > -1);
  //   var checkChap = (chapt.indexOf(dataa[chapt]) > -1);
  //   if (check) {
  //     dataa[domain] = "";
  //   } else {
  //     dmain.push(dataa[domain]);
  //   }
  //   if (checkChap) {
  //     dataa[chapt] = "";
  //   } else {
  //     chapt.push(dataa[chapt]);
  //   }
  //   rowData.push(dataa);
  //   rowDataChapt.push(dataa);
  // })
  // return rowData, rowDataChapt;
}


function removeRepeatTopicProgress(data, input, domain, topic, parameter) {
  console.log("rmvrpt data:", data);
  console.log("rmvrpt input", input);
  console.log("rmvrpt domain", domain);
  console.log("rmvrpt topic", topic);
  console.log("rmvrpt parameter", parameter);
  let row = [];
  let column = [];

  if (parameter === "all") {
    if (data[0]?.hasOwnProperty(domain)) {
      viewby.push("Domain", "Student Score", "Distinct Questions");
      data.map((dataa, i) => {
        row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"]]);
        column.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"],
        ]);
        return null;
      });
    } else if (data[0]?.hasOwnProperty(topic)) {
      console.log(
        "inside data[0]?.hasOwnProperty(topic)",
        data[0]?.hasOwnProperty(topic)
      );
      data.map((dataa, i) => {
        row.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"],
        ]);
        column.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"],
        ]);
        return null;
      });

      viewby.push("Chapter", "Topic", "Student Score", "Distinct Questions");
    } else {
      data.map((dataa, i) => {
        row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"]]);
        column.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"],
        ]);
        return null;
      });
      viewby.push("Chapter", "Student Score", "Distinct Questions");
    }
  }

  if (parameter[0] === "topic") {
    console.log("inside params = topic");
    console.log(
      "data[0]?.hasOwnProperty(domain)",
      data[0]?.hasOwnProperty(topic)
    );
    if (data[0]?.hasOwnProperty(domain)) {
      viewby.push("Domain");
      data.map((dataa, i) => {
        row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"]]);
        column.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"],
        ]);
        return null;
      });
    } else if (data[0]?.hasOwnProperty(topic)) {
      data.map((dataa, i) => {
        row.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"],
        ]);
        column.push([
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],
          dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"],
        ]);
        return null;
      });
      // alert("true")
      viewby.push("Chapter", "Topic");
    } else {
      data.map((dataa, i) => {
        // alert("chapter")
        row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"]]);
        column.push([
          dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],
          dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"],
        ]);
        return null;
      });
      viewby.push("Chapter");
    }
  }

  // let proficiencyIdName=[];

  // ---------------chapter topic ------------
  // var uniques = [];
  var itemsFound = {};
  for (var i = 0, l = row.length; i < l; i++) {
    var stringified = JSON.stringify(row[i]);
    if (itemsFound[stringified]) {
      continue;
    }
    rowUniques.push(row[i]);
    itemsFound[stringified] = true;
  }

  // ---------------proficiency id name ------------
  // var uniques1 = [];
  var itemsFound1 = {};
  for (var i = 0, l = column.length; i < l; i++) {
    var stringified1 = JSON.stringify(column[i]);
    if (itemsFound1[stringified1]) {
      continue;
    }
    columnUniques.push(column[i]);
    itemsFound1[stringified1] = true;
  }
  // uniques.sort((a, b) => a[0].localeCompare(b[0]));
  // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

  // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
  rowUniques.sort(function (a, b) {
    return a.toString().localeCompare(b); //using String.prototype.localCompare()
  });
  rowSpan = {};
  rowUniques.map((rowUniqs, i) => {
    if (rowUniqs[0] in rowSpan === true) {
      rowSpan[rowUniqs[0]] = rowSpan[rowUniqs[0]] + 1;
    } else {
      rowSpan[rowUniqs[0]] = 1;
    }
    return null;
  });

  columnUniques = columnUniques.sort(function (a, b) {
    return a[0] - b[0];
  });

  for (var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i);
  }
  for (var i = 0, l = data.length; i < l; i++) {
    domainData.push(data[i]);
  }

  dataProficiency = [];

  dataProficiency = data;

  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));
}

function removeRepeatSkillProgress(data, input, domain, topic, parameter) {
  // alert("skill")
  let data1=data;
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestions.tmSthwqtDomainName"]])
          column.push([dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestions.tmSthwqtSkillName"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestions.tmSthwqtTopicName"]])
          column.push([dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestions.tmSthwqtSkillName"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"]])
          column.push([dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestions.tmSthwqtSkillName"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestions.tmSthwqtDomainName"]])
              column.push([dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestions.tmSthwqtSkillName"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestions.tmSthwqtTopicName"]])
              column.push([dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestions.tmSthwqtSkillName"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"]])
              column.push([dataa["TmStudenthomeworkquestions.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestions.tmSthwqtSkillName"]])
            })
            viewby.push("Chapter")
          }
      



          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });
      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    domainData.push(data[i])
    // for(var j = 0, ln = columnUniques.length; j < ln; j++) {
    //   // alert("2st")
    //   if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
    //     // alert("3rd")
    //     // data.push(data["order"] = uniques1[j][3])
    //     data[i].order=columnUniques[j][2]

    //   }
    // }
  }

  dataProficiency=[];

dataProficiency = data;



  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));



}

function removeRepeatProficiencyProgress(data, input, domain, topic, parameter) {
  // alert("skill")
  let data1=data;
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestions.tmSthwqtDomainName"]])
          column.push([dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestions.tmSthwqtTopicName"]])
          column.push([dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"]])
          column.push([dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestions.tmSthwqtDomainName"]])
              column.push([dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestions.tmSthwqtTopicName"]])
              column.push([dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmStudenthomeworkquestions.tmSthwqtChapterName"]])
              column.push([dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestions.tmSthwqtProficiencyName"]])
            })
            viewby.push("Chapter")
          }
      



          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });
      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    domainData.push(data[i])
    // for(var j = 0, ln = columnUniques.length; j < ln; j++) {
    //   // alert("2st")
    //   if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
    //     // alert("3rd")
    //     // data.push(data["order"] = uniques1[j][3])
    //     data[i].order=columnUniques[j][2]

    //   }
    // }
  }

  dataProficiency=[];

dataProficiency = data;



  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));



}

function removeRepeatSkillProgressStudent(data, input, domain, topic, parameter) {
  // alert("skill")
  let data1=data;
  console.log(data1,"query data")
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"]])
          column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillName"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"]])
          column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillName"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"]])
          column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillName"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"]])
              column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillName"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"]])
              column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillName"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"]])
              column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtSkillName"]])
            })
            viewby.push("Chapter")
          }
      

      console.log(row,"row chap topic")
      console.log(column,"column prof")


          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });
      console.log(rowUniques,"multiple time ")
      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
        console.log(rowSpan,"rowSpan")
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
    console.log(rowUniques,"sort chapter")
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  console.log(columnUniques,"sort profi")
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  console.log(rowUniques,"uniques1 id")
  console.log(data,"data profi")
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    console.log(data[i],"data[i]")
    domainData.push(data[i])
    // for(var j = 0, ln = columnUniques.length; j < ln; j++) {
    //   // alert("2st")
    //   if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
    //     // alert("3rd")
    //     // data.push(data["order"] = uniques1[j][3])
    //     data[i].order=columnUniques[j][2]

    //     // console.log(data[i],"data[i]")
    //   }
    // }
  }
  console.log(domainData,"data profi")

  dataProficiency=[];

dataProficiency = data;


    console.log(rowUniques,"chapterunique")
    console.log(columnUniques,"chapterunique")

  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));

// console.log(new Set(proficiency),"chaptertopicarray")
  console.log(uniqueChapters,"chaptertopicarray")
  console.log(uniqueProficiency,"chaptertopicarray")


}

function removeRepeatProficiencyProgressStudent(data, input, domain, topic, parameter) {
  // alert("skill")
  let data1=data;
  console.log(data1,"query data")
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"]])
          column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"]])
          column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"]])
          column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtDomainName"]])
              column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"],dataa["TmStudenthomeworkquestionTest.tmSthwqtTopicName"]])
              column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtChapterName"]])
              column.push([dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyId"],dataa["TmStudenthomeworkquestionTest.tmSthwqtProficiencyName"]])
            })
            viewby.push("Chapter")
          }
      

      console.log(row,"row chap topic")
      console.log(column,"column prof")


          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });
      console.log(rowUniques,"multiple time ")
      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
        console.log(rowSpan,"rowSpan")
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
    console.log(rowUniques,"sort chapter")
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  console.log(columnUniques,"sort profi")
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  console.log(rowUniques,"uniques1 id")
  console.log(data,"data profi")
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    console.log(data[i],"data[i]")
    domainData.push(data[i])
    // for(var j = 0, ln = columnUniques.length; j < ln; j++) {
    //   // alert("2st")
    //   if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
    //     // alert("3rd")
    //     // data.push(data["order"] = uniques1[j][3])
    //     data[i].order=columnUniques[j][2]

    //     // console.log(data[i],"data[i]")
    //   }
    // }
  }
  console.log(domainData,"data profi")

  dataProficiency=[];

dataProficiency = data;


    console.log(rowUniques,"chapterunique")
    console.log(columnUniques,"chapterunique")

  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));

// console.log(new Set(proficiency),"chaptertopicarray")
  console.log(uniqueChapters,"chaptertopicarray")
  console.log(uniqueProficiency,"chaptertopicarray")


}

function removeRepeatSkill(data, input, domain, topic, parameter) {
  // alert("skill")
  let data1=data;
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.domain"]])
          column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
          column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmHwQnAnsStat.chapterName"]])
          column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.domain"]])
              column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
              column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmHwQnAnsStat.chapterName"]])
              column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
            })
            viewby.push("Chapter")
          }
      



          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });
      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    domainData.push(data[i])
    // for(var j = 0, ln = columnUniques.length; j < ln; j++) {
    //   // alert("2st")
    //   if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
    //     // alert("3rd")
    //     // data.push(data["order"] = uniques1[j][3])
    //     data[i].order=columnUniques[j][2]

    //   }
    // }
  }

  dataProficiency=[];

dataProficiency = data;



  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));



}

function removeRepeatt(data, input, domain) {
  rowData = [];
  let rowDataChapt = [];
  var dmain = [];
  var chapt = [];
  data.map((dataa, i) => {
    var check = (dmain.indexOf(dataa[domain]) > -1);
    var checkChap = (chapt.indexOf(dataa[input]) > -1);
    if (check) {
      dataa[domain] = "";
    } else {
      dmain.push(dataa[domain]);
    }
    if (checkChap) {

      rowData[i-1]["TmHwQnAnsStat.chapterName"]="";
      chapt.push(dataa[input]);
    } else {
      chapt.push(dataa[input]);
    }
    rowData.push(dataa);
    rowDataChapt.push(dataa);
  })
  return rowData, rowDataChapt;

}


function changeTitle(array) {
  var res = [];
  array.map((arr, i) => {

    if (arr.title == "Assignments Completed") {
      arr.title = "Ass. Completed"
    }

    if (arr.title == "Assignments Not Attempted") {
      arr.title = "Not Attempted"
    }
    if (arr.title == "Student Score") {
      arr.title = "Student Average"
    }
    if (arr.title == "Class Score") {
      arr.title = "Class Average"
    }
    if (arr.title == "Distinct Questions") {
      arr.title = "No Of Questions"
    }
    res.push(arr)
  })
  return res
}

function changeColumn(array) {
  var res = [];
  array.map((arr, i) => {

    if (arr.title == "  stu Student Email") {
      arr.title = "Student Email"
    }
    if (arr.title == "  Username"){
      arr.title = "Student Username"
    }

    
    res.push(arr)
  })
  return res
}


function manipulateInput(array, jsonArray, resultSet) {debugger;
  let progressType = 'circle';
  if (resultSet.loadResponse.annotation?.dimensions['TmHwQnAnsStat.skillName']?.shortTitle != undefined || resultSet.loadResponse.annotation?.dimensions['TmHwQnAnsStat.proficiencyName']?.shortTitle != undefined) {
    progressType = 'circle';
  } else {
    progressType = '';
  }

  let i, key;
  var jsonArray = JSON.stringify(jsonArray);
  var jsonParsedArray = JSON.parse(jsonArray);
  var MaximumValue = 0;
  for (i = 0; i < jsonParsedArray.length; i++) {
    for (key in jsonParsedArray[i]) {
      if (jsonParsedArray[i].hasOwnProperty(key)) {
        if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1) && jsonParsedArray[i][key] > MaximumValue) {
          MaximumValue = jsonParsedArray[i][key];
        }

      }
    }
  }


  var res = [];
  {
    array.map((arr, i) => {
      arr.title = arr.shortTitle
      var key = arr.key;
      if (key) {

        if (key == 'TmHwQnAnsStat.averageScore') {
          arr.render = value => renderFunction(value, 'studentscore', value, '%', progressType)
        }
        else if (key.indexOf('.average') != -1 && key != 'TmHwQnAnsStat.averageScore') {
          arr.render = value => renderFunction(value, 'exception', value, '%', progressType)
        }

        else if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1)) {
          arr.render = value => renderFunction2(value) // renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
        }

      }
      var chi = [];
      {
        arr.children &&
          arr.children.map((child, i) => {
            child.title = child.shortTitle;
            if (child.type == 'number' && child.format == 'percent') {
              child.render = value => renderFunction(value, 'exception', value, '%', progressType)
            }
            else
              if (child.type == 'number' && child.format == undefined) {
                child.render = value => renderFunction2(value) // => renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
              }
            chi.push(child)
          })
        arr.children = chi;
      }
      res.push(arr)
    })
  }
  return res;
}

function manipulateInputProgress(array, jsonArray, resultSet) {debugger;
  if(array[5].title == '  Remediation Completed?'){debugger;
    let arr = array[4]
    array[4]=array[7]
    array[7]=array[6]
    array[6]=array[5]
    array[5]=arr
  }
  let progressType = 'circle';
  if (resultSet.loadResponse.annotation?.dimensions['TmHwQnAnsStat.skillName']?.shortTitle != undefined || resultSet.loadResponse.annotation?.dimensions['TmHwQnAnsStat.proficiencyName']?.shortTitle != undefined) {
    progressType = 'circle';
  } else {
    progressType = '';
  }

  let i, key;
  var jsonArray = JSON.stringify(jsonArray);
  var jsonParsedArray = JSON.parse(jsonArray);
  var MaximumValue = 0;
  for (i = 0; i < jsonParsedArray.length; i++) {
    for (key in jsonParsedArray[i]) {
      if (jsonParsedArray[i].hasOwnProperty(key)) {
        if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1) && jsonParsedArray[i][key] > MaximumValue) {
          MaximumValue = jsonParsedArray[i][key];
        }

      }
    }
  }


  var res = [];
  {
    array.map((arr, i) => {
      arr.title = arr.shortTitle
      var key = arr.key;
      if (key) {

        if (key == 'TmHwQnAnsStat.averageScore') {
          arr.render = value => renderFunction(value, 'studentscore', value, '%', progressType)
        }
        else if (key.indexOf('.average') != -1 && key != 'TmHwQnAnsStat.averageScore') {
          arr.render = value => renderFunction(value, 'exception', value, '%', progressType)
        }

        else if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1)) {
          arr.render = value => renderFunction2(value) // renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
        }

      }
      var chi = [];
      {
        arr.children &&
          arr.children.map((child, i) => {
            child.title = child.shortTitle;
            if (child.type == 'number' && child.format == 'percent') {
              child.render = value => renderFunction(value, 'exception', value, '%', progressType)
            }
            else
              if (child.type == 'number' && child.format == undefined) {
                child.render = value => renderFunction2(value) // => renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
              }
            chi.push(child)
          })
        arr.children = chi;
      }
      res.push(arr)
    })
  }
  return res;
}


function renderFunction2(text) {

  return <CenterWrapper type={text} />;

}

function renderFunction(text, status, value, percentage, progressType) {
  let strokeColor = '';
  let strokeColor2 = '';
  if (Math.round(value) >= 71) { // 71 - 100
    strokeColor = '#92a055';
  }
  else if (Math.round(value) >= 50) {  // 50 - 70  
    strokeColor = '#dc8147';
  }
  else {   // 1 - 49
    strokeColor = '#9e3433';
  }

  if (status == 'studentscore') {
    strokeColor2 = '#3B76AF';
  } else {
    strokeColor2 = '#b7bb5a';
  }

  

  if (text) {
    if (progressType == 'circle') {
      return <div className="textCenter"><Progress percent={text} type='circle' strokeColor={strokeColor} status={status} format={percent => `${Math.round(value)} ${percentage}`} /></div>
    } else {
      return <div className="textCenter"> <Progress percent={text} status={status} strokeColor={strokeColor2} format={percent => `${Math.round(value)} ${percentage}`} /></div>
    }
  } else {
    return <div className="textCenter">0</div>
  }
}


let calculatePercentage = (maxNumber, currentNumber) => Math.round((currentNumber / maxNumber) * 100);


const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map(key => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = Component => ({ resultSet, error }) => (pivotConfig) => (parameters) =>
  (resultSet && <Component resultSet={resultSet} pivotConfig={pivotConfig}  parameters={parameters}/>) ||
  (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState }) => {
  const { query, chartType, pivotConfig, parameters } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  let history = useHistory();
  if (renderProps.error && renderProps.error == 'Error: Invalid token') {
    history.push('/home');
  }
  return component && renderChart(component)(renderProps)(pivotConfig)(parameters);
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
};
ChartRenderer.defaultProps = {
  vizState: {},
};
export default ChartRenderer;
