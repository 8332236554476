import React, { PureComponent } from "react";
// import TreeMap from "react-d3-treemap";
// import "react-d3-treemap/dist/react.d3.treemap.css"; // Breadcrumb, // Button, Radio, Checkbox, Card, // , Space
import { Spin, Row, Col, Layout, Menu, Select, DatePicker } from "antd";
import Grid from "@material-ui/core/Grid";
//import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartRendererTabbie";
import ChartRendererCustomised from "../components/ChartRendererTabbie";
import { Table, Divider, Input } from "antd";
// import Link from 'umi/link';
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import cookie from "js-cookie";
import moment from "moment";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import schoolValue from "../pages/Student";
import ReactToPrint from "react-to-print";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import SinglePage from "../components/ChartRendererTabbie";
// import PrintButton from "../components/PrintButton";

import { Redirect } from "react-router";
//import { authenticate, isAuth } from '../components/helpers';
import {
  baseUrlCube,
  homeUrlCube,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";

import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;

const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;



class StudentAttempts extends PureComponent {

  
  constructor(props) {


    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      studentReport: [],
      isRedirect403: false,
      collapsed: true,
      schoolList: [],
      classList: [],
      groupList: [],
      assignmentList: [],
      dateRangeList: [
        moment().subtract(12, "months"),
        moment().startOf("second"),
      ], //undefined, // ["2021-04-05", "2021-04-20"],
      school: [],
      loader: false,
      selectedSchool: [],
      selectedLevel: [],
      schoolName: [],
      selectedSchoolID: "",
      selectedLevelID: "4",
      selectedSchoolValue: "",
      selectedClassValue: "",
      selectedGroupValue: "",
      selectedClassID: "",
      selectedGroupID: "",
      class: [],
      selectedClass: [""],
      group: [],
     // selectedGroup: [""],
     selectedGroup: [this.props.match.params.groupId?.toString()],
      selectedDateRange: ["", ""],
      selectedAssignment: [""],
      selectedAssignmentLabel: "",
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
       dateRangeArr: [this.props.match.params.sdateRange?.toString(),this.props.match.params.edateRange?.toString()],
       //  dateRangeArr: [],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      ifAdaptive: 0,
    };

    
  }



  

  printOrder = () => {
    let orderHTML = "";
    const printableElements = document.getElementById("printme").innerHTML;
    orderHTML =
      '<html><head><title></title></head><body style="padding:20px">' +
      printableElements +
      "</body></html>";
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage;
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onChangeViweBy = (value) => {
    this.setState({
      viewBy: [value],
    });

    if (value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (value == "skill") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.skillName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.skillName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }
  };

  onChangeAggregateBy = (value) => {
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value],
      });
    }
  };

  onChangeLevel = (value, option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedLevel: [option.value.toString()],
      selectedLevelID: option.value.toString(),
      selectedLevelValue: option.children.toString(),
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };

    let access_token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4";
    axios
      .get(
        `${homeUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentMock.tmStmktkStandardId","TmStudentMock.tmStmktkStandardName"],"order":{"TmStudentMock.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkSchoolId","operator":"equals","values":["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    // let access_token = cookie?.get('token');
    let access_token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4";
    //   loader: false,
    // });

    // const schoolList = response;
    // let schoolId = schoolList[0]["TmSchool.schoolId"];
    // let schoolName = schoolList[0]["TmSchool.schoolName"];
    // //        alert(schoolName);
    //   schoolName: schoolName,
    // });
    // // Assign School list
    // });

    // });

    /*************** Class Section  *************/
    axios
      .get(
        `${homeUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentMock.tmStmktkStandardId","TmStudentMock.tmStmktkStandardName"],"order":{"TmStudentMock.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkSchoolId","operator":"equals","values":["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStudentMock.tmStmktkStandardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStudentMock.tmStmktkStandardId"];
        let className = classList[0]["TmStudentMock.tmStmktkStandardName"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });

        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStudentMock.tmStmktkStandardId"] != undefined) {
              classListOnLoad.push(
                x["TmStudentMock.tmStmktkStandardId"]?.toString()
              );
            }
          });
        this.setState({ selectedClass: [classListOnLoad[0]] });

        /*************** Group Section  *************/

        axios
          .get(
            `${homeUrlCube}cubejs-api/v1/load?query=
                {"dimensions":["TmStudentMock.tmStmktkGroupId","TmStudentMock.tmStmktkGroupName"],"order":{"TmStudentMock.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkSchoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentMock.tmStmktkStandardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`,
            {
              // {"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `, {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const groupList = res.data.data;
            this.setState({ groupList: groupList });
            // });
            const groupListOnLoad = [];
            groupList &&
              groupList.map((x) => {
                groupListOnLoad.push(
                  x["TmStudentMock.tmStmktkGroupName"].toString()
                );
              });

            this.setState({
             /*  selectedGroup: ["0"], */
              selectedGroupID: ["0"],
              selectedGroupValue: ["All"],
            });
          })
          .catch((error) => {});
        /*************** Group Section  *************/

        /*************** Assignment Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"], "order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              //axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format('YYYY-MM-DD')}","${this.state.dateRangeList[1].toString('YYYY-MM-DD')}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmHomeworkgroup.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`, {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            // const dateRangeList = res.data.data;
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };

  onChangeClass = (option) => {
    this.state.ifAdaptive = 0;
    this.state.selectedClass = [option.value.toString()];

    this.state.selectedClassValue = option.children.toString();
    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: {
        "TmSchoolGroups.classCount": "desc",
      },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    // let access_token = cookie?.get('token'); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let access_token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4";
    let groupQuery = "";

    // groupQuery = `cubejs-api/v1/load?query={"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${option.value}"]}],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format('YYYY-MM-DD')}","${this.state.dateRangeList[1].format('YYYY-MM-DD')}"]}],"segments":["TmSchoolHomework.assgnClosed"],"dimensions":["TmSchoolHomework.groupId","TmSchoolHomework.group"],"order":[["TmSchoolHomework.group","asc"]]}`;
    groupQuery = `cubejs-api/v1/load?query=
        {"dimensions":["TmStudentMock.tmStmktkGroupId","TmStudentMock.tmStmktkGroupName"],"order":{"TmStudentMock.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkSchoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentMock.tmStmktkStandardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;

    // {"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    axios
      .get(`${homeUrlCube + groupQuery}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["0"],
          selectedAssignment: ["0"],
        });

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"], "order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              //axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format('YYYY-MM-DD')}","${this.state.dateRangeList[1].toString('YYYY-MM-DD')}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmHomeworkgroup.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`, {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            // const dateRangeList = res.data.data;
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});
  };

  onChangeGroup = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedGroup: [option.value],
      selectedGroupValue: option.label,
    });
    // let access_token = cookie?.get('token');
    let access_token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4";
    if (option.value == 0) {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
            "YYYY-MM-DD"
          )}","${this.state.dateRangeList[1].format(
            "YYYY-MM-DD"
          )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
            this.state.selectedSchool
          }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
            this.state.selectedClass
          }"]}]}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        )
        .then((res) => {
          const assignmentList = res.data.data;
          this.setState({ assignmentList: assignmentList });
          this.setState({ selectedAssignment: ["0"] });
        })
        .catch((error) => {});
    } else {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
            "YYYY-MM-DD"
          )}","${this.state.dateRangeList[1].format(
            "YYYY-MM-DD"
          )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
            this.state.selectedSchool
          }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
            this.state.selectedClass
          }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
            option.value
          }"]}]}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        )
        .then((res) => {
          const assignmentList = res.data.data;
          this.setState({ assignmentList: assignmentList });
          this.setState({ selectedAssignment: ["0"] });
        })
        .catch((error) => {});
    }
  };

  onDateRangeChange = (value, dateString) => {
    this.state.ifAdaptive = 0;
    dateString = dateString?.toString().split(",");
    this.setState({ dateRangeList: value }); //[dateString.toString()]
    if (dateString[0] != "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        this.state.selectedGroup
      }"]}]}`;
    } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`;
    } else {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    }
    // let access_token = cookie?.get('token'); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let access_token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4";
    axios
      .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });
        if (assignmentList.length > 0) {
          if (this.state.selectedGroup[0].toString() == "0") {
            this.setState({ selectedAssignment: ["0"] });
          } else {
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            this.setState({ selectedAssignment: ["0"] });
          }
        } else {
          this.setState({ selectedAssignment: ["0"], assignmentList: null });
        }
      })
      .catch((error) => {});
  };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value == "0") {
      this.setState({
        selectedAssignment: ["0"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
      });
      this.state.selectedAssignment = [value.value.toString()];
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }

      this.state.selectedAssignmentLabel = value.label;
    }
  };
  renderRedirect = () => {
    return <Redirect to="/home" />;
  };

  componentDidMount() {

    


    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isAssignmentTrue = false;
    this.setState({ loginUser: localStorage.getItem("username") });
    let loginUserName = localStorage.getItem("user");
    let loginUserId = "";
    loginUserId = loginUserName;


    //  let access_token = cookie?.get('token');
    let access_token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4";

    /*  
            axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmProfiles.shortname"],"timeDimensions":[],"order":{"TmProfiles.shortname":"asc"},"filters":[{"member":"TmProfiles.userId","operator":"equals","values":["${loginUserId}"]}]}`,{
            headers: {
                'Authorization': `${access_token}`
            }
            })
            .then(res => {
                isSchoolTrue=true;
                const loginUser=res.data.data[0]["TmProfiles.shortname"];
                this.setState({loginUser:loginUser})
                localStorage.setItem('username',loginUser)
                
            }).
            catch(error => {
            });
        */
    axios
      .get(
        `${homeUrlCube}cubejs-api/v1/load?query={"dimensions":["TmSchool.schoolId","TmSchool.schoolName"],"order":{"TmSchool.schoolId":"asc"},"filters":[{"member":"TmSchool.schoolId","operator": "equals","values": ["${this.props?.match?.params?.schoolId}"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const schoolList = res.data.data;
        let schoolId = schoolList[0]["TmSchool.schoolId"];
        let schoolName = schoolList[0]["TmSchool.schoolName"];

        this.setState({
          schoolName: schoolName,
        });
        // Assign School list
        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });

        this.setState({
          selectedSchoolID: schoolId.toString(),
          selectedSchoolValue: schoolName.toString(),
        });
        /*************** Class Section  *************/

        axios
          .get(
            `${homeUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentMock.tmStmktkStandardId","TmStudentMock.tmStmktkStandardName"],"order":{"TmStudentMock.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkSchoolId","operator":"equals","values": ["${this.state.selectedSchoolID}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            isClassTrue = true;
            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStudentMock.tmStmktkStandardId"] != null) {
                  classList.push(x);
                }
              });

            let classId = classList[0]["TmStudentMock.tmStmktkStandardId"];
            let className = classList[0]["TmStudentMock.tmStmktkStandardName"];
            this.setState({ classList: classList });
            this.setState({
              selectedClassID: classId.toString(),
              selectedClassValue: className.toString(),
            });

            let classListOnLoad = [];
            classList &&
              classList.map((x) => {
                if (x["TmStudentMock.tmStmktkStandardId"] != undefined) {
                  classListOnLoad.push(
                    x["TmStudentMock.tmStmktkStandardId"]?.toString()
                  );
                }
              });
            this.setState({ selectedClass: [classListOnLoad[0]] });

            /*************** Group Section  *************/

            axios
              .get(
                `${homeUrlCube}cubejs-api/v1/load?query=
                {"dimensions":["TmStudentMock.tmStmktkGroupId","TmStudentMock.tmStmktkGroupName"],"order":{"TmStudentMock.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMock.tmStmktkSchoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentMock.tmStmktkStandardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                isGroupTrue = true;
                this.setState({
                  loader: true,
                });
                const groupList = res.data.data;
                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList &&
                  groupList.map((x) => {
                    groupListOnLoad.push(
                      x["TmStudentMock.tmStmktkGroupName"].toString()
                    );
                  });

                this.setState({
                /*   selectedGroup: ["0"], */
                  selectedGroupID: ["0"],
                  selectedGroupValue: ["All"],
                });
              })
              .catch((error) => {});
            /*************** Group Section  *************/

            /*************** Assignment Section  *************/

            axios
              .get(
                `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                  "YYYY-MM-DD"
                )}","${this.state.dateRangeList[1].format(
                  "YYYY-MM-DD"
                )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                  this.state.selectedSchool
                }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                  this.state.selectedClass
                }"]}]} `,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                isAssignmentTrue = true;
                const assignmentList = res.data.data;

                this.setState({ assignmentList: assignmentList });

                const assignmentListOnLoad = [];
                assignmentList &&
                  assignmentList.map((x) => {
                    assignmentListOnLoad.push(
                      x["TmSchoolHomework.assignmentId"].toString()
                    );
                  });

                this.setState({ selectedAssignment: ["0"] });

                if (
                  isSchoolTrue &&
                  isClassTrue &&
                  isGroupTrue &&
                  isAssignmentTrue == true
                ) {
                  this.setState({
                    loader: true,
                  });
                }
              })
              .catch((error) => {});

            /*************** Assignment Section  *************/
          })
          .catch((error) => {
            this.setState({
              loader: true,
            });
          });

        /*************** Class Section  *************/
      })
      .catch((error) => {
        if (error && error == "Error: Request failed with status code 403") {
          this.setState({ isRedirect403: true, loader: false });
        }
      });
  }

  render() {
    if (this.state.isRedirect403) {
      return <Redirect to="/home" />;
    }
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: this.state.dateRangeList,
      });
    }

    let dateRangeArrayBoxesHome = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayBoxesHome.push({
        dimension: "TmStudentMock.tmStmktkStartedon",
      });
    } else {
      dateRangeArrayBoxesHome.push({
        dimension: "TmStudentMock.tmStmktkStartedon",
        dateRange: this.state.dateRangeList,
      });
    }

    let dateRangeArrayQuestion = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesAdaptiveScore = [];
    let dateRangeArrayBoxesDigScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestion = [];
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
      });

      dateRangeArrayBoxesDigScore = [];
      dateRangeArrayBoxesDigScore.push({
        dimension: "DiagnosticReport.diag_Publish_Date",
      });
    } else {
      dateRangeArrayQuestion = [];
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesDigScore = [];
      dateRangeArrayBoxesDigScore.push({
        dimension: "DiagnosticReport.diag_Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptive = [];
    let dateRangeArrayBoxesAdaptiveReport = [];
    let dateRangeArrayBoxesDigReport = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });

      dateRangeArrayBoxesAdaptive = [];
      dateRangeArrayBoxesAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });

      dateRangeArrayBoxesAdaptiveReport = [];
      dateRangeArrayBoxesAdaptiveReport.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
      });
      dateRangeArrayBoxesDigReport = [];
      dateRangeArrayBoxesDigReport.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesAdaptive = [];
      dateRangeArrayBoxesAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesAdaptiveReport = [];
      dateRangeArrayBoxesAdaptiveReport.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesDigReport = [];
      dateRangeArrayBoxesDigReport.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dimensionArray = [];
    if (this.state.aggregateBy[0] == "domain") {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy[0] == "chapter") {
      dimensionArray.push("TmHwQnAnsStat.chapterName");
    } else if (this.state.aggregateBy[0] == "topic") {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    } else {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicAdaptive = [];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicAdaptive.push(
        "AdaptiveReport.Domain_Name",
        "AdaptiveReport.Domain_result"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    if (this.state.viewBy[0] == "skill") {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy[0] == "proficiency") {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");
    } else {
    }

    let filter = [];
    if (this.state.selectedSchool.length >= 1) {
      filter.push({
        member: "TmSchool.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass.length > 0) {
      filter.push({
        member: "TmStandard.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup.length > 0) {
      filter.push({
        member: "TmSchoolGroups.group",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    let chartHomeFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartHomeFilter.push({
        member: "TmStudentMock.tmStmktkSchoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartHomeFilter.push({
        member: "TmStudentMock.tmStmktkStandardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartHomeFilter.push({
        member: "TmStudentMock.tmStmktkGroupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    /* *************** Chapter Filter ************** */
    let chapterFilter = [];

    if (this.props?.match?.params?.categoryId.toString() != "0") {
      chapterFilter.push({
        member: "TmAllChapterList.categoryId",
        operator: "equals",
        values: [this.props?.match?.params?.categoryId],
      });
    }
    /* *************** Chapter Filter ************** */

    /* *************** Chapter Summary Filter ************** */
    let dateRangeArrayChapterOverview = [];
    if (this.state.dateRangeArr[0] != null) {

      if(this.state.selectedGroup[0]?.toString() != "0") {
              dateRangeArrayChapterOverview.push({
                dimension: "TmChapterOverview.completedDate",
                dateRange: [
                  this.state.dateRangeArr[0],
                  this.state.dateRangeArr[1],
                ],
              });
      }
      else {
              dateRangeArrayChapterOverview.push({
                dimension: "TmChapterOverviewNoGrp.completedDate",
                dateRange: [
                  this.state.dateRangeArr[0],
                  this.state.dateRangeArr[1],
                ],
              });
      }

      
    }

    let chapterSummaryReportFilter = [];

    if(this.state.selectedGroup[0]?.toString() != "0") {
              if (this.props?.match?.params?.schoolId.toString() != "0") {
                chapterSummaryReportFilter.push({
                  member: "TmChapterOverview.schoolId",
                  operator: "equals",
                  values: [this.props?.match?.params?.schoolId.toString()],
                });
              }
          
              if (this.props?.match?.params?.standardId.toString() != "0") {
                chapterSummaryReportFilter.push({
                  member: "TmChapterOverview.gradeId",
                  operator: "equals",
                  values: [this.props?.match?.params?.standardId],
                });
              }
              if (this.props?.match?.params?.categoryId.toString() != "0") {
                chapterSummaryReportFilter.push({
                  member: "TmChapterOverview.categoryId",
                  operator: "equals",
                  values: [this.props?.match?.params?.categoryId],
                });
              }
              if (this.state.selectedGroup[0]?.toString() != "0") {
                chapterSummaryReportFilter.push({
                  member: "TmChapterOverview.groupId",
                  operator: "equals",
                  values: [this.state.selectedGroup[0]],
                });
              }
              chapterSummaryReportFilter.push({
                member: "TmChapterOverview.attemptedReportCount",
                operator: "gt",
                values: ["0"],
              });
    }
    else {
            if (this.props?.match?.params?.schoolId.toString() != "0") {
              chapterSummaryReportFilter.push({
                member: "TmChapterOverviewNoGrp.schoolId",
                operator: "equals",
                values: [this.props?.match?.params?.schoolId.toString()],
              });
            }
        
            if (this.props?.match?.params?.standardId.toString() != "0") {
              chapterSummaryReportFilter.push({
                member: "TmChapterOverviewNoGrp.gradeId",
                operator: "equals",
                values: [this.props?.match?.params?.standardId],
              });
            }
            if (this.props?.match?.params?.categoryId.toString() != "0") {
              chapterSummaryReportFilter.push({
                member: "TmChapterOverviewNoGrp.categoryId",
                operator: "equals",
                values: [this.props?.match?.params?.categoryId],
              });
            }
            chapterSummaryReportFilter.push({
              member: "TmChapterOverviewNoGrp.attemptedReportCount",
              operator: "gt",
              values: ["0"],
            });
    }

    
    
    /* *************** Chapter Summary Filter ************** */

    /* *************** Student List Filter ************** */
    let dateRangeArrayStudentList = [];
    if (this.state.dateRangeArr[0] != null) {

      if(this.state.selectedGroup[0]?.toString() != "0") {
              dateRangeArrayStudentList.push({
                dimension: "TmChapterOverview.completedDate",
                dateRange: [
                  this.state.dateRangeArr[0],
                  this.state.dateRangeArr[1],
                ],
              });
      }
      else {
              dateRangeArrayStudentList.push({
                dimension: "TmChapterOverviewNoGrp.completedDate",
                dateRange: [
                  this.state.dateRangeArr[0],
                  this.state.dateRangeArr[1],
                ],
              });
      }
      
      
    }

    let studentListReportFilter = [];
    if(this.state.selectedGroup[0]?.toString() != "0") {
              if (this.props?.match?.params?.schoolId.toString() != "0") {
                studentListReportFilter.push({
                  member: "TmChapterOverview.schoolId",
                  operator: "equals",
                  values: [this.props?.match?.params?.schoolId.toString()],
                });
              }
          
              if (this.props?.match?.params?.standardId.toString() != "0") {
                studentListReportFilter.push({
                  member: "TmChapterOverview.gradeId",
                  operator: "equals",
                  values: [this.props?.match?.params?.standardId],
                });
              }
              if (this.props?.match?.params?.categoryId.toString() != "0") {
                studentListReportFilter.push({
                  member: "TmChapterOverview.categoryId",
                  operator: "equals",
                  values: [this.props?.match?.params?.categoryId],
                });
              }
          
              if (this.state.selectedLevel.toString() != "0") {
                {
                  console.log(this.state.selectedLevel);
                }
                studentListReportFilter.push({
                  member: "TmChapterOverview.levelId",
                  operator: "equals",
                  values: this.state.selectedLevel,
                });
              }


              if (this.state.selectedGroup[0]?.toString() != "0") {
                studentListReportFilter.push({
                  member: "TmChapterOverview.groupId",
                  operator: "equals",
                  values: [this.state.selectedGroup[0]],
                });
              }

          
              studentListReportFilter.push({
                member: "TmChapterOverview.attemptedReportCount",
                operator: "gt",
                values: ["0"],
              });
    }
    else {

          if (this.props?.match?.params?.schoolId.toString() != "0") {
            studentListReportFilter.push({
              member: "TmChapterOverviewNoGrp.schoolId",
              operator: "equals",
              values: [this.props?.match?.params?.schoolId.toString()],
            });
          }
      
          if (this.props?.match?.params?.standardId.toString() != "0") {
            studentListReportFilter.push({
              member: "TmChapterOverviewNoGrp.gradeId",
              operator: "equals",
              values: [this.props?.match?.params?.standardId],
            });
          }
          if (this.props?.match?.params?.categoryId.toString() != "0") {
            studentListReportFilter.push({
              member: "TmChapterOverviewNoGrp.categoryId",
              operator: "equals",
              values: [this.props?.match?.params?.categoryId],
            });
          }
      
          if (this.state.selectedLevel.toString() != "0") {
            {
              console.log(this.state.selectedLevel);
            }
            studentListReportFilter.push({
              member: "TmChapterOverviewNoGrp.levelId",
              operator: "equals",
              values: this.state.selectedLevel,
            });
          }
      
          studentListReportFilter.push({
            member: "TmChapterOverviewNoGrp.attemptedReportCount",
            operator: "gt",
            values: ["0"],
          });

    }
    
    /* *************** Student List Filter ************** */

    /* *************** Intro Student Attempts Filter ************** */
    let dateRangeArrayIntro = [];
    if (this.state.dateRangeArr[0] != null) {
          if(this.state.selectedGroup[0]?.toString() != "0") {
                dateRangeArrayIntro.push({
                  dimension: "TmIntroAttempts.completedDate",
                  dateRange: [
                    this.state.dateRangeArr[0],
                    this.state.dateRangeArr[1],
                  ],
                });
          }
          else {
                dateRangeArrayIntro.push({
                  dimension: "TmIntroAttemptsNoGrp.completedDate",
                  dateRange: [
                    this.state.dateRangeArr[0],
                    this.state.dateRangeArr[1],
                  ],
                });
          }
    }

    let introStudentAttemptsReportFilter = [];

    if(this.state.selectedGroup[0]?.toString() != "0") {
            if (this.props?.match?.params?.schoolId.toString() != "0") {
              introStudentAttemptsReportFilter.push({
                member: "TmIntroAttempts.schoolId",
                operator: "equals",
                values: [this.props?.match?.params?.schoolId.toString()],
              });
            }
        
            if (this.props?.match?.params?.standardId.toString() != "0") {
              introStudentAttemptsReportFilter.push({
                member: "TmIntroAttempts.gradeId",
                operator: "equals",
                values: [this.props?.match?.params?.standardId],
              });
            }
            if (this.props?.match?.params?.categoryId.toString() != "0") {
              introStudentAttemptsReportFilter.push({
                member: "TmIntroAttempts.categoryId",
                operator: "equals",
                values: [this.props?.match?.params?.categoryId],
              });
            }
            if (this.state.selectedGroup[0]?.toString() != "0") {
              introStudentAttemptsReportFilter.push({
                member: "TmIntroAttempts.groupId",
                operator: "equals",
                values: [this.state.selectedGroup[0]],
              });
            }
  
    }
    else {

          if (this.props?.match?.params?.schoolId.toString() != "0") {
            introStudentAttemptsReportFilter.push({
              member: "TmIntroAttemptsNoGrp.schoolId",
              operator: "equals",
              values: [this.props?.match?.params?.schoolId.toString()],
            });
          }
      
          if (this.props?.match?.params?.standardId.toString() != "0") {
            introStudentAttemptsReportFilter.push({
              member: "TmIntroAttemptsNoGrp.gradeId",
              operator: "equals",
              values: [this.props?.match?.params?.standardId],
            });
          }
          if (this.props?.match?.params?.categoryId.toString() != "0") {
            introStudentAttemptsReportFilter.push({
              member: "TmIntroAttemptsNoGrp.categoryId",
              operator: "equals",
              values: [this.props?.match?.params?.categoryId],
            });
          }
  
    }


    
    /* *************** Intro Student Attempts Filter ************** */

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptive = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptiveReport = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterDigscore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dimensionDynamic = [
      "TmHwQnAnsStat.proficiency",
      "TmDifficulty.proficiency",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamic.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamic.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamic.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicSkill = [
      "TmHwQnAnsStat.skillName",
      "TmHwQnAnsStat.skillStd",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicSkill.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    /* *************** Chapter name Report ************** */
    const DashboardChapterTop = [
      {
        id: 1,
        name: "chapter",
        vizState: {
          query: {
            order: { "TmAllChapterList.categoryName": "asc" },
            dimensions: ["TmAllChapterList.categoryName"],
            filters: chapterFilter,
          },
          chartType: "chapter_name",
          parameters: [],
        },
      },
    ];
    /* *************** Chapter name Report ************** */

    /* *************** Chapter Summary Report ************** */
    let DashboardChapterSummary = [];
    if(this.state.selectedGroup[0]?.toString() != "0") {
                DashboardChapterSummary = [
                  {
                    id: 1,
                    name: "Summary",
                    desc: "This section shows for each proficiency level, how many students have attempted that level, and the % of students who have scored EE, ME, PM & NI performance grades.",
                    vizState: {
                      query: {
                        dimensions: [
                          "TmChapterOverview.levelId",
                          "TmChapterOverview.level",
                        ],
                        measures: [
                          "TmChapterOverview.attemptedReportCount",
                          "TmChapterOverview.exceedReportPercent",
                          "TmChapterOverview.meetReportPercent",
                          "TmChapterOverview.partialReportPercent",
                          "TmChapterOverview.improvementReportPercent",
                        ],
                        order: { "TmChapterOverview.levelId": "asc" },
                        filters: chapterSummaryReportFilter,
                        timeDimensions: dateRangeArrayChapterOverview,
                      },
            
                      chartType: "chapter_overview_report",
                      parameters: [
                        { School: this.state.selectedSchoolID },
                        { Class: this.state.selectedClass },
                        { Group: this.state.selectedGroup },
                        { Daterange: this.state.dateRangeList },
                        { Assignment: this.state.selectedAssignment },
                        { ViewBy: this.state.viewBy },
                        { AggregatedBy: this.state.aggregateBy },
                        { Datebetween: this.state.dateRangeArr },
                      ],
                    },
                  },
                ];
    }
    else {
              DashboardChapterSummary = [
                {
                  id: 1,
                  name: "Summary",
                  desc: "This section shows for each proficiency level, how many students have attempted that level, and the % of students who have scored EE, ME, PM & NI performance grades.",
                  vizState: {
                    query: {
                      dimensions: [
                        "TmChapterOverviewNoGrp.levelId",
                        "TmChapterOverviewNoGrp.level",
                      ],
                      measures: [
                        "TmChapterOverviewNoGrp.attemptedReportCount",
                        "TmChapterOverviewNoGrp.exceedReportPercent",
                        "TmChapterOverviewNoGrp.meetReportPercent",
                        "TmChapterOverviewNoGrp.partialReportPercent",
                        "TmChapterOverviewNoGrp.improvementReportPercent",
                      ],
                      order: { "TmChapterOverviewNoGrp.levelId": "asc" },
                      filters: chapterSummaryReportFilter,
                      timeDimensions: dateRangeArrayChapterOverview,
                    },
          
                    chartType: "chapter_overview_report",
                    parameters: [
                      { School: this.state.selectedSchoolID },
                      { Class: this.state.selectedClass },
                      { Group: this.state.selectedGroup },
                      { Daterange: this.state.dateRangeList },
                      { Assignment: this.state.selectedAssignment },
                      { ViewBy: this.state.viewBy },
                      { AggregatedBy: this.state.aggregateBy },
                      { Datebetween: this.state.dateRangeArr },
                    ],
                  },
                },
              ];
    }
     

    /* *************** Chapter Summary Report ************** */

    /* *************** Student List Level Report ************** */
    
    let DashboardStudentEEList = [];
    if(this.state.selectedGroup[0]?.toString() != "0") {
            DashboardStudentEEList = [
              {
                id: 1,
                name: "Exceeds Expectation",
                desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
                vizState: {
                  query: {
                    dimensions: [
                      "TmChapterOverview.studentId",
                      "TmChapterOverview.studentName",
                      "TmChapterOverview.gradeId",
                      "TmChapterOverview.user_attempts",
                      "TmChapterOverview.user_overall_percentage",
                    ],
                    order: { "TmChapterOverview.studentId": "desc" },
                    filters: studentListReportFilter,
                    timeDimensions: dateRangeArrayStudentList,
                    measures: ["TmChapterOverview.exceedExpectationChapter"],
                    segments: ["TmChapterOverview.exceedExpectation"],
                  },
        
                  chartType: "student_list_report",
                  parameters: [
                    { School: this.props?.match?.params?.schoolId },
                    { Class: this.props?.match?.params?.standardId },
                    { Group: this.state.selectedGroup },
                    { Daterange: this.state.dateRangeList },
                    { Assignment: this.state.selectedAssignment },
                    { ViewBy: this.state.viewBy },
                    { AggregatedBy: this.state.aggregateBy },
                    { Datebetween: this.state.dateRangeArr },
                  ],
                },
              },
            ];
            
    }
    else {
            DashboardStudentEEList = [
              {
                id: 1,
                name: "Exceeds Expectation",
                desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
                vizState: {
                  query: {
                    dimensions: [
                      "TmChapterOverviewNoGrp.studentId",
                      "TmChapterOverviewNoGrp.studentName",
                      "TmChapterOverviewNoGrp.gradeId",
                      "TmChapterOverviewNoGrp.user_attempts",
                      "TmChapterOverviewNoGrp.user_overall_percentage",
                    ],
                    order: { "TmChapterOverviewNoGrp.studentId": "desc" },
                    filters: studentListReportFilter,
                    timeDimensions: dateRangeArrayStudentList,
                    measures: ["TmChapterOverviewNoGrp.exceedExpectationChapter"],
                    segments: ["TmChapterOverviewNoGrp.exceedExpectation"],
                  },
        
                  chartType: "student_list_report",
                  parameters: [
                    { School: this.props?.match?.params?.schoolId },
                    { Class: this.props?.match?.params?.standardId },
                    { Group: this.state.selectedGroup },
                    { Daterange: this.state.dateRangeList },
                    { Assignment: this.state.selectedAssignment },
                    { ViewBy: this.state.viewBy },
                    { AggregatedBy: this.state.aggregateBy },
                    { Datebetween: this.state.dateRangeArr },
                  ],
                },
              },
            ];
    }
    
     
    let DashboardStudentMEList = [];

    
if(this.state.selectedGroup[0]?.toString() != "0") {
          DashboardStudentMEList = [
            {
              id: 1,
              name: "Meets Expectation",
              desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
              vizState: {
                query: {
                  dimensions: [
                    "TmChapterOverview.studentId",
                    "TmChapterOverview.studentName",
                    "TmChapterOverview.gradeId",
                    "TmChapterOverview.user_attempts",
                    "TmChapterOverview.user_overall_percentage",
                  ],
                  order: { "TmChapterOverview.studentId": "desc" },
                  filters: studentListReportFilter,
                  timeDimensions: dateRangeArrayStudentList,
                  measures: ["TmChapterOverview.meetExpectationChapter"],
                  segments: ["TmChapterOverview.meetExpectation"],
                },

                chartType: "student_list_report",
                parameters: [
                  { School: this.props?.match?.params?.schoolId },
                  { Class: this.props?.match?.params?.standardId },
                  { Group: this.state.selectedGroup },
                  { Daterange: this.state.dateRangeList },
                  { Assignment: this.state.selectedAssignment },
                  { ViewBy: this.state.viewBy },
                  { AggregatedBy: this.state.aggregateBy },
                  { Datebetween: this.state.dateRangeArr },
                ],
              },
            },
          ];
}
else {
          DashboardStudentMEList = [
            {
              id: 1,
              name: "Meets Expectation",
              desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
              vizState: {
                query: {
                  dimensions: [
                    "TmChapterOverviewNoGrp.studentId",
                    "TmChapterOverviewNoGrp.studentName",
                    "TmChapterOverviewNoGrp.gradeId",
                    "TmChapterOverviewNoGrp.user_attempts",
                    "TmChapterOverviewNoGrp.user_overall_percentage",
                  ],
                  order: { "TmChapterOverviewNoGrp.studentId": "desc" },
                  filters: studentListReportFilter,
                  timeDimensions: dateRangeArrayStudentList,
                  measures: ["TmChapterOverviewNoGrp.meetExpectationChapter"],
                  segments: ["TmChapterOverviewNoGrp.meetExpectation"],
                },

                chartType: "student_list_report",
                parameters: [
                  { School: this.props?.match?.params?.schoolId },
                  { Class: this.props?.match?.params?.standardId },
                  { Group: this.state.selectedGroup },
                  { Daterange: this.state.dateRangeList },
                  { Assignment: this.state.selectedAssignment },
                  { ViewBy: this.state.viewBy },
                  { AggregatedBy: this.state.aggregateBy },
                  { Datebetween: this.state.dateRangeArr },
                ],
              },
            },
          ];
}


     
 let DashboardStudentPMList = [];

if(this.state.selectedGroup[0]?.toString() != "0") {
        DashboardStudentPMList = [
          {
            id: 1,
            name: "Partially Meets Expectation",
            desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
            vizState: {
              query: {
                dimensions: [
                  "TmChapterOverview.studentId",
                  "TmChapterOverview.studentName",
                  "TmChapterOverview.gradeId",
                  "TmChapterOverview.user_attempts",
                  "TmChapterOverview.user_overall_percentage",
                ],
                order: { "TmChapterOverview.studentId": "desc" },
                filters: studentListReportFilter,
                timeDimensions: dateRangeArrayStudentList,
                measures: ["TmChapterOverview.partialExpectationChapter"],
                segments: ["TmChapterOverview.partialExpectation"],
              },

              chartType: "student_list_report",
              parameters: [
                { School: this.props?.match?.params?.schoolId },
                { Class: this.props?.match?.params?.standardId },
                { Group: this.state.selectedGroup },
                { Daterange: this.state.dateRangeList },
                { Assignment: this.state.selectedAssignment },
                { ViewBy: this.state.viewBy },
                { AggregatedBy: this.state.aggregateBy },
                { Datebetween: this.state.dateRangeArr },
              ],
            },
          },
        ];
}
else {

      DashboardStudentPMList = [
        {
          id: 1,
          name: "Partially Meets Expectation",
          desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
          vizState: {
            query: {
              dimensions: [
                "TmChapterOverviewNoGrp.studentId",
                "TmChapterOverviewNoGrp.studentName",
                "TmChapterOverviewNoGrp.gradeId",
                "TmChapterOverviewNoGrp.user_attempts",
                "TmChapterOverviewNoGrp.user_overall_percentage",
              ],
              order: { "TmChapterOverviewNoGrp.studentId": "desc" },
              filters: studentListReportFilter,
              timeDimensions: dateRangeArrayStudentList,
              measures: ["TmChapterOverviewNoGrp.partialExpectationChapter"],
              segments: ["TmChapterOverviewNoGrp.partialExpectation"],
            },

            chartType: "student_list_report",
            parameters: [
              { School: this.props?.match?.params?.schoolId },
              { Class: this.props?.match?.params?.standardId },
              { Group: this.state.selectedGroup },
              { Daterange: this.state.dateRangeList },
              { Assignment: this.state.selectedAssignment },
              { ViewBy: this.state.viewBy },
              { AggregatedBy: this.state.aggregateBy },
              { Datebetween: this.state.dateRangeArr },
            ],
          },
        },
      ];
}

     
 let DashboardStudentNIList = [];
if(this.state.selectedGroup[0]?.toString() != "0") {
            DashboardStudentNIList = [
              {
                id: 1,
                name: "Needs Improvement",
                desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
                vizState: {
                  query: {
                    dimensions: [
                      "TmChapterOverview.studentId",
                      "TmChapterOverview.studentName",
                      "TmChapterOverview.gradeId",
                      "TmChapterOverview.user_attempts",
                      "TmChapterOverview.user_overall_percentage",
                    ],
                    order: { "TmChapterOverview.studentId": "desc" },
                    filters: studentListReportFilter,
                    timeDimensions: dateRangeArrayStudentList,
                    measures: ["TmChapterOverview.needExpectationChapter"],
                    segments: ["TmChapterOverview.improvementExpectation"],
                  },

                  chartType: "student_list_report",
                  parameters: [
                    { School: this.props?.match?.params?.schoolId },
                    { Class: this.props?.match?.params?.standardId },
                    { Group: this.state.selectedGroup },
                    { Daterange: this.state.dateRangeList },
                    { Assignment: this.state.selectedAssignment },
                    { ViewBy: this.state.viewBy },
                    { AggregatedBy: this.state.aggregateBy },
                    { Datebetween: this.state.dateRangeArr },
                  ],
                },
              },
            ];
}
else {
        DashboardStudentNIList = [
          {
            id: 1,
            name: "Needs Improvement",
            desc: "This section shows the student who have exceeded, met, partially met or did not meet expectations in tasks of this chapter.",
            vizState: {
              query: {
                dimensions: [
                  "TmChapterOverviewNoGrp.studentId",
                  "TmChapterOverviewNoGrp.studentName",
                  "TmChapterOverviewNoGrp.gradeId",
                  "TmChapterOverviewNoGrp.user_attempts",
                  "TmChapterOverviewNoGrp.user_overall_percentage",
                ],
                order: { "TmChapterOverviewNoGrp.studentId": "desc" },
                filters: studentListReportFilter,
                timeDimensions: dateRangeArrayStudentList,
                measures: ["TmChapterOverviewNoGrp.needExpectationChapter"],
                segments: ["TmChapterOverviewNoGrp.improvementExpectation"],
              },

              chartType: "student_list_report",
              parameters: [
                { School: this.props?.match?.params?.schoolId },
                { Class: this.props?.match?.params?.standardId },
                { Group: this.state.selectedGroup },
                { Daterange: this.state.dateRangeList },
                { Assignment: this.state.selectedAssignment },
                { ViewBy: this.state.viewBy },
                { AggregatedBy: this.state.aggregateBy },
                { Datebetween: this.state.dateRangeArr },
              ],
            },
          },
        ];
}


     



    let DashboardIntroStudentAttempts = [];
    if(this.state.selectedGroup[0]?.toString() != "0") {
                DashboardIntroStudentAttempts = [
                  {
                    id: 1,
                    name: "Students who have attempted intro level",
                    desc: "Shows the student who have attempted the level, what their final percentage is for the intro level and the number of attempts of the task.",
                    vizState: {
                      query: {
                        dimensions: [
                          "TmIntroAttempts.studentId",
                          "TmIntroAttempts.gradeId",
                          "TmIntroAttempts.studentName",
                          "TmIntroAttempts.noAttempts",
                        ],
                        measures: [
                          "TmIntroAttempts.totalPercent",
                      /*     "TmIntroAttempts.attemptCount", */
                        ],
                        order: { "TmIntroAttempts.noAttempts": "asc" },
                        filters: introStudentAttemptsReportFilter,
                        timeDimensions: dateRangeArrayIntro,
                      },
            
                      chartType: "intro_student_attempts",
                      parameters: [
                        { School: this.state.selectedSchoolID },
                        { Class: this.state.selectedClass },
                        { Group: this.state.selectedGroup },
                        { Daterange: this.state.dateRangeList },
                        { Assignment: this.state.selectedAssignment },
                        { ViewBy: this.state.viewBy },
                        { AggregatedBy: this.state.aggregateBy },
                        { Datebetween: this.state.dateRangeArr },
                      ],
                    },
                  },
                ];
    }
    else {

                DashboardIntroStudentAttempts = [
                  {
                    id: 1,
                    name: "Students who have attempted intro level",
                    desc: "Shows the student who have attempted the level, what their final percentage is for the intro level and the number of attempts of the task.",
                    vizState: {
                      query: {
                        dimensions: [
                          "TmIntroAttemptsNoGrp.studentId",
                          "TmIntroAttemptsNoGrp.gradeId",
                          "TmIntroAttemptsNoGrp.studentName",
                          "TmIntroAttemptsNoGrp.noAttempts",
                        ],
                        measures: [
                          "TmIntroAttemptsNoGrp.totalPercent",
                      /*     "TmIntroAttemptsNoGrp.attemptCount", */
                        ],
                        order: { "TmIntroAttemptsNoGrp.noAttempts": "asc" },
                        filters: introStudentAttemptsReportFilter,
                        timeDimensions: dateRangeArrayIntro,
                      },
            
                      chartType: "intro_student_attempts",
                      parameters: [
                        { School: this.state.selectedSchoolID },
                        { Class: this.state.selectedClass },
                        { Group: this.state.selectedGroup },
                        { Daterange: this.state.dateRangeList },
                        { Assignment: this.state.selectedAssignment },
                        { ViewBy: this.state.viewBy },
                        { AggregatedBy: this.state.aggregateBy },
                        { Datebetween: this.state.dateRangeArr },
                      ],
                    },
                  },
                ];
    }
    
     

    /* *************** Student List Level Report ************** */

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Task Report",
        vizState: {
          query: {
            dimensions: [
              "TmStudentMock.tmStmktkStudentId",
              "TmStudentMock.tmStmktkStudentName",
            ],
            timeDimensions: dateRangeArrayBoxesHome,
            order: [["TmStudentMock.StmktkCompleted", "desc"]],
            measures: [
              "TmStudentMock.StmktkCompleted",
              "TmStudentMock.taskPending",
              "TmStudentMock.StmktkPoints",
            ],
            filters: chartHomeFilter, // filter, //chartFilter,
          },

          chartType: "task_report",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
    ];

    const { collapsed } = this.state;
    const dateFormat = "DD-MM-YYYY";

    let allGroupList = [];
    let groupChildren = [];
    let classChildren = [];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    const cubejsApi = cubejs(
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4",
      {
        apiUrl: `${homeUrlCube}cubejs-api/v1`,
      }
    );

    return (
      
      <React.Fragment>
        <CubeProvider cubejsApi={cubejsApi}>
          <Layout className="print_width" style={{ minHeight: "100vh" }}>
            <Sider
              width={230}
              collapsible
              collapsed={collapsed}
              onCollapse={this.onCollapse}
              className="side_menu site-layout-background"
            >
              <Menu theme="dark" mode="inline" className="nav_panel">
                <Menu.Item icon={<SolutionOutlined />} key="2">
                  <Link
                    to="/teacher-usage"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Teachers Usage
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<TeamOutlined />} key="1">
                  <Link to="/" className="sub-menu ant-menu-submenu-active1">
                    Class Usage
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<UserOutlined />}
                  key="3"
                  className="ant-menu-submenu "
                >
                  <Link
                    to="/student"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Individual Student Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FormOutlined />} key="4">
                  <Link
                    to="/assignment"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Assignment Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<MailOutlined />} key="5">
                  <Link
                    to="/send-email"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Send Email
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FundProjectionScreenOutlined />} key="6">
                  <Link
                    to="/home-module"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Self Prep
                  </Link>
                </Menu.Item>
                {localStorage.getItem("userType") == 1 && (
                  <>
                <Menu.Item icon={<LoginOutlined />} key="7">
                  <Link
                    to="/login-report"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Login Report
                  </Link>
                </Menu.Item>
                {/* <Menu.Item icon={<DatabaseOutlined />} key="8">
                  <Link
                    to="/task-data"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Task Report
                  </Link>
                </Menu.Item> */}
                <Menu.Item icon={<LineChartOutlined />} key="9">
                  <Link
                    to="/task-analysis"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Task Analysis
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FundOutlined />} key="10">
                  <Link
                    to="/question-analysis"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Question Analysis
                  </Link>
                </Menu.Item>
                 </>
                 )}
              </Menu>
            </Sider>

            <Layout style={{ padding: "0 0px 0px" }}>
              <Grid
                xs={12}
                sm={12}
                lg={12}
                className="header header-customized"
              >
                <nav className="custom-nav">
                  <button
                    className="nav-toggl"
                    onClick={() => {
                      this.setState({ mobNav: !this.state.mobNav });
                      console.log("The button is clicked", this.state.mobNav);
                    }}
                  >
                    <MenuOutlinedIcon
                      className="icon-style-custom"
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "auto",
                        display: "flex",
                      }}
                    />
                  </button>
                  {this.state.mobNav && (
                    <ul className="custom-ul">
                      <Menu theme="dark" mode="inline" className="nav_panel">
                        <Menu.Item icon={<SolutionOutlined />} key="3">
                          <Link
                            to="/teacher-usage"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Teachers Usage
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<TeamOutlined />} key="4">
                          <Link
                            to="/"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Class Usage
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          icon={<UserOutlined />}
                          key="2"
                          className="ant-menu-submenu "
                        >
                          <Link
                            to="/student"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Individual Student Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<FormOutlined />} key="5">
                          <Link
                            to="/assignment"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Assignment Report
                          </Link>
                        </Menu.Item>
                        <Menu.Item icon={<MailOutlined />} key="6">
                          <Link
                            to="/send-email"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Send Email
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          icon={<FundProjectionScreenOutlined />}
                          key="1"
                        >
                          <Link
                            to="/home-module"
                            className="sub-menu ant-menu-submenu-active1"
                          >
                            Self Prep
                          </Link>
                        </Menu.Item>
                        {localStorage.getItem("userType") == 1 && (
                          <>
                            <Menu.Item icon={<LoginOutlined />} key="7">
                              <Link
                                to="/login-report"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Login Report
                              </Link>
                            </Menu.Item>
                            {/* <Menu.Item icon={<DatabaseOutlined />} key="8">
                              <Link
                                to="/task-data"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Task Report
                              </Link>
                            </Menu.Item> */}
                            <Menu.Item icon={<LineChartOutlined />} key="9">
                              <Link
                                to="/task-analysis"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Task Analysis
                              </Link>
                            </Menu.Item>
                            <Menu.Item icon={<FundOutlined />} key="10">
                              <Link
                                to="/question-analysis"
                                className="sub-menu ant-menu-submenu-active1"
                              >
                                Question Analysis
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                      </Menu>
                    </ul>
                  )}
                </nav>
                <Grid
                  xs={24}
                  lg={24}
                  container
                  spacing={0}
                  style={{ padding: 0 }}
                  justify="left"
                  alignItems="flex-start"
                >
                  <Col span={8} className="logo_header">
                    <img class="logoWidth" src={logo} />
                  </Col>
                  <Col span={8} className="breadcrumb">
                    <center>
                      <h2>
                        <b>Task Overview</b>
                      </h2>
                    </center>
                  </Col>
                  <Col span={8} className="user_detail">
                    <h5>
                      <p>
                        <span className="c1">Hello!,</span>{" "}
                        <span className="c2">{this.state.loginUser}</span>
                        <br />
                        <div className="none">
                          {this.state.selectedSchoolValue}
                        </div>
                        <Link
                          className="logout_btn"
                          onClick={(event) => {
                            event.preventDefault();
                            signout(() => <Redirect to="/home" />);
                          }}
                        >
                          {" "}
                          Home{" "}
                        </Link>
                      </p>
                    </h5>
                  </Col>
                </Grid>
              </Grid>
              <center>
                <h2>
                  <b>
                    <div
                      style={{
                        display: this.state.display,
                        border: "2px solid black",
                        width: "30%",
                        height: "100px",
                        marginTop: "30px",
                        paddingTop: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      No Records Found
                    </div>
                  </b>
                </h2>
              </center>
              <Content
                className="ant-layout-content site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {this.state.display == "none"
                  ? !this.state.loader && <Spin />
                  : this.setState({ display: "block" })}

                {this.state.loader && (
                  <Dashboard>
                    <Grid
                      xs={10}
                      sm={10}
                      lg={10}
                      className="header printme-extra-content"
                    >
                      <Grid
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col span={8} className="logo_header_print">
                          <img class="logoWidth" src={logo} style={{paddingLeft:"40px"}} />
                        </Col>
                        <Col span={8} className="breadcrumb_print">
                          <center>
                            <h2>
                              <b>Task Overview</b>
                            </h2>
                          </center>
                        </Col>
                        <Col span={8} className="user_detail_print">
                          <h5>
                            <p>
                              <span className="c1">School:</span>{" "}
                              <span className="c2">
                                {this.state.selectedSchoolValue}
                              </span>
                              <br />
                              <span className="c1">Class:</span>{" "}
                              <span className="c2">
                                {this.state.selectedClassValue}
                              </span>
                              <br />
                              <span className="c1">Group:</span>{" "}
                              <span className="c2">
                                {this.state.selectedGroupValue == "0"
                                  ? "All"
                                  : this.state.selectedGroupValue}
                              </span>
                              <br />
                              {/* <span className="c1">Date Range:</span>{" "}
                              <span className="c2">
                                {this.state.dateRangeList[0].format(
                                  "YYYY-MM-DD"
                                )}{" "}
                                To{" "}
                                {this.state.dateRangeList[1].format(
                                  "YYYY-MM-DD"
                                )}
                              </span>
                              <br /> */}
                            </p>
                          </h5>
                        </Col>
                      </Grid>
                    </Grid>

                    {/* *************************** New Chapter report starts here**************************** */}
{/* ======PROP==={this.props.match.params.groupId?.toString()}===========GROUP==={this.state.selectedGroup[0]?.toString()}========== */}
                    <Row
                      justify={"center"}
                      align={"middle"}
                      style={{ width: "100%" }}
                    >
                      <Grid xs={10} sm={10} lg={10}>
                        <Col span={24} lg={24}>
                          <div class="title-container">
                            <div class="div-left div-left-custom">
                              <ChartRendererCustomised
                                vizState={DashboardChapterTop[0].vizState}
                              />
                            </div>
                            <div class="div-right attempt print_btn_print">
                              <Link to={`/home-module`}>
                                <button class="print_btn_web print_btn_web-stdAtmp">
                                  Back
                                </button>
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Grid>
                      {/* <Col key={DashboardChapterTop[0].id} className="topWidget topWidCustom" id=""  flex={"100px"}>
                                            <div style={{ fontSize:"16px", fontWeight:"bold" }}><ChartRendererCustomised vizState={DashboardChapterTop[0].vizState} /></div>    
                                    </Col>
                                    <Col flex={"auto"}>
                                        <Link to={`/home-module`}><button class="print_btn_web print_btn_web-stdAtmp">Back</button></Link>
                                    </Col> 
                                    */}
                    </Row>
                    {/* {this.props?.match?.params?.categoryId}==={this.props?.match?.params?.standardId}=== {this.props?.match?.params?.groupIdName}=={this.props?.match?.params?.schoolId} */}

                    <Grid xs={10} sm={10} lg={10}>
                      {/*   <Col span={24} lg={24} id="" style={{ textAlign:'right'  }}>
                                <button onClick={() => history.goBack()}>Back222</button>
                              
                               </Col> */}
                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardChapterSummary[0].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem
                            title={DashboardChapterSummary[0].name}
                          >
                            <p>{DashboardChapterSummary[0].desc}</p>
                            <div className="labels-wrapr">
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#b4d3b2" }}>
                                    EE
                                  </span>
                                  Exceeds Expectation (71% - 100%)
                                </p>
                                <p>
                                  <span style={{ background: "#daf0f7" }}>
                                    ME
                                  </span>
                                  Meets Expectation (51% - 70%)
                                </p>
                              </div>
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#ece5c7" }}>
                                    PME
                                  </span>
                                  Partially Meets Expectation (33% - 50%)
                                </p>
                                <p>
                                  <span style={{ background: "#ffe3e1" }}>
                                    NI
                                  </span>
                                  Needs Improvement (0% - 32%)
                                </p>
                              </div>
                            </div>
                            <div
                              className="table-designed"
                              style={{ width: "70%", margin: "auto" }}
                            >
                              {
                                <ChartRendererCustomised
                                  vizState={DashboardChapterSummary[0].vizState}
                                />
                              }
                            </div>
                          </DashboardItem>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          id=""
                          style={{ width: "60%", textAlign: "center" }}
                        >
                          <div style={{ padding: "20px" }}>
                            <b>Choose a proficient level &nbsp;</b>
                            <Select
                              style={{ width: "200px" }}
                              onChange={(value, option) =>
                                this.onChangeLevel(value, option)
                              }
                              value={{
                                value: this.state.selectedLevelID?.toString(),
                              }}
                            >
                              <Option value="4">Intro</Option>
                              <Option value="1">Beginner</Option>
                              <Option value="2">Competent</Option>
                              <Option value="3">Expert</Option>
                            </Select>
                          </div>
                        </Col>
                        {this.state.selectedLevelID != 4 && (
                          <Col
                            span={24}
                            lg={24}
                            id=""
                            style={{ textalign: "center" }}
                          >
                            <Row>
                              <Col span={24} lg={24}>
                                <p>{DashboardStudentEEList[0].desc}</p>
                              </Col>
                            </Row>
                            <Row className="cols-wrapper-row-design">
                              {/* <Col
                                span={24}
                                lg={24}
                                key={DashboardStudentLevelList[0].id}
                                id=""
                              >
                                <DashboardItem>
                                  {
                                    <ChartRendererCustomised
                                      vizState={
                                        DashboardStudentLevelList[0].vizState
                                      }
                                    />
                                  }
                                </DashboardItem>
                              </Col> */}

                              {/* *********** ********** */}
                              <Col
                                span={6}
                                lg={6}
                                key={DashboardStudentEEList[0].id}
                                id=""
                              >
                                <DashboardItem
                                  title={DashboardStudentEEList[0].name}
                                >
                                  {
                                    <ChartRendererCustomised
                                      vizState={
                                        DashboardStudentEEList[0].vizState
                                      }
                                    />
                                  }
                                </DashboardItem>
                              </Col>
                              <Col
                                span={6}
                                lg={6}
                                key={DashboardStudentMEList[0].id}
                                id=""
                              >
                                <DashboardItem
                                  title={DashboardStudentMEList[0].name}
                                >
                                  {
                                    <ChartRendererCustomised
                                      vizState={
                                        DashboardStudentMEList[0].vizState
                                      }
                                    />
                                  }
                                </DashboardItem>
                              </Col>
                              <Col
                                span={6}
                                lg={6}
                                key={DashboardStudentPMList[0].id}
                                id=""
                              >
                                <DashboardItem
                                  title={DashboardStudentPMList[0].name}
                                >
                                  {
                                    <ChartRendererCustomised
                                      vizState={
                                        DashboardStudentPMList[0].vizState
                                      }
                                    />
                                  }
                                </DashboardItem>
                              </Col>
                              <Col
                                span={6}
                                lg={6}
                                key={DashboardStudentNIList[0].id}
                                id=""
                              >
                                <DashboardItem
                                  title={DashboardStudentNIList[0].name}
                                >
                                  {
                                    <ChartRendererCustomised
                                      vizState={
                                        DashboardStudentNIList[0].vizState
                                      }
                                    />
                                  }
                                </DashboardItem>
                              </Col>
                              {/* ************ ********* */}
                            </Row>
                          </Col>
                        )}

                        {/* Intro student attempts starts here */}
                        {this.state.selectedLevelID == 4 && (
                          <Col
                            span={24}
                            lg={24}
                            id=""
                            style={{ textalign: "center" }}
                          >
                            <Row>
                              <Col span={24} lg={24}>
                                <p>{DashboardIntroStudentAttempts[0].desc}</p>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col
                                span={24}
                                lg={24}
                                id=""
                                style={{ textalign: "center" }}
                              >
                                <DashboardItem>
                                  {
                                    <ChartRendererCustomised
                                      vizState={
                                        DashboardIntroStudentAttempts[0]
                                          .vizState
                                      }
                                    />
                                  }
                                </DashboardItem>
                              </Col>
                            </Row>
                          </Col>
                        )}

                        {/* Intro student attempts ends here */}
                      </Grid>
                    </Grid>

                    <Grid
                      xs={2}
                      sm={2}
                      lg={2}
                      className="print_width_filter print_filters"
                    >
                      <Grid
                        container
                        justify="left"
                        alignItems="flex-start"
                        id="filterContainer-title"
                      >
                        <div
                          className="filter-bg white-bg print_btn_print"
                          style={{ float: "center" }}
                        >
                          <ReactToPrint
                            trigger={() => (
                              <button className="print_btn_web">
                                <PrinterFilled />
                                &nbsp;&nbsp;Print
                              </button>
                            )}
                            content={() => window.print()}
                          />
                        </div>
                        <div style={{ margin: "10px" }}> </div>
                      </Grid>
                    </Grid>

                    {/* <Grid xs={2} sm={2} lg={2} className="print_width_filter print_filters">

                                    <Grid container justify="left" alignItems="flex-start" id="filterContainer-title">
                                        <div className="filter-bg white-bg print_btn_print" style={{float:"center"}}>
                                            <ReactToPrint
                                            trigger={() => <button className="print_btn_web"><PrinterFilled />&nbsp;&nbsp;Print</button>}
                                            content={() => window.print()}
                                            />
                                        </div>
                                        <DashboardItem title="Filters" className="ant-card-body ">
                                            <Col span={24} lg={24} xs={24} sm={24} md={24} className="filterContainer">
                                                <div className="filter-bg color-1 white-bg">
                                                <label > <strong> School Name </strong>  </label>
                                                <schoolValue data={this.state.selectedSchool}></schoolValue>
                                                <Select
                                                    showSearch 
                                                    style={{ width: '100%', paddingTop: 5 }}
                                                    placeholder="Select school name"
                                                    optionFilterProp="children"
                                                    onChange={(value,option)=> this.onChangeSchool(option)}
                                                    labelInValue
                                                    value={{ value: this.state.selectedSchoolID?.toString() }}
                                                >
                                                    {this.state.schoolList.map((x) => {
                                                    return (
                                                        <Option key={(x)["TmStudentMock.tmStmktkSchoolId"]} value={(x)["TmStudentMock.tmStmktkSchoolId"]?.toString()}>
                                                        {(x)["TmStudentMock.tmStmktkSchoolName"]}
                                                        
                                                        </Option>
                                                    )
                                                    })}

                                                </Select>

                                                </div>
                                            </Col>

                                            <Col span={24} lg={24} xs={24} sm={24} md={24} className="filterContainer">
                                                <div className="filter-bg color-1 white-bg">
                                                <label> <strong> Class </strong>  </label>

                                                <Select
                                                    showSearch
                                                    style={{ width: '100%', paddingTop: 5 }}
                                                    labelInValue
                                                    value={{ value: this.state.selectedClass[0]?.toString() }}
                                                    placeholder="Select Class"
                                                    optionFilterProp="children"
                                                    onChange={(value,option)=> this.onChangeClass(option)}
                                                    filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                    }
                                                >
                                                    if({this.state.selectedClass.length > 0})
                                                    {this.state.classList && this.state.classList.map((x) => {
                                                    classChildren.push(<Option key={(x)["TmStudentMock.tmStmktkStandardId"]?.toString()} value={(x)["TmStudentMock.tmStmktkStandardId"]?.toString()}>{(x)["TmStudentMock.tmStmktkStandardName"]}</Option>);
                                                        allClassList.push((x)["TmStudentMock.tmStmktkStandardId"]);
                                                })}
                                                    <Option value="0">All</Option>
                                                    {classChildren}
                                                </Select>
                                                </div>
                                            </Col>

                                            <Col span={24} lg={24} xs={24} sm={24} md={24} className="filterContainer">
                                                <div className="filter-bg color-1 white-bg">
                                                <label> <strong> Group </strong>  </label>
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    value={{ value: this.state.selectedGroupValue?.toString() }}
                                                    style={{ width: '100%', paddingTop: 5 }}
                                                    placeholder="Select Group"
                                                    optionFilterProp="children"
                                                    onChange={this.onChangeGroup}
                                                >
                                                    if({this.state.selectedGroup.length > 0}) {
                                                    this.state.groupList && this.state.groupList.map((x) => {
                                                        groupChildren.push(<Option key={(x)["TmStudentMock.tmStmktkGroupId"]} id={(x)["TmStudentMock.tmStmktkGroupId"]}>{(x)["TmStudentMock.tmStmktkGroupName"]}</Option>);
                                                        allGroupList.push((x)["TmStudentMock.tmStmktkGroupId"]);
                                                    })
                                                    }
                                                    <Option value='0'> All </Option>
                                                    {groupChildren}
                                                </Select>
                                                </div>
                                            </Col>

                                            <Col span={24} lg={24} xs={24} sm={24} md={24} className="filterContainer" >
                                                <div className="filter-bg color-1 white-bg">
                                                <label> <strong> Date Range </strong>  </label>
                                                <RangePicker
                                                    style={{ top: 5 }}
                                                    onChange={this.onDateRangeChange}
                                                    value={[moment(this.state.dateRangeList[0], dateFormat), moment(this.state.dateRangeList[1], dateFormat)]} format={dateFormat}
                                                />
                                                </div>
                                            </Col>


                                        
                                        </DashboardItem>
                                    </Grid>
                            </Grid> */}
                    {/* *************************** New Chapter report ends here**************************** */}
                    {/*     <Grid xs={10} sm={10} lg={10}>
                                    <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                                        <Col span={24} lg={24} key={DashboardItemsTop[0].id} className={this.state.active === "" ? 'active' : ''} id="" >
                                            <DashboardItem title={DashboardItemsTop[0].name}>
                                            <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                                            </DashboardItem>
                                        </Col>
                                    </Grid>
                                </Grid> */}
                             
                  </Dashboard>
                )}
              </Content>
            </Layout>
          </Layout>
        </CubeProvider>
      </React.Fragment>
    );
  }
}

export default StudentAttempts;
