import cookie from 'js-cookie';
import jwt from 'jwt-decode' // import dependency
import axios from 'axios';


export const baseUrlCube = 'https://report-dev.tabbiemath.com/tabbiecube/'; // DEV
// export const baseUrlCube = 'https://preprod.tabbiemath.com/reports/tabbiecube/' // PREPROD
 // export const baseUrlCube = 'https://reports.tabbiemath.com/tabbiecube/'; // LIVE

 export const homeUrlCube = 'https://dev-app.tabbiemath.com/tabbiecube-v2/'; // DEV
// export const homeUrlCube = 'http://34.242.222.156:4000/';                    
//export const homeUrlCube = 'http://localhost:4000/'; 
// export const homeUrlCube = 'https://preprod.tabbiemath.com/tabbiecubev2/'; // PREPROD
// export const homeUrlCube = 'https://reports.tabbiemath.com/tabbiecubev2/'; // LIVE


 //export const baseUrlApp = 'http://localhost:3000'; 
 export const baseUrlApp = 'https://report-dev.tabbiemath.com';          // DEV
//  export const baseUrlApp = 'https://preprod.tabbiemath.com/reports'; // PREPROD
 // export const baseUrlApp = 'https://reports.tabbiemath.com'; // LIVE


 export const newReportUrl = "https://dev-app.tabbiemath.com/new-report/#"; // DEV
// export const newReportUrl = "https://preprod.tabbiemath.com/new-report/#"; // PREPROD
//export const newReportUrl = "https://reports.tabbiemath.com/new-report/#"; // LIVE


 export const sendMailPath = "https://report-dev.tabbiemath.com/api/auth/sendEmailThematic"; // DEV
//export const sendMailPath = "https://preprod.tabbiemath.com/reports/api/public/api/auth/sendEmail"; // PREPROD
// export const sendMailPath = "https://reports.tabbiemath.com/api/public/api/auth/sendEmail"; // LIVE


export const apitokenPath = "https://report-dev.tabbiemath.com/api/auth/verifyTocken"; // DEV
//export const apitokenPath = "https://preprod.tabbiemath.com/reports/api/public/api/auth/verifyTocken"; // PREPROD
//export const apitokenPath = "https://reports.tabbiemath.com/api/public/api/auth/verifyTocken"; // LIVE


export const isBrowser = () => typeof window !== "undefined"
    

// set in cookie
export const setCookie = (key, value) => {
    if (isBrowser) {
        cookie.set(key, value, {
            expires: 1
        });
    }
};
// remove from cookie
export const removeCookie = key => {
    if (isBrowser) {
        cookie.remove(key, {
            expires: 1
        });
    }
};
// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = key => {
    if (isBrowser) {
        return cookie.get(key);
    }
};
// set in localstorage
export const setLocalStorage = (key, value) => {
    if (isBrowser) {
        localStorage.setItem(key, JSON.stringify(value));
    }
};
// remove from localstorage
export const removeLocalStorage = key => {
    if (isBrowser) {
        localStorage.removeItem(key);
    }
};

// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (response, next) => {

    console.log('AUTHENTICATE HELPER ON SIGNIN RESPONSE', response);
    setCookie('token', response);

     let user = jwt(response);
     console.log(user,"userid")

     let UserId = user.user_id;
     setLocalStorage('user', UserId);
     setLocalStorage('userType', user.user_role);
     setLocalStorage('reportType', user.report_type);
    next();
};


 


export const getUser = () =>
    isBrowser() && window.localStorage.getItem("user")
        ? JSON.parse(window.localStorage.getItem("user"))
        : {}
 

// access user info from localstorage
export const isAuth = () => {
    if (isBrowser) {
        const cookieChecked = getCookie('token');
        if (cookieChecked) {
            return true;
        } else {
            return false;
        }
    }
};

 

export const signout = next => {
    removeCookie('token');
    removeLocalStorage('user');
    removeLocalStorage('username')
    localStorage.clear();
    sessionStorage.clear();
   //  window.location.href = "http://localhost:3000/#/home";
  //  window.location.href = "https://dev-app.tabbiemath.com/tabbiemath-dev/user/login.html"; // DEV
  //   window.location.href = "https://preprod.tabbiemath.com"; // PREPROD
     window.location.href = "https://app.tabbiemath.com/user/login.html"; // LIVE

};

export const updateUser = (response, next) => {
    console.log('UPDATE USER IN LOCALSTORAGE HELPERS', response);
    if (typeof isBrowser) {
        let auth = JSON.parse(localStorage.getItem('user'));
        auth = response.data;
        localStorage.setItem('user', JSON.stringify(auth));
    }
    next();
};


 
 