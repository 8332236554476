import React from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Layout, Menu, Breadcrumb, Select, Button, Radio, Checkbox, Card, DatePicker, Space } from "antd";
import Grid from "@material-ui/core/Grid";
import logo from '../img/logo.jpg';

import {baseUrlApp, removeCookie} from '../components/helpers';

const { Header, Content } = Layout;
class PreAuth extends React.Component {
  
  componentDidMount(){
    removeCookie('token');
    window.localStorage.clear(); //clear all localstorage
  }


  render() {

 
    return (
        <React.Fragment>
        <Layout>
          <Layout>
            <Layout style={{ padding: '0 24px 24px',margin: '16px 0' }}>
              <Content
                className="site-layout-background ant-layout-content1"
                style={{
                  padding: 24,
                  margin: 0, 
                  minHeight: 100,
                }}
                >
                <Grid container spacing={3} style={{ paddingLeft: 10,paddingTop: 100, paddingBottom: 100 }} justify="" alignItems="flex-start" id="filterContainer-title">
                      <div className="filter-bg color-1 white-bg" style={{width:"100%"}}>
                          <img src={logo} className="preauth_logo"></img>
                          <h4 className="preauth_text">You need to be authorized to see this page, kindly go to <a href="https://www.tabbiemath.com/" 
                          className="preauth_link">https://www.tabbiemath.com/ </a>and login</h4>
                          {/* // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4`}>
                          //   Super Admin RbI
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4`}>
                          //   Super Admin check
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMDk5NSwidXNlcl90eXBlIjoxMiwidXNlcl9yb2xlIjowfQ.q_CRoZdJMb8LOZOb0UK6PURNkBU5BaJZ4WDObdq1SUg`}>
                          //   Kathleen
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3MzksInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MH0.halJfMVxHSe7JlH50z_V1X_0Zpgbl9H7rVuUc5Tlx1s`}>
                          //   George
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMTA3NywidXNlcl90eXBlIjoxMiwidXNlcl9yb2xlIjowfQ.bzP3kw4i-prRHv_UAwLf0Ml0EJEXPQ4Rn_uXAIean0U`}>
                          //   lev
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo2NTUwNywidXNlcl90eXBlIjoxMiwidXNlcl9yb2xlIjowfQ.MGr9qcJKltiPzPiIVDWJw0lk9YJ-XZCiLWkXhmYogfs`}>
                          //   varghese                             
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4`}>
                          //    dev report mail
                          // </a> &nbsp;&nbsp;
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4`}>
                          //   preprod
                          // </a>
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMzc1NzEsInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MH0.rjsLX18MgM3_n2J_Yu42gATdpobE6-ArX6RLxJ6_w4c`}>
                          //   jude
                          // </a>
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNzA0NSwidXNlcl90eXBlIjoxMiwidXNlcl9yb2xlIjowfQ.4Q_hS5Q8w1KwF9mO7y5n-CCErUrOJvbc3wx6frxlnW0`}>
                          //   jasmin
                          // </a>
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMzY0MzcsInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MH0.TDJZk1-OZIrc7xBLsgDBMJ4Wuz4wads1LfHJppOpbpg`}>
                          //   janu
                          // </a> 
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNTAwNzksInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MH0.YtzWZPkRKu68OHW5eUCWO3vc94rJ3s0Jx3ZIMs-WL2g`}>
                          //   Ruby
                          // </a>
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNTA3OTksInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjo0fQ.s0pG2rAv1Dx3_om-D3l4oqndT8JakXabeuA4zi-9qUU`}>
                          //   Anna   
                          // </a>  
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNTAwNzksInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MH0.YtzWZPkRKu68OHW5eUCWO3vc94rJ3s0Jx3ZIMs-WL2g`}>
                          //   Anna  clarie 
                          // </a>   
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNTE3ODAsInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MH0.VZDVxdfcxvHk7YVAPdCP9DqtVl-EWdYXSlzK1VUI8iA?selfprep`}>
                          //   test teacher
                          // </a> 
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxLCJyZXBvcnRfdHlwZSI6MX0.GUkDyjNDjDLwfDp5xaKy2-q0SZWcWsCVMfwQQQiak7o`}>
                          //   thematic report
                          // </a>  
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxLCJyZXBvcnRfdHlwZSI6M30.-QiMnM4dTe6vWLCJNmwavDAJbkge7nQtJiMtvU_HN1w`}>
                          //   Learning Analytics
                          // </a>  
                          // <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNTA3MjQsInVzZXJfdHlwZSI6MTIsInVzZXJfcm9sZSI6MCwicmVwb3J0X3R5cGUiOjN9.7Qz1s1eoujNgToSrrhKt9bse66_A4LpBU7iCOyEZKn8`}>
                          //   teacher admin
                          // </a>   */}


                         {/*    <a className="preauth_btn" type="button" href={`${baseUrlApp}/#/reports/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyMjMsInVzZXJfdHlwZSI6MSwidXNlcl9yb2xlIjoxfQ.bjM7ABBvjoySROK89DIU0a4iHbu84RGHwNrpFWXMUG4`}>
                              dev report mail
                           </a> &nbsp;&nbsp; */}
                      </div>                 
                  </Grid>
              </Content>
            </Layout>
          </Layout>
        </Layout>


      </React.Fragment>
    )
  }
}

export default PreAuth;