import React from "react";
import { Card } from "antd";

const Wrapper = ({ children, type }) => (
  <div className={type}>
    {children}
    </div>
);

export default Wrapper;


