import React, { PureComponent } from 'react';
import "react-d3-treemap/dist/react.d3.treemap.css";
import { Spin, Row, Col, Layout, Menu, Breadcrumb, Select, Form, Button, Radio, Checkbox, Card, DatePicker, Space, message } from "antd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from 'antd';
import { Link, useParams } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import 'antd/dist/antd.css';
import axios from 'axios';
import jwt from 'jwt-decode' // import dependency
import { useCubeQuery } from "@cubejs-client/react";
import cookie from 'js-cookie';
import moment from 'moment'
import logo from "../img/logo.png";
import { Redirect } from 'react-router';
import { baseUrlCube, apitokenPath, getUser, authenticate, isAuth, signout } from "../components/helpers"
import ReactToPrint from "react-to-print";

import { MailOutlined, TeamOutlined, UserOutlined, PrinterFilled,SolutionOutlined, FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props)
    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.mySubmitHandler = this.mySubmitHandler.bind(this);

    this.state = {
      decodeValue:"",
      dateUrl:"",
      collapsed: true,
      subject: "",
      message: "",
      schoolList: [],
      classList: [],
      groupList: [],
      studentList: [],
      assignmentList: [],
      assignmentDimensions:[],
      assignmentDimensionsAdaptive:[],
      assignmentDimensionsDiag:[],
      invalidToken:true,
      studentReport:[],
      assignmentMeasure:[],
      selectedRowKeys: [],
      dateRangeList: [moment().subtract(3, 'months'), moment().startOf('second')],
      school: [''],
      processDateRangeList: [moment().subtract(5, 'months'), moment().startOf('second')],
      loader: false,
      selectedSchool: [''],
      selectedSchoolID: '',
      selectedClassID: '',
      selectedGroupID: '',
      selectedStudentID: '',
      selectedSchoolValue:'',
      selectedClassValue:'',
      selectedGroupValue:'',
      selectedStudentValue:'',
      selectedDateRange: ["", ""],
      student: [''],
      selectedStudent: [''],
      class: [""],
      selectedClass: [''],
      selectedAssignment: [''],
      group: [''],
      selectedGroup: [''],
      assignmentsAll: [],
      assignments: [],
      viewBy: ['all'],
      aggregateBy: ['chapter'],
      orderBy: [
        [
          "TmHwQnAnsStat.domain",
          "asc"
        ],
        [
          "TmHwQnAnsStat.chapterName",
          "asc"
        ],
        [
          "TmHwQnAnsStat.topicName",
          "asc"
        ]
      ],
      pivotConfig:{},
      prevButton: '',
      nextButton: 'disabled',
      ifAdaptive:0
    };
  }
  onCollapse = collapsed => {
    this.setState({ collapsed });
  };
  onSubmit(values) {
    this.props.reset();
  }

  onChangeViweBy = (value) => {

    
    
    this.setState({
      viewBy: [value.value]
    });

    if (value.value == 'proficiency') {
      this.setState({
        orderBy: [
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.proficiencyName",
            "asc"
          ]
        ]
      });


      this.setState({
        pivotConfig: {
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.proficiencyName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
      })
    }

    if (value.value == 'skill') {
      this.setState({
        orderBy: [
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.skillName",
            "asc"
          ]


        ]
      });

      this.setState({
        pivotConfig: {
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.skillName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
      });
    }
  }


  onChangeNextButton = () => {
    var currentDate = '';
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD"); 
    var futureMonth = currentDate.add(3, 'M').format('YYYY-MM-DD');
    var StateDate = [moment(futureMonth, 'YYYY-MM-DD').add(-5, 'months'), futureMonth];

    var currentDateLive = moment().format('YYYY-MM-DD');
    if (futureMonth > currentDateLive) {
      this.setState({
        nextButton: 'disabled'
      });
    } else if (futureMonth <= currentDateLive) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: ''
      });
    }
  }

  onChangePrevButton = () => {
    var currentDate = '';
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD"); 
    var futureMonth = moment().add(3, 'M').format('YYYY-MM-DD');
    var prevMonth = currentDate.add(-3, 'M').format('YYYY-MM-DD');
    var fifthYear = moment().add(-3, 'Y').format('YYYY-MM-DD');
    var StateDate = [moment(prevMonth, 'YYYY-MM-DD').add(-5, 'months'), prevMonth];
    this.setState({
      nextButton: '',
      processDateRangeList: StateDate,
      nextButton: ''
    });

    if (prevMonth > fifthYear) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: ''
      });
    } else if (prevMonth <= fifthYear) {
      this.setState({
        prevButton: 'disable'
      });
    }


  }

  myChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  mySubmitHandler = (event) => {
    let subject = this.state.subject;
    let message = this.state.message;
    if (subject === "" || message === "" || this.state.selectedRowKeys == 0) {
      return false;
    }
    else {
    }
  }
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };





  CheckQuery = () => {
    let query = {
      "measures": [
        "TmSchoolGroups.classCount"
      ],
      "timeDimensions": [],
      "order": {
        "TmSchoolGroups.classCount": "desc"
      },
      "dimensions": [
        "TmSchool.schoolId",
        "TmSchool.schoolName"
      ]
    };
    const renderProps = useCubeQuery(query);
    if (renderProps.resultSet) {
    }
  }

  componentDidMount() {
    var token = this.props?.match?.params?.jwt;
    const validity={'token': token}
    let axiosConfig = {
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };
    // axios.post(`https://report-dev.tabbiemath.com/api/verifyTocken`,validity)
     axios.post(`${apitokenPath}`,validity,axiosConfig)
    //  axios.post(`https://preprod.tabbiemath.com/reports/api/public/api/auth/verifyTocken`,validity,axiosConfig)
    // axios.post(`https://reports.tabbiemath.com/api/public/api/auth/verifyTocken`,validity,axiosConfig)

    .then(res => {
    var decoded = jwt(token);
    this.state.decodeValue=decoded;


    if(this.state.decodeValue["hidePublish"]==="false" && this.state.decodeValue["hideClassAverageScore"]==="false"){
      var assignmentDimensionsParameter=[
              "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.percentageString",
              "AssessmentStatistics.averageScore"
        ]
        var assignmentDimensionsParameterAdaptive=[
          "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.Result",
              "TmSchoolHomework.OverallResult"
        ]
        var assignmentDimensionsParameterDiag=[
          "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.Diag_Student_Result",
              "TmSchoolHomework.Diag_ClassScore"
    ]
  }
  else if(this.state.decodeValue["hidePublish"]==="false" && this.state.decodeValue["hideClassAverageScore"]==="true"){
      var assignmentDimensionsParameter=[
        "TmSingleHwstudStatistics.completedDateString",
        "TmSchoolHomework.assignmentName",
        "TmSchoolHomework.assigId",
        "TmSchoolHomework.asgGroupId",
        "TmSchoolHomework.teacher",
        "TmSchoolHomework.publishType",
        "TmSingleHwstudStatistics.percentageString",
        ]
        var assignmentDimensionsParameterAdaptive=[
          "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.Result",
        ]
        var assignmentDimensionsParameterDiag=[
          "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.Diag_Student_Result",
    ]
  }
  else if(this.state.decodeValue["hidePublish"]==="true" && this.state.decodeValue["hideClassAverageScore"]==="false"){
    var assignmentDimensionsParameter=[
      "TmSingleHwstudStatistics.completedDateString",
      "TmSchoolHomework.assignmentName",
      "TmSchoolHomework.assigId",
      "TmSchoolHomework.teacher",
      "TmSchoolHomework.publishType",
      "TmSingleHwstudStatistics.percentageString",
      "AssessmentStatistics.averageScore"
      ]
      var assignmentDimensionsParameterAdaptive=[
        "TmSingleHwstudStatistics.completedDateString",
            "TmSchoolHomework.assignmentName",
            "TmSchoolHomework.assigId",
            "TmHomeworkgroup.groupId",
            "TmSchoolHomework.teacher",
            "TmSchoolHomework.publishType",
            "TmSingleHwstudStatistics.Result",
            "TmSchoolHomework.OverallResult"
      ]
      var assignmentDimensionsParameterDiag=[
        "TmSingleHwstudStatistics.completedDateString",
            "TmSchoolHomework.assignmentName",
            "TmSchoolHomework.assigId",
            "TmHomeworkgroup.groupId",
            "TmSchoolHomework.teacher",
            "TmSchoolHomework.publishType",
            "TmSingleHwstudStatistics.Diag_Student_Result",
            "TmSchoolHomework.Diag_ClassScore"
  ]
}
else if(this.state.decodeValue["hidePublish"]==="true" && this.state.decodeValue["hideClassAverageScore"]==="true"){
  var assignmentDimensionsParameter=[
    "TmSingleHwstudStatistics.completedDateString",
    "TmSchoolHomework.assignmentName",
    "TmSchoolHomework.assigId",
    "TmSchoolHomework.teacher",
    "TmSchoolHomework.publishType",
    "TmSingleHwstudStatistics.percentageString",
    ]
    var assignmentDimensionsParameterAdaptive=[
      "TmSingleHwstudStatistics.completedDateString",
          "TmSchoolHomework.assignmentName",
          "TmSchoolHomework.assigId",
          "TmHomeworkgroup.groupId",
          "TmSchoolHomework.teacher",
          "TmSchoolHomework.publishType",
          "TmSingleHwstudStatistics.Result",
    ]
    var assignmentDimensionsParameterDiag=[
      "TmSingleHwstudStatistics.completedDateString",
          "TmSchoolHomework.assignmentName",
          "TmSchoolHomework.assigId",
          "TmHomeworkgroup.groupId",
          "TmSchoolHomework.teacher",
          "TmSchoolHomework.publishType",
          "TmSingleHwstudStatistics.Diag_Student_Result",
]
}
  this.setState({assignmentDimensions:assignmentDimensionsParameter})
  this.setState({assignmentDimensionsAdaptive:assignmentDimensionsParameterAdaptive})
  this.setState({assignmentDimensionsDiag:assignmentDimensionsParameterDiag})
  if(this.state.decodeValue["hideClassAverageScore"]==="false"){
  var classScore=[
    "TmHwQnAnsStat.averageScore",
    "AssesmentStatisticsQuestions.averageScore",
    "TmHWQs.distCount"
  ]
}
else{
  var classScore=[
    "TmHwQnAnsStat.averageScore",
              "TmHWQs.distCount"
  ]
}
this.setState({studentReport:classScore})

    let isSchoolTrue=false;
    let isClassTrue=false;
    let isGroupTrue=false;
    let isStudentTrue=false;
    let isAssignmentTrue=false;

    if (this.state.decodeValue["sdateRange"]) {
      if (this.state.decodeValue["sdateRange"].toString() == 'Invalid date') {
        this.setState({ dateRangeList: ["", ""] });

      } else {
        var inputstartdate = moment(this.state.decodeValue["sdateRange"]);
        var inputenddate = moment(this.state.decodeValue["edateRange"]);

        this.state.dateRangeList= [inputstartdate,inputenddate] ;

      }
    }

    
    
     if (this.state.decodeValue["aggregatedBy"]) {
        this.state.aggregateBy= [this.state.decodeValue["aggregatedBy"]];
      }
      

      if (this.state.decodeValue["viewBy"]) {
        this.state.viewBy= [this.state.decodeValue["viewBy"]];

      }

      if (this.state.decodeValue["assignmentType"] == 1) {
        this.state.ifAdaptive = 1;
      }

      if (this.state.decodeValue["assignmentType"] == 2) {
        this.state.ifAdaptive = 2;
      }


    let access_token = cookie?.get('token');
      axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmSchoolUser.schoolName","asc"]],"dimensions":["TmSchoolUser.schoolId","TmSchoolUser.schoolName"],"segments":[],"filters":[]}`, {
      headers: {
        'Authorization': `${access_token}`
      }
    })
      .then(res => {
        isSchoolTrue=true;
        const schoolList = res.data.data;
        let schoolId='';
        let schoolName='';
        let schoolNameArray=[];
        if(this.state.decodeValue["school"]){
          schoolId = this.state.decodeValue["school"];
          schoolList && schoolList.map((x) => {
            if ((x)["TmSchoolUser.schoolId"] == schoolId) {
              schoolNameArray.push((x)["TmSchoolUser.schoolName"])
            }
          })
          schoolName = schoolNameArray.toString();
        }else {
          schoolId = schoolList[0]["TmSchoolUser.schoolId"];
          schoolName = schoolList[0]["TmSchoolUser.schoolName"];
        }
 
        // Assign School list
        this.setState({
          selectedSchool: schoolId.toString(),
        });
        this.setState({ schoolList });
        if (this.state.decodeValue["school"]) {
          this.setState({ 
            selectedSchoolID: this.state.decodeValue["school"].toString(),
            selectedSchoolValue:schoolName.toString()
          });
        } else {
          this.setState({
            selectedSchoolID: schoolId.toString(),
            selectedSchoolValue:schoolName.toString()
          });
        }
      })
        /*************** Class Section  *************/
          this.setState({selectedSchool:this.state.decodeValue["school"]})
          this.setState({selectedClass:this.state.decodeValue["class"]})

          axios.get(`${baseUrlCube}cubejs-api/v1/load?query=
          {"measures":[],"timeDimensions":[],"order":[["TmChapterStandard.standardId","asc"]],"dimensions":["TmChapterStandard.standardId","TmChapterStandard.className"],"segments":[],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]}]}`, {
            headers: {
              'Authorization': `${access_token}`
            }
          })
        .then(res => {;
          isClassTrue=true;
          const classListtt = res.data.data;
          let classList = [];
          classListtt && classListtt.map((x) => {
            if ((x)["TmChapterStandard.standardId"] != null) {
              classList.push(x);
            }
          })

          this.setState({ classList: classList });
          

          let classListOnLoad = [];
          classList && classList.map((x) => {
            if ((x)["TmChapterStandard.standardId"] != undefined) {
              classListOnLoad.push((x)["TmChapterStandard.standardId"]?.toString());
            }
          })



            let classId=this.state.decodeValue["class"];
          let className=[];
          this.setState({
            selectedClassID: classId.toString(),
            selectedClassValue:className.toString()
          });
          classList && classList.map((x) => {;
            if ((x)["TmChapterStandard.standardId"] == classId) {
              className.push((x)["TmChapterStandard.className"]?.toString());
            }
            
          })
          classListOnLoad[0]=className;
            this.setState({
              selectedClassId: classId,
              selectedClassValue: className });
         
        })

            /*************** Group Section  *************/
            let studentListUrl = '';

            if(this.state.decodeValue["student"] && this.state.decodeValue["group"]=="0"){
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"timeDimensions":[],"order":[["TmStudentCurrent.groupId","asc"]],"measures":[],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.decodeValue["school"]}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.decodeValue["class"]}"]},{"member":"TmStudentCurrent.userId","operator":"equals","values":["${this.state.decodeValue["student"]}"]}]}`;
            } else {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.decodeValue["school"]}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.decodeValue["class"]}"]}]}`;
            }
            axios.get(`${studentListUrl}`, {
                headers: {
                  'Authorization': `${access_token}`
                }
              })

              .then(res => {;
                this.setState({
                  loader: true,
                });
                isGroupTrue=true;
                const groupList = res.data.data;
                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList && groupList.map((x) => {
                  groupListOnLoad.push((x)["TmStudentCurrent.groupId"].toString());
                })
                if (this.state.decodeValue["group"] == "0") {


                  let groupId=this.state.decodeValue["group"].toString()
                  let groupName=[];
                  let groupIdName=[];
                  groupList && groupList.map((x) => {
                    if ((x)["TmStudentCurrent.groupId"] == groupId) {
                      groupName.push((x)["TmStudentCurrent.groupName"])
                    }              
                  })
                  if (groupId==0) {
                    groupName.push("ALL")
                    groupIdName.push("0")
                    }
                  this.setState({ selectedGroup: groupId,
                    selectedGroupId: groupIdName,
                    selectedGroupValue: groupName });
                }
                else if (this.state.decodeValue["group"]!="0") {


                  let groupId=this.state.decodeValue["group"].toString()
                  let groupName=[];
                  let groupIdName=[];
                  groupList && groupList.map((x) => {
                    if ((x)["TmStudentCurrent.groupId"] == groupId) {
                      groupName.push((x)["TmStudentCurrent.groupName"])
                    }              
                  })
                  if (groupId==0) {
                      groupName.push(groupListOnLoad[0])
                      groupIdName.push(groupListOnLoad[0])
                    }
                  this.setState({ selectedGroup: [this.state.decodeValue["group"]],
                    selectedGroupId: groupIdName,
                    selectedGroupValue: groupName });
                }
                 else {
                  this.setState({ selectedGroup: ['0'],selectedGroupID:['0'], selectedGroupValue:['All'] });
                }
             
            /*************** Group Section  *************/


                if(this.state.decodeValue["class"]=="0"){
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},,{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${this.state.decodeValue["group"]}"]}]} `;
                }
                else if(this.state.decodeValue["group"] =="0"){
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.decodeValue["class"]}"]}]}`;
                }
                else{
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.decodeValue["class"]}"]},{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${this.state.decodeValue["group"]}"]}]} `;
                }
                axios.get(`${studentListUrl}`, {
                  headers: {
                    'Authorization': `${access_token}`
                  }
                })
                  .then(res => {
                    isStudentTrue=true;
                    const studentList = res.data.data;
                    this.setState({ studentList: studentList });
                    const studentListOnLoad = [];
                    studentList && studentList.map((x) => {
                      studentListOnLoad.push((x)["TmStudentCurrent.userId"].toString());
                    })
                      let studentId=[this.state.decodeValue["student"].toString()]
                    let studentName=[];
                    studentList && studentList.map((x) => {
                      if ((x)["TmStudentCurrent.userId"] == studentId) {
                        studentName.push((x)["TmStudentCurrent.studentName"])
                      }
                      
                    })
                    this.setState({
                      selectedStudentID: studentId.toString(),
                      selectedStudentValue: studentName.toString()
                    });
                      this.setState({
                        selectedStudent:studentId,
                        selectedStudentId: studentId,
                        selectedStudentValue: studentName });

                /*************** Assignment Section  *************/

              axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format('YYYY-MM-DD')}","${this.state.dateRangeList[1].format('YYYY-MM-DD')}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSingleHwstudStatistics.studentId","operator":"equals","values":["${this.state.selectedStudent}"]}]}`, {
  
                headers: {
                  'Authorization': `${access_token}`
                }
              })
                .then(res => {;
                  isAssignmentTrue=true;
                  const assignmentList = res.data.data;
                  this.setState({ assignmentList: assignmentList });
  
                  if (this.state.decodeValue["assignment"]) {
                    let assignmentId=this.state.decodeValue["assignment"].toString()
                    let assignmentName=[];
                    assignmentList && assignmentList.map((x) => {
                      if ((x)["TmSingleHwstudStatistics.assignmentId"] == assignmentId) {
                        assignmentName.push((x)["TmSchoolHomework.assignmentName"])
                      }              
                    })
                    if (assignmentId==0) {
                      assignmentName.push("All")
                      }
                    this.setState({ selectedAssignment: [this.state.decodeValue["assignment"].toString()],
                       });
                  } else {
                    this.setState({ selectedAssignment: ['0'] });
                  }
  
                if(isSchoolTrue && isClassTrue && isGroupTrue && isStudentTrue && isAssignmentTrue == true){
                    this.setState({
                      loader: true,
                    });
                }  
                }).catch(error => {
                });
  
              /*************** Assignment Section  *************/
              }).
              catch(error => {
              });
            /* Student Section */
          }).
          catch(error => {
          });
          }).
    
  catch(error => {
    this.setState({
          invalidToken:false

    })
  });
  }

  render() {
    let width = 1024;
    let dateRangeArray = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        "dimension": "TmSchoolHomework.publishDate"

      })
    } else {
      dateRangeArray.push({
        "dimension": "TmSchoolHomework.publishDate",
        "dateRange": this.state.dateRangeList
      })

    }

    let dateRangeArrayQuestion = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesDomain = [];
    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptiveScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestion.push({
        "dimension": "TmSchoolHomework.publishDate"
      })
      dateRangeArrayQuestionAdaptive.push({
        "dimension": "AdaptiveReport.Publish_Date"
      })
      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        "dimension": "TmSchoolHomework.publishDate",
      })

      dateRangeArrayBoxesDomain = [];
      dateRangeArrayBoxesDomain.push({
        "dimension": "TmSingleHwstudStatistics.Publish_Date",
      })
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        "dimension": "classScore.Publish_Date"
      })
 
    } else {

      dateRangeArrayQuestion.push({
        "dimension": "TmSchoolHomework.publishDate",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      }
      )

      dateRangeArrayQuestionAdaptive.push({
        "dimension": "AdaptiveReport.Publish_Date",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      }
      )

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        "dimension": "TmSchoolHomework.publishDate",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      })

      dateRangeArrayBoxesDomain = [];
      dateRangeArrayBoxesDomain.push({
        "dimension": "TmSingleHwstudStatistics.Publish_Date",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      })
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        "dimension": "classScore.Publish_Date",
        "dateRange": [this.state.dateRangeList[0].format("YYYY-MM-DD"),this.state.dateRangeList[1].format("YYYY-MM-DD")]

      })

    }

    let dimensionArray = []
   
    if (this.state.aggregateBy == 'domain') {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == 'chapter') {
      dimensionArray.push("TmHwQnAnsStat.chapterName");

    } else if (this.state.aggregateBy == 'topic') {
      dimensionArray.push("TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.topicName");

    } else {
      dimensionArray.push("TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.topicName");

    }



    if (this.state.viewBy == 'proficiency') {
      this.state.orderBy=[
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.proficiencyName",
            "asc"
          ]
        ]

      this.state.pivotConfig= {
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.proficiencyName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
    }

    if (this.state.viewBy == 'skill') {
      this.state.orderBy=[
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.skillName",
            "asc"
          ]
        ]

      this.state.pivotConfig={
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.skillName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
    }
  //}


    if (this.state.viewBy == 'skill') {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy == 'proficiency') {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");

    } else {
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.schoolId",
        "operator": "equals",
        "values": this.state.selectedSchool
      })
    }

    if (this.state.selectedClass[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.standardId",
        "operator": "equals",
        "values": this.state.selectedClass
      });
    }

    if (this.state.selectedGroup[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.groupId",
        "operator": "equals",
        "values": this.state.selectedGroup
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.assigId",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let studentFilter = [];
    

    if (this.state.selectedStudent[0]?.toString() != '0') {
      studentFilter.push({
        "member": "TmSingleHwstudStatistics.studentId",
        "operator": "equals",
        "values": this.state.selectedStudent
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      studentFilter.push({
        "member": "TmSingleHwstudStatistics.assignmentId",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let studentFilterDig = [];
    

    if (this.state.selectedStudent[0]?.toString() != '0') {
      studentFilterDig.push({
        "member": "TmSingleHwstudStatistics.studentId",
        "operator": "equals",
        "values": this.state.selectedStudent
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      studentFilterDig.push({
        "member": "TmSchoolHomework.assignmentId",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let studentFilterAdaptive = [];
    

    if (this.state.selectedStudent[0]?.toString() != '0') {
      studentFilterAdaptive.push({
        "member": "AdaptiveReport.Student_Id",
        "operator": "equals",
        "values": this.state.selectedStudent
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      studentFilterAdaptive.push({
        "member": "AdaptiveReport.Assignment_Id",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
        "member": "classScore.School_Id",
        "operator": "equals",
        "values":  [this.state.selectedSchool]
      })
    }

    if (this.state.selectedClass[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
       "member": "classScore.Standard_Id",
       "operator": "equals",
        "values": [this.state.selectedClass]
      });
    }

    if (this.state.selectedGroup[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
        "member": "classScore.Group_Id",
        "operator": "equals",
        "values": this.state.selectedGroup
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
        "member": "classScore.Assignment_Id",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let dimensionDynamic=["TmHwQnAnsStat.proficiency","TmDifficulty.proficiency"];
    if(this.state.aggregateBy=="domain"){
      dimensionDynamic.push("TmHwQnAnsStat.domain")
    }
    else if(this.state.aggregateBy=="chapter"){
      dimensionDynamic.push("TmHwQnAnsStat.chapterName")
    }
    else{
      dimensionDynamic.push("TmHwQnAnsStat.chapterName","TmHwQnAnsStat.topicName")
    }


    let dimensionDynamicSkill=["TmHwQnAnsStat.skillName","TmHwQnAnsStat.skillStd"];
    if(this.state.aggregateBy=="domain"){
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain")
    }
    else if(this.state.aggregateBy=="chapter"){
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName")
    }
    else{
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName","TmHwQnAnsStat.topicName")
    }

    let dimensionDynamicAdaptive=[];
    if(this.state.aggregateBy=="domain"){
      dimensionDynamicAdaptive.push("AdaptiveReport.Domain_Name","AdaptiveReport.Domain_result")
    }
    else if(this.state.aggregateBy=="chapter"){
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.skillName","TmHwQnAnsStat.skillStd","TmHwQnAnsStat.chapterName")
    }
    else{
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.skillName","TmHwQnAnsStat.skillStd","TmHwQnAnsStat.chapterName","TmHwQnAnsStat.topicName")
    }



    const DashboardItemsTop = [
      {
        id: 1,
        name: "Assignment Completed",
        vizState: {
          query: {
            "measures": [
              "TmSingleHwstudStatistics.totalCompleted",
            ],
            "timeDimensions": dateRangeArrayBoxes,
            "order": {},
            "dimensions": [],
            "filters": studentFilter,
            "segments": [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.completedAssgn",
            ]
          },
          chartType: "number",
        }
      }, {
        id: 2,
        name: "Not Attempted",  
        vizState: { 
          query: {
            "measures": [
              "TmSingleHwstudStatistics.totalNotAttempted",
              ],
            "timeDimensions": dateRangeArrayBoxes,
            "order": {},
            "dimensions": [],
            "filters": studentFilter,
            "segments": [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.assgnNotAtt"
            ]
          },
          chartType: "number",
        }
      },
      {
        id: 3,
        name: "Student Score",
        vizState: {
          query: {
            "measures": [
              "TmSingleHwstudStatistics.averageScore",
            ],
            "timeDimensions": dateRangeArrayBoxes,
            "order": {},
            "dimensions": [],
            "filters": studentFilter,
            "segments": [
              "TmSchoolHomework.assgnClosed",
            ]
          },
          chartType: "number",
        }
      }, {
        id: 4,
        name: "Class Score",
        vizState: {
          query: {
            "measures": [             
              "AssessmentStatistics.totalAverage"
            ],
            "timeDimensions": dateRangeArrayBoxes,
            "order": {},
            "dimensions": [],
            "filters": studentFilter,
            "segments": [
                "TmSchoolHomework.assgnClosed",
            ]
          },
          chartType: "number",
        }
      }, {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query: {
            "segments": [
                "TmSchoolHomework.assgnClosed",
                "TmSingleHwstudStatistics.completedAssgn"
            ],

            "filters": studentFilter,
            "dimensions": dimensionArray,
            "timeDimensions": dateRangeArrayQuestion,
            "order": [
              [
                "TmHwQnAnsStat.chapterName",
                "asc"
              ],
              [
                "TmHwQnAnsStat.topicName",
                "asc"
              ],
              [
                "TmHwQnAnsStat.proficiencyName",
                "asc"
              ],
              [
                "TmHwQnAnsStat.skillName",
                "asc"
              ]
            ],
            "measures": this.state.studentReport
          },
          chartType: "table_pivot_individual",
          pivotConfig: this.state.pivotConfig
        }
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query:{
            "segments": [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed"
            ],
            "filters": studentFilter,
            "dimensions": dimensionDynamic,
            "timeDimensions": dateRangeArrayQuestion,
 	          "order": [
              [
                "TmHwQnAnsStat.proficiency",
                "asc"
              ]
            ],
            "measures": this.state.studentReport
           
          }
          ,
          chartType: "table_pivot_html_student",
          parameters: [
            {'ViewBy':this.state.aggregateBy},
            {'AggregatedBy':this.state.viewBy}
          ]  
        }
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query:{
            "segments": [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed"
            ],
            "filters": studentFilter,
            "dimensions": dimensionDynamicSkill,
            "timeDimensions": dateRangeArrayQuestion,
 	          "order": [
              [
                "TmHwQnAnsStat.skillStd",
                "asc"
              ]
            ],
            "measures": this.state.studentReport
           
          }
          ,
          chartType: "table_pivot_html_skill_student_domain",
          parameters: [
            {'ViewBy':this.state.aggregateBy},
            {'AggregatedBy':this.state.viewBy}
          ] 
        }
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query:{
            "filters": studentFilterAdaptive,
            "dimensions": dimensionDynamicAdaptive,              
            "timeDimensions": dateRangeArrayQuestionAdaptive,
 	          "order": [
              [
                "AdaptiveReport.Domain_Name",
                "asc"
              ]
            ],           
          }
          ,
          chartType: "table_pivot_individual_adaptive",
          parameters: [
            {'ViewBy':this.state.aggregateBy},
            {'AggregatedBy':this.state.viewBy}
          ] 
        }
      },
      {
        id: 6,
        name: "Assignments",
        vizState: {
          query: {
            "filters": studentFilter,
            "dimensions": this.state.assignmentDimensions,
            "timeDimensions": dateRangeArrayBoxes,
            "order": [
              [
                "TmSchoolHomework.assignmentName",
                "asc"
              ]
            ],
            "segments":
              [
                "TmSchoolHomework.assgnClosed",
                "TmSingleHwstudStatistics.attAndNtAttp"
              ],
          },
          chartType: "table_assignment_mail_page",
          parameters: [{ 'School': this.state.selectedSchoolID },
          { 'Class': this.state.selectedClass },
          { 'Group': this.state.selectedGroup },
          { 'Daterange': this.state.dateRangeList },
          { 'Assignment': this.state.selectedAssignment },
          {'ViewBy':this.state.viewBy},
          {'AggregatedBy':this.state.aggregateBy}]
        }
      },
      {
        id: 6,
        name: "Assignments",
        vizState: {
          query: {
            "filters": studentFilter,
            
            "dimensions": this.state.assignmentDimensionsAdaptive,
            "timeDimensions": dateRangeArrayBoxes,
            "order": [
              [
                "TmSchoolHomework.assignmentName",
                "asc"
              ]
            ],
            "segments":
              [
                "TmSchoolHomework.assgnClosed",
                "TmSingleHwstudStatistics.attAndNtAttp"
              ],
          },
          chartType: "table_assignment_adaptive_mail_page",
          parameters: [{ 'School': this.state.selectedSchoolID },
          { 'Class': this.state.selectedClass },
          { 'Group': this.state.selectedGroup },
          { 'Daterange': this.state.dateRangeList },
          { 'Assignment': this.state.selectedAssignment },
          {'ViewBy':this.state.viewBy},
          {'AggregatedBy':this.state.aggregateBy}]
        }
      },
      {
        id: 7,
        name: "Overall Progress",
        vizState: {
          query: {
            "filters": [
              {
                "member": "TmSingleHwstudStatistics.studentId",
                "operator": "equals",
                "values": this.state.selectedStudent
              }
            ],
            "dimensions": ["TmHwQnAnsStat.domain"],
            "timeDimensions": [
              {
                "dimension": "TmSchoolHomework.publishDate",
                "granularity": "month",
                "dateRange": this.state.processDateRangeList
              }
            ]
            ,
            "order": {},
            "segments":
              [
                "TmSchoolHomework.assgnClosed",
              ],
            "measures": [
              "TmSingleHwstudStatistics.averageScore"
            ]
          },
          chartType: "bar_overall"
        }
      },
      {
        id: 3,
        name: "Student Score",
        vizState: {
          query: {
            "timeDimensions": dateRangeArrayBoxesDomain,
            "order": {"TmSingleHwstudStatistics.Result":"asc"},
            "dimensions": ["TmSingleHwstudStatistics.Result"],
            "filters": studentFilter,
          },
          chartType: "domain_text_istudent",
        }
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            "dimensions": [
              "classScore.overall_result"
            ],
            "timeDimensions": dateRangeArrayBoxesAdaptiveScore,
            "order":{"classScore.overall_result":"asc"},
            "filters": chartFilterAdaptivescore,
          },
          chartType: "domain_text",
        }
      },
      {
        id: 3,
        name: "Student Score",
        vizState: {
          query: {
            "dimensions": [
              "TmSingleHwstudStatistics.Diag_Student_Result"
            ],
            "timeDimensions": dateRangeArrayBoxesDomain,
            "order":{"TmSingleHwstudStatistics.Diag_Student_Result": "asc"},
            "filters": studentFilter,
          },
          chartType: "domain_text_dig",
        }
      },
      {
        id: 6,
        name: "Assignments",
        vizState: {
          query: {
            "filters": studentFilter,
            
            "dimensions": this.state.assignmentDimensionsDiag,
            "timeDimensions": dateRangeArrayBoxes,
            "order": [
              [
                "TmSchoolHomework.assignmentName",
                "asc"
              ]
            ],
            "segments":
              [
                "TmSchoolHomework.assgnClosed",
                "TmSingleHwstudStatistics.attAndNtAttp"
              ],
          },
          chartType: "table_assignment_adaptive_mail_page",
          parameters: [{ 'School': this.state.selectedSchoolID },
          { 'Class': this.state.selectedClass },
          { 'Group': this.state.selectedGroup },
          { 'Daterange': this.state.dateRangeList },
          { 'Assignment': this.state.selectedAssignment },
          {'ViewBy':this.state.viewBy},
          {'AggregatedBy':this.state.aggregateBy}]
        }
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            "dimensions": [
              "TmSchoolHomework.Diag_ClassScore"
            ],
            "timeDimensions": dateRangeArrayQuestion,
            "order":{"TmSchoolHomework.Diag_ClassScore": "asc"},
            "filters": studentFilterDig,
          },
          chartType: "domain_text_dign",
        }
      },
    ];


    const DashboardItems =
      [
        {
          id: 1,
          name: "Number Chart",
          vizState: {
            query: {
              "measures": [
                "TmHWQs.distCount"
              ],
              "timeDimensions": [],
              "order": {},
              "dimensions": [],
              "filters": [],
              "limit": 10
            },
            chartType: "number"
          }
        }
      ];
    const dashboardItem = item => (
      <React.Fragment>
        {item.isVisible && (
          <Col span={24} lg={12} key={item.id} style={{ marginBottom: "24px" }}>
            <DashboardItem title={item.name}>
              <ChartRenderer vizState={item.vizState} />
            </DashboardItem>
          </Col>
        )}
      </React.Fragment>
    );
    const dashboardItemTop = item => (

      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "24px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>

    );



    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let selectedSchoolID = this.state.selectedSchool[0];
    let selectedClassID = this.state.selectedClass[0];
    let selectedStudentID = this.state.selectedStudent[0];

    var classOption = [];
    const { collapsed } = this.state;
    const dateFormat = 'DD-MM-YYYY';

    let allGroupList = [];
    let groupChildren = [];
    let classChildren=[];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    return DashboardItems.length ? (

      <React.Fragment>
        <Layout className="print_width_sdetail" style={{ minHeight: '100vh' }}>
          <Layout style={{ padding: '0 0px 0px' }}>
            <Grid xs={12} sm={12} lg={12} className="header header-customized" >
              <Grid xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                <Col lg={8} xs={8} className="logo_header">
                  <img class='logoWidth' src={logo} />
                </Col>
                <Col lg={8} xs={10} className="breadcrumb">
                  <center><h2><b> Individual Student Report</b></h2></center>
                </Col>
                <Col lg={8} xs={6} className="user_detail">
                <div className="filter-bg white-bg print_report">
                        <ReactToPrint
                          trigger={() => <button className="print_btn_web"><PrinterFilled />Print</button>}
                          content={() => window.print()}
                        />
                      </div>
                </Col>
              </Grid>
            </Grid>

            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Students Usage</Breadcrumb.Item>
              </Breadcrumb> */}
            <Content
              id="printme"
              className="site-layout-background"
              style={{
                padding: 24,
                margin: "13px 0px 0 13px",
                minHeight: 280,
              }}
            >
              {/* Content */}
              {this.state.invalidToken?
              <Grid>
              {!this.state.loader && <Spin />}
              {this.state.loader && <Dashboard>
                <Grid xs={12} sm={12} lg={12} className="content-print" style={{width: "100%"}}>
                  <Grid xs={12} sm={12} lg={12} className="header printme-extra-content" >
                    <Grid xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                      <Col span={8} className="logo_header">
                        <img class='logoWidth' src={logo} />
                      </Col>
                      <Col span={8} className="breadcrumb">
                        <center><h2><b>Students Usage Report</b></h2></center>
                      </Col>
                      <Col span={8} className="user_detail">
                        <h5><p>
                        <span className='c1'>School:</span> <span className='c2'>{this.state.selectedSchoolValue}</span><br />
                        <span className='c1'>Student Name:</span> <span className='c2'>{this.state.selectedStudentValue}</span><br />
                        <span className='c1'>Class:</span> <span className='c2'>{this.state.selectedClassValue}</span><br />
                        <span className='c1'>Group:</span> <span className='c2'>{this.state.decodeValue["group"] == '0' ? 'All' : this.state.selectedGroupValue}</span><br />
                        <span className='c1'>Date Range:</span> <span className='c2'>{this.state.decodeValue["sdateRange"]} To {this.state.decodeValue["edateRange"]}</span><br />
                        </p></h5>
                      </Col>
                    </Grid>
                  </Grid>
                  {/* <h5><p>
                        <span className='c1'>School:</span> <span className='c2'>{this.state.selectedSchoolValue}</span><br />
                        <span className='c1'>Student Name:</span> <span className='c2'>{this.state.decodeValue["student"]}</span><br />
                        <span className='c1'>Class:</span> <span className='c2'>{this.state.selectedClassValue}</span><br />
                        <span className='c1'>Group:</span> <span className='c2'>{this.state.decodeValue["group"] == '0' ? 'All' : this.state.decodeValue["group"]}</span><br />
                        <span className='c1'>Date Range:</span> <span className='c2'>{this.state.decodeValue["sdateRange"]} To {this.state.decodeValue["edateRange"]}</span><br />
                        </p></h5> */}
                  {this.state.decodeValue["hideClassAverageScore"]==="false"?
                  <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                    <Col lg={6} xs={24} key={DashboardItemsTop[0].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[0].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                      </DashboardItem>
                    </Col>
                    <Col lg={6} xs={24} key={DashboardItemsTop[1].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[1].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[1].vizState} />
                      </DashboardItem>
                    </Col>
                    {this.state.ifAdaptive =="1"  ?
                    <Col lg={6} xs={24} key={DashboardItemsTop[11].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[11].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[11].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    this.state.ifAdaptive =="2" ?
                    <Col lg={6} xs={24} key={DashboardItemsTop[13].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[13].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[13].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    <Col lg={6} xs={24} key={DashboardItemsTop[2].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[2].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[2].vizState} />
                      </DashboardItem>
                    </Col>
                    }
                   {this.state.ifAdaptive =="1" ?
                    <Col lg={6} xs={24} key={DashboardItemsTop[12].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[12].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[12].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    this.state.ifAdaptive =="2" ?
                    <Col lg={6} xs={24} key={DashboardItemsTop[15].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[15].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[15].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    <Col lg={6} xs={24} key={DashboardItemsTop[3].id} className="topWidget" id="">
                    <DashboardItem title={DashboardItemsTop[3].name}>
                      <ChartRendererCustomised vizState={DashboardItemsTop[3].vizState} />
                    </DashboardItem>
                  </Col>
                  }
                  </Grid>
                :
                  <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                    <Col lg={8} xs={24} key={DashboardItemsTop[0].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[0].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                      </DashboardItem>
                    </Col>
                    <Col lg={8} xs={24} key={DashboardItemsTop[1].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[1].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[1].vizState} />
                      </DashboardItem>
                    </Col>
                    {this.state.ifAdaptive =="1"  ?
                    <Col lg={8} xs={24} key={DashboardItemsTop[11].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[11].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[11].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    this.state.ifAdaptive =="2" ?
                    <Col lg={8} xs={24} key={DashboardItemsTop[13].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[13].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[13].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    <Col lg={8} xs={24} key={DashboardItemsTop[2].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[2].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[2].vizState} />
                      </DashboardItem>
                    </Col>
                    }
                  </Grid>}
                <br/>



                <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                  {this.state.ifAdaptive =="1" ?
                  this.state.viewBy=="all"?
                  this.state.aggregateBy == "domain"?
                  <Col span={24} lg={24} key={DashboardItemsTop[7].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                  <DashboardItem title={DashboardItemsTop[7].name}>
                    <ChartRenderer vizState={DashboardItemsTop[7].vizState} />
                  </DashboardItem>
                </Col>
                :
                <Col span={24} lg={24} key={DashboardItemsTop[4].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[4].name}>
                        <ChartRenderer vizState={DashboardItemsTop[4].vizState} />
                      </DashboardItem>
                    </Col>
                 :this.state.viewBy=="proficiency"?
                 <Col span={24} lg={24} key={DashboardItemsTop[5].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                   <DashboardItem title={DashboardItemsTop[5].name}>
                     <ChartRenderer vizState={DashboardItemsTop[5].vizState} />
                   </DashboardItem>
                 </Col>
                 :
                 <Col span={24} lg={24} key={DashboardItemsTop[6].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                   <DashboardItem title={DashboardItemsTop[6].name}>
                     <ChartRenderer vizState={DashboardItemsTop[6].vizState} />
                   </DashboardItem>
                 </Col>
                :
                  this.state.viewBy=="all"?
                    <Col span={24} lg={24} key={DashboardItemsTop[4].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[4].name}>
                        <ChartRenderer vizState={DashboardItemsTop[4].vizState} />
                      </DashboardItem>
                    </Col>
                    :this.state.viewBy=="proficiency"?
                    <Col span={24} lg={24} key={DashboardItemsTop[5].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[5].name}>
                        <ChartRenderer vizState={DashboardItemsTop[5].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    <Col span={24} lg={24} key={DashboardItemsTop[6].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[6].name}>
                        <ChartRenderer vizState={DashboardItemsTop[6].vizState} />
                      </DashboardItem>
                    </Col>
                  }
                  </Grid><br/>

                  <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                    <Col span={24} lg={24} key={DashboardItemsTop[10].id}
                      className={this.state.active === "" ? 'active' : ''} id="" >
                      <DashboardItem title={DashboardItemsTop[10].name}>
                        <div className="button-container">
                          <Button className="btnDuration" onClick={this.onChangePrevButton} disabled={this.state.prevButton}>
                            Previous
                          </Button>
                          <Button className="btnDuration" onClick={this.onChangeNextButton} disabled={this.state.nextButton}>
                            Next
                          </Button>
                        </div>
                        <ChartRendererCustomised vizState={DashboardItemsTop[10].vizState} />
                      </DashboardItem>
                    </Col>
                  </Grid><br/>

                  <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                  {this.state.ifAdaptive =="1" ?
                  <Col span={24} lg={24} key={DashboardItemsTop[9].id}
                      className={this.state.active === "" ? 'active' : ''} id="" >
                      <DashboardItem title={DashboardItemsTop[9].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[9].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    this.state.ifAdaptive =="2" ?
                  <Col span={24} lg={24} key={DashboardItemsTop[14].id}
                      className={this.state.active === "" ? 'active' : ''} id="" >
                      <DashboardItem title={DashboardItemsTop[14].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[14].vizState} />
                      </DashboardItem>
                    </Col>
                    :
                    <Col span={24} lg={24} key={DashboardItemsTop[8].id}
                      className={this.state.active === "" ? 'active' : ''} id="" >
                      <DashboardItem title={DashboardItemsTop[8].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[8].vizState} />
                      </DashboardItem>
                    </Col>
                    }
                  </Grid>

                  </Grid>
              </Dashboard>}
              </Grid>
              :
                  <center><h2>Id doesn't match or validity date is expired, please contact admin</h2></center>}
            </Content>
          </Layout>
        </Layout>


      </React.Fragment>
    ) : (
      <div style={{ textAlign: "center", padding: 12 }}  >  <Typography variant="h5" color="inherit">  There are no charts on this dashboard. Use Playground Build to add one. </Typography>  </div>
    );



  }
}

export default CustomerDashboardPage;