import React from "react";
import { Card } from "antd";

const CenterWrapper = ({ children, type }) => (
  <div className="textCenter">
    {type}
    </div>
);

export default CenterWrapper;


