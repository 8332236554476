import React, { PureComponent } from "react";
import { Spin, Row, Col, Layout, Menu, Select, DatePicker } from "antd";
import Grid from "@material-ui/core/Grid";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from "antd";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import cookie from "js-cookie";
import moment from "moment";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import schoolValue from "../pages/Student";
import ReactToPrint from "react-to-print";
import { Redirect } from "react-router";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  baseUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";
import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      studentReport: [],
      isRedirect403: false,
      collapsed: true,
      schoolList: [],
      classList: [],
      groupList: [],
      assignmentList: [],
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ], //undefined, // ["2021-04-05", "2021-04-20"],
      school: [],
      loader: false,
      selectedSchool: [],
      schoolName: [],
      selectedSchoolID: "",
      selectedSchoolValue: "",
      selectedClassValue: "",
      selectedGroupValue: "",
      selectedClassID: "",
      selectedGroupID: "",
      class: [],
      selectedClass: [""],
      group: [],
      selectedGroup: [""],
      selectedDateRange: ["", ""],
      selectedAssignment: [""],
      selectedAssignmentLabel: "",
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      ifAdaptive: 0,
    };
  }

  printOrder = () => {
    let orderHTML = "";
    const printableElements = document.getElementById("printme").innerHTML;
    orderHTML =
      '<html><head><title></title></head><body style="padding:20px">' +
      printableElements +
      "</body></html>";
    const oldPage = document.body.innerHTML;
    document.body.innerHTML = orderHTML;
    window.print();
    document.body.innerHTML = oldPage;
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onChangeViweBy = (value) => {
    this.setState({
      viewBy: [value],
    });

    if (value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (value == "skill") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.skillName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.skillName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }
  };

  onChangeAggregateBy = (value) => {
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
  };

  onChangeSchool = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedSchool: [option.value.toString()],
      selectedSchoolID: option.value.toString(),
      selectedSchoolValue: option.children.toString(),
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    let access_token = cookie?.get("token");

    /*************** Class Section  *************/
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStandard.standardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStandard.standardId"];
        let className = classList[0]["TmStandard.className"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });

        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStandard.standardId"] != undefined) {
              classListOnLoad.push(x["TmStandard.standardId"]?.toString());
            }
          });
        this.setState({ selectedClass: [classListOnLoad[0]] });

        /*************** Group Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const groupList = res.data.data;
            let groupId = groupList[0]["TmSchoolHomework.groupId"];
            let groupName = groupList[0]["TmSchoolHomework.group"];
            this.setState({ groupList: groupList });

            const groupListOnLoad = [];
            groupList &&
              groupList.map((x) => {
                groupListOnLoad.push(x["TmSchoolHomework.groupId"]);
              });

            this.setState({
              selectedGroup: ["0"],
              selectedGroupID: ["0"],
              selectedGroupValue: ["All"],
            });
          })
          .catch((error) => {});
        /*************** Group Section  *************/

        /*************** Assignment Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"], "order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };

  onChangeClass = (option) => {
    this.state.ifAdaptive = 0;
    this.state.selectedClass = [option.value.toString()];

    this.state.selectedClassValue = option.children.toString();
    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: {
        "TmSchoolGroups.classCount": "desc",
      },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let groupQuery = "";

    groupQuery = `cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    axios
      .get(`${baseUrlCube + groupQuery}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["0"],
          selectedAssignment: ["0"],
        });

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"], "order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});
  };

  onChangeGroup = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedGroup: [option.value],
      selectedGroupValue: option.label,
    });
    let access_token = cookie?.get("token");
    if (option.value == 0) {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
            "YYYY-MM-DD"
          )}","${this.state.dateRangeList[1].format(
            "YYYY-MM-DD"
          )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
            this.state.selectedSchool
          }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
            this.state.selectedClass
          }"]}]}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        )
        .then((res) => {
          const assignmentList = res.data.data;
          this.setState({ assignmentList: assignmentList });
          this.setState({ selectedAssignment: ["0"] });
        })
        .catch((error) => {});
    } else {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
            "YYYY-MM-DD"
          )}","${this.state.dateRangeList[1].format(
            "YYYY-MM-DD"
          )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
            this.state.selectedSchool
          }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
            this.state.selectedClass
          }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
            option.value
          }"]}]}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        )
        .then((res) => {
          const assignmentList = res.data.data;
          this.setState({ assignmentList: assignmentList });
          this.setState({ selectedAssignment: ["0"] });
        })
        .catch((error) => {});
    }
  };

  onDateRangeChange = (value, dateString) => {
    this.state.ifAdaptive = 0;
    dateString = dateString?.toString().split(",");
    this.setState({ dateRangeList: value }); //[dateString.toString()]

    if (dateString[0] != "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        this.state.selectedGroup
      }"]}]}`;
    } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`;
    } else {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    }
    let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });
        if (assignmentList.length > 0) {
          if (this.state.selectedGroup[0].toString() == "0") {
            this.setState({ selectedAssignment: ["0"] });
          } else {
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            this.setState({ selectedAssignment: ["0"] });
          }
        } else {
          this.setState({ selectedAssignment: ["0"], assignmentList: null });
        }
      })
      .catch((error) => {});
  };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value == "0") {
      this.setState({
        selectedAssignment: ["0"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
      });
      this.state.selectedAssignment = [value.value.toString()];
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }

      this.state.selectedAssignmentLabel = value.label;
    }
  };
  renderRedirect = () => {
    return <Redirect to="/home" />;
  };

  componentDidMount() {
    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isAssignmentTrue = false;
    let loginUserName = localStorage.getItem("user");
    let loginUserId = "";
    loginUserId = loginUserName;
    let access_token = cookie?.get("token");

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmProfiles.shortname"],"timeDimensions":[],"order":{"TmProfiles.shortname":"asc"},"filters":[{"member":"TmProfiles.userId","operator":"equals","values":["${loginUserId}"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        isSchoolTrue = true;
        const loginUser = res.data.data[0]["TmProfiles.shortname"];
        this.setState({ loginUser: loginUser });
        localStorage.setItem("username", loginUser);
      })
      .catch((error) => {});

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmSchool.count"],"timeDimensions": [],"order": [["TmSchool.schoolName","asc"]],"dimensions": ["TmSchool.schoolId","TmSchool.schoolName"]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const schoolList = res.data.data;
        let schoolId = schoolList[0]["TmSchool.schoolId"];
        let schoolName = schoolList[0]["TmSchool.schoolName"];
        this.setState({
          schoolName: schoolName,
        });
        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });

        this.setState({
          selectedSchoolID: schoolId.toString(),
          selectedSchoolValue: schoolName.toString(),
        });
        /*************** Class Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${this.state.selectedSchoolID}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            isClassTrue = true;
            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStandard.standardId"] != null) {
                  classList.push(x);
                }
              });

            let classId = classList[0]["TmStandard.standardId"];
            let className = classList[0]["TmStandard.className"];
            this.setState({ classList: classList });
            this.setState({
              selectedClassID: classId.toString(),
              selectedClassValue: className.toString(),
            });

            let classListOnLoad = [];
            classList &&
              classList.map((x) => {
                if (x["TmStandard.standardId"] != undefined) {
                  classListOnLoad.push(x["TmStandard.standardId"]?.toString());
                }
              });
            this.setState({ selectedClass: [classListOnLoad[0]] });

            /*************** Group Section  *************/

            axios
              .get(
                `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                isGroupTrue = true;
                this.setState({
                  loader: true,
                });
                const groupList = res.data.data;
                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList &&
                  groupList.map((x) => {
                    groupListOnLoad.push(
                      x["TmStudentCurrent.groupName"].toString()
                    );
                  });

                this.setState({
                  selectedGroup: ["0"],
                  selectedGroupID: ["0"],
                  selectedGroupValue: ["All"],
                });
              })
              .catch((error) => {});
            /*************** Group Section  *************/

            /*************** Assignment Section  *************/

            axios
              .get(
                `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                  "YYYY-MM-DD"
                )}","${this.state.dateRangeList[1].format(
                  "YYYY-MM-DD"
                )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                  this.state.selectedSchool
                }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                  this.state.selectedClass
                }"]}]} `,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                isAssignmentTrue = true;
                const assignmentList = res.data.data;

                this.setState({ assignmentList: assignmentList });

                const assignmentListOnLoad = [];
                assignmentList &&
                  assignmentList.map((x) => {
                    assignmentListOnLoad.push(
                      x["TmSchoolHomework.assignmentId"].toString()
                    );
                  });

                this.setState({ selectedAssignment: ["0"] });

                if (
                  isSchoolTrue &&
                  isClassTrue &&
                  isGroupTrue &&
                  isAssignmentTrue == true
                ) {
                  this.setState({
                    loader: true,
                  });
                }
              })
              .catch((error) => {});

            /*************** Assignment Section  *************/
          })
          .catch((error) => {
            this.setState({
              loader: true,
            });
          });

        /*************** Class Section  *************/
      })
      .catch((error) => {
        if (error && error == "Error: Request failed with status code 403") {
          this.setState({ isRedirect403: true, loader: false });
        }
      });
  }

  render() {
    if (this.state.isRedirect403) {
      return <Redirect to="/home" />;
    }
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: this.state.dateRangeList,
      });
    }

    let dateRangeArrayQuestion = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesAdaptiveScore = [];
    let dateRangeArrayBoxesDigScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestion = [];
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
      });

      dateRangeArrayBoxesDigScore = [];
      dateRangeArrayBoxesDigScore.push({
        dimension: "DiagnosticReport.diag_Publish_Date",
      });
    } else {
      dateRangeArrayQuestion = [];
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesDigScore = [];
      dateRangeArrayBoxesDigScore.push({
        dimension: "DiagnosticReport.diag_Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptive = [];
    let dateRangeArrayBoxesAdaptiveReport = [];
    let dateRangeArrayBoxesDigReport = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });

      dateRangeArrayBoxesAdaptive = [];
      dateRangeArrayBoxesAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });

      dateRangeArrayBoxesAdaptiveReport = [];
      dateRangeArrayBoxesAdaptiveReport.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
      });
      dateRangeArrayBoxesDigReport = [];
      dateRangeArrayBoxesDigReport.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesAdaptive = [];
      dateRangeArrayBoxesAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesAdaptiveReport = [];
      dateRangeArrayBoxesAdaptiveReport.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesDigReport = [];
      dateRangeArrayBoxesDigReport.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dimensionArray = [];
    if (this.state.aggregateBy == "domain") {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArray.push("TmHwQnAnsStat.chapterName");
    } else if (this.state.aggregateBy == "topic") {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    } else {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicAdaptive = [];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicAdaptive.push(
        "AdaptiveReport.Domain_Name",
        "AdaptiveReport.Domain_result"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    if (this.state.viewBy[0] == "skill") {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy[0] == "proficiency") {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");
    } else {
    }

    let filter = [];
    if (this.state.selectedSchool.length >= 1) {
      filter.push({
        member: "TmSchool.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
    if (this.state.selectedClass.length > 0) {
      filter.push({
        member: "TmStandard.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }
    if (this.state.selectedGroup.length > 0) {
      filter.push({
        member: "TmSchoolGroups.group",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptive = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptiveReport = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptiveReport.push({
        member: "TmSingleHwstudStatistics.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterDigscore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterDigscore.push({
        member: "DiagnosticReport.diag_Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dimensionDynamic = [
      "TmHwQnAnsStat.proficiency",
      "TmDifficulty.proficiency",
      "TmDifficulty.difficultyOrder",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamic.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamic.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamic.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicSkill = [
      "TmHwQnAnsStat.skillName",
      "TmHwQnAnsStat.skillStd",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicSkill.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Total Assignments",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.count"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },
      {
        id: 2,
        name: "Students Completed",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.totalCompleted"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },

      {
        id: 3,
        name: "Students Not Attempted",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.notAttempted"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },

      {
        id: 4,
        name: "Distinct Questions",
        vizState: {
          query: {
            measures: ["TmHomeworkQuestions.distCount"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },

      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            measures: ["TmSchoolHomework.classScoreAvg"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },
      {
        id: 6,
        name: "Class Usage Report",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
            timeDimensions: dateRangeArrayQuestion,
            order: [
              ["TmHwQnAnsStat.chapterName", "asc"],
              ["TmHwQnAnsStat.skillName", "asc"],
            ],
            dimensions: dimensionArray,
            filters: chartFilter,
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
          },
          chartType: "table_pivot",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 6,
        name: "Class Usage Report",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
            dimensions: dimensionDynamic,
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],

            timeDimensions: dateRangeArrayQuestion,

            filters: chartFilter,
           // order: [["TmHwQnAnsStat.proficiency", "desc"]],
           order: [["TmDifficulty.difficultyOrder", "asc"]],
          },
          chartType: "table_pivot_html",
          parameters: [
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 6,
        name: "Class Usage Report",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
            dimensions: dimensionDynamicSkill,
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
            timeDimensions: dateRangeArrayQuestion,
            filters: chartFilter,
            order: [["TmHwQnAnsStat.skillStd", "asc"]],
          },
          chartType: "table_pivot_html_skill_adap",
          parameters: [
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 6,
        name: "Class Usage Report",
        vizState: {
          query: {
            dimensions: dimensionDynamicAdaptive,
            timeDimensions: dateRangeArrayQuestionAdaptive,
            filters: chartFilterAdaptive,
            order: [["AdaptiveReport.Domain_Name", "asc"]],
          },
          chartType: "table_pivot_adaptive",
          parameters: [
            { AssignmentLabel: this.state.selectedAssignmentLabel },
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 7,
        name: "Students Report - Table View",
        vizState: {
          query: {
            dimensions: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.studentId",
            ],
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmSingleHwstudStatistics.studentName", "asc"]],
            measures: [
              "TmSingleHwstudStatistics.totalCompleted",
              "TmSingleHwstudStatistics.totalNotAttempted",
              "TmSingleHwstudStatistics.averageScore",
            ],
            segments: ["TmSchoolHomework.assgnClosed"],
            filters: chartFilter,
          },

          chartType: "table_individual",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
      {
        id: 7,
        name: "Students Report - Table View",
        vizState: {
          query: {
            dimensions: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.Result",
              "TmSingleHwstudStatistics.studentId",
            ],
            timeDimensions: dateRangeArrayBoxesAdaptiveReport,
            order: [["TmSingleHwstudStatistics.studentName", "asc"]],

            filters: chartFilterAdaptiveReport,
          },

          chartType: "table_individual_adaptive",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateByAdaptive },
          ],
        },
      },
      {
        id: 6,
        name: "Class Usage Report",
        vizState: {
          query: {
            dimensions: dimensionDynamicAdaptive,
            timeDimensions: dateRangeArrayQuestionAdaptive,
            filters: chartFilterAdaptive,
            order: [["TmHwQnAnsStat.skillStd", "asc"]],
          },
          chartType: "table_pivot_html_skill",
          parameters: [
            { ViewBy: this.state.aggregateByAdaptive },
            { AggregatedBy: this.state.viewBy },
          ],
        },
      },
      {
        id: 6,
        name: "Class Usage Report",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
            timeDimensions: dateRangeArrayQuestion,
            order: [
              ["TmHwQnAnsStat.chapterName", "asc"],
              ["TmHwQnAnsStat.skillName", "asc"],
            ],
            dimensions: dimensionArray,
            filters: chartFilter,
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
          },
        },
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            dimensions: ["classScore.overall_result"],
            timeDimensions: dateRangeArrayBoxesAdaptiveScore,
            order: { "classScore.overall_result": "asc" },
            filters: chartFilterAdaptivescore,
          },
          chartType: "domain_text",
        },
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            dimensions: ["TmSchoolHomework.Diag_ClassScore"],
            timeDimensions: dateRangeArrayBoxes,
            order: { "TmSchoolHomework.Diag_ClassScore": "asc" },
            filters: chartFilter,
          },
          chartType: "domain_text_dign",
        },
      },
      {
        id: 7,
        name: "Students Report - Table View",
        vizState: {
          query: {
            measures: [
              "TmSingleHwstudStatistics.totalCompleted",
              "TmSingleHwstudStatistics.totalNotAttempted",
            ],
            dimensions: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.studentId",
              "TmSingleHwstudStatistics.Diag_Student_Result",
            ],
            timeDimensions: dateRangeArrayBoxesDigReport,
            order: [["TmSingleHwstudStatistics.studentName", "asc"]],

            segments: ["TmSchoolHomework.assgnClosed"],
            filters: chartFilter,
          },

          chartType: "table_individual",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateByAdaptive },
          ],
        },
      },
    ];

    const { collapsed } = this.state;
    const dateFormat = "DD-MM-YYYY";

    let allGroupList = [];
    let groupChildren = [];
    let classChildren = [];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    const cubejsApi = cubejs(cookie?.get("token"), {
      apiUrl: `${baseUrlCube}cubejs-api/v1`,
    });

    return (
      <React.Fragment>
        <CubeProvider cubejsApi={cubejsApi}>
          <Layout className="print_width" style={{ minHeight: "100vh" }}>
            <Sider
              width={230}
              collapsible
              collapsed={collapsed}
              onCollapse={this.onCollapse}
              className="side_menu site-layout-background"
            >
              <Menu theme="dark" mode="inline" className="nav_panel">
                <Menu.Item icon={<SolutionOutlined />} key="2">
                  <Link
                    to="/teacher-usage"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Teachers Usage
                  </Link>
                </Menu.Item>
               {/*  <Menu.Item icon={<FileDoneOutlined />} key="13">
                    <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                </Menu.Item> */}
                <Menu.Item icon={<TeamOutlined />} key="1">
                  <Link to="/" className="sub-menu ant-menu-submenu-active1">
                    Class Usage
                  </Link>
                </Menu.Item>
                <Menu.Item
                  icon={<UserOutlined />}
                  key="3"
                  className="ant-menu-submenu "
                >
                  <Link
                    to="/student"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Individual Student Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<FormOutlined />} key="4">
                  <Link
                    to="/assignment"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Assignment Report
                  </Link>
                </Menu.Item>
                <Menu.Item icon={<MailOutlined />} key="5">
                  <Link
                    to="/send-email"
                    className="sub-menu ant-menu-submenu-active1"
                  >
                    Send Email
                  </Link>
                </Menu.Item>
              {/*   <Menu.Item icon={<FundProjectionScreenOutlined />} key="6">

                  <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                </Menu.Item> */}
                {/* {localStorage.getItem("userType") == 1 && (
                  <>
                    <Menu.Item icon={<LoginOutlined />} key="7">
                      <Link
                        to="/login-report"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Login Report
                      </Link>
                    </Menu.Item>
                   
                    <Menu.Item icon={<LineChartOutlined />} key="9">
                      <Link
                        to="/task-analysis"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Task Analysis
                      </Link>
                    </Menu.Item>
                    <Menu.Item icon={<FundOutlined />} key="10">
                      <Link
                        to="/question-analysis"
                        className="sub-menu ant-menu-submenu-active1"
                      >
                        Question Analysis
                      </Link>
                    </Menu.Item>
                  </>
                )} */}
              </Menu>
            </Sider>

            <Layout style={{ padding: "0 0px 0px" }}>
              <Grid
                xs={12}
                sm={12}
                lg={12}
                className="header header-customized"
              >
                <nav className="custom-nav">
                  <button
                    className="nav-toggl"
                    onClick={() => {
                      this.setState({ mobNav: !this.state.mobNav });
                      console.log("The button is clicked", this.state.mobNav);
                    }}
                  >
                    <MenuOutlinedIcon
                      className="icon-style-custom"
                      style={{
                        width: "20px",
                        height: "20px",
                        margin: "auto",
                        display: "flex",
                      }}
                    />
                  </button>
                  {this.state.mobNav && (
                    <ul className="custom-ul">
                      <Menu theme="dark" mode="inline" className="nav_panel">
                      {localStorage.getItem("reportType") == 1 && (
                          <>
                              <Menu.Item icon={<SolutionOutlined />} key="3">
                                <Link
                                  to="/teacher-usage"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Teachers Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<TeamOutlined />} key="4">
                                <Link
                                  to="/"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Class Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<UserOutlined />}
                                key="2"
                                className="ant-menu-submenu "
                              >
                                <Link
                                  to="/student"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Individual Student Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<FormOutlined />} key="5">
                                <Link
                                  to="/assignment"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Assignment Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<MailOutlined />} key="6">
                                <Link
                                  to="/send-email"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Send Email
                                </Link>
                              </Menu.Item>

                          </>
                      )}

                      {localStorage.getItem("reportType") == 3 && (
                              <>

                                  <Menu.Item icon={<DatabaseOutlined   />} key="1">
                                    <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<FileDoneOutlined   />} key="2">
                                    <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<AreaChartOutlined />} key="3">
                                    <a href={`${newReportUrl}/progress-report`}>Progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<SolutionOutlined  />} key="4">
                                    <Link
                                      to="/teacher-usage"
                                      className="sub-menu ant-menu-submenu-active1"
                                    >
                                      Teacher Usage
                                    </Link>
                                  </Menu.Item>



                                  <Menu.Item icon={<MailOutlined   />} key="5">
                                    <Link
                                      to="/send-email"
                                      className="sub-menu ant-menu-submenu-active1"
                                    >
                                      Mail
                                    </Link>
                                  </Menu.Item>


                                  <Menu.Item
                                    icon={<FundProjectionScreenOutlined />}
                                    key="6"
                                  >
                                    <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                                  </Menu.Item>


                                      {localStorage.getItem("userType") == 1 && (
                                        <>
                                          <Menu.Item icon={<LoginOutlined />} key="7">
                                            <Link
                                              to="/login-report"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Login Report
                                            </Link>
                                          </Menu.Item>
                      
                                          <Menu.Item icon={<LineChartOutlined />} key="9">
                                            <Link
                                              to="/task-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Task Analysis
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<FundOutlined />} key="10">
                                            <Link
                                              to="/question-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Question Analysis
                                            </Link>
                                          </Menu.Item>
                                        </>
                                      )}
                            </>
                        )}
                      </Menu>
                    </ul>
                  )}
                </nav>
                <Grid
                  xs={24}
                  lg={24}
                  container
                  spacing={0}
                  style={{ padding: 0 }}
                  justify="left"
                  alignItems="flex-start"
                >
                  <Col span={8} className="logo_header">
                    <img class="logoWidth" src={logo} />
                  </Col>
                  <Col span={8} className="breadcrumb">
                    <center>
                      <h2>
                        <b>Class Usage Report</b>
                      </h2>
                    </center>
                  </Col>
                  <Col span={8} className="user_detail">
                    <h5>
                      <p>
                        <span className="c1">Hello!,</span>{" "}
                        <span className="c2">{this.state.loginUser}</span>
                        <br />
                        <div className="none">
                          {this.state.selectedSchoolValue}
                        </div>
                        <Link
                          className="logout_btn"
                          onClick={(event) => {
                            event.preventDefault();
                            signout(() => <Redirect to="/home" />);
                          }}
                        >
                          {" "}
                          Home{" "}
                        </Link>
                      </p>
                    </h5>
                  </Col>
                </Grid>
              </Grid>
              <center>
                <h2>
                  <b>
                    <div
                      style={{
                        display: this.state.display,
                        border: "2px solid black",
                        width: "30%",
                        height: "100px",
                        marginTop: "30px",
                        paddingTop: "30px",
                        backgroundColor: "white",
                      }}
                    >
                      No Records Found
                    </div>
                  </b>
                </h2>
              </center>
              <Content
                className="ant-layout-content site-layout-background"
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {this.state.display == "none"
                  ? !this.state.loader && <Spin />
                  : this.setState({ display: "block" })}

                {this.state.loader && (
                  <Dashboard>
                    <Grid
                      xs={10}
                      sm={10}
                      lg={10}
                      className="header printme-extra-content"
                    >
                      <Grid
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col span={8} className="logo_header_print">
                          <img class="logoWidth" src={logo} />
                        </Col>
                        <Col span={8} className="breadcrumb_print">
                          <center>
                            <h2>
                              <b>Class Usage Report</b>
                            </h2>
                          </center>
                        </Col>
                        <Col span={8} className="user_detail_print">
                          <h5>
                            <p>
                              <span className="c1">School:</span>{" "}
                              <span className="c2">
                                {this.state.selectedSchoolValue}
                              </span>
                              <br />
                              <span className="c1">Class:</span>{" "}
                              <span className="c2">
                                {this.state.selectedClassValue}
                              </span>
                              <br />
                              <span className="c1">Group:</span>{" "}
                              <span className="c2">
                                {this.state.selectedGroupValue == "0"
                                  ? "All"
                                  : this.state.selectedGroupValue}
                              </span>
                              <br />
                              <span className="c1">Date Range:</span>{" "}
                              <span className="c2">
                                {this.state.dateRangeList[0].format(
                                  "DD-MM-YYYY"
                                )}{" "}
                                To{" "}
                                {this.state.dateRangeList[1].format(
                                  "DD-MM-YYYY"
                                )}
                              </span>
                              <br />
                            </p>
                          </h5>
                        </Col>
                      </Grid>
                    </Grid>
                    <Grid xs={10} sm={10} lg={10} className="cust-grid-1">
                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={5}
                          key={DashboardItemsTop[0].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[0].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[0].vizState}
                            />
                          </DashboardItem>
                        </Col>
                        <Col
                          span={5}
                          key={DashboardItemsTop[1].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[1].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[1].vizState}
                            />
                          </DashboardItem>
                        </Col>
                        <Col
                          span={5}
                          key={DashboardItemsTop[2].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[2].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[2].vizState}
                            />
                          </DashboardItem>
                        </Col>
                        <Col
                          span={5}
                          key={DashboardItemsTop[3].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[3].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[3].vizState}
                            />
                          </DashboardItem>
                        </Col>
                        {this.state.ifAdaptive == "1" ? (
                          <Col
                            span={4}
                            key={DashboardItemsTop[13].id}
                            className="topWidget"
                            id=""
                          >
                            <DashboardItem title={DashboardItemsTop[13].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[13].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : this.state.ifAdaptive == "2" ? (
                          <Col
                            span={4}
                            key={DashboardItemsTop[14].id}
                            className="topWidget"
                            id=""
                          >
                            <DashboardItem title={DashboardItemsTop[14].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[14].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : (
                          <Col
                            span={4}
                            key={DashboardItemsTop[4].id}
                            className="topWidget"
                            id=""
                          >
                            <DashboardItem title={DashboardItemsTop[4].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[4].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        )}
                      </Grid>
                      <br />

                      <Grid
                        className="top-widgets topWidgetOne"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        {this.state.ifAdaptive == "1" ? (
                          this.state.viewBy == "all" ? (
                            this.state.aggregateBy == "domain" ? (
                              <Col
                                span={24}
                                lg={24}
                                key={DashboardItemsTop[8].id}
                                className={
                                  this.state.active === "" ? "active" : ""
                                }
                                id=""
                                onClick={this.onClickDashboardTop}
                              >
                                <DashboardItem
                                  title={DashboardItemsTop[8].name}
                                >
                                  <ChartRenderer
                                    vizState={DashboardItemsTop[8].vizState}
                                  />
                                </DashboardItem>
                              </Col>
                            ) : (
                              <Col
                                span={24}
                                lg={24}
                                key={DashboardItemsTop[5].id}
                                className={
                                  this.state.active === "" ? "active" : ""
                                }
                                id=""
                                onClick={this.onClickDashboardTop}
                              >
                                <DashboardItem
                                  title={DashboardItemsTop[5].name}
                                >
                                  <ChartRenderer
                                    vizState={DashboardItemsTop[5].vizState}
                                  />
                                </DashboardItem>
                              </Col>
                            )
                          ) : this.state.viewBy == "proficiency" ? (
                            <Col
                              span={24}
                              lg={24}
                              key={DashboardItemsTop[6].id}
                              className={
                                this.state.active === "" ? "active" : ""
                              }
                              id=""
                              onClick={this.onClickDashboardTop}
                            >
                              <DashboardItem title={DashboardItemsTop[6].name}>
                                <ChartRenderer
                                  vizState={DashboardItemsTop[6].vizState}
                                />
                              </DashboardItem>
                            </Col>
                          ) : (
                            <Col
                              span={24}
                              lg={24}
                              key={DashboardItemsTop[7].id}
                              className={
                                this.state.active === "" ? "active" : ""
                              }
                              id=""
                              onClick={this.onClickDashboardTop}
                            >
                              <DashboardItem title={DashboardItemsTop[7].name}>
                                <ChartRenderer
                                  vizState={DashboardItemsTop[7].vizState}
                                />
                              </DashboardItem>
                            </Col>
                          )
                        ) : this.state.viewBy == "all" ? (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[5].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[5].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[5].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : this.state.viewBy == "proficiency" ? (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[6].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[6].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[6].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[7].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[7].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[7].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        )}
                      </Grid>
                      <br />

                      <Grid
                        className="top-widgets"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        {this.state.ifAdaptive == "1" ? (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[10].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                          >
                            <DashboardItem title={DashboardItemsTop[10].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[10].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : this.state.ifAdaptive == "2" ? (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[15].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                          >
                            <DashboardItem title={DashboardItemsTop[15].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[15].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[9].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                          >
                            <DashboardItem title={DashboardItemsTop[9].name}>
                              <ChartRendererCustomised
                                vizState={DashboardItemsTop[9].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      xs={2}
                      sm={2}
                      lg={2}
                      className="print_width_filter print_filters print_width_filter-cust2"
                    >
                      <Grid
                        container
                        justify="left"
                        alignItems="flex-start"
                        id="filterContainer-title"
                      >
                        <div
                          className="filter-bg white-bg print_btn_print"
                          style={{ float: "center" }}
                        >
                          <ReactToPrint
                            trigger={() => (
                              <button className="print_btn_web">
                                <PrinterFilled />
                                &nbsp;&nbsp;Print
                              </button>
                            )}
                            content={() => window.print()}
                          />
                        </div>
                        <DashboardItem
                          title="Filters"
                          className="ant-card-body "
                        >
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> School Name </strong>{" "}
                              </label>
                              <schoolValue
                                data={this.state.selectedSchool}
                              ></schoolValue>
                              <Select
                                showSearch
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select school name"
                                optionFilterProp="children"
                                onChange={(value, option) =>
                                  this.onChangeSchool(option)
                                }
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedSchoolID?.toString(),
                                }}
                              >
                                {this.state.schoolList.map((x) => {
                                  return (
                                    <Option
                                      key={x["TmSchool.schoolId"]}
                                      value={x["TmSchool.schoolId"]?.toString()}
                                    >
                                      {x["TmSchool.schoolName"]}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Class </strong>{" "}
                              </label>

                              <Select
                                showSearch
                                style={{ width: "100%", paddingTop: 5 }}
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedClass[0]?.toString(),
                                }}
                                placeholder="Select Class"
                                optionFilterProp="children"
                                onChange={(value, option) =>
                                  this.onChangeClass(option)
                                }
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                if({this.state.selectedClass.length > 0})
                                {this.state.classList &&
                                  this.state.classList.map((x) => {
                                    classChildren.push(
                                      <Option
                                        key={x[
                                          "TmStandard.standardId"
                                        ]?.toString()}
                                        value={x[
                                          "TmStandard.standardId"
                                        ]?.toString()}
                                      >
                                        {x["TmStandard.className"]}
                                      </Option>
                                    );
                                    allClassList.push(
                                      x["TmStandard.standardId"]
                                    );
                                  })}
                                <Option value="0">All</Option>
                                {classChildren}
                              </Select>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Group </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                labelInValue
                                value={{
                                  value: this.state.selectedGroup?.toString(),
                                }}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select Group"
                                optionFilterProp="children"
                                onChange={this.onChangeGroup}
                              >
                                if({this.state.selectedGroup.length > 0}){" "}
                                {this.state.groupList &&
                                  this.state.groupList.map((x) => {
                                    groupChildren.push(
                                      <Option
                                        key={x["TmStudentCurrent.groupId"]}
                                        id={x["TmStudentCurrent.groupId"]}
                                      >
                                        {x["TmStudentCurrent.groupName"]}
                                      </Option>
                                    );
                                    allGroupList.push(
                                      x["TmStudentCurrent.groupId"]
                                    );
                                  })}
                                <Option value="0"> All </Option>
                                {groupChildren}
                              </Select>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Date Range </strong>{" "}
                              </label>
                              <RangePicker
                                style={{ top: 5 }}
                                onChange={this.onDateRangeChange}
                                value={[
                                  moment(
                                    this.state.dateRangeList[0],
                                    dateFormat
                                  ),
                                  moment(
                                    this.state.dateRangeList[1],
                                    dateFormat
                                  ),
                                ]}
                                format={dateFormat}
                              />
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ paddingBottom: 6, paddingTop: 5 }}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Assignments </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                style={{ width: "100%", paddingTop: 5 }}
                                labelInValue
                                value={{
                                  value:
                                    this.state.selectedAssignment[0]?.toString(),
                                }}
                                placeholder="Select Assignments"
                                optionFilterProp="children"
                                onChange={this.onChangeAssignment}
                              >
                                if({this.state.selectedAssignment.length > 0}){" "}
                                {this.state.assignmentList &&
                                  this.state.assignmentList.map((x) => {
                                    assignmentChildren.push(
                                      <Option
                                        key={x["TmSchoolHomework.assignmentId"]}
                                      >
                                        {x["TmSchoolHomework.assignmentName"]}
                                      </Option>
                                    );
                                    allAssignmentList.push(
                                      x["TmSchoolHomework.assignmentId"]
                                    );
                                  })}
                                <Option value="0"> All </Option>
                                {assignmentChildren}
                              </Select>
                            </div>
                          </Col>

                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> Aggregate by </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                defaultValue={"all"}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select View by"
                                optionFilterProp="children"
                                onChange={this.onChangeViweBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="all">Overall</Option>
                                <Option value="skill">Skill</Option>
                                <Option value="proficiency">Proficiency</Option>
                              </Select>
                            </div>
                          </Col>
                          {this.state.ifAdaptive == 0 ||
                          this.state.ifAdaptive == 2 ? (
                            <Col
                              span={24}
                              lg={24}
                              xs={24}
                              sm={24}
                              md={24}
                              style={{ paddingBottom: 6 }}
                              className="filterContainer"
                            >
                              <div className="filter-bg color-1 white-bg">
                                <label>
                                  {" "}
                                  <strong> View by </strong>{" "}
                                </label>
                                <Select
                                  showSearch
                                  value={this.state.aggregateBy}
                                  style={{ width: "100%", paddingTop: 5 }}
                                  placeholder="Select Aggregate by"
                                  optionFilterProp="children"
                                  onChange={this.onChangeAggregateBy}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                >
                                  <Option value="domain">Domain</Option>
                                  <Option value="chapter">Chapter</Option>
                                  <Option value="topic">Topic</Option>
                                </Select>
                              </div>
                            </Col>
                          ) : (
                            <Col
                              span={24}
                              lg={24}
                              xs={24}
                              sm={24}
                              md={24}
                              style={{ paddingBottom: 6 }}
                              className="filterContainer"
                            >
                              <div className="filter-bg color-1 white-bg">
                                <label>
                                  {" "}
                                  <strong> View by</strong>{" "}
                                </label>
                                <Select
                                  showSearch
                                  value={this.state.aggregateByAdaptive}
                                  style={{ width: "100%", paddingTop: 5 }}
                                  placeholder="Select Aggregate by"
                                  optionFilterProp="children"
                                  onChange={this.onChangeAggregateBy}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(
                                        optionB.children.toLowerCase()
                                      )
                                  }
                                >
                                  <Option value="domain">Domain</Option>
                                  <Option value="chapter">Chapter</Option>
                                  <Option value="topic">Topic</Option>
                                </Select>
                              </div>
                            </Col>
                          )}
                        </DashboardItem>
                      </Grid>
                    </Grid>
                  </Dashboard>
                )}
              </Content>
            </Layout>
          </Layout>
        </CubeProvider>
      </React.Fragment>
    );
  }
}

export default CustomerDashboardPage;
