import React, { PureComponent } from "react";
import TreeMap from "react-d3-treemap";
import "react-d3-treemap/dist/react.d3.treemap.css";
import {
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Select,
  Button,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Space,
} from "antd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from "antd";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import moment from "moment";
import { Redirect } from "react-router";
import {
  baseUrlCube,
  newReportUrl,
  sendMailPath,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";
import axios from "axios";
import cookie from "js-cookie";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
  AreaChartOutlined,FilePdfOutlined, OrderedListOutlined, BarChartOutlined  
} from "@ant-design/icons";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;

class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      studetnArray: [],
      wrongEmail: false,
      mailData: "",
      enabledPublishCheckBox: false,
      enabledScoreCheckBox: false,
      errorSubject: "",
      errorMessage: "",
      messageSent:"",
      subject: "",
      message: "tabbieMath",
      check: "",
      selectedRowKeys: [],
      selectedRowValue: [],
      allRowValue: [],
      isRedirect403: false,
      collapsed: true,
      schoolList: [],
      classList: [],
      groupList: [],
      assignmentList: [],
      expiryDate: moment().add(15, "days"),
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ],
      school: [],
      loader: false,
      selectedSchool: [],
      schoolName: [],
      selectedSchoolID: "",
      selectedSchoolValue: "",
      selectedClassValue: "",
      selectedGroupValue: "",
      selectedClassID: "",
      class: [],
      selectedClass: [""],
      group: [],
      selectedGroup: [""],
      selectedDateRange: ["", ""],
      selectedAssignment: [""],
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      ifAdaptive: 0,
    };
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onChangeViweBy = (value) => {
    this.setState({
      viewBy: [value.value],
    });

    if (value.value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (value.value == "skill") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.skillName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.skillName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }
  };

  sendMail = () => {
  
    this.setState({ wrongEmail: false });
    var studentData = JSON.parse(localStorage.getItem("studentArray"));
    if (
      studentData == [] ||
      studentData == null ||
      studentData == undefined ||
      studentData?.length < 1
    ) {
     
      this.setState({ wrongEmail: true });
    } else {

      studentData.map((data, i) => {
//console.log("====>"+studentData[i]["TmSchoolHomework.standardId"].toString());
//console.log("====>"+wrongEmail);
        if (
          data["TmStudent.stuStudent_email"] == "" && !data["TmStudent.stuStudent_email"]?.includes("@") ||
          data["TmStudent.parent_email"] == "" && !data["TmStudent.parent_email"]?.includes("@")
        ) {
       
          this.setState({ wrongEmail: true });
          return false;
        } else {
 
          this.setState({ wrongEmail: false });
          var studentId = studentData[0]["TmUsers.username"];
          if (this.state.message.includes(" ")) {
            this.setState({
              message: this.state.message.split(" ").join("<br>"),
            });
          }
          this.setState({
            mailData: [
              {
                filter: {
                  school: this.state.selectedSchoolID,
                  //class: this.state.selectedClass.toString(),
                //  class: data["TmSchoolHomework.standardId"].toString(),
                 class : studentData[i]["TmSchoolHomework.standardId"].toString(),
                  group: this.state.selectedGroup.toString(),
                  dateRange: [
                    this.state.dateRangeList[0].format("YYYY-MM-DD"),
                    this.state.dateRangeList[1].format("YYYY-MM-DD"),
                  ],
                  assignment: this.state.selectedAssignment.toString(),
                  assignmentType: this.state.ifAdaptive,
                  viewBy: this.state.viewBy.toString(),
                  aggregatedBy: this.state.aggregateBy.toString(),
                  hidePublish: this.state.enabledPublishCheckBox.toString(),
                  hideClassAverageScore:
                    this.state.enabledScoreCheckBox.toString(),
                },
                student: JSON.parse(localStorage.getItem("studentArray")),
                subject: this.state.subject,
                message: this.state.message,
                expiryDate: this.state.expiryDate.format("YYYY-MM-DD"),
                url:
                  "https://www.tabbiemath.com/student-detail/" +
                  studentId +
                  "/" +
                  this.state.selectedSchoolID +
                  "/" +
                  this.state.selectedClass +
                  "/" +
                  this.state.selectedGroup +
                  "/" +
                  this.state.dateRangeList[0].format("YYYY-MM-DD") +
                  "/" +
                  this.state.dateRangeList[1].format("YYYY-MM-DD") +
                  "/" +
                  this.state.selectedAssignment +
                  "/" +
                  this.state.viewBy +
                  "/" +
                  this.state.aggregateBy +
                  "/" +
                  this.state.enabledPublishCheckBox +
                  "/" +
                  this.state.enabledScoreCheckBox,
                createdBy: JSON.parse(localStorage.getItem("user")),
              },
            ],
          });
        }
      });
    }
  };

  onChangePublishCheckBox = () => {
    this.setState({
      enabledPublishCheckBox: !this.state.enabledPublishCheckBox,
    });
  };

  onChangeScoreCheckBox = () => {
    this.setState({
      enabledScoreCheckBox: !this.state.enabledScoreCheckBox,
    });
  };

  onChangeAggregateBy = (value) => {
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value],
      });
    }
  };

  onChangeSchool = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedSchool: [option.value.toString()],
      selectedSchoolID: option.value.toString(),
      selectedSchoolValue: option.children.toString(),
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    let access_token = cookie?.get("token");

    /*************** Class Section  *************/
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStandard.standardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStandard.standardId"];
        let className = classList[0]["TmStandard.className"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });

        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStandard.standardId"] != undefined) {
              classListOnLoad.push(x["TmStandard.standardId"]?.toString());
            }
          });
        this.setState({ selectedClass: [classListOnLoad[0]] });

        /*************** Group Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const groupList = res.data.data;
            let groupId = groupList[0]["TmSchoolHomework.groupId"];
            let groupName = groupList[0]["TmSchoolHomework.group"];
            this.setState({ groupList: groupList });

            const groupListOnLoad = [];
            groupList &&
              groupList.map((x) => {
                groupListOnLoad.push(x["TmSchoolHomework.groupId"]);
              });

            this.setState({
              selectedGroup: ["0"],
              selectedGroupID: ["0"],
              selectedGroupValue: ["All"],
            });
          })
          .catch((error) => {});
        /*************** Group Section  *************/

        /*************** Assignment Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"], "order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };
  onChangeClass = (option) => {
    this.state.ifAdaptive = 0;
    this.state.selectedClass = [option.value.toString()];

    this.state.selectedClassValue = option.children;
    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: {
        "TmSchoolGroups.classCount": "desc",
      },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let groupQuery = "";

    groupQuery = `cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    axios
      .get(`${baseUrlCube + groupQuery}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["0"],
          selectedAssignment: ["0"],
        });

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"], "order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});
  };

  onChangeGroup = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedGroup: [option.value],
      selectedGroupValue: option.label,
    });
    let access_token = cookie?.get("token");
    if (option.value == 0) {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
            "YYYY-MM-DD"
          )}","${this.state.dateRangeList[1].format(
            "YYYY-MM-DD"
          )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
            this.state.selectedSchool
          }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
            this.state.selectedClass
          }"]}]}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        )
        .then((res) => {
          const assignmentList = res.data.data;
          this.setState({ assignmentList: assignmentList });
          this.setState({ selectedAssignment: ["0"] });
        })
        .catch((error) => {});
    } else {
      axios
        .get(
          `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
            "YYYY-MM-DD"
          )}","${this.state.dateRangeList[1].format(
            "YYYY-MM-DD"
          )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
            this.state.selectedSchool
          }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
            this.state.selectedClass
          }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
            option.value
          }"]}]}`,
          {
            headers: {
              Authorization: `${access_token}`,
            },
          }
        )
        .then((res) => {
          const assignmentList = res.data.data;
          this.setState({ assignmentList: assignmentList });
          this.setState({ selectedAssignment: ["0"] });
        })
        .catch((error) => {});
    }
  };

  onDateRangeChange = (value, dateString) => {
    this.state.ifAdaptive = 0;
    dateString = dateString?.toString().split(",");
    this.setState({ dateRangeList: value }); //[dateString.toString()]

    if (dateString[0] != "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        this.state.selectedGroup
      }"]}]}`;
    } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`;
    } else {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    }
    let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });
        if (assignmentList.length > 0) {
          if (this.state.selectedGroup[0].toString() == "0") {
            this.setState({ selectedAssignment: ["0"] });
          } else {
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            this.setState({ selectedAssignment: ["0"] });
          }
        } else {
          this.setState({ selectedAssignment: ["0"], assignmentList: null });
        }
      })
      .catch((error) => {});
  };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value == "0") {
      this.setState({
        selectedAssignment: ["0"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
      });
      this.state.selectedAssignment = [value.value.toString()];
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }

      this.state.selectedAssignmentLabel = value.label;
    }
  };

  myChangeSubjectHandler = (event) => {
    if (this.state.errorSubject !== "") {
      this.setState({ errorSubject: "" });
    }
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  myChangeMessageHandler = (event) => {
    if (this.state.errorMessage !== "") {
      this.setState({ errorMessage: "" });
    }

    var tarea = document.getElementById("tarea");
    this.setState({
      [event.target.name]: tarea.value,
    });
  };
  mySubmitHandler = (event) => {
    let subject = this.state.subject;
    let message = this.state.message;
    if (subject === "" && message === "") {
      event.preventDefault();
      this.setState({ errorSubject: "error", errorMessage: "error" });
      return false;
    }
    if (subject === "") {
      event.preventDefault();
      this.setState({ errorSubject: "error" });
      return false;
    }
    if (message === "") {
      event.preventDefault();
      this.setState({ errorMessage: "error" });
      return false;
    }
    if (this.state.selectedRowKeys == 0) {
      this.state.selectedRowValue = this.state.allRowValue;
    }

    const user = this.state.mailData;

    if (this.state.wrongEmail) {
    //  console.log("wrong"+this.state.wrongEmail);
    } else {
      axios
        .post(`${sendMailPath}`, user)
        // axios.post(`http://127.0.0.1:8000/api/sendEmail`,user)
        // axios.post(`https://reports.tabbiemath.com/api/public/api/auth/sendEmail`,user)
        // axios.post(`https://preprod.tabbiemath.com/reports/api/public/api/auth/sendEmail`,user) //old
        // axios.post(`https://reports-preprod.tabbiemath.com/reports/api/public/api/auth/sendEmail`,user) //new
        .then((res) => {  
          //this.setState({ messageSent: "Email sent successfully!" });  
          alert('Email sent successfully!');
          window.location.reload();
          /* setTimeout(() => {
            window.location.reload()
          }, 4000); */
        })
        .catch((err) => {});
    }
    event.preventDefault();
  };
  onSelectChange = (selectedRowKeys, selectedRowValue) => {
    this.setState({ selectedRowKeys, selectedRowValue });
  };
  componentDidMount() {
    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isAssignmentTrue = false;
    let loginUserName = localStorage.getItem("user");
    let loginUserId = "";
    loginUserId = loginUserName;
    let access_token = cookie?.get("token");

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmProfiles.shortname"],"timeDimensions":[],"order":{"TmProfiles.shortname":"asc"},"filters":[{"member":"TmProfiles.userId","operator":"equals","values":["${loginUserId}"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        isSchoolTrue = true;
        const loginUser = res.data.data[0]["TmProfiles.shortname"];
        this.setState({ loginUser: loginUser });
        localStorage.setItem("username", loginUser);
      })
      .catch((error) => {});

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmSchool.count"],"timeDimensions": [],"order": [["TmSchool.schoolName","asc"]],"dimensions": ["TmSchool.schoolId","TmSchool.schoolName"]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const schoolList = res.data.data;
        let schoolId = schoolList[0]["TmSchool.schoolId"];
        let schoolName = schoolList[0]["TmSchool.schoolName"];
        this.setState({
          schoolName: schoolName,
        });
        // Assign School list
        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });

        this.setState({
          selectedSchoolID: schoolId.toString(),
          selectedSchoolValue: schoolName.toString(),
        });
        /*************** Class Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${this.state.selectedSchoolID}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            isClassTrue = true;
            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStandard.standardId"] != null) {
                  classList.push(x);
                }
              });

            let classId = classList[0]["TmStandard.standardId"];
            let className = classList[0]["TmStandard.className"];
            this.setState({ classList: classList });
            this.setState({
              selectedClassID: classId.toString(),
              selectedClassValue: className.toString(),
            });

            let classListOnLoad = [];
            classList &&
              classList.map((x) => {
                if (x["TmStandard.standardId"] != undefined) {
                  classListOnLoad.push(x["TmStandard.standardId"]?.toString());
                }
              });
            this.setState({ selectedClass: [classListOnLoad[0]] });

            /*************** Group Section  *************/

            axios
              .get(
                `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                isGroupTrue = true;
                this.setState({
                  loader: true,
                });
                const groupList = res.data.data;
                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList &&
                  groupList.map((x) => {
                    groupListOnLoad.push(
                      x["TmStudentCurrent.groupName"].toString()
                    );
                  });

                this.setState({
                  selectedGroup: ["0"],
                  selectedGroupID: ["0"],
                  selectedGroupValue: ["All"],
                });
              })
              .catch((error) => {});
            /*************** Group Section  *************/

            /*************** Assignment Section  *************/

            axios
              .get(
                `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                  "YYYY-MM-DD"
                )}","${this.state.dateRangeList[1].format(
                  "YYYY-MM-DD"
                )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                  this.state.selectedSchool
                }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                  this.state.selectedClass
                }"]}]} `,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                isAssignmentTrue = true;
                const assignmentList = res.data.data;

                this.setState({ assignmentList: assignmentList });

                const assignmentListOnLoad = [];
                assignmentList &&
                  assignmentList.map((x) => {
                    assignmentListOnLoad.push(
                      x["TmSchoolHomework.assignmentId"].toString()
                    );
                  });

                this.setState({ selectedAssignment: ["0"] });

                if (
                  isSchoolTrue &&
                  isClassTrue &&
                  isGroupTrue &&
                  isAssignmentTrue == true
                ) {
                  this.setState({
                    loader: true,
                  });
                }
              })
              .catch((error) => {});

            /*************** Assignment Section  *************/
          })
          .catch((error) => {
            this.setState({
              loader: true,
            });
          });

        /*************** Class Section  *************/
      })
      .catch((error) => {
        if (error && error == "Error: Request failed with status code 403") {
          this.setState({ isRedirect403: true, loader: false });
        }
      });
  }

  render() {
    let width = 1024;
    let dateRangeArray = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmStudentHomeworks.completionDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmStudentHomeworks.completionDate",
        dateRange: this.state.dateRangeList,
      });
    }

    let dimensionArray = [];
    if (this.state.aggregateBy[0] == "chapter") {
      dimensionArray.push("TmChapter.chapter");
    } else if (this.state.aggregateBy[0] == "topic") {
      dimensionArray.push("TmChapter.chapter", "TmTopic.topic");
    } else {
      dimensionArray.push("TmChapter.chapter", "TmTopic.topic");
    }

    if (this.state.viewBy[0] == "skill") {
      dimensionArray.push("TmSkill.skillName");
    } else if (this.state.viewBy[0] == "proficiency") {
      dimensionArray.push("TmDifficulty.proficiency");
    } else {
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0" || !this.state) {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dateRangeArrayBoxes = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }
    const DashboardItemsTop = [
      {
        id: 1,
        name: "Send Email",
        vizState: {
          query: {
            measures: [],
            timeDimensions: dateRangeArrayBoxes,
            order: {
              "TmSingleHwstudStatistics.studentName": "asc",
            },
            dimensions: [
              "TmSingleHwstudStatistics.studentId",
              "TmUsers.username",
              "TmSingleHwstudStatistics.studentName",
              "TmStudent.stuStudent_email",
              "TmSchoolHomework.standardId",
              "TmStudent.parent_email",
            ],
            filters: chartFilter,
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "table_selection",
          parameters: [
            { school: this.state.selectedSchoolID },
            { class: this.state.selectedClass },
            { group: this.state.selectedGroup },
            { daterange: this.state.dateRangeList },
            { assignment: this.state.selectedAssignment },
            { assignmentType: this.state.ifAdaptive },
            { hidePublish: this.state.enabledPublishCheckBox },
            { hideScore: this.state.enabledScoreCheckBox },
            { subject: this.state.subject },
            { message: this.state.message },
          ],
        },
      },
    ];
    const DashboardItems = [
      {
        id: 1,
        name: "Number Chart",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.noOfQuestions"],
            timeDimensions: [],
            order: {},
            dimensions: [],
            filters: [],
            limit: 10,
          },
          chartType: "number",
        },
      },
    ];
    const dashboardItem = (item) => (
      <React.Fragment>
        {item.isVisible && (
          <Col span={24} lg={12} key={item.id} style={{ marginBottom: "24px" }}>
            <DashboardItem title={item.name}>
              <ChartRenderer vizState={item.vizState} />
            </DashboardItem>
          </Col>
        )}
      </React.Fragment>
    );
    const dashboardItemTop = (item) => (
      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "24px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>
    );

    const { loading, selectedRowKeys, selectedRowValue } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRowValue,
      onChange: this.onSelectChange,
    };

    const { collapsed } = this.state;
    const graphChart = {
      bottom: "33px",
      right: 32,
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    };
    let allGroupList = [];
    let classChildren = [];
    let allClassList = [];
    let groupChildren = [];
    let assignmentChildren = [];
    let allAssignmentList = [];
    let selectedSchoolID = this.state.selectedSchool[0];
    var classOption = [];
    const dateFormat = "DD-MM-YYYY";
    const onFinish = (values) => {};

    const onFinishFailed = (errorInfo) => {};
    const columns = [
      {
        title: "Student Id",
        dataIndex: "id",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Student Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
    ];
    const data = [
      {
        key: "1",
        id: "ois.155639",
        name: "arun",
        email: "ARUN.A_ois@tabblemath.com",
      },
      {
        key: "2",
        id: "ois.155639",
        name: "abdul",
        email: "ABDUL.K_ois@tabblemath.com",
      },
      {
        key: "3",
        id: "ois.155639",
        name: "akash",
        email: "AKASH.A_ois@tabblemath.com",
      },
      {
        key: "4",
        id: "ois.155639",
        name: "alvin",
        email: "ALVIN.SK_ois@tabblemath.com",
      },
    ];
    {
      this.state.allRowValue = data;
    }

    return DashboardItems.length ? (
      <React.Fragment>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            width={230}
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            className="side_menu site-layout-background"
          >
            <Menu theme="dark" mode="inline" className="nav_panel">
            {localStorage.getItem("reportType") == 1 && (
                                <>
              <Menu.Item icon={<SolutionOutlined />} key="2">
                <Link
                  to="/teacher-usage"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Teachers Usage
                </Link>
              </Menu.Item>
              <Menu.Item icon={<TeamOutlined />} key="4">
                <Link to="/" className="sub-menu ant-menu-submenu-active1">
                  Class Usage
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<UserOutlined />}
                key="3"
                className="ant-menu-submenu "
              >
                <Link
                  to="/student"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Individual Student Report
                </Link>
              </Menu.Item>
              <Menu.Item icon={<FormOutlined />} key="5">
                <Link
                  to="/assignment"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Assignment Report
                </Link>
              </Menu.Item>
              <Menu.Item icon={<MailOutlined />} key="1">
                <Link
                  to="/send-email"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Send Email
                </Link>
              </Menu.Item>

             

              </>
          )}



            {localStorage.getItem("reportType") == 3 && (
              <>

              <Menu.Item icon={<DatabaseOutlined   />} key="1">
                <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
              </Menu.Item>

              <Menu.Item icon={<FileDoneOutlined   />} key="2">
                <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
              </Menu.Item>

              <Menu.Item icon={<AreaChartOutlined />} key="3">
                <a href={`${newReportUrl}/progress-report`}>Progress</a>
              </Menu.Item>


              <Menu.Item icon={<SolutionOutlined />} key="3">
                <a href={`${newReportUrl}/teachers-usage`}>Teacher Usage</a>
              </Menu.Item>


              <Menu.Item icon={<MailOutlined   />} key="5">
                <Link
                  to="/send-email"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Mail
                </Link>
              </Menu.Item>

              <Menu.Item
                icon={<BarChartOutlined />}
                key="6"
              >
                <a href={`${newReportUrl}/self-prep-progress`}>Self Prep Progress</a>
              </Menu.Item>

             
               {localStorage.getItem("userType") == 1 && (
                    <>
                      <Menu.Item icon={<LoginOutlined />} key="7">
                        <Link
                          to="/login-report"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Login Report
                        </Link>
                      </Menu.Item>
                      <Menu.Item icon={<LineChartOutlined />} key="9">
                        <Link
                          to="/task-analysis"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Task Analysis
                        </Link>
                      </Menu.Item>
                      <Menu.Item icon={<FundOutlined />} key="10">
                        <Link
                          to="/question-analysis"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Question Analysis
                        </Link>
                      </Menu.Item>
                    </>
              )}

             

              </>
          )}

            </Menu>
          </Sider>
          <Layout style={{ padding: "0 0px 0px" }}>
            <Grid xs={12} sm={12} lg={12} className="header header-customized">
              <nav className="custom-nav">
                <button
                  className="nav-toggl"
                  onClick={() => {
                    this.setState({ mobNav: !this.state.mobNav });
                    console.log("The button is clicked", this.state.mobNav);
                  }}
                >
                  <MenuOutlinedIcon
                    className="icon-style-custom"
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "auto",
                      display: "flex",
                    }}
                  />
                </button>
                {this.state.mobNav && (
                  <ul className="custom-ul">
                    <Menu theme="dark" mode="inline" className="nav_panel">
                    {localStorage.getItem("reportType") == 1 && (
                          <>
                              <Menu.Item icon={<SolutionOutlined />} key="3">
                                <Link
                                  to="/teacher-usage"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Teachers Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<TeamOutlined />} key="4">
                                <Link
                                  to="/"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Class Usage
                                </Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<UserOutlined />}
                                key="2"
                                className="ant-menu-submenu "
                              >
                                <Link
                                  to="/student"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Individual Student Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<FormOutlined />} key="5">
                                <Link
                                  to="/assignment"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Assignment Report
                                </Link>
                              </Menu.Item>
                              <Menu.Item icon={<MailOutlined />} key="6">
                                <Link
                                  to="/send-email"
                                  className="sub-menu ant-menu-submenu-active1"
                                >
                                  Send Email
                                </Link>
                              </Menu.Item>

                          </>
                      )}

                      {localStorage.getItem("reportType") == 3 && (
                              <>

                                  <Menu.Item icon={<DatabaseOutlined   />} key="1">
                                    <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<FileDoneOutlined   />} key="2">
                                    <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<AreaChartOutlined />} key="3">
                                    <a href={`${newReportUrl}/progress-report`}>Progress</a>
                                  </Menu.Item>

                                  <Menu.Item icon={<SolutionOutlined />} key="3">
                                    <a href={`${newReportUrl}/teachers-usage`}>Teacher Usage</a>
                                  </Menu.Item>



                                  <Menu.Item icon={<MailOutlined   />} key="5">
                                    <Link
                                      to="/send-email"
                                      className="sub-menu ant-menu-submenu-active1"
                                    >
                                      Mail
                                    </Link>
                                  </Menu.Item>


                                  <Menu.Item
                                    icon={<FundProjectionScreenOutlined />}
                                    key="6"
                                  >
                                    <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                                  </Menu.Item>

                                  <Menu.Item
                                    icon={<BarChartOutlined />}
                                    key="6"
                                  >
                                    <a href={`${newReportUrl}/self-prep-progress`}>Self Prep Progress</a>
                                  </Menu.Item>


                                      {localStorage.getItem("userType") == 1 && (
                                        <>
                                          <Menu.Item icon={<LoginOutlined />} key="7">
                                            <Link
                                              to="/login-report"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Login Report
                                            </Link>
                                          </Menu.Item>
                      
                                          <Menu.Item icon={<LineChartOutlined />} key="9">
                                            <Link
                                              to="/task-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Task Analysis
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item icon={<FundOutlined />} key="10">
                                            <Link
                                              to="/question-analysis"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Question Analysis
                                            </Link>
                                          </Menu.Item>
                                        </>
                                      )}

                                  <Menu.Item
                                    icon={<OrderedListOutlined />}
                                    key="6"
                                  >
                                    <a href={`${newReportUrl}/group-school`}>Group School</a>
                                  </Menu.Item>
                            </>
                        )}
                    </Menu>
                  </ul>
                )}
              </nav>
              <Grid
                xs={24}
                lg={24}
                container
                spacing={0}
                style={{ padding: 0 }}
                justify="left"
                alignItems="flex-start"
              >
                <Col span={8} className="logo_header">
                  <img class="logoWidth" src={logo} />
                </Col>
                <Col span={8} className="breadcrumb">
                  <center>
                    <h2>
                      <b> Send Mail</b>
                    </h2>
                  </center>
                </Col>
                <Col span={8} className="user_detail">
                  <h5>
                    <p>
                      <span className="c1">Hello!,</span>{" "}
                      <span className="c2">
                        {this.state.loginUser}
                        {/* {localStorage.getItem('user')}  */}
                        {/* ABC Teacher */}{" "}
                      </span>
                      <br />
                      {this.state.selectedSchoolValue?.toString()} <br />
                      <Link
                        className="logout_btn"
                        onClick={(event) => {
                          event.preventDefault();
                          signout(() => <Redirect to="/home" />);
                        }}
                      >
                        {" "}
                        Home{" "}
                      </Link>
                    </p>
                  </h5>
                </Col>
              </Grid>
            </Grid>

            {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item>Students Usage</Breadcrumb.Item>
              </Breadcrumb> */}
            <center>
              <h2>
                <b>
                  <div
                    style={{
                      display: this.state.display,
                      border: "2px solid black",
                      width: "30%",
                      height: "100px",
                      marginTop: "30px",
                      paddingTop: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    One of the Data not provided
                  </div>
                </b>
              </h2>
            </center>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {/* Content */}

              {/* {!this.state.loader && <Spin />} */}
              {this.state.display == "none"
                ? !this.state.loader && <Spin />
                : this.setState({ display: "block" })}
              {this.state.loader && (
                <Dashboard>
                  <Grid
                    xs={10}
                    sm={10}
                    lg={10}
                    className="ant-layout1 ant-layout1-has-sider ant-layout1"
                  >
                    <Grid
                      className="top-widgets top-widgets4"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={24}
                        lg={24}
                        key={DashboardItemsTop[0].id}
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[0].name}>
                          <form onSubmit={this.mySubmitHandler}>
                            <div className="column_box">
                              <Grid
                                className="top-widgets"
                                xs={24}
                                lg={24}
                                container
                                spacing={0}
                                style={{ padding: 0 }}
                                justify="left"
                                alignItems="flex-start"
                              >
                                <Col span={8}>
                                  <div className="filter-bg color-1 white-bg text_box">
                                    <input
                                      type="text"
                                      maxlength="191"
                                      name="subject"
                                      className="mail-content"
                                      value={this.state.subject}
                                      style={{
                                        border:
                                          this.state.errorSubject !== ""
                                            ? "1px solid red"
                                            : "1px solid black",
                                        height: "60px",
                                      }}
                                      onChange={this.myChangeSubjectHandler}
                                      rows={5}
                                      placeholder="Specify Subject of Email"
                                    />
                                    {this.state.errorSubject !== "" && (
                                      <span style={{ color: "red" }}>
                                        Please Fill Subject Field
                                      </span>
                                    )}
                                      {this.state.messageSent !== "" && (
                                      <span style={{ color: "green" }}>
                                        {this.state.messageSent}
                                      </span>)}
                                    
                                  </div>
                                </Col>
                                {/* <Col span={8}>
                      <div className="filter-bg color-1 white-bg text_box">
                        <textarea name="message" id="tarea" className="mail-content" value={this.state.message} style={{border:this.state.errorMessage!==""?"1px solid red":"1px solid black"}} onChange={this.myChangeMessageHandler} rows={5} placeholder="Specify Message for Email"/>
                        {this.state.errorMessage!=="" && <span style={{color:"red"}}>Please Fill Message Field</span>}
                      </div>
                      </Col> */}
                                <Col span={8}>
                                  <div className="filter-bg color-1 white-bg button_send_mail">
                                    <button
                                      type="submit"
                                      onClick={this.sendMail}
                                    >
                                      Send Email
                                    </button>
                                    {this.state.mail}
                                  </div>
                                </Col>
                              </Grid>
                            </div>
                            <div className="column">
                              <ChartRenderer
                                vizState={DashboardItemsTop[0].vizState}
                              />
                            </div>
                            {/* <div className="filter-bg color-1 white-bg">
                      <Table
                      onChange={this.myChangeHandler}
                      name="check"
                      value ={this.state.check}
                        rowSelection={
                          rowSelection                         
                        }
                        
                        pagination={false}
                        columns={columns}
                        dataSource={data}
                      />
                      </div> */}
                          </form>
                        </DashboardItem>
                      </Col>
                    </Grid>
                  </Grid>
                  <Grid xs={2} sm={2} lg={2}>
                    <Grid
                      container
                      justify="left"
                      alignItems="flex-start"
                      id="filterContainer-title"
                    >
                      <DashboardItem title="Filters" className="ant-card-body">
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> School Name </strong>{" "}
                            </label>
                            <schoolValue
                              data={this.state.selectedSchool}
                            ></schoolValue>
                            <Select
                              showSearch
                              defaultActiveFirstOption={true}
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select school name"
                              optionFilterProp="children"
                              onChange={(value, option) =>
                                this.onChangeSchool(option)
                              }
                              labelInValue
                              value={{
                                value: this.state.selectedSchoolID?.toString(),
                              }}
                            >
                              {this.state.schoolList.map((x) => {
                                return (
                                  <Option
                                    key={x["TmSchool.schoolId"]}
                                    value={x["TmSchool.schoolId"]?.toString()}
                                  >
                                    {x["TmSchool.schoolName"]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Class </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value:
                                  typeof this.state.selectedClass[0] == "string"
                                    ? this.state.selectedClass[0]
                                    : this.state.selectedClass[0]?.toString(),
                              }}
                              placeholder="Select Class"
                              optionFilterProp="children"
                              onChange={this.onChangeClass}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              if({this.state.selectedClass.length > 0})
                              {this.state.classList &&
                                this.state.classList.map((x) => {
                                  classChildren.push(
                                    <Option
                                      value={x[
                                        "TmStandard.standardId"
                                      ]?.toString()}
                                    >
                                      {x["TmStandard.className"]}
                                    </Option>
                                  );
                                  allClassList.push(x["TmStandard.standardId"]);
                                })}
                              <Option value="0">All</Option>
                              {classChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Group </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              labelInValue
                              value={{
                                value: this.state.selectedGroup?.toString(),
                              }}
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select Group"
                              optionFilterProp="children"
                              onChange={this.onChangeGroup}
                            >
                              if({this.state.selectedGroup.length > 0}){" "}
                              {this.state.groupList &&
                                this.state.groupList.map((x) => {
                                  groupChildren.push(
                                    <Option key={x["TmStudentCurrent.groupId"]}>
                                      {x["TmStudentCurrent.groupName"]}
                                    </Option>
                                  );
                                  allGroupList.push(
                                    x["TmStudentCurrent.groupId"]
                                  );
                                })}
                              <Option value="0"> All </Option>
                              {groupChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Date Range </strong>{" "}
                            </label>
                            <RangePicker
                              style={{ top: 5 }}
                              onChange={this.onDateRangeChange}
                              value={
                                this.state.dateRangeList[0] != "0"
                                  ? [
                                      moment(
                                        this.state.dateRangeList[0],
                                        dateFormat
                                      ),
                                      moment(
                                        this.state.dateRangeList[1],
                                        dateFormat
                                      ),
                                    ]
                                  : ""
                              }
                              format={dateFormat}
                            />
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ paddingBottom: 6, paddingTop: 5 }}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Assignments</strong>{" "}
                            </label>
                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value:
                                  this.state.selectedAssignment?.toString(),
                              }}
                              placeholder="Select Assignments"
                              optionFilterProp="children"
                              onChange={this.onChangeAssignment}
                            >
                              if({this.state.selectedAssignment.length > 0}){" "}
                              {this.state.assignmentList &&
                                this.state.assignmentList.map((x) => {
                                  assignmentChildren.push(
                                    <Option
                                      key={x["TmSchoolHomework.assignmentId"]}
                                    >
                                      {x["TmSchoolHomework.assignmentName"]}
                                    </Option>
                                  );
                                  allAssignmentList.push(
                                    x["TmSchoolHomework.assignmentId"]
                                  );
                                })}
                              <Option value="0"> All </Option>
                              {assignmentChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Aggregate by</strong>{" "}
                            </label>
                            <Select
                              showSearch
                              labelInValue
                              defaultValue={{
                                value: this.state.viewBy.toString(),
                              }}
                              style={{ width: "100%" }}
                              placeholder="Select View by"
                              optionFilterProp="children"
                              onChange={this.onChangeViweBy}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              <Option value="all">Overall</Option>
                              <Option value="skill">Skill</Option>
                              <Option value="proficiency">Proficiency</Option>
                            </Select>
                          </div>
                        </Col>
                        {this.state.ifAdaptive == 0 ||
                        this.state.ifAdaptive == 2 ? (
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> View by </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                value={this.state.aggregateBy}
                                style={{ width: "100%" }}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> View by </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                value={this.state.aggregateByAdaptive}
                                style={{ width: "100%" }}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                              </Select>
                            </div>
                          </Col>
                        )}

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ paddingBottom: 6 }}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Hide </strong>{" "}
                            </label>
                            <br />
                            <Checkbox
                              style={{ width: "100%", paddingTop: 5 }}
                              onChange={this.onChangePublishCheckBox}
                            >
                              Publish To
                            </Checkbox>
                            <br />
                            <Checkbox
                              style={{ width: "100%", paddingTop: 5 }}
                              onChange={this.onChangeScoreCheckBox}
                            >
                              Average Score
                            </Checkbox>
                          </div>
                        </Col>
                      </DashboardItem>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    lg={24}
                    container
                    spacing={3}
                    style={{ padding: 20 }}
                    justify="left"
                    alignItems="flex-start"
                  >
                    {this.state.dateRangeList == undefined ? (
                      <h2 className="main-score-card-sub-title"> </h2>
                    ) : (
                      <h2 className="main-score-card-sub-title"></h2>
                    )}
                    <br />
                  </Grid>
                </Dashboard>
              )}
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    ) : (
      <div style={{ textAlign: "center", padding: 12 }}>
        {" "}
        <Typography variant="h5" color="inherit">
          {" "}
          There are no charts on this dashboard. Use Playground Build to add
          one.{" "}
        </Typography>{" "}
      </div>
    );
  }
}

export default CustomerDashboardPage;
