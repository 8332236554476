import React, { PureComponent } from 'react';
import { Spin, Row, Col, Layout, Menu, Select, DatePicker, Button } from "antd";
import Grid from "@material-ui/core/Grid";
import { CSVLink } from 'react-csv'
import { Table, Divider, Input } from 'antd';
import { Link } from "react-router-dom";
import logo from '../../img/logo.jpg';
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import cookie from 'js-cookie';
import moment from 'moment';
import ChartRenderer from "../../components/ChartTeacher";
import ChartRendererCustomised from "../../components/ChartTeacher";
import Dashboard from "../../components/Dashboard";
import DashboardItem from "../../components/DashboardItem";
import 'antd/dist/antd.css';
import jwt from 'jwt-decode' // import dependency
import plus from "../../img/Group 515.svg";
import minus from "../../img/Group 518.svg";
import axios from 'axios';
import ReactToPrint from "react-to-print";
import { Redirect } from 'react-router';
import { baseUrlCube, homeUrlCube,apitokenPath, getUser, authenticate, isAuth, signout } from "../../components/helpers"
import { MailOutlined, TeamOutlined, UserOutlined,PrinterFilled,SolutionOutlined, FundOutlined, LineChartOutlined,DatabaseOutlined, FormOutlined, LeftCircleOutlined, LoginOutlined ,FundProjectionScreenOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
let access_token = cookie?.get('token');


class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props)
      this.state = {
        loginUser:"",
        display:"none",
        invalidToken:true,
        dateUrl:"",
        collapsed: true,
        subject: "",
        message: "",
        resultShow: false,
        schoolList: [],
        classList: [],
        groupList: [],
        studentList: [],
        assignmentList: [],
        planId:props?.match?.params?.planId,
        individualSchool: [props?.match?.params?.schoolId],
        individualClass: [props?.match?.params?.standardId],
        individualGroup: [props?.match?.params?.groupIdName],
        individualStudent: [props?.match?.params?.studentId],
        individualDateRange: props?.match?.params?.dateRange,
        individualAssignment: [props?.match?.params?.assignment],
        individualAggregated:[props?.match?.params?.aggregatedBy],
        individualviewBy:[props?.match?.params?.viewBy],
        selectedRowKeys: [],
        dateRangeList: [moment().subtract(3, 'months'), moment().startOf('second')], //undefined, // ["2021-04-05", "2021-04-20"], 
        timezone: 'America/Los_Angeles',
        school: [''],
        processDateRangeList: [moment().subtract(5, 'months'), moment().startOf('second')],
        loader: false,
        selectedSchool: [''],
        selectedSchoolID: '',
        selectedClassID: '',
        selectedGroupID: '',
        selectedStudentID: '',
        selectedSchoolValue:'',
        selectedClassValue:'',
        selectedGroupValue:'',
        selectedStudentValue:'',
        selectedDateRange: ["", ""],
        student: [''],
        selectedStudent: [''],
        class: [""],
        selectedClass: [''],
        selectedAssignment: [''],
        group: [''],
        selectedGroup: [''],
        assignmentsAll: [],
        assignments: [],
        viewBy: ['all'],
        aggregateBy: ['chapter'],
        aggregateByAdaptive: ['domain'],
        orderBy: [
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ]
        ],
        pivotConfig:{},
        prevButton: '',
        nextButton: 'disabled',
        ifAdaptive:0,
        loadSpin: true,
      };
    this.csvLink = React.createRef();
  }


  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  onSubmit(values) {
    console.log(values);
    this.props.reset();
  }

  handleOpen = () => {
    this.setState({ resultShow: !this.state.resultShow }); // Toggle accordion
  };
  onChangeViweBy = (value) => {
    console.log(this.state.individualviewBy[0],"12345678") 
    this.setState({individualviewBy:""})
    this.setState({
      viewBy: [value.value]
    });
    this.state.viewBy = [value.value]
    console.log(this.state.viewBy,"viewby")
    if (value.value == 'proficiency') {
      this.setState({
        orderBy: [
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.proficiencyName",
            "asc"
          ]
        ]
      });


      this.setState({
        pivotConfig: {
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.proficiencyName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
      })
    }

    if (this.state.viewBy[0] == 'skill') {

      this.state.pivotConfig= {
          "x": [
           "TmStudenthomeworkquestionTest.tmSthwqtChapterName"
          ],
          "y": [
            "TmStudenthomeworkquestionTest.tmSthwqtSkillName",
            "measures"
          ]
        };
    }
    console.log(this.state.pivotConfig,"viewby")
  }



  onChangeNextButton = () => {
    var currentDate = '';
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD"); // moment(this.state.processDateRangeList[1]).format('YYY-MM-DD'); 
    var futureMonth = currentDate.add(3, 'M').format('YYYY-MM-DD');
    var StateDate = [moment(futureMonth, 'YYYY-MM-DD').add(-5, 'months'), futureMonth];
    var currentDateLive = moment().format('YYYY-MM-DD');

    if (futureMonth > currentDateLive) {
      this.setState({
        nextButton: 'disabled'
      });
    } else if (futureMonth <= currentDateLive) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: ''
      });
    }
  }

  onChangePrevButton = () => {
    var currentDate = '';
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD"); // moment(this.state.processDateRangeList[1]).format('YYY-MM-DD'); 
    var futureMonth = moment().add(3, 'M').format('YYYY-MM-DD');
    var prevMonth = currentDate.add(-3, 'M').format('YYYY-MM-DD');
    var fifthYear = moment().add(-3, 'Y').format('YYYY-MM-DD');
    var StateDate = [moment(prevMonth, 'YYYY-MM-DD').add(-5, 'months'), prevMonth];
    this.setState({
      nextButton: '',
      processDateRangeList: StateDate,
      nextButton: ''
    });

    if (prevMonth > fifthYear) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: ''
      });
    } else if (prevMonth <= fifthYear) {
      this.setState({
        prevButton: 'disable'
      });
    }


  }

  onChangeAggregateBy = (value) => {
    this.setState({individualAggregated:""})
    if(this.state.ifAdaptive == 1){
      this.setState({
        aggregateBy: [value]
      });
      this.setState({
        aggregateByAdaptive: [value]
      });
    }
    if(this.state.ifAdaptive == 2){
      this.setState({
        aggregateBy: [value]
      });
      this.setState({
        aggregateByAdaptive: [value]
      });
    }
    else{
    this.setState({
      aggregateBy: [value.toString()]
    });
    this.setState({
      aggregateByAdaptive: [value]
    });
  }
}

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value.value == '0') {
      this.setState({
        selectedAssignment: ['0']
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()]
      });
      if(this.state.assignmentList?.length>0){
        this.state.assignmentList.map((alist,i)=>{
          if(alist["TmSchoolHomework.assignmentId"]==value.value){
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"]
          }
        })
      }
      if(this.state.ifAdaptive == 1){
        this.setState({aggregateBy:"domain"});
        this.setState({aggregateByAdaptive:"domain"});
      }
    }
  }

   onDateRangeChange = (value, dateString) => {
    console.log(dateString,"ondatechange")
    this.setState({dateRangeList:value}); 
  }

  componentDidMount() {
    let access_token = cookie?.get('token');
    this.setState({loginUser:localStorage.getItem('username')})
    let isSchoolTrue=false;
    let isClassTrue=false;
    let isGroupTrue=false;
    let isStudentTrue=false;
    let isAssignmentTrue=false;

    var token = this.props?.match?.params?.jwt;
    const validity={'token': token}
    let axiosConfig = {
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    
    };
    axios.post(`${apitokenPath}`,validity) //dev
    //  axios.post(`https://preprod.tabbiemath.com/reports/api/public/api/auth/verifyTocken`,validity,axiosConfig) //preprod
    // axios.post(`https://reports.tabbiemath.com/api/public/api/auth/verifyTocken`,validity,axiosConfig) //Prod

    .then(res => {
    var decoded = jwt(token);
    console.log(decoded.student_id,"decoded")
    this.state.decodeValue=decoded;

      this.setState({selectedStudent:[decoded.student_id]})
        axios.get(`${homeUrlCube}cubejs-api/v1/load?query=
        {"dimensions":["TmStudentMockTest.tmStmktkSchoolId","TmStudentMockTest.tmStmktkSchoolName","TmStudentMockTest.tmStmktkStandardId","TmStudentMockTest.tmStmktkStandardName"],"order":{"TmStudentMockTest.tmStmktkSchoolId":"asc"},"filters":[{"member":"TmStudentMockTest.tmStmktkStudentId","operator":"equals","values":["${decoded.student_id}"]},{"member":"TmStudentMockTest.tmStmktkStandardId","operator":"equals","values":["${this.props?.match?.params?.standardId}"]}]} `,{
            headers: {
              'Authorization': `${access_token}`
            }
          })
        .then(res => {
          console.log(res?.data?.data,"loginusername")
          this.setState({selectedSchool:[res?.data?.data[0]["TmStudentMockTest.tmStmktkSchoolId"]]})
          this.setState({selectedClass:[res?.data?.data[0]["TmStudentMockTest.tmStmktkStandardId"]]})
          this.setState({selectedGroup:[res?.data?.data[0]["TmStudentMockTest.tmStmktkGroupId"]]})
          this.setState({selectedSchoolValue:[res?.data?.data[0]["TmStudentMockTest.tmStmktkSchoolName"]]})
          this.setState({selectedClassValue:[res?.data?.data[0]["TmStudentMockTest.tmStmktkStandardName"]]})
          this.setState({selectedGroupValue:[res?.data?.data[0]["TmStudentMockTest.tmStmktkGroupName"]]})
          this.setState({selectedStudentValue:[this.state.loginUser]})
          this.setState({loadSpin : false})
      }).
      catch(error => {
        console.log('username not loaded', error);
        this.setState({loadSpin : false})
      });
    })
    .catch(error => {
      this.setState({
            invalidToken:false
  
      })
      this.setState({loadSpin : false});
    });
 
   
  }



  render() {

    var containerClasses = [];
    if (this.state.open) {
      containerClasses.push("open");
     }
    let width = 1024;
    let dateRangeArray = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        "dimension": "TmSchoolHomework.publishDate"

      })
    } else {
      dateRangeArray.push({
        "dimension": "TmSchoolHomework.publishDate",
        "dateRange": this.state.dateRangeList
      })

    }

    let dateRangeArrayQuestion = [];
    let dateRangeArrayQuestionTask = [];
    let dateRangeArrayQuestionReport = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesDomain = [];
    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptiveScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestion.push({
        "dimension": "TmStudentMock.tmStmktkStartedon"
      })
      dateRangeArrayQuestionTask.push({
        "dimension": "TmStudentMockTest.tmStmktkStartedon"
      })
      dateRangeArrayQuestionReport.push({
        "dimension": "TmStudenthomeworkquestionTest.tmSthwqtUpdatedon"
      })
      dateRangeArrayQuestionAdaptive.push({
        "dimension": "IndividualStudentReport.Publish_Date"
      })
      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        "dimension": "TmSchoolHomework.publishDate",
      })

      dateRangeArrayBoxesDomain = [];
      dateRangeArrayBoxesDomain.push({
        "dimension": "TmSingleHwstudStatistics.Publish_Date",
      })
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        "dimension": "classScore.Publish_Date"
      })
 
    } else {

      dateRangeArrayQuestion.push({
        "dimension": "TmStudentMock.tmStmktkStartedon",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      }
      )
      /* dateRangeArrayQuestionTask.push({
        "dimension": "TmStudentMockTest.tmStmktkStartedon",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      }) */
    /*   dateRangeArrayQuestionReport.push({
        "dimension": "TmStudenthomeworkquestionTest.tmSthwqtUpdatedon",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      }) */

      dateRangeArrayQuestionAdaptive.push({
        "dimension": "IndividualStudentReport.Publish_Date",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      }
      )

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        "dimension": "TmSchoolHomework.publishDate",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      })

      dateRangeArrayBoxesDomain = [];
      dateRangeArrayBoxesDomain.push({
        "dimension": "TmSingleHwstudStatistics.Publish_Date",
        "dateRange":[this.state.dateRangeList[0].format('YYYY-MM-DD'),this.state.dateRangeList[1].format('YYYY-MM-DD')]
      })
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        "dimension": "classScore.Publish_Date",
        "dateRange": [this.state.dateRangeList[0].format("YYYY-MM-DD"),this.state.dateRangeList[1].format("YYYY-MM-DD")]

      })

    }

    let dimensionArray = []
   
    if (this.state.aggregateBy == 'domain') {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == 'chapter') {
      dimensionArray.push("TmStudentMockTask.tmStmktkCompletedon","TmStudentMockTask.tmStmktkCategoryName","TmStudentMockTask.tmStmktkTaskName","TmStudentMockTask.tmStmktkLevelName","TmStudentMockTask.tmRemediationCompleted");

    } else if (this.state.aggregateBy == 'topic') {
      dimensionArray.push("TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.topicName");

    } else {
      dimensionArray.push("TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.topicName");

    }

    let dimensionArrayReport = []
   
    if (this.state.aggregateBy == 'domain') {
      dimensionArrayReport.push("TmStudenthomeworkquestionTest.tmSthwqtDomainName");
    } else if (this.state.aggregateBy == 'chapter') {
      dimensionArrayReport.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName");

    } else if (this.state.aggregateBy == 'topic') {
      dimensionArrayReport.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtTopicName");

    } else {
      dimensionArrayReport.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtTopicName");

    }

    let dateRangeArrayQuestionReportOrder = {}
    if (this.state.aggregateBy == 'domain') {
      dateRangeArrayQuestionReportOrder={"TmStudenthomeworkquestionTest.tmSthwqtDomainName":"asc"};
    } else if (this.state.aggregateBy == 'chapter') {
      dateRangeArrayQuestionReportOrder={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc"};

    } else if (this.state.aggregateBy == 'topic') {
      dateRangeArrayQuestionReportOrder={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtTopicName":"asc"};

    } else {
      dateRangeArrayQuestionReportOrder={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtTopicName":"asc"};

    }

    let dimensionArrayReportSkill = []
   
    if (this.state.aggregateBy == 'domain') {
      dimensionArrayReportSkill.push("TmStudenthomeworkquestionTest.tmSthwqtDomainName","TmStudenthomeworkquestionTest.tmSthwqtSkillName");
    } else if (this.state.aggregateBy == 'chapter') {
      dimensionArrayReportSkill.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtSkillName","TmStudenthomeworkquestionTest.tmSthwqtSkillId");

    } else if (this.state.aggregateBy == 'topic') {
      dimensionArrayReportSkill.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtTopicName","TmStudenthomeworkquestionTest.tmSthwqtSkillName");

    } else {
      dimensionArrayReportSkill.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtTopicName","TmStudenthomeworkquestionTest.tmSthwqtSkillName");

    }

    let dateRangeArrayQuestionReportOrderSkill = {}
      if (this.state.aggregateBy == 'domain') {
        dateRangeArrayQuestionReportOrderSkill={"TmStudenthomeworkquestionTest.tmSthwqtDomainName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
      } else if (this.state.aggregateBy == 'chapter') {
        dateRangeArrayQuestionReportOrderSkill={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
  
      } else if (this.state.aggregateBy == 'topic') {
        dateRangeArrayQuestionReportOrderSkill={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtTopicName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
  
      } else {
        dateRangeArrayQuestionReportOrderSkill={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtTopicName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
  
      }

      let dimensionArrayReportProficiency = []
   
      if (this.state.aggregateBy == 'domain') {
        dimensionArrayReportProficiency.push("TmStudenthomeworkquestionTest.tmSthwqtDomainName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyId");
      } else if (this.state.aggregateBy == 'chapter') {
        dimensionArrayReportProficiency.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyId");
  
      } else if (this.state.aggregateBy == 'topic') {
        dimensionArrayReportProficiency.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtTopicName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyId");
  
      } else {
        dimensionArrayReportProficiency.push("TmStudenthomeworkquestionTest.tmSthwqtChapterName","TmStudenthomeworkquestionTest.tmSthwqtTopicName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyName","TmStudenthomeworkquestionTest.tmSthwqtProficiencyId");
  
      }

      let dateRangeArrayQuestionReportOrderProficiency= {}
        if (this.state.aggregateBy == 'domain') {
          dateRangeArrayQuestionReportOrderProficiency={"TmStudenthomeworkquestionTest.tmSthwqtDomainName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
        } else if (this.state.aggregateBy == 'chapter') {
          dateRangeArrayQuestionReportOrderProficiency={"TmStudenthomeworkquestionTest.tmSthwqtProficiencyId":"asc"};
    
        } else if (this.state.aggregateBy == 'topic') {
          dateRangeArrayQuestionReportOrderProficiency={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtTopicName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
    
        } else {
          dateRangeArrayQuestionReportOrderProficiency={"TmStudenthomeworkquestionTest.tmSthwqtChapterName":"asc","TmStudenthomeworkquestionTest.tmSthwqtTopicName":"asc","TmStudenthomeworkquestionTest.tmSthwqtSkillName":"asc"};
    
        }

    if (this.state.viewBy == 'proficiency') {
      this.state.orderBy=[
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.proficiencyName",
            "asc"
          ]
        ]

      this.state.pivotConfig= {
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.proficiencyName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
    }

    if (this.state.viewBy == 'skill') {
      this.state.orderBy=[
          [
            "TmHwQnAnsStat.domain",
            "asc"
          ],
          [
            "TmHwQnAnsStat.chapterName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.topicName",
            "asc"
          ],
          [
            "TmHwQnAnsStat.skillName",
            "asc"
          ]
        ]

      this.state.pivotConfig={
          "x": [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName"
          ],
          "y": [
            "TmHwQnAnsStat.skillName",
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        }
    }


    if (this.state.viewBy == 'skill') {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy == 'proficiency') {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");

    } else {
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.schoolId",
        "operator": "equals",
        "values": this.state.selectedSchool
      })
    }

    if (this.props?.match?.params?.planId != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.standardId",
        "operator": "equals",
        "values": [this.props?.match?.params?.standardId]
      });
    }

    if (this.state.selectedGroup[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.groupId",
        "operator": "equals",
        "values": this.state.selectedGroup
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      chartFilter.push({
        "member": "TmSchoolHomework.assigId",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let filterProgressLevelCompleted = [];
    if (this.state.selectedStudent[0]?.toString() != '0') {
      filterProgressLevelCompleted.push({
        "member": "TmTaskLevelTest.studentid",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }
    if (this.props?.match?.params?.planId != '0') {
      filterProgressLevelCompleted.push({
        "member": "TmTaskLevelTest.StandardId",
        "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }

    let filterProgressLevelPending = [];
    if (this.state.selectedStudent[0]?.toString() != '0') {
      filterProgressLevelPending.push({
        "member": "TmLevelPendingTest.studentid",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }
    if (this.props?.match?.params?.planId != '0') {
      filterProgressLevelPending.push({
        "member": "TmLevelPendingTest.tmStmktkStandardId",
        "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }

    let filterProgressTaskCompleted = [];
    if (this.state.selectedStudent[0]?.toString() != '0') {
      filterProgressTaskCompleted.push({
        "member": "TmStudentMockTest.tmStmktkStudentId",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }
    if (this.props?.match?.params?.planId != '0') {
      filterProgressTaskCompleted.push({
        "member": "TmStudentMockTest.tmStmktkStandardId",
        "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }

    let filterProgressTaskPending = [];
    if (this.state.selectedStudent[0]?.toString() != '0') {
      filterProgressTaskPending.push({
        "member": "TmTaskPendingTest.studentid",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }

    if (this.props?.match?.params?.planId != '0') {
      filterProgressTaskPending.push({
        "member": "TmTaskPendingTest.tmStmktkStandardId",
        "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }


    let filterProgressPoint = [];
    if (this.state.selectedStudent[0]?.toString() != '0') {
      filterProgressPoint.push({
        "member": "TmStudentMockTest.tmStmktkStudentId",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }

    if (this.props?.match?.params?.planId != '0') {
      filterProgressPoint.push({
        "member": "TmStudentMockTest.tmStmktkStandardId",
        "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }

    let filter = [];
    

    if (this.state.selectedStudent[0]?.toString() != '0') {
      filter.push({
        "member": "TmStudentMockTest.tmStmktkStudentId",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }

    if (this.props?.match?.params?.planId != '0') {
      filter.push({
        "member": "TmStudentMockTest.tmStmktkStandardId",
        "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }

    filter.push(
      {"member":"TmStudentMockTest.tkAttempted",
      "operator":"equals",
      "values":["1"]});

    filter.push(
      {"member":"TmStudentMockTest.tkCategoryStatus",
      "operator":"equals",
      "values":["1"]});

    

    let filterReport = [];

    if (this.state.selectedStudent[0]?.toString() != '0') {
      filterReport.push({
        "member": "TmStudenthomeworkquestionTest.tmSthwqtStudentId",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
    }

    if (this.props?.match?.params?.planId != '0') {
      filterReport.push({
       "member": "TmStudenthomeworkquestionTest.standardId",
       "operator": "equals",
        "values": [this.props?.match?.params?.standardId]
      });
    }

    let studentFilterDig = [];
    

    if (this.state.selectedStudent[0]?.toString() != '0') {
      studentFilterDig.push({
        "member": "TmSingleHwstudStatistics.studentId",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
      console.log(this.state.selectedStudent,"selectedStu")
      console.log(studentFilterDig,"selectedstu")
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      studentFilterDig.push({
        "member": "TmSchoolHomework.assignmentId",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let studentFilterAdaptive = [];
    

    if (this.state.selectedStudent[0]?.toString() != '0') {
      studentFilterAdaptive.push({
        "member": "IndividualStudentReport.Student_Id",
        "operator": "equals",
        "values": [this.state.selectedStudent.toString()]
      });
      console.log(this.state.selectedStudent,"selectedStu")
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      studentFilterAdaptive.push({
        "member": "IndividualStudentReport.Assignment_Id",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
        "member": "classScore.School_Id",
        "operator": "equals",
        "values":  this.state.selectedSchool
      })
    }

    if (this.props?.match?.params?.planId != '0') {
      chartFilterAdaptivescore.push({
       "member": "classScore.Standard_Id",
       "operator": "equals",
        "values":[this.props?.match?.params?.standardId]
      });
    }

    if (this.state.selectedGroup[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
        "member": "classScore.Group_Id",
        "operator": "equals",
        "values": this.state.selectedGroup
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != '0') {
      chartFilterAdaptivescore.push({
        "member": "classScore.Assignment_Id",
        "operator": "equals",
        "values": this.state.selectedAssignment
      });
    }

    /* ****************** Student Progress Summary ******************* */
    let filterStudentSummary = [];
    if (this.state.selectedSchool[0]?.toString() != "0" && this.state.selectedSchool[0]?.toString() != "") {
      filterStudentSummary.push({
        member: "TmStudentProgress.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }
  /*   if (this.state.selectedClass[0]?.toString() != "0" && this.state.selectedClass[0]?.toString() != "") {
      filterStudentSummary.push({
        member: "TmStudentProgress.gradeId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    } */
    if (this.props?.match?.params?.standardId.toString() != "0" && this.props?.match?.params?.standardId.toString() != "") {
      filterStudentSummary.push({
        member: "TmStudentProgress.gradeId",
        operator: "equals",
        values: this.state.individualClass,
      });
    }
    if (this.state.selectedStudent[0]?.toString() != "0" && this.state.selectedStudent[0]?.toString() != "") {
      filterStudentSummary.push({
        member: "TmStudentProgress.studentId",
        operator: "equals",
        values:  [this.state.selectedStudent.toString()],
      });
    }

    filterStudentSummary.push({
      member: "TmStudentProgress.planStatus",
      operator: "equals",
      values:  ["0"],
    });

    // let dateRangeArrayStudentSummary = [];
    //if (this.state.selectedDuration[0]?.toString() != "0") {
     /*  if (this.state.dateRangeList[0] == "") {
        dateRangeArrayStudentSummary.push({
          dimension: "TmStudentProgress.completedDate",
        });
      } else {
        dateRangeArrayStudentSummary.push({
          dimension: "TmStudentProgress.completedDate",
          dateRange: [
            this.state.dateRangeList[0].format("YYYY-MM-DD"),
            this.state.dateRangeList[1].format("YYYY-MM-DD"),
          ],
        });
      } */
    //}

    /* ****************** Student Progress Summary ******************* */


    /* *************** Point/Name Filter ************** */
    let studentPointsFilter = [];

    if (this.state.selectedStudent[0]?.toString() != "0" && this.state.selectedStudent[0]?.toString() != "") {
      studentPointsFilter.push({
        member: "TmStudentPoints.tmStmktkStudentId",
        operator: "equals",
        values: [this.state.selectedStudent.toString()],
      });
    }
    
    if (this.props?.match?.params?.standardId.toString() != "0" && this.props?.match?.params?.standardId.toString() != "") {
      studentPointsFilter.push({
        member: "TmStudentPoints.tmStmktkStandardId",
        operator: "equals",
        values: this.state.individualClass,
      });
    }

    let studentFilter = [];

    if (this.state.selectedStudent[0]?.toString() != "0" && this.state.selectedStudent[0]?.toString() != "") {
      studentFilter.push({
        member: "TmStudentDetails.studentId",
        operator: "equals",
        values: [this.state.selectedStudent.toString()],
      });
    }
    /* *************** Point/Name Filter ************** */
    /* *************** Student Points & name  ************** */
   /*  if (this.state.selectedStudent[0]?.toString() != "0" && this.state.selectedStudent[0]?.toString() != "") { */
          let DashboardStudentPoints = [
            {
              id: 1,
              name: "Student",
              vizState: {
                query: {
                  order: { "TmStudentPoints.totalPoints": "asc" },
                  measures: ["TmStudentPoints.totalPoints"],
                  filters: studentPointsFilter,
                },
                chartType: "number",
                parameters: [],
              },
            },
          ];
  /* } */
    const DashboardStudentDetails = [
      {
        id: 1,
        name: "Student",
        vizState: {
          query: {
            order: { "TmStudentDetails.studentName": "asc" },
            dimensions: ["TmStudentDetails.studentName"],
            filters: studentFilter,
          },
          chartType: "frontend_student_name",
          parameters: [],
        },
      },
    ];
    /* *************** Student Points & name  ************** */

    let dimensionDynamic=["TmHwQnAnsStat.proficiency","TmDifficulty.proficiency"];
    if(this.state.aggregateBy=="domain"){
      dimensionDynamic.push("TmHwQnAnsStat.domain")
    }
    else if(this.state.aggregateBy=="chapter"){
      dimensionDynamic.push("TmHwQnAnsStat.chapterName")
    }
    else{
      dimensionDynamic.push("TmHwQnAnsStat.chapterName","TmHwQnAnsStat.topicName")
    }
    console.log(dimensionDynamic,"dimensiondynmic")


    let dimensionDynamicSkill=["TmHwQnAnsStat.skillName","TmHwQnAnsStat.skillStd"];
    if(this.state.aggregateBy=="domain"){
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain")
    }
    else if(this.state.aggregateBy=="chapter"){
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName")
    }
    else{
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName","TmHwQnAnsStat.topicName")
    }

    let dimensionDynamicAdaptive=[];
    if(this.state.aggregateBy=="domain"){
      dimensionDynamicAdaptive.push("IndividualStudentReport.Domain_Name","IndividualStudentReport.Domain_result")
    }
    else if(this.state.aggregateBy=="chapter"){
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.skillName","TmHwQnAnsStat.skillStd","TmHwQnAnsStat.chapterName")
    }
    else{
      dimensionDynamicAdaptive.push("TmHwQnAnsStat.skillName","TmHwQnAnsStat.skillStd","TmHwQnAnsStat.chapterName","TmHwQnAnsStat.topicName")
    }

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Levels Completed",
        vizState: {
          query: {
            "measures": [
              "TmLevelPendingTest.level_completed",
            ],
            "filters": filterProgressLevelCompleted
          },
          chartType: "number",
        }
      }, {
        id: 2,
        name: "Levels Pending",  
        vizState: { 
          query: {
            "measures": [
              "TmLevelPendingTest.level_pending",
              ],
              "filters": filterProgressLevelPending
            },
          chartType: "number",
        }
      },
      {
        id: 3,
        name: "Tasks Completed",
        vizState: {
          query: {
            "measures": [
              "TmStudentMockTest.StmktkCompleted",
            ],
            "filters": filterProgressTaskCompleted
          },
          chartType: "number",
        }
      }, /* {
        id: 4,
        name: "Tasks Pending",
        vizState: {
          query: {
            "measures": [
              "TmTaskPendingTest.task_pending",
            ],
            "filters": filterProgressTaskPending
          },
          chartType: "number",
        }
      } */
      {
        id: 4,
        name: "Summary",
        desc: "This section shows the tasks that have been attempted by the student and their performance at each proficiency level.",
        vizState: {
          query: {
            filters: filterStudentSummary,
           /*  timeDimensions: dateRangeArrayStudentSummary, */
            dimensions: [
              "TmStudentProgress.categoryId",
              "TmStudentProgress.categoryName",
            ],
            order: [["TmStudentProgress.introExpectationText", "desc"]],
            measures: [
              "TmStudentProgress.introExpectationText",
              "TmStudentProgress.beginerExpectationText",
              "TmStudentProgress.intermediateExpectationText",
              "TmStudentProgress.advancedExpectationText",
            ],
            // "measures": ["TmStudentMock.StmktkScore"]
          },
          chartType: "student_summary_report",
          parameters: [{ Student: 1 }],
        },
      },{
        id: 5,
        name: "Details of Tasks Attempted",
        vizState: {
          query: {
            "filters": filter,
            "dimensions": [
              "TmStudentMockTest.tmStmktkCompletedon",
              "TmStudentMockTest.tmStmktkCategoryName",
              "TmStudentMockTest.tmStmktkTaskName",
             /*  "TmStudentMockTest.tmStmktkLevelName", */
             "TmStudentMockTest.tmLevelName",
              "TmStudentMockTest.Score",
              "TmStudentMockTest.tkexpect",
              "TmStudentMockTest.tmRemediationCompleted",
              "TmStudentMockTest.StmktkAttemptAvg"
            ],
            "timeDimensions": dateRangeArrayQuestionTask,
            "order": [["TmStudentMockTest.tmStmktkCompletedon","desc"]],
          },
          chartType: "table_pivot_task_detail",
          pivotConfig: this.state.pivotConfig
        }
      }, {
        id: 6,
        name: "Analysis",
        vizState: {
          query: {
            "filters": filterReport,
            "dimensions": dimensionArrayReport,
            "timeDimensions": dateRangeArrayQuestionReport,
            "order": dateRangeArrayQuestionReportOrderSkill,
            
            "measures": ["TmStudenthomeworkquestionTest.averageScore","TmStudenthomeworkquestionTest.distCount"]
          },
          chartType: "table_pivot_progress_topic",
          pivotConfig: this.state.pivotConfig,
          parameters: [
            { ViewBy: this.state.aggregateBy },
            { AggregatedBy: this.state.viewBy },
          ],
        }
      },
      {
        id: 6,
        name: "Analysis",
        vizState: {
          query: {
            "filters": filterReport,
            "dimensions": dimensionArrayReportSkill,
            "timeDimensions": dateRangeArrayQuestionReport,
            "order": dateRangeArrayQuestionReportOrder,
            
            "measures": ["TmStudenthomeworkquestionTest.averageScore","TmStudenthomeworkquestionTest.distCount"]
          },
          chartType: "table_pivot_html_skill_progress_student",
          pivotConfig: this.state.pivotConfig,
          parameters: [
            {'ViewBy':this.state.aggregateBy},
            {'AggregatedBy':this.state.viewBy}
        ]  
        }
      },
      {
        id: 6,
        name: "Analysis",
        vizState: {
          query: {
            "filters": filterReport,
            "dimensions": dimensionArrayReportProficiency,
            "timeDimensions": dateRangeArrayQuestionReport,
            "order": dateRangeArrayQuestionReportOrderProficiency,
            "measures": ["TmStudenthomeworkquestionTest.averageScore","TmStudenthomeworkquestionTest.distCount"]
          },
          chartType: "table_pivot_html_proficiency_progress_student",
          pivotConfig: this.state.pivotConfig,
          parameters: [
            {'ViewBy':this.state.aggregateBy},
            {'AggregatedBy':this.state.viewBy}
        ]  
        }
      },
      {
        id: 7,
        name: "Points",
        vizState: {
          query: {
            "measures": [
              "TmStudentMockTest.StmktkPoints",
            ],
            "order":{"TmStudentMockTest.tmStmktkStartedon":"asc"},
            "filters": filterProgressPoint
          },
          chartType: "number",
        }
      }
    ];


    const DashboardItems =
      [
        {
          id: 1,
          name: "Number Chart",
          vizState: {
            query: {
              "measures": [
                "TmHWQs.distCount"
              ],
              "timeDimensions": [],
              "order": {},
              "dimensions": [],
              "filters": [],
              "limit": 10
            },
            chartType: "number"
          }
        }
      ];
    const dashboardItem = item => (
      <React.Fragment>
        {item.isVisible && (
          <Col span={24} lg={12} key={item.id} style={{ marginBottom: "10px" }}>
            <DashboardItem title={item.name}>
              <ChartRenderer vizState={item.vizState} />
            </DashboardItem>
          </Col>
        )}
      </React.Fragment>
    );
    /* { console.log(this.state.dateRangeListt, "dfgh") } */
    const dashboardItemTop = item => (

      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "10px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>

    );



    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let selectedSchoolID = this.state.selectedSchool[0];
    let selectedClassID = this.state.selectedClass[0];
    let selectedStudentID = this.state.selectedStudent[0];

    var classOption = [];
    const { collapsed } = this.state;
    const dateFormat = 'DD-MM-YYYY';

    let allGroupList = [];
    let groupChildren = [];
    let classChildren=[];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    const cubejsApi = cubejs(cookie?.get('token'), {
      apiUrl: `${homeUrlCube}cubejs-api/v1`
    });
 

    return (

      <React.Fragment>

{this.state.loadSpin && (
          <Layout className="contanerSpin">
            <Spin />
          </Layout>
        )}

{!this.state.loadSpin && (
        <CubeProvider cubejsApi={cubejsApi}>
        <Layout className="print_width " style={{ minHeight: '100vh' }}>
          {/* <Sider width={230} collapsible collapsed={collapsed} onCollapse={this.onCollapse} className="side_menu site-layout-background">
            <Menu theme="dark" mode="inline" className="nav_panel">
            <Menu.Item icon={<SolutionOutlined />} key="2"><Link to="/teacher-usage" className="sub-menu ant-menu-submenu-active1">Teachers Usage</Link></Menu.Item>
              <Menu.Item icon={<TeamOutlined />} key="1"><Link to="/" className="sub-menu ant-menu-submenu-active1">Class Usage</Link></Menu.Item>
              <Menu.Item icon={<UserOutlined />} key="3" className="ant-menu-submenu " ><Link to="/student" className="sub-menu ant-menu-submenu-active1">Individual Student Report</Link></Menu.Item>
              <Menu.Item icon={<FormOutlined />} key="4"><Link to="/assignment" className="sub-menu ant-menu-submenu-active1">Assignment Report</Link></Menu.Item>
              <Menu.Item icon={<MailOutlined />} key="5"><Link to="/send-email" className="sub-menu ant-menu-submenu-active1">Send Email</Link></Menu.Item>
              <Menu.Item icon={<FundProjectionScreenOutlined />} key="6"><Link to="/home-module" className="sub-menu ant-menu-submenu-active1">Self Prep</Link></Menu.Item>
              {localStorage.getItem("userType") == 1 &&
              <> 
              <Menu.Item icon={<LoginOutlined />} key="7"><Link to="/login-report" className="sub-menu ant-menu-submenu-active1">Login Report</Link></Menu.Item>
              <Menu.Item icon={<DatabaseOutlined />} key="8"><Link to="/task-data" className="sub-menu ant-menu-submenu-active1">Task Report</Link></Menu.Item>
              <Menu.Item icon={<LineChartOutlined />} key="9"><Link to="/task-analysis" className="sub-menu ant-menu-submenu-active1">Task Analysis</Link></Menu.Item>
              <Menu.Item icon={<FundOutlined />} key="10"><Link to="/question-analysis" className="sub-menu ant-menu-submenu-active1">Question Analysis</Link></Menu.Item>
              </>
              }
              </Menu>
          </Sider> */}

          <Layout style={{ padding: '0 0px 0px' }}>
            <Grid xs={12} sm={12} lg={12} className="header" >
              <Grid xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                <Col span={8} className="logo_header">
                  <img class='logoWidth' src={logo} />
                </Col>
                <Col span={8} className="breadcrumb">
                  <center><h2><b>Progress Report</b></h2></center>
                </Col>
                <Col span={8} className="user_detail">
                  <h5><p><span className='c1'>Hello!,</span> <span className='c2'>
                   {/*  {this.state.selectedStudent}  */}
                    <ChartRendererCustomised
                      vizState={DashboardStudentDetails[0].vizState}
                    />
                    </span><br />
                    <div className="none">
                      {this.state.selectedSchoolValue}  
                        </div>
                    {/* <Link className="logout_btn" onClick={event => { event.preventDefault(); signout(() => <Redirect to="/home" />) }} > Logout </Link> */}
                  </p></h5>
                </Col>
              </Grid>
            </Grid>
            <center><h2><b><div style={{display:this.state.display,border:'2px solid black',width:'30%',height:'100px',marginTop:'30px',paddingTop:'30px',backgroundColor:'white'}}>No Records Found</div></b></h2></center>
            {/* <Content style={{ display: this.state.tab }}>
                          <center><h2><b><div style={{display:this.state.display,border:'2px solid black',width:'30%',height:'100px',marginTop:'30px',paddingTop:'30px',backgroundColor:'white'}}>One of the Data not provided</div></b></h2></center> */}
            <Content
              id="printme"
              className="site-layout-background"
              style={{
                padding: 10,
                margin: 0,
                minHeight: 280,
              }}
            >
              {/* Content */}

              {/* {!this.state.loader && <Spin />} */}
              {/* {this.state.display=="none"?!this.state.loader && <Spin />:this.setState({display:"block"})} */}
              {this.state.invalidToken?
              !this.state.loader && 
              <>
              <Grid xs={12} sm={12} lg={12} className="print_width_filter preport print_filters filtersFlex" style={{padding:"0 1.4rem"}}>
                  <Grid container justify="left" alignItems="flex-start" id="filterContainer-title" style={{display:"flex"}}>
                  <div className="filterWeb adminModule">
                    <DashboardItem className="ant-card-body filtersFlex filterWeb" >
                  

                       {/*  <Col lg={6} xs={24} sm={12} md={6} className="filterContainer" >
                            <div className="filter-bg color-1 white-bg">
                              <label> <strong> Date Range </strong>  </label><br/>
                              <RangePicker
                              className="progressReport"
                                style={{ top: 5 }}
                                onChange={this.onDateRangeChange}
                                value={this.state.dateRangeList[0] != "0" ? [moment(this.state.dateRangeList[0], dateFormat), moment(this.state.dateRangeList[1], dateFormat)] : "" } format={dateFormat}
                              />
                            </div>
                            </Col> */}
                            <Col lg={1} xs={0} sm={0} md={1} ></Col>
                          <Col lg={6} xs={24} sm={12} md={6} className="filterContainer">
                            <div className="filter-bg color-1 white-bg">
                              <label> <strong> Aggregate by</strong>  </label>
                              <Select
                                showSearch
                                labelInValue
                                defaultValue={{ value: this.state.viewBy.toString() }}
                                style={{ width: '100%', top:5 }}
                                placeholder="Select View by"
                                optionFilterProp="children"
                                onChange={this.onChangeViweBy}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                <Option value="all">Overall</Option>
                                <Option value="skill">Skill</Option>
                                <Option value="proficiency">Proficiency</Option>
                              </Select>


                            </div></Col>
                            <Col lg={1} xs={0} sm={0} md={1} ></Col>
                            {this.state.ifAdaptive == 0 || this.state.ifAdaptive == 2 || this.props?.match?.params?.viewBy != "domain"?
                            <Col  lg={6} xs={24} sm={12} md={6} style={{ paddingBottom: 6 }} className="filterContainer">
                            <div className="filter-bg color-1 white-bg">
                              <label> <strong> View by </strong>  </label>
                              <Select
                                showSearch
                                value={this.state.aggregateBy}
                                style={{ width: '100%',top: 5}}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                {/* <Option value="domain">Domain</Option> */}
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                                </Select>
                            </div>
                                </Col>
                            :
                            <Col lg={6} xs={24} sm={12} md={6} style={{ paddingBottom: 6 }} className="filterContainer">
                            <div className="filter-bg color-1 white-bg">
                              <label> <strong> View by </strong>  </label>
                              <Select
                                showSearch
                                value={this.state.aggregateByAdaptive}
                                style={{ width: '100%', top: 5}}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                                </Select>
                            </div>
                                </Col>
                              }
                            <Col lg={1} xs={0} sm={0} md={1} ></Col>
                            <Col lg={3} xs={24} sm={12} md={3} style={{ paddingBottom: 6 }} className="filterContainer">
                              <div className="filter-bg white-bg print_btn_print" style={{float:"left", top:"1.3rem", position:"relative"}}>
                              <ReactToPrint
                                trigger={() => <button className="print_btn_web"><PrinterFilled />&nbsp;&nbsp;Print</button>}
                                content={() => window.print()}
                              />
                          </div>
                          </Col>
                        
                    </DashboardItem>
                    </div>
                    <div className="filterMob">
                    <div className=" accordian ">
                      <div
                        className="accordian-header"
                        onClick={this.handleOpen}
                      >
                        <div>
                        Filters
                        </div>
                        <div>
                          {this.state.resultShow ? (
                            <img className="sign" src={minus} />
                          ) : (
                            <img className="sign" src={plus} />
                          )}
                        </div>
                        </div>
                        {this.state.resultShow && (
                        <>
                        <div className="accordian-body">
                        {/* <Col lg={6} xs={24} sm={12} md={6} className="filterContainer" >
                        <div className="filter-bg color-1 white-bg">
                          <label> <strong> Date Range </strong>  </label>
                          <RangePicker
                            style={{ top: 5 }}
                            onChange={this.onDateRangeChange}
                            value={this.state.dateRangeList[0] != "0" ? [moment(this.state.dateRangeList[0], dateFormat), moment(this.state.dateRangeList[1], dateFormat)] : "" } format={dateFormat}
                          />
                        </div></Col> */}
                        <Col lg={1} xs={0} sm={0} md={1} ></Col>
                      <Col lg={6} xs={24} sm={12} md={6} className="filterContainer">
                        <div className="filter-bg color-1 white-bg">
                          <label> <strong> Aggregate by</strong>  </label>
                          <Select
                            showSearch
                            labelInValue
                            defaultValue={{ value: this.state.viewBy.toString() }}
                            style={{ width: '100%', top: 5 }}
                            placeholder="Select View by"
                            optionFilterProp="children"
                            onChange={this.onChangeViweBy}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            <Option value="all">Overall</Option>
                            <Option value="skill">Skill</Option>
                            <Option value="proficiency">Proficiency</Option>
                          </Select>

                        </div></Col>
                        <Col lg={1} xs={0} sm={0} md={1} ></Col>
                        {this.state.ifAdaptive == 0 || this.state.ifAdaptive == 2 || this.props?.match?.params?.viewBy != "domain"?
                        <Col  lg={6} xs={24} sm={12} md={6} style={{ paddingBottom: 6 }} className="filterContainer">
                        <div className="filter-bg color-1 white-bg">
                          <label> <strong> View by </strong>  </label>
                          <Select
                            showSearch
                            value={this.state.aggregateBy}
                            style={{ width: '100%',top: 5}}
                            placeholder="Select Aggregate by"
                            optionFilterProp="children"
                            onChange={this.onChangeAggregateBy}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {/* <Option value="domain">Domain</Option> */}
                            <Option value="chapter">Chapter</Option>
                            <Option value="topic">Topic</Option>
                            </Select>
                        </div>
                            </Col>
                        :
                        <Col lg={6} xs={24} sm={12} md={6} style={{ paddingBottom: 6 }} className="filterContainer">
                        <div className="filter-bg color-1 white-bg">
                          <label> <strong> View by </strong>  </label>
                          <Select
                            showSearch
                            value={this.state.aggregateByAdaptive}
                            style={{ width: '100%', top: 5}}
                            placeholder="Select Aggregate by"
                            optionFilterProp="children"
                            onChange={this.onChangeAggregateBy}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                            }
                          >
                            {/* <Option value="domain">Domain</Option> */}
                            <Option value="chapter">Chapter</Option>
                            <Option value="topic">Topic</Option>
                            </Select>
                        </div>
                            </Col>
                          }
                        <Col lg={1} xs={0} sm={0} md={1} ></Col>
                        <Col lg={3} xs={24} sm={12} md={3} style={{ paddingBottom: 6 }} className="filterContainer">
                          <div className="filter-bg white-bg print_btn_print" style={{float:"left", top:"1.3rem", position:"relative"}}>

                        <div className="filter-bg white-bg print_btn_print" style={{float:"center"}}>
                              <ReactToPrint
                                trigger={() => <button className="print_btn_web"><PrinterFilled />&nbsp;&nbsp;Print</button>}
                                content={() => window.print()}
                              />
                      </div>
                      </div>
                      </Col>
                          </div>
                          </>
                        )}
                          </div>
                          </div>
                  </Grid>
              </Grid>
              <br/>
              <Dashboard>
              <Grid xs={10} sm={10} lg={10} className="header printme-extra-content" >
                <Grid xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                  <Col span={8} className="logo_header_print">
                    <img class='logoWidth' src={logo} style={{paddingLeft:"40px"}} />
                  </Col>
                  <Col span={8} className="breadcrumb_print">
                    <center><h2><b>Progress Report</b></h2></center>
                  </Col>
                  <Col span={8} className="user_detail_print">
                    <h5><p>
                    <span className='c1'>Student Name:</span> <span className='c2'>
                      <ChartRendererCustomised
                      vizState={DashboardStudentDetails[0].vizState}
                    /></span><br />
                    <span className='c1'>School:</span> <span className='c2'>{this.state.selectedSchoolValue}</span><br />
                    <span className='c1'>Class:</span> <span className='c2'>{this.state.selectedClassValue}</span><br />
                    {/* <span className='c1'>Group:</span> <span className='c2'>{this.state.selectedGroupValue == '0' ? 'All' : this.state.selectedGroupValue}</span><br /> */}
                    {/* <span className='c1'>Date Range:</span> <span className='c2'>{this.state.dateRangeList[0].format('DD-MM-YYYY')} To {this.state.dateRangeList[1].format('DD-MM-YYYY')}</span><br /> */}
                    </p></h5>
                  </Col>
                </Grid>
              </Grid>
               
                <Grid xs={12} sm={12} lg={12} className="preport " style={{width:"100%"}}>
                 <div className='cardMob' style={{ padding: "0px 10px" }}>
                  <Grid className='top-widgets' xs={24} lg={24} container spacing={0} style={{ padding: "2px", marginRight:"13px" }} justify="left" alignItems="flex-start">
                  {/* <Col md={5} xs={24} sm={24} key={DashboardItemsTop[0].id} className="topWidget mobCard" id="" style={{marginBottom:"10px", display:"flex"}}>
                      <>{DashboardItemsTop[0].name} : 
                        <center ><span style={{display:"flex",justifyContent:"center"}}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                        </span></center>
                        </>
                    </Col> */}
                    {/* <Col md={5} xs={24} sm={24} key={DashboardItemsTop[1].id} className="topWidget mobCard" id="" style={{marginBottom:"10px", display:"flex"}}>
                      <>{DashboardItemsTop[1].name} : 
                      <center ><span style={{display:"flex",justifyContent:"center"}}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[1].vizState} />
                        </span>
                        </center>
                      </>
                    </Col> */}
                    {/* <Col md={5} xs={24} sm={24} key={DashboardItemsTop[2].id} className="topWidget mobCard" id="" style={{marginBottom:"10px", display:"flex"}}>
                      <>{DashboardItemsTop[2].name} : 
                      <center><span style={{display:"flex",justifyContent:"center"}}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[2].vizState} />
                        </span></center>
                      </>
                    </Col> */}
                    <Col md={5} xs={24} sm={24}   className="topWidget mobCard" id="" style={{marginBottom:"10px", display:"flex"}}>
                    {(() => {

                      if (this.state.selectedStudent[0]?.toString() != "0" && this.state.selectedStudent[0]?.toString() != "") {
                      return (<div class="student-points">
                        <div class="g-progress">
                          <div class="sub-ui-widget">
                            <center style={{ fontSize: 7 }}>
                              <div class="ui-values">
                                <ChartRendererCustomised
                                    vizState={
                                      DashboardStudentPoints[0].vizState
                                    }
                                />
                              </div>
                              <div class="ui-labels">BEE POINTS</div>
                            </center>
                          </div>
                        </div>
                      </div>)
                      }
                    })()}
                    </Col>
                    <Col md={5} xs={24} sm={24} key={DashboardItemsTop[3].id} className="topWidget mobCard" id="" style={{marginBottom:"10px", display:"flex"}}>
                      <>
                      
                      <DashboardItem  title={DashboardItemsTop[3].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[3].vizState} />
                        </DashboardItem>
                       
                      </>
                    </Col>
                  {/* <Col md={4} xs={24} sm={24} key={DashboardItemsTop[8].id} className="topWidget mobCard" id="" style={{marginBottom:"10px", display:"flex"}}>
                    <>{DashboardItemsTop[8].name} :
                    <center ><span style={{display:"flex",justifyContent:"center"}}>
                      <ChartRendererCustomised vizState={DashboardItemsTop[8].vizState} />
                      </span></center>
                    </>
                  </Col> */}
                  </Grid><br/>

                  </div>
                  <div className='cardWeb'>
                  <Grid className='top-widgets' xs={10} lg={10} container spacing={0} alignItems="flex-start">

                  <Col span={24} lg={24}>
                          <div class="title-container student-overview">
                           {/*  ========{this.state.selectedStudent[0]?.toString()}======= */}
                            {(() => {
                              if (this.state.selectedStudent[0]?.toString() != "0" && this.state.selectedStudent[0]?.toString() != "") {
                              return  (<div class="student-points">
                                  <div class="g-progress">
                                    <div class="sub-ui-widget">
                                      <center style={{ fontSize: 7 }}>
                                        <div class="ui-values">
                                          <ChartRendererCustomised
                                              vizState={
                                                DashboardStudentPoints[0].vizState
                                              }
                                          />
                                        </div>
                                        <div class="ui-labels">BEE POINTS</div>
                                      </center>
                                    </div>
                                  </div>
                                </div>)
                                }
                              })()}
                            <div
                              class="div-left"
                              className="custom-student-name"
                            >
                              <ChartRendererCustomised
                                vizState={DashboardStudentDetails[0].vizState}
                              />
                            </div>
                            <div class="div-right">
                              <div class="print_btn_print">
                               {/*  <Link to={`/home-module`}>
                                  <button class="print_btn_web print_btn_web-stdAtmp">
                                    Back
                                  </button>
                                </Link> */}
                                </div>
                            </div>
                          </div>
                        </Col>
                    {/* =============={this.state.selectedStudent[0]?.toString()}================ */}
                  {/* <Col md={5} xs={24} sm={24} key={DashboardItemsTop[0].id} className="topWidget" id="">
                      <DashboardItem title={DashboardItemsTop[0].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[0].vizState} />
                      </DashboardItem>
                    </Col>
                    <Col md={5} xs={24} sm={24} key={DashboardItemsTop[1].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                      <DashboardItem title={DashboardItemsTop[1].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[1].vizState} />
                      </DashboardItem>
                    </Col>
                    <Col md={5} xs={24} sm={24} key={DashboardItemsTop[2].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                      <DashboardItem title={DashboardItemsTop[2].name}>
        
                        <ChartRendererCustomised vizState={DashboardItemsTop[2].vizState} />
                      </DashboardItem>
                    </Col>
                    <Col md={5} xs={24} sm={24} key={DashboardItemsTop[3].id} className="topWidget" id="" style={{marginBottom:"10px"}}>
                      <DashboardItem title={DashboardItemsTop[3].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[3].vizState} />
                      </DashboardItem>
                    </Col>
                  <Col md={4} xs={24} sm={24} key={DashboardItemsTop[8].id} className="topWidget" id="">
                    <DashboardItem title={DashboardItemsTop[8].name}>
                      <ChartRendererCustomised vizState={DashboardItemsTop[8].vizState} />
                    </DashboardItem>
                  </Col> */}
                  </Grid><br/>

                  {/* New student summary report starts here */}
                  <Grid
                        className="top-widgets mt400"
                        xs={24}
                        lg={24}
                        container
                        spacing={0}
                        style={{ padding: 0 }}
                        justify="left"
                        alignItems="flex-start"
                      >
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[3].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[3].name}>
                          <p>{DashboardItemsTop[3].desc}</p>
                          <div className="labels-wrapr">
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#b4d3b2" }}>
                                    EE
                                  </span>
                                  Exceeds Expectation (71% - 100%)
                                </p>
                                <p>
                                  <span style={{ background: "#daf0f7" }}>
                                    ME
                                  </span>
                                  Meets Expectation (51% - 70%)
                                </p>
                              </div>
                              <div className="label-flex">
                                <p>
                                  <span style={{ background: "#ece5c7" }}>
                                    PME
                                  </span>
                                  Partially Meets Expectation (33% - 50%)
                                </p>
                                <p>
                                  <span style={{ background: "#ffe3e1" }}>
                                    NI
                                  </span>
                                  Needs Improvement (0% - 32%)
                                </p>
                              </div>
                            </div>
                           
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[3].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      </Grid>
                      <br />
                      {/* New student summary report ends here */}

                  </div>


                  <Grid className="progressReport" xs={24} lg={24} container spacing={0} style={{ padding: 0 }} justify="left" alignItems="flex-start">
                    <Col span={24} lg={24} key={DashboardItemsTop[4].id}  id="" onClick={this.onClickDashboardTop} className={"task-details"}>
                      <DashboardItem  title={DashboardItemsTop[4].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[4].vizState} />
                      </DashboardItem>
                    </Col>
                  </Grid><br/>

                  {this.state.viewBy[0] == "all"?
                  <Grid className="progressReport" xs={24} lg={24} container spacing={0} style={{ padding: 0,width:"100%" }} justify="left" alignItems="flex-start">
                    <Col span={24} lg={24} key={DashboardItemsTop[5].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[5].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[5].vizState} />
                      </DashboardItem>
                    </Col>
                  </Grid>
                  :
                  this.state.viewBy[0] == "skill"?
                  <Grid className="progressReport" xs={24} lg={24} container spacing={0} style={{ padding: 0,width:"100%" }} justify="left" alignItems="flex-start">
                    <Col span={24} lg={24} key={DashboardItemsTop[6].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[6].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[6].vizState} />
                      </DashboardItem>
                    </Col>
                  </Grid>
                  :
                  <Grid className="progressReport" xs={24} lg={24} container spacing={0} style={{ padding: 0,width:"100%" }} justify="left" alignItems="flex-start">
                    <Col span={24} lg={24} key={DashboardItemsTop[7].id} className={this.state.active === "" ? 'active' : ''} id="" onClick={this.onClickDashboardTop}>
                      <DashboardItem title={DashboardItemsTop[7].name}>
                        <ChartRendererCustomised vizState={DashboardItemsTop[7].vizState} />
                      </DashboardItem>
                    </Col>
                  </Grid>
              }                

                  

                </Grid>
                
              </Dashboard>
              </>
              :
              <center><h2>Id doesn't match or validity date is expired, please contact admin</h2></center>}
            </Content>
            {/* </Content> */}
          </Layout>
        </Layout>

        </CubeProvider>
        )}

      </React.Fragment>
    )
  }
}


export default CustomerDashboardPage;
