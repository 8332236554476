import React, { useState, useEffect } from 'react';
import "./Tabbie.css";
import "./body.css";
import "antd/dist/antd.css";
import { Layout } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import Header from "./components/Header";
import cookie from 'js-cookie';
import { baseUrlCube, homeUrlCube } from "../src/components/helpers"
 
const AppLayout = ({ children }) => (
   <Layout style={{ height: "100%"}}>
    <Header />
    <Layout.Content>  {children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => {
const [CUBEJS_TOKEN, setCUBEJS_TOKEN] = useState('');
const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: `${baseUrlCube}cubejs-api/v1`
});

// Set Token on Page Load 
useEffect(() => {
  setCUBEJS_TOKEN('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjMwMCwiUm9sZSI6NCwiVXNlclR5cGUiOjF9.HbEfbtAMGXsmm2Y60YAUAmPiEYlZkdccyg0qlQFiVXY');
});

return(
  
  <CubeProvider cubejsApi={cubejsApi}>
    
    {console.log("--children--",cubejsApi)}
    <AppLayout>{children}</AppLayout>
  </CubeProvider>
  )
};

export default App;
