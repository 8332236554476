import React, { PureComponent } from "react";
import "react-d3-treemap/dist/react.d3.treemap.css";
import {
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Select,
  Form,
  Button,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Space,
  message,
} from "antd";
import Grid from "@material-ui/core/Grid";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from "antd";
import { Link, useParams } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import axios from "axios";
import jwt from "jwt-decode"; // import dependency
import { useCubeQuery } from "@cubejs-client/react";
import cookie from "js-cookie";
import moment from "moment";
import logo from "../img/logo.png";
import { Redirect } from "react-router";
import {
  baseUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";
import ReactToPrint from "react-to-print";

import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
  AreaChartOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;
class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.myChangeHandler = this.myChangeHandler.bind(this);
    this.mySubmitHandler = this.mySubmitHandler.bind(this);

    this.state = {
      loginUser: "",
      display: "none",
      dateUrl: "",
      collapsed: true,
      subject: "",
      message: "",
      schoolList: [],
      classList: [],
      groupList: [],
      studentList: [],
      assignmentList: [],
      individualSchool: [props?.match?.params?.schoolId],
      individualClass: [props?.match?.params?.standardId],
      individualGroup: [props?.match?.params?.groupIdName],
      individualStudent: [props?.match?.params?.studentId],
      individualDateRange: props?.match?.params?.dateRange,
      individualAssignment: [props?.match?.params?.assignment],
      individualAggregated: [props?.match?.params?.aggregatedBy],
      individualviewBy: [props?.match?.params?.viewBy],
      selectedRowKeys: [],
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ], //undefined, // ["2021-04-05", "2021-04-20"],
      timezone: "America/Los_Angeles",
      school: [""],
      processDateRangeList: [
        moment().subtract(5, "months"),
        moment().startOf("second"),
      ],
      loader: false,
      selectedSchool: [""],
      selectedSchoolID: "",
      selectedClassID: "",
      selectedGroupID: "",
      selectedStudentID: "",
      selectedSchoolValue: "",
      selectedClassValue: "",
      selectedGroupValue: "",
      selectedStudentValue: "",
      selectedDateRange: ["", ""],
      student: [""],
      selectedStudent: [""],
      class: [""],
      selectedClass: [""],
      selectedAssignment: [""],
      group: [""],
      selectedGroup: [""],
      assignmentsAll: [],
      assignments: [],
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      aggregateByAdaptive: ["domain"],
      orderBy: [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      prevButton: "",
      nextButton: "disabled",
      ifAdaptive: 0,
    };
  }
  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  onSubmit(values) {
    this.props.reset();
  }

  onChangeViweBy = (value) => {
    //;

    this.setState({ individualviewBy: "" });

    this.setState({
      viewBy: [value.value],
    });

    if (value.value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (value.value == "skill") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.skillName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.skillName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }
  };

  onChangeNextButton = () => {
    var currentDate = "";
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD"); // moment(this.state.processDateRangeList[1]).format('YYY-MM-DD');
    var futureMonth = currentDate.add(3, "M").format("YYYY-MM-DD");
    var StateDate = [
      moment(futureMonth, "YYYY-MM-DD").add(-5, "months"),
      futureMonth,
    ];

    var currentDateLive = moment().format("YYYY-MM-DD");

    if (futureMonth > currentDateLive) {
      this.setState({
        nextButton: "disabled",
      });
    } else if (futureMonth <= currentDateLive) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: "",
      });
    }
  };

  onChangePrevButton = () => {
    var currentDate = "";
    currentDate = moment(this.state.processDateRangeList[1], "YYYY-MM-DD"); // moment(this.state.processDateRangeList[1]).format('YYY-MM-DD');
    var futureMonth = moment().add(3, "M").format("YYYY-MM-DD");
    var prevMonth = currentDate.add(-3, "M").format("YYYY-MM-DD");
    var fifthYear = moment().add(-3, "Y").format("YYYY-MM-DD");
    var StateDate = [
      moment(prevMonth, "YYYY-MM-DD").add(-5, "months"),
      prevMonth,
    ];
    this.setState({
      nextButton: "",
      processDateRangeList: StateDate,
      nextButton: "",
    });

    if (prevMonth > fifthYear) {
      this.setState({
        processDateRangeList: StateDate,
        prevButton: "",
      });
    } else if (prevMonth <= fifthYear) {
      this.setState({
        prevButton: "disable",
      });
    }
  };

  onChangeAggregateBy = (value) => {
    this.setState({ individualAggregated: "" });
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value.toString()],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
  };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value.value == "0") {
      this.setState({
        selectedAssignment: ["0"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
      });
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }
    }
  };

  onChangeSchool = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedSchool: [option.value.toString()],
      selectedSchoolID: option.value.toString(),
      selectedSchoolValue: option.children.toString(),
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmSchool.schoolName","asc"]],"dimensions":["TmSchool.schoolId","TmSchool.schoolName"],"segments":[],"filters":[{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;
        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    let access_token = cookie?.get("token");

    /*************** Class Section  *************/

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query=
{"measures":[],"timeDimensions":[],"order":[["TmStandard.standardId","asc"]],"dimensions":["TmStandard.standardId","TmStandard.className"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStandard.standardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStandard.className"];
        let className = classList[0]["TmStandard.className"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });
        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStandard.standardId"] != undefined) {
              classListOnLoad.push(x["TmStandard.standardId"]?.toString());
            }
          });
        this.setState({ selectedClass: [classListOnLoad[0]] });

        /*************** Student Section  *************/

        /* Student Section */

        /*************** Student Section  *************/

        /*************** Group Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const groupList = res.data.data;
            let groupId = groupList[0]["TmStudentCurrent.groupId"];
            let groupName = groupList[0]["TmStudentCurrent.groupName"];
            this.setState({ groupList: groupList });
            this.setState({
              selectedGroupID: ["0]"],
              selectedGroupValue: ["All"],
            });
            const groupListOnLoad = [];
            groupList &&
              groupList.map((x) => {
                groupListOnLoad.push(x["TmStudentCurrent.groupId"].toString());
              });

            this.state.selectedGroup = ["0"];

            let studentListUrl = "";

            if (this.state.selectedGroup != "0") {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]} `;
            } else {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `;
            }
            axios
              .get(`${studentListUrl}`, {
                headers: {
                  Authorization: `${access_token}`,
                },
              })
              .then((res) => {
                const studentList = res.data.data;
                let studentId = studentList[0]["TmStudentCurrent.userId"];
                let studentName =
                  studentList[0]["TmStudentCurrent.studentName"];

                this.setState({ studentList: studentList });
                this.setState({
                  selectedStudentID: studentId.toString(),
                  selectedStudentValue: studentName.toString(),
                });

                const studentListOnLoad = [];
                studentList &&
                  studentList.map((x) => {
                    studentListOnLoad.push(x["TmStudentCurrent.userId"]);
                  });

                this.setState({
                  selectedStudent: [studentListOnLoad[0].toString()],
                  selectedStudentValue: studentName.toString(),
                });

                /*************** Assignment Section  *************/

                axios
                  .get(
                    `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                      "YYYY-MM-DD"
                    )}","${this.state.dateRangeList[1].format(
                      "YYYY-MM-DD"
                    )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSingleHwstudStatistics.studentId","operator":"equals","values":["${
                      this.state.selectedStudent
                    }"]}]}`,
                    {
                      headers: {
                        Authorization: `${access_token}`,
                      },
                    }
                  )
                  .then((res) => {
                    const assignmentList = res.data.data;

                    this.setState({ assignmentList: assignmentList });

                    const assignmentListOnLoad = [];
                    assignmentList &&
                      assignmentList.map((x) => {
                        assignmentListOnLoad.push(
                          x["TmSchoolHomework.assignmentId"].toString()
                        );
                      });

                    this.setState({ selectedAssignment: ["0"] });
                  })
                  .catch((error) => {});

                /*************** Assignment Section  *************/
              })
              .catch((error) => {});
            /*************** Group Section  *************/
          })
          .catch((error) => {});
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };
  onChangeClass = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedClass: [option.value.toString()],
      selectedClassValue: option.label.toString(),
    });
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    let groupQuery = "";
    groupQuery = `cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${option.value}"]}]}`;
    axios
      .get(`${baseUrlCube + groupQuery}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["All"],
          selectedAssignment: ["0"],
        });
      })
      .catch((error) => {});
    /* Student Section */
    let studentListUrl = "";

    if (this.state.selectedGroup != "0") {
      studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]} `;
    } else {
      studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${option.value}"]}]} `;
    }
    axios
      .get(`${studentListUrl}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const studentList = res.data.data;
        let studentId = studentList[0]["TmStudentCurrent.userId"];
        let studentName = studentList[0]["TmStudentCurrent.studentName"];
        this.setState({ studentList: studentList });
        const studentListOnLoad = [];
        studentList &&
          studentList.map((x) => {
            studentListOnLoad.push(x["TmStudentCurrent.userId"].toString());
          });
        this.setState({
          selectedStudent: [studentListOnLoad[0].toString()],
          selectedStudentValue: studentName,
        });

        /*************** Assignment Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSingleHwstudStatistics.studentId","operator":"equals","values":["${
              this.state.selectedStudent
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });

            this.setState({
              selectedAssignment: [assignmentListOnLoad[0].toString()],
            });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});
  };

  onChangeGroup = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedGroup: [option.value.toString()],
      selectedGroupValue: option.label.toString(),
    });
    let access_token = cookie?.get("token");

    let studentListUrl = "";

    if (option.value != "0") {
      studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${option.value}"]}]} `;
    } else {
      studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `;
    }
    axios
      .get(`${studentListUrl}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const studentList = res.data.data;
        this.setState({ studentList: studentList });
        if (this.state.studentList.length > 0) {
          let studentId = studentList[0]["TmStudentCurrent.userId"];
          let studentName = studentList[0]["TmStudentCurrent.studentName"];
          const studentListOnLoad = [];
          studentList &&
            studentList.map((x) => {
              studentListOnLoad.push(x["TmStudentCurrent.userId"].toString());
            });

          this.setState({
            selectedStudent: [studentListOnLoad[0].toString()],
            selectedStudentValue: studentName,
          });
        } else {
          this.setState({ selectedStudent: [""] });
          this.setState({ studentList: [] });
          this.setState({ selectedStudentValue: "" });
        }

        /*************** Assignment Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSingleHwstudStatistics.studentId","operator":"equals","values":["${
              this.state.selectedStudent
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(x["TmSchoolHomework.assignmentId"]);
              });

            this.setState({ selectedAssignment: ["0"] });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});
    /*** Student ****/
  };

  onChangeStudent = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedStudent: [option.value.toString()],
      selectedStudentValue: option.label.toString(),
    });
    let access_token = cookie?.get("token");
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
          "YYYY-MM-DD"
        )}","${this.state.dateRangeList[1].format(
          "YYYY-MM-DD"
        )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSingleHwstudStatistics.studentId","operator":"equals","values":["${
          option.value
        }"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const assignmentList = res.data.data;
        const assignmentListOnLoad = [];
        assignmentList &&
          assignmentList.map((x) => {
            assignmentListOnLoad.push(
              x["TmSchoolHomework.assignmentId"].toString()
            );
          });
        this.setState({ selectedAssignment: ["0"] });
        this.setState({ assignmentList: assignmentList });
      })
      .catch((error) => {});
  };

  onDateRangeChange = (value, dateString) => {
    this.state.ifAdaptive = 0;
    dateString = dateString?.toString().split(",");
    this.setState({ dateRangeList: value }); //[dateString.toString()]
    if (dateString[0] != "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        this.state.selectedGroup
      }"]}]}`;
    } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`;
    } else {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    }
    let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });

        if (assignmentList.length > 0) {
          if (this.state.selectedGroup[0].toString() == "0") {
            this.setState({ selectedAssignment: ["0"] });
          } else {
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            this.setState({ selectedAssignment: ["0"] });
          }
        } else {
          this.setState({ selectedAssignment: ["0"], assignmentList: null });
        }
      })
      .catch((error) => {});
  };

  onDateRangeChange_BK = (value, dateString) => {
    this.setState({
      selectedDateRange: [dateString.toString()],
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE";
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${dateString[0].format(
          "YYYY-MM-DD"
        )}","${dateString[1].format(
          "YYYY-MM-DD"
        )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assigId","TmSchoolHomework.assignmentName"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmStudentHomeworks.studentId","operator":"equals","values":["${
          this.state.selectedStudent
        }"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });
        this.setState({ assignmentList: assignmentList });

        if (assignmentList.length > 1) {
          if (this.state.selectedGroup[0].toString() == "0") {
            this.setState({ selectedAssignment: ["0"] });
          } else {
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assigId"].toString()
                );
              });
            this.setState({ selectedAssignment: assignmentListOnLoad });
          }
        } else {
          this.setState({ selectedAssignment: [""], assignmentList: null });
        }
      })
      .catch((error) => {});
  };

  myChangeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  mySubmitHandler = (event) => {
    let subject = this.state.subject;
    let message = this.state.message;
    if (subject === "" || message === "" || this.state.selectedRowKeys == 0) {
      return false;
    } else {
    }
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  CheckQuery = () => {
    let query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: {
        "TmSchoolGroups.classCount": "desc",
      },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    const renderProps = useCubeQuery(query);
    if (renderProps.resultSet) {
    }
  };

  componentDidMount() {
    let access_token = cookie?.get("token");
    this.setState({ loginUser: localStorage.getItem("username") });
    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isStudentTrue = false;
    let isAssignmentTrue = false;

    if (this.props?.match?.params?.sdateRange) {
      if (this.props?.match?.params?.sdateRange.toString() == "Invalid date") {
        this.setState({ dateRangeList: ["", ""] });
      } else {
        var inputstartdate = moment(this.props?.match?.params?.sdateRange);
        var inputenddate = moment(this.props?.match?.params?.edateRange);

        this.state.dateRangeList = [inputstartdate, inputenddate];
      }
    }

    if (this.props?.match?.params?.aggregatedBy) {
      this.state.aggregateBy = this.props?.match?.params?.aggregatedBy;
    }

    if (this.props?.match?.params?.viewBy) {
      this.state.viewBy = this.props?.match?.params?.viewBy;
    }
    if (this.props?.match?.params?.adaptive == 1) {
      this.state.ifAdaptive = 1;
    }
    if (this.props?.match?.params?.adaptive == 2) {
      this.state.ifAdaptive = 2;
    }

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmSchool.schoolName","asc"]],"dimensions":["TmSchool.schoolId","TmSchool.schoolName"],"segments":[],"filters":[]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        isSchoolTrue = true;
        const schoolList = res.data.data;
        let schoolId = "";
        let schoolName = "";
        let schoolNameArray = [];
        if (this.props?.match?.params?.schoolId) {
          schoolId = this.props?.match?.params?.schoolId;
          schoolList &&
            schoolList.map((x) => {
              if (x["TmSchool.schoolId"] == schoolId) {
                schoolNameArray.push(x["TmSchool.schoolName"]);
              }
            });
          schoolName = schoolNameArray.toString();
        } else {
          schoolId = schoolList[0]["TmSchool.schoolId"];
          schoolName = schoolList[0]["TmSchool.schoolName"];
        }

        // Assign School list
        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });
        if (this.props?.match?.params?.schoolId) {
          this.setState({
            selectedSchoolID: this.props?.match?.params?.schoolId.toString(),
            selectedSchoolValue: schoolName.toString(),
          });
        } else {
          this.setState({
            selectedSchoolID: schoolId.toString(),
            selectedSchoolValue: schoolName.toString(),
          });
        }
        /*************** Class Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query=
        {"measures":[],"timeDimensions":[],"order":[["TmStandard.standardId","asc"]],"dimensions":["TmStandard.standardId","TmStandard.className"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${schoolId}"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            isClassTrue = true;
            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStandard.standardId"] != null) {
                  classList.push(x);
                }
              });

            this.setState({ classList: classList });

            let classListOnLoad = [];
            let classValue = [];
            classList &&
              classList.map((x) => {
                if (x["TmStandard.standardId"] != undefined) {
                  classListOnLoad.push(x["TmStandard.standardId"]?.toString());
                }
              });
            classList &&
              classList.map((x) => {
                if (
                  x["TmStandard.standardId"] == classListOnLoad[0].toString()
                ) {
                  classValue.push(x["TmStandard.className"]?.toString());
                }
              });
            this.setState({
              selectedClassValue: classValue[0].toString(),
            });

            if (this.props?.match?.params?.standardId) {
              let classId = this.props?.match?.params?.standardId.toString();
              let className = [];
              let classValue = [];
              classList &&
                classList.map((x) => {
                  if (
                    x["TmStandard.standardId"] ==
                    this.props?.match?.params?.standardId.toString()
                  ) {
                    classValue.push(x["TmStandard.className"]?.toString());
                  }
                });
              this.setState({
                selectedClassID: classId.toString(),
                selectedClassValue: classValue[0].toString(),
              });
              classList &&
                classList.map((x) => {
                  if (x["TmStandard.standardId"] == classId) {
                    className.push(x["TmStandard.standardId"]?.toString());
                  }
                });
              classListOnLoad[0] = className;
              if (this.props?.match?.params?.standardId == "0") {
                this.state.selectedClass = classId;
              } else {
                this.state.selectedClass = className;
              }
            } else {
              this.setState({
                selectedClass: [classListOnLoad[0]?.toString()],
              });
            }

            /*************** Group Section  *************/
            let studentListUrl = "";

            if (
              this.props?.match?.params?.studentId &&
              this.props?.match?.params?.groupIdName == "0"
            ) {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"timeDimensions":[],"order":[["TmStudentCurrent.groupId","asc"]],"measures":[],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.userId","operator":"equals","values":["${this.props?.match?.params?.studentId}"]}]}`;
            } else {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
            }
            axios
              .get(`${studentListUrl}`, {
                headers: {
                  Authorization: `${access_token}`,
                },
              })

              .then((res) => {
                this.setState({
                  loader: true,
                });
                isGroupTrue = true;
                const groupList = res.data.data;

                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList &&
                  groupList.map((x) => {
                    groupListOnLoad.push(
                      x["TmStudentCurrent.groupId"].toString()
                    );
                  });
                if (this.props?.match?.params?.groupIdName) {
                  if (this.props?.match?.params?.groupIdName == "0") {
                    let groupId =
                      this.props?.match?.params?.groupIdName.toString();
                    let groupName = [];
                    let groupIdName = [];
                    groupList &&
                      groupList.map((x) => {
                        if (x["TmStudentCurrent.groupId"] == groupId) {
                          groupName.push(x["TmStudentCurrent.groupName"]);
                        }
                      });
                    if (groupId == 0) {
                      groupName.push("ALL");
                      groupIdName.push("0");
                    }
                    this.setState({
                      selectedGroup: groupId,
                      selectedGroupId: groupIdName,
                      selectedGroupValue: groupName,
                    });
                  } else if (this.props?.match?.params?.groupIdName != "0") {
                    let groupId =
                      this.props?.match?.params?.groupIdName.toString();
                    let groupName = [];
                    let groupIdName = [];
                    groupList &&
                      groupList.map((x) => {
                        if (x["TmStudentCurrent.groupId"] == groupId) {
                          groupName.push(x["TmStudentCurrent.groupName"]);
                        }
                      });
                    if (groupId == 0) {
                      groupName.push(groupListOnLoad[0]);
                      groupIdName.push(groupListOnLoad[0]);
                    }
                    this.setState({
                      selectedGroup: [this.props?.match?.params?.groupIdName],
                      selectedGroupId: groupIdName,
                      selectedGroupValue: groupName,
                    });
                  }
                } else {
                  this.setState({
                    selectedGroup: ["0"],
                    selectedGroupID: ["0"],
                    selectedGroupValue: ["All"],
                  });
                }

                /*************** Group Section  *************/

                /* Student Section */
                if (
                  this.props?.match?.params?.studentId &&
                  this.props?.match?.params?.groupIdName == "0"
                ) {
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.userId","operator":"equals","values":["${this.props?.match?.params?.studentId}"]}]} `;
                } else if (
                  this.props?.match?.params?.studentId &&
                  this.props?.match?.params?.standardId == "0"
                ) {
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${this.state.selectedGroup}"]},{"member":"TmStudentCurrent.userId","operator":"equals","values":["${this.props?.match?.params?.studentId}"]}]} `;
                } else if (this.state.selectedGroup == "0") {
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `;
                } else {
                  studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.studentName","asc"]],"dimensions":["TmStudentCurrent.userId","TmStudentCurrent.studentName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmStudentCurrent.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]} `;
                }
                axios
                  .get(`${studentListUrl}`, {
                    headers: {
                      Authorization: `${access_token}`,
                    },
                  })
                  .then((res) => {
                    isStudentTrue = true;
                    const studentList = res.data.data;
                    let studentId = studentList[0]["TmStudentCurrent.userId"];
                    let studentName =
                      studentList[0]["TmStudentCurrent.studentName"];

                    this.setState({ studentList: studentList });
                    const studentListOnLoad = [];
                    studentList &&
                      studentList.map((x) => {
                        studentListOnLoad.push(
                          x["TmStudentCurrent.userId"].toString()
                        );
                      });
                    if (this.props?.match?.params?.studentId) {
                      let studentId = [
                        this.props?.match?.params?.studentId.toString(),
                      ];
                      let studentName = [];
                      studentList &&
                        studentList.map((x) => {
                          if (x["TmStudentCurrent.userId"] == studentId) {
                            studentName.push(x["TmStudentCurrent.studentName"]);
                          }
                        });
                      this.setState({
                        selectedStudentID: studentId.toString(),
                        selectedStudentValue: studentName.toString(),
                      });
                      this.setState({
                        selectedStudent: studentId,
                        selectedStudentId: studentId,
                        selectedStudentValue: studentName,
                      });
                    } else {
                      this.setState({
                        selectedStudent: [studentListOnLoad[0].toString()],
                        selectedStudentValue: studentName,
                      });
                    }

                    /*************** Assignment Section  *************/

                    axios
                      .get(
                        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                          "YYYY-MM-DD"
                        )}","${this.state.dateRangeList[1].format(
                          "YYYY-MM-DD"
                        )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSingleHwstudStatistics.studentId","operator":"equals","values":["${
                          this.state.selectedStudent
                        }"]}]}`,
                        {
                          headers: {
                            Authorization: `${access_token}`,
                          },
                        }
                      )
                      .then((res) => {
                        isAssignmentTrue = true;
                        const assignmentList = res.data.data;

                        this.setState({ assignmentList: assignmentList });

                        if (this.props?.match?.params?.assignment) {
                          let assignmentId =
                            this.props?.match?.params?.assignment.toString();
                          let assignmentName = [];
                          assignmentList &&
                            assignmentList.map((x) => {
                              if (
                                x["TmSingleHwstudStatistics.assignmentId"] ==
                                assignmentId
                              ) {
                                assignmentName.push(
                                  x["TmSchoolHomework.assignmentName"]
                                );
                              }
                            });
                          if (assignmentId == 0) {
                            assignmentName.push("All");
                          }
                          this.state.selectedAssignment = [
                            this.props?.match?.params?.assignment.toString(),
                          ];
                          this.setState({
                            selectedAssignment: [
                              this.props?.match?.params?.assignment.toString(),
                            ],
                          });
                        } else {
                          this.setState({
                            selectedAssignment: ["0"],
                            selectedAssignmentValue: ["All"],
                          });
                        }

                        if (
                          isSchoolTrue &&
                          isClassTrue &&
                          isGroupTrue &&
                          isStudentTrue &&
                          isAssignmentTrue == true
                        ) {
                          this.setState({
                            loader: true,
                          });
                        }
                      })
                      .catch((error) => {});

                    /*************** Assignment Section  *************/
                  })
                  .catch((error) => {});
                /* Student Section */
              })
              .catch((error) => {});
          })
          .catch((error) => {
            this.setState({
              loader: true,
            });
          });
      })
      .catch((error) => {});
  }

  render() {
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: this.state.dateRangeList,
      });
    }

    let dateRangeArrayQuestion = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayBoxesDomain = [];
    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptiveScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
      });
      dateRangeArrayQuestionAdaptive.push({
        dimension: "IndividualStudentReport.Publish_Date",
      });
      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
      });

      dateRangeArrayBoxesDomain = [];
      dateRangeArrayBoxesDomain.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
      });
    } else {
      dateRangeArrayQuestion.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayQuestionAdaptive.push({
        dimension: "IndividualStudentReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxes = [];
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });

      dateRangeArrayBoxesDomain = [];
      dateRangeArrayBoxesDomain.push({
        dimension: "TmSingleHwstudStatistics.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dimensionArray = [];

    if (this.state.aggregateBy == "domain") {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArray.push("TmHwQnAnsStat.chapterName");
    } else if (this.state.aggregateBy == "topic") {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    } else {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    if (this.state.viewBy == "proficiency") {
      this.state.orderBy = [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
        ["TmHwQnAnsStat.proficiencyName", "asc"],
      ];

      this.state.pivotConfig = {
        x: [
          "TmHwQnAnsStat.domain",
          "TmHwQnAnsStat.chapterName",
          "TmHwQnAnsStat.topicName",
        ],
        y: ["TmHwQnAnsStat.proficiencyName", "measures"],
        fillMissingDates: true,
        joinDateRange: false,
      };
    }

    if (this.state.viewBy == "skill") {
      this.state.orderBy = [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
        ["TmHwQnAnsStat.skillName", "asc"],
      ];

      this.state.pivotConfig = {
        x: [
          "TmHwQnAnsStat.domain",
          "TmHwQnAnsStat.chapterName",
          "TmHwQnAnsStat.topicName",
        ],
        y: ["TmHwQnAnsStat.skillName", "measures"],
        fillMissingDates: true,
        joinDateRange: false,
      };
    }

    if (this.state.viewBy == "skill") {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy == "proficiency") {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");
    } else {
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assigId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let studentFilter = [];

    if (this.state.selectedStudent[0]?.toString() != "0") {
      studentFilter.push({
        member: "TmSingleHwstudStatistics.studentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      studentFilter.push({
        member: "TmSingleHwstudStatistics.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let studentFilterDig = [];

    if (this.state.selectedStudent[0]?.toString() != "0") {
      studentFilterDig.push({
        member: "TmSingleHwstudStatistics.studentId",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      studentFilterDig.push({
        member: "TmSchoolHomework.assignmentId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let studentFilterAdaptive = [];

    if (this.state.selectedStudent[0]?.toString() != "0") {
      studentFilterAdaptive.push({
        member: "IndividualStudentReport.Student_Id",
        operator: "equals",
        values: this.state.selectedStudent,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      studentFilterAdaptive.push({
        member: "IndividualStudentReport.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dimensionDynamic = [
      "TmHwQnAnsStat.proficiency",
      "TmDifficulty.proficiency",
      "TmDifficulty.difficultyOrder",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamic.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamic.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamic.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicSkill = [
      "TmHwQnAnsStat.skillName",
      "TmHwQnAnsStat.skillStd",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicSkill.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicAdaptive = [];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicAdaptive.push(
        "IndividualStudentReport.Domain_Name",
        "IndividualStudentReport.Domain_result"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.skillName",
        "TmHwQnAnsStat.skillStd",
        "TmHwQnAnsStat.chapterName"
      );
    } else {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.skillName",
        "TmHwQnAnsStat.skillStd",
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Assignment Completed",
        vizState: {
          query: {
            measures: ["TmSingleHwstudStatistics.totalCompleted"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            dimensions: [],
            filters: studentFilter,
            segments: [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.completedAssgn",
            ],
          },
          chartType: "number",
        },
      },
      {
        id: 2,
        name: "Not Attempted",
        vizState: {
          query: {
            measures: ["TmSingleHwstudStatistics.totalNotAttempted"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            dimensions: [],
            filters: studentFilter,
            segments: [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.assgnNotAtt",
            ],
          },
          chartType: "number",
        },
      },
      {
        id: 3,
        name: "Student Score",
        vizState: {
          query: {
            measures: ["TmSingleHwstudStatistics.averageScore"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            dimensions: [],
            filters: studentFilter,
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },
      {
        id: 4,
        name: "Class Score",
        vizState: {
          query: {
            measures: ["AssessmentStatistics.totalAverage"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            dimensions: [],
            filters: studentFilter,
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "number",
        },
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query: {
            segments: [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.completedAssgn",
            ],

            filters: studentFilter,
            dimensions: dimensionArray,
            timeDimensions: dateRangeArrayQuestion,
            order: [
              ["TmHwQnAnsStat.chapterName", "asc"],
              ["TmHwQnAnsStat.topicName", "asc"],
              ["TmHwQnAnsStat.proficiencyName", "asc"],
              ["TmHwQnAnsStat.skillName", "asc"],
            ],

            measures: [
              "TmHwQnAnsStat.averageScore",
              "AssesmentStatisticsQuestions.averageScore",
              "TmHWQs.distCount",
            ],
          },
          chartType: "table_pivot_individual",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query: {
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
            filters: studentFilter,
            dimensions: dimensionDynamic,
            timeDimensions: dateRangeArrayQuestion,
           // order: [["TmHwQnAnsStat.proficiency", "asc"]],
           order: [["TmDifficulty.difficultyOrder", "asc"]],
            measures: [
              "TmHwQnAnsStat.averageScore",
              "AssesmentStatisticsQuestions.averageScore",
              "TmHWQs.distCount",
            ],
          },
          chartType: "table_pivot_html_student",
          parameters: [
            {
              ViewBy:
                this.state.individualAggregated[0] == undefined
                  ? this.state.aggregateBy
                  : this.state.individualAggregated,
            },
            {
              AggregatedBy: this.state.individualviewBy
                ? this.state.individualviewBy
                : this.state.viewBy,
            },
          ],
        },
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query: {
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
            filters: studentFilter,
            dimensions: dimensionDynamicSkill,
            timeDimensions: dateRangeArrayQuestion,
            order: [["TmHwQnAnsStat.skillStd", "asc"]],
            measures: [
              "TmHwQnAnsStat.averageScore",
              "AssesmentStatisticsQuestions.averageScore",
              "TmHWQs.distCount",
            ],
          },
          chartType: "table_pivot_html_skill_student_domain",
          parameters: [
            {
              ViewBy:
                this.state.individualAggregated[0] == undefined
                  ? this.state.aggregateBy
                  : this.state.individualAggregated,
            },
            {
              AggregatedBy: this.state.individualviewBy
                ? this.state.individualviewBy
                : this.state.viewBy,
            },
          ],
        },
      },
      {
        id: 5,
        name: "Individual Student Report",
        vizState: {
          query: {
            filters: studentFilterAdaptive,
            dimensions: dimensionDynamicAdaptive,
            timeDimensions: dateRangeArrayQuestionAdaptive,
            order: [["IndividualStudentReport.Domain_Name", "asc"]],
          },
          chartType: "table_pivot_individual_adaptive",
          parameters: [
            {
              ViewBy:
                this.state.individualAggregated[0] == undefined
                  ? this.state.aggregateBy
                  : this.state.individualAggregated,
            },
            {
              AggregatedBy: this.state.individualviewBy
                ? this.state.individualviewBy
                : this.state.viewBy,
            },
          ],
        },
      },

      {
        id: 6,
        name: "Assignments",
        vizState: {
          query: {
            filters: studentFilter,
            dimensions: [
              "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.teacherId",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.percentageString",
              "AssessmentStatistics.averageScore",
            ],
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmSchoolHomework.assignmentName", "asc"]],
            segments: [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.attAndNtAttp",
            ],
          },
          chartType: "table_assignment",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
      {
        id: 6,
        name: "Assignments",
        vizState: {
          query: {
            filters: studentFilter,
            dimensions: [
              "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.teacherId",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.Result",
              "TmSchoolHomework.OverallResult",
            ],
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmSchoolHomework.assignmentName", "asc"]],
            segments: [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.attAndNtAttp",
            ],
          },
          chartType: "table_assignment",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
      {
        id: 7,
        name: "Overall Progress",
        vizState: {
          query: {
            filters: [
              {
                member: "TmSingleHwstudStatistics.studentId",
                operator: "equals",
                values: this.state.selectedStudent,
              },
            ],
            dimensions: ["TmHwQnAnsStat.domain"],
            timeDimensions: [
              {
                dimension: "TmSchoolHomework.publishDate",
                granularity: "month",
                dateRange: this.state.processDateRangeList,
              },
            ],
            order: {},
            segments: ["TmSchoolHomework.assgnClosed"],
            measures: ["TmSingleHwstudStatistics.averageScore"],
          },
          chartType: "bar_overall",
        },
      },
      {
        id: 3,
        name: "Student Score",
        vizState: {
          query: {
            timeDimensions: dateRangeArrayBoxesDomain,
            order: { "TmSingleHwstudStatistics.Result": "asc" },
            dimensions: ["TmSingleHwstudStatistics.Result"],
            filters: studentFilter,
          },
          chartType: "domain_text_istudent",
        },
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            dimensions: ["classScore.overall_result"],
            timeDimensions: dateRangeArrayBoxesAdaptiveScore,
            order: { "classScore.overall_result": "asc" },
            filters: chartFilterAdaptivescore,
          },
          chartType: "domain_text",
        },
      },
      {
        id: 3,
        name: "Student Score",
        vizState: {
          query: {
            dimensions: ["TmSingleHwstudStatistics.Diag_Student_Result"],
            timeDimensions: dateRangeArrayBoxesDomain,
            order: { "TmSingleHwstudStatistics.Diag_Student_Result": "asc" },
            filters: studentFilter,
          },
          chartType: "domain_text_dig",
        },
      },
      {
        id: 6,
        name: "Assignments",
        vizState: {
          query: {
            filters: studentFilter,
            dimensions: [
              "TmSingleHwstudStatistics.completedDateString",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.assigId",
              "TmSchoolHomework.asgGroupId",
              "TmHomeworkgroup.groupId",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.teacherId",
              "TmSchoolHomework.publishType",
              "TmSingleHwstudStatistics.Diag_Student_Result",
              "TmSchoolHomework.Diag_ClassScore",
            ],
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmSchoolHomework.assignmentName", "asc"]],
            segments: [
              "TmSchoolHomework.assgnClosed",
              "TmSingleHwstudStatistics.attAndNtAttp",
            ],
          },
          chartType: "table_assignment",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            dimensions: ["TmSchoolHomework.Diag_ClassScore"],
            timeDimensions: dateRangeArrayQuestion,
            order: { "TmSchoolHomework.Diag_ClassScore": "asc" },
            filters: studentFilterDig,
          },
          chartType: "domain_text_dign",
        },
      },
    ];

    const DashboardItems = [
      {
        id: 1,
        name: "Number Chart",
        vizState: {
          query: {
            measures: ["TmHWQs.distCount"],
            timeDimensions: [],
            order: {},
            dimensions: [],
            filters: [],
            limit: 10,
          },
          chartType: "number",
        },
      },
    ];
    const dashboardItem = (item) => (
      <React.Fragment>
        {item.isVisible && (
          <Col span={24} lg={12} key={item.id} style={{ marginBottom: "24px" }}>
            <DashboardItem title={item.name}>
              <ChartRenderer vizState={item.vizState} />
            </DashboardItem>
          </Col>
        )}
      </React.Fragment>
    );
    const dashboardItemTop = (item) => (
      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "24px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>
    );

    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    let selectedSchoolID = this.state.selectedSchool[0];
    let selectedClassID = this.state.selectedClass[0];
    let selectedStudentID = this.state.selectedStudent[0];

    var classOption = [];
    const { collapsed } = this.state;
    const dateFormat = "DD-MM-YYYY";

    let allGroupList = [];
    let groupChildren = [];
    let classChildren = [];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    return DashboardItems.length ? (
      <React.Fragment>
        <Layout className="print_width" style={{ minHeight: "100vh" }}>
          <Sider
            width={230}
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            className="side_menu site-layout-background"
          >
            <Menu theme="dark" mode="inline" className="nav_panel">

            {localStorage.getItem("reportType") == 1 && (
              <>
              <Menu.Item icon={<SolutionOutlined />} key="2">
                <Link
                  to="/teacher-usage"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Teachers Usage
                </Link>
              </Menu.Item>
      
              <Menu.Item icon={<TeamOutlined />} key="3">
                <Link to="/" className="sub-menu ant-menu-submenu-active1">
                  Class Usage
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<UserOutlined />}
                key="1"
                className="ant-menu-submenu "
              >
                <Link
                  to="/student"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Individual Student Report
                </Link>
              </Menu.Item>
              <Menu.Item icon={<FormOutlined />} key="4">
                <Link
                  to="/assignment"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Assignment Report
                </Link>
              </Menu.Item>
              <Menu.Item icon={<MailOutlined />} key="5">
                <Link
                  to="/send-email"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Send Email
                </Link>
              </Menu.Item>
              {/* <Menu.Item icon={<FundProjectionScreenOutlined />} key="6">
                <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
              </Menu.Item> */}
              {/* {localStorage.getItem("userType") == 1 && (
                <>
                  <Menu.Item icon={<LoginOutlined />} key="7">
                    <Link
                      to="/login-report"
                      className="sub-menu ant-menu-submenu-active1"
                    >
                      Login Report
                    </Link>
                  </Menu.Item>
      
                  <Menu.Item icon={<LineChartOutlined />} key="9">
                    <Link
                      to="/task-analysis"
                      className="sub-menu ant-menu-submenu-active1"
                    >
                      Task Analysis
                    </Link>
                  </Menu.Item>
                  <Menu.Item icon={<FundOutlined />} key="10">
                    <Link
                      to="/question-analysis"
                      className="sub-menu ant-menu-submenu-active1"
                    >
                      Question Analysis
                    </Link>
                  </Menu.Item>
                </>
              )} */}
            </>
          )}
{localStorage.getItem("reportType") == 3 && (
              <>
              <Menu.Item icon={<DatabaseOutlined   />} key="1">
                <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
              </Menu.Item>

              <Menu.Item icon={<FileDoneOutlined   />} key="2">
                <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
              </Menu.Item>

              <Menu.Item icon={<AreaChartOutlined />} key="3">
                <a href={`${newReportUrl}/progress-report`}>Progress</a>
              </Menu.Item>

              <Menu.Item icon={<SolutionOutlined  />} key="4">
                <Link
                  to="/teacher-usage"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Teacher Usage
                </Link>
              </Menu.Item>

              <Menu.Item icon={<MailOutlined   />} key="5">
                <Link
                  to="/send-email"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Mail
                </Link>
              </Menu.Item>

             {/*  <Menu.Item
                icon={<FundProjectionScreenOutlined />}
                key="6"
              >
                <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
              </Menu.Item> */}
              </>
          )}

            </Menu>
          </Sider>
          <Layout style={{ padding: "0 0px 0px" }}>
            <Grid xs={12} sm={12} lg={12} className="header header-customized">
              <nav className="custom-nav">
                <button
                  className="nav-toggl"
                  onClick={() => {
                    this.setState({ mobNav: !this.state.mobNav });
                    console.log("The button is clicked", this.state.mobNav);
                  }}
                >
                  <MenuOutlinedIcon
                    className="icon-style-custom"
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "auto",
                      display: "flex",
                    }}
                  />
                </button>
                {this.state.mobNav && (
                  <ul className="custom-ul">
                    <Menu theme="dark" mode="inline" className="nav_panel">
                      
                            {localStorage.getItem("reportType") == 1 && (
                                  <>
                                      <Menu.Item icon={<SolutionOutlined />} key="3">
                                        <Link
                                          to="/teacher-usage"
                                          className="sub-menu ant-menu-submenu-active1"
                                        >
                                          Teachers Usage
                                        </Link>
                                      </Menu.Item>
                                      <Menu.Item icon={<TeamOutlined />} key="4">
                                        <Link
                                          to="/"
                                          className="sub-menu ant-menu-submenu-active1"
                                        >
                                          Class Usage
                                        </Link>
                                      </Menu.Item>
                                      <Menu.Item
                                        icon={<UserOutlined />}
                                        key="2"
                                        className="ant-menu-submenu "
                                      >
                                        <Link
                                          to="/student"
                                          className="sub-menu ant-menu-submenu-active1"
                                        >
                                          Individual Student Report
                                        </Link>
                                      </Menu.Item>
                                      <Menu.Item icon={<FormOutlined />} key="5">
                                        <Link
                                          to="/assignment"
                                          className="sub-menu ant-menu-submenu-active1"
                                        >
                                          Assignment Report
                                        </Link>
                                      </Menu.Item>
                                      <Menu.Item icon={<MailOutlined />} key="6">
                                        <Link
                                          to="/send-email"
                                          className="sub-menu ant-menu-submenu-active1"
                                        >
                                          Send Email
                                        </Link>
                                      </Menu.Item>

                                  </>
                              )}

                              {localStorage.getItem("reportType") == 3 && (
                                      <>

                                          <Menu.Item icon={<DatabaseOutlined   />} key="1">
                                            <a href={`${newReportUrl}/overall-progress`}>Overall progress</a>
                                          </Menu.Item>

                                          <Menu.Item icon={<FileDoneOutlined   />} key="2">
                                            <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                                          </Menu.Item>

                                          <Menu.Item icon={<AreaChartOutlined />} key="3">
                                            <a href={`${newReportUrl}/progress-report`}>Progress</a>
                                          </Menu.Item>

                                          <Menu.Item icon={<SolutionOutlined  />} key="4">
                                            <Link
                                              to="/teacher-usage"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Teacher Usage
                                            </Link>
                                          </Menu.Item>



                                          <Menu.Item icon={<MailOutlined   />} key="5">
                                            <Link
                                              to="/send-email"
                                              className="sub-menu ant-menu-submenu-active1"
                                            >
                                              Mail
                                            </Link>
                                          </Menu.Item>


                                          <Menu.Item
                                            icon={<FundProjectionScreenOutlined />}
                                            key="6"
                                          >
                                            <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                                          </Menu.Item>


                                              {localStorage.getItem("userType") == 1 && (
                                                <>
                                                  <Menu.Item icon={<LoginOutlined />} key="7">
                                                    <Link
                                                      to="/login-report"
                                                      className="sub-menu ant-menu-submenu-active1"
                                                    >
                                                      Login Report
                                                    </Link>
                                                  </Menu.Item>
                              
                                                  <Menu.Item icon={<LineChartOutlined />} key="9">
                                                    <Link
                                                      to="/task-analysis"
                                                      className="sub-menu ant-menu-submenu-active1"
                                                    >
                                                      Task Analysis
                                                    </Link>
                                                  </Menu.Item>
                                                  <Menu.Item icon={<FundOutlined />} key="10">
                                                    <Link
                                                      to="/question-analysis"
                                                      className="sub-menu ant-menu-submenu-active1"
                                                    >
                                                      Question Analysis
                                                    </Link>
                                                  </Menu.Item>
                                                </>
                                              )}
                                    </>
                                )}
                    </Menu>
                  </ul>
                )}
              </nav>
              <Grid
                xs={24}
                lg={24}
                container
                spacing={0}
                style={{ padding: 0 }}
                justify="left"
                alignItems="flex-start"
              >
                <Col span={8} className="logo_header">
                  <img class="logoWidth" src={logo} />
                </Col>
                <Col span={8} className="breadcrumb">
                  <center>
                    <h2>
                      <b> Individual Student Report</b>
                    </h2>
                  </center>
                </Col>
                <Col span={8} className="user_detail">
                  <h5>
                    <p>
                      <span className="c1">Hello!,</span>{" "}
                      <span className="c2">{this.state.loginUser} </span>
                      <br />
                      {this.state.selectedSchoolValue} <br />
                      <Link
                        className="logout_btn"
                        onClick={(event) => {
                          event.preventDefault();
                          signout(() => <Redirect to="/home" />);
                        }}
                      >
                        {" "}
                        Home{" "}
                      </Link>
                    </p>
                  </h5>
                </Col>
              </Grid>
            </Grid>
            <center>
              <h2>
                <b>
                  <div
                    style={{
                      display: this.state.display,
                      border: "2px solid black",
                      width: "30%",
                      height: "100px",
                      marginTop: "30px",
                      paddingTop: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    One of the Data not provided
                  </div>
                </b>
              </h2>
            </center>
            <Content
              id="printme"
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {this.state.display == "none"
                ? !this.state.loader && <Spin />
                : this.setState({ display: "block" })}
              {this.state.loader && (
                <Dashboard>
                  <Grid
                    xs={10}
                    sm={10}
                    lg={10}
                    className="header printme-extra-content"
                  >
                    <Grid
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col span={8} className="logo_header_print">
                        <img class="logoWidth" src={logo} />
                      </Col>
                      <Col span={8} className="breadcrumb_print">
                        <center>
                          <h2>
                            <b>Individual Student Report</b>
                          </h2>
                        </center>
                      </Col>
                      <Col span={8} className="user_detail_print">
                        <h5>
                          <p>
                            <span className="c1">School:</span>{" "}
                            <span className="c2">
                              {this.state.selectedSchoolValue}
                            </span>
                            <br />
                            <span className="c1">Student Name:</span>{" "}
                            <span className="c2">
                              {this.state.selectedStudentValue}
                            </span>
                            <br />
                            <span className="c1">Class:</span>{" "}
                            <span className="c2">
                              {this.state.selectedClassValue}
                            </span>
                            <br />
                            <span className="c1">Group:</span>{" "}
                            <span className="c2">
                              {this.state.selectedGroupValue == "0"
                                ? "All"
                                : this.state.selectedGroupValue}
                            </span>
                            <br />
                            <span className="c1">Date Range:</span>{" "}
                            <span className="c2">
                              {this.state.dateRangeList[0].format("DD-MM-YYYY")}{" "}
                              To{" "}
                              {this.state.dateRangeList[1].format("DD-MM-YYYY")}
                            </span>
                            <br />
                          </p>
                        </h5>
                      </Col>
                    </Grid>
                  </Grid>
                  <Grid xs={10} sm={10} lg={10}>
                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={6}
                        key={DashboardItemsTop[0].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[0].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[0].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      <Col
                        span={6}
                        key={DashboardItemsTop[1].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[1].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[1].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      {this.state.ifAdaptive == "1" ? (
                        <Col
                          span={6}
                          key={DashboardItemsTop[11].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[11].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[11].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.ifAdaptive == "2" ? (
                        <Col
                          span={6}
                          key={DashboardItemsTop[13].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[13].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[13].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={6}
                          key={DashboardItemsTop[2].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[2].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[2].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                      {this.state.ifAdaptive == "1" ? (
                        <Col
                          span={6}
                          key={DashboardItemsTop[12].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[12].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[12].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.ifAdaptive == "2" ? (
                        <Col
                          span={6}
                          key={DashboardItemsTop[15].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[15].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[15].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={6}
                          key={DashboardItemsTop[3].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[3].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[3].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                    </Grid>
                    <br />

                    <Grid
                      className="top-widgets top-widgets3"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      {this.state.ifAdaptive == "1" ? (
                        this.state.viewBy == "all" ? (
                          this.state.aggregateBy == "domain" ? (
                            <Col
                              span={24}
                              lg={24}
                              key={DashboardItemsTop[7].id}
                              className={
                                this.state.active === "" ? "active" : ""
                              }
                              id=""
                              onClick={this.onClickDashboardTop}
                            >
                              <DashboardItem title={DashboardItemsTop[7].name}>
                                <ChartRenderer
                                  vizState={DashboardItemsTop[7].vizState}
                                />
                              </DashboardItem>
                            </Col>
                          ) : (
                            <Col
                              span={24}
                              lg={24}
                              key={DashboardItemsTop[4].id}
                              className={
                                this.state.active === "" ? "active" : ""
                              }
                              id=""
                              onClick={this.onClickDashboardTop}
                            >
                              <DashboardItem title={DashboardItemsTop[4].name}>
                                <ChartRenderer
                                  vizState={DashboardItemsTop[4].vizState}
                                />
                              </DashboardItem>
                            </Col>
                          )
                        ) : this.state.viewBy == "proficiency" ? (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[5].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[5].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[5].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[6].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[6].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[6].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        )
                      ) : this.state.viewBy == "all" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[4].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[4].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[4].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.viewBy == "proficiency" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[5].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[5].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[5].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[6].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[6].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[6].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                    </Grid>
                    <br />

                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={24}
                        lg={24}
                        key={DashboardItemsTop[10].id}
                        className={this.state.active === "" ? "active" : ""}
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[10].name}>
                          <div className="button-container">
                            <Button
                              className="btnDuration"
                              onClick={this.onChangePrevButton}
                              disabled={this.state.prevButton}
                            >
                              Previous
                            </Button>
                            <Button
                              className="btnDuration"
                              onClick={this.onChangeNextButton}
                              disabled={this.state.nextButton}
                            >
                              Next
                            </Button>
                          </div>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[10].vizState}
                          />
                        </DashboardItem>
                      </Col>
                    </Grid>
                    <br />

                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      {this.state.ifAdaptive == "1" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[9].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[9].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[9].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.ifAdaptive == "2" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[14].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[14].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[14].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[8].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[8].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[8].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    xs={2}
                    sm={2}
                    lg={2}
                    className="print_width_filter print_filters print_width_filter-cust2"
                  >
                    <Grid
                      container
                      justify="left"
                      alignItems="flex-start"
                      id="filterContainer-title"
                    >
                      <div
                        className="filter-bg white-bg print_btn_print"
                        style={{ float: "left" }}
                      >
                        <ReactToPrint
                          trigger={() => (
                            <button className="print_btn_web">
                              <PrinterFilled />
                              &nbsp;&nbsp;Print
                            </button>
                          )}
                          content={() => window.print()}
                        />
                      </div>
                      <DashboardItem title="Filters" className="ant-card-body">
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> School Name </strong>{" "}
                            </label>
                            <schoolValue
                              data={this.state.selectedSchool}
                            ></schoolValue>
                            <Select
                              showSearch
                              defaultActiveFirstOption={true}
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select school name"
                              optionFilterProp="children"
                              onChange={(value, option) =>
                                this.onChangeSchool(option)
                              }
                              labelInValue
                              value={{
                                value: this.state.selectedSchoolID?.toString(),
                              }}
                            >
                              {this.state.schoolList.map((x) => {
                                return (
                                  <Option
                                    key={x["TmSchool.schoolId"]}
                                    value={x["TmSchool.schoolId"]?.toString()}
                                  >
                                    {x["TmSchool.schoolName"]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Class </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value:
                                  typeof this.state.selectedClass[0] == "string"
                                    ? this.state.selectedClass[0]
                                    : this.state.selectedClass[0]?.toString(),
                              }}
                              placeholder="Select Class"
                              optionFilterProp="children"
                              onChange={this.onChangeClass}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              if({this.state.selectedClass.length > 0})
                              {this.state.classList &&
                                this.state.classList.map((x) => {
                                  classChildren.push(
                                    <Option
                                      value={x[
                                        "TmStandard.standardId"
                                      ]?.toString()}
                                    >
                                      {x["TmStandard.className"]}
                                    </Option>
                                  );
                                  allClassList.push(x["TmStandard.standardId"]);
                                })}
                              <Option value="0">All</Option>
                              {classChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Group </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              labelInValue
                              value={{
                                value: this.state.selectedGroup?.toString(),
                              }}
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select Group"
                              optionFilterProp="children"
                              onChange={this.onChangeGroup}
                            >
                              if({this.state.selectedGroup.length > 0}){" "}
                              {this.state.groupList &&
                                this.state.groupList.map((x) => {
                                  groupChildren.push(
                                    <Option key={x["TmStudentCurrent.groupId"]}>
                                      {x["TmStudentCurrent.groupName"]}
                                    </Option>
                                  );
                                  allGroupList.push(
                                    x["TmStudentCurrent.groupId"]
                                  );
                                })}
                              <Option value="0"> All </Option>
                              {groupChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Student </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              labelInValue
                              value={{
                                value:
                                  this.state.selectedStudentValue?.toString(),
                              }}
                              style={{ width: "100%" }}
                              placeholder="Select Student"
                              optionFilterProp="children"
                              onChange={this.onChangeStudent}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.studentList.map((x) => {
                                return (
                                  <Option value={x["TmStudentCurrent.userId"]}>
                                    {x["TmStudentCurrent.studentName"]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Date Range </strong>{" "}
                            </label>
                            <RangePicker
                              style={{ top: 5 }}
                              onChange={this.onDateRangeChange}
                              value={
                                this.state.dateRangeList[0] != "0"
                                  ? [
                                      moment(
                                        this.state.dateRangeList[0],
                                        dateFormat
                                      ),
                                      moment(
                                        this.state.dateRangeList[1],
                                        dateFormat
                                      ),
                                    ]
                                  : ""
                              }
                              format={dateFormat}
                            />
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ paddingBottom: 6, paddingTop: 5 }}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Assignments</strong>{" "}
                            </label>
                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value:
                                  this.state.selectedAssignment[0]?.toString(),
                              }}
                              placeholder="Select Assignments"
                              optionFilterProp="children"
                              onChange={this.onChangeAssignment}
                            >
                              if({this.state.selectedAssignment.length > 0}){" "}
                              {this.state.assignmentList &&
                                this.state.assignmentList.map((x) => {
                                  assignmentChildren.push(
                                    <Option
                                      key={x["TmSchoolHomework.assignmentId"]}
                                    >
                                      {x["TmSchoolHomework.assignmentName"]}
                                    </Option>
                                  );
                                  allAssignmentList.push(
                                    x["TmSchoolHomework.assignmentId"]
                                  );
                                })}
                              <Option value="0"> All </Option>
                              {assignmentChildren}
                            </Select>
                          </div>
                        </Col>

                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Aggregate by</strong>{" "}
                            </label>
                            <Select
                              showSearch
                              labelInValue
                              defaultValue={{
                                value: this.state.viewBy.toString(),
                              }}
                              style={{ width: "100%" }}
                              placeholder="Select View by"
                              optionFilterProp="children"
                              onChange={this.onChangeViweBy}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              <Option value="all">Overall</Option>
                              <Option value="skill">Skill</Option>
                              <Option value="proficiency">Proficiency</Option>
                            </Select>
                          </div>
                        </Col>
                        {this.state.ifAdaptive == 0 ||
                        this.state.ifAdaptive == 2 ||
                        this.props?.match?.params?.viewBy != "domain" ? (
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ paddingBottom: 6 }}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> View by </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                value={this.state.aggregateBy}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ paddingBottom: 6 }}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> View by </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                value={this.state.aggregateByAdaptive}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                              </Select>
                            </div>
                          </Col>
                        )}
                      </DashboardItem>
                    </Grid>
                  </Grid>
                </Dashboard>
              )}
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    ) : (
      <div style={{ textAlign: "center", padding: 12 }}>
        {" "}
        <Typography variant="h5" color="inherit">
          {" "}
          There are no charts on this dashboard. Use Playground Build to add
          one.{" "}
        </Typography>{" "}
      </div>
    );
  }
}

export default CustomerDashboardPage;
