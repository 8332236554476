import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useCubeQuery } from "@cubejs-client/react";
import { Spin, Row, Col, Statistic, Table, Progress } from "antd";
import DashboardItem from "../components/DashboardItem";
import Wrapper from "../components/Wrapper";
import CenterWrapper from "../components/CenterWrapper";
import { useHistory } from "react-router-dom";
import cookie from 'js-cookie';
import axios from 'axios';
import { baseUrlCube, getUser, authenticate, isAuth, signout } from "../components/helpers"


import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
  Label,
  LabelList,
  Text
} from "recharts";
import { Link } from "react-router-dom";

// import {HorizontalBar} from 'react-chartjs-2';

import moment from "moment";
var createReactClass = require('create-react-class');

var rowUniques=[];
var columnUniques=[];
var uniqPro=["Student Score","Distinct Questions"];
var uniqProStudent=["Student Score","Class Score","Distinct Questions"];
var uniqProStudentNotClass=["Student Score","Distinct Questions"];
var dataProficiency=[];
var dataOrderName=[];
var viewby=[];
var result=[];
var domainData=[];
var rowSpan={};

const dateFormatter = item => moment(item).format("MMM 'YY");
const dateDayFormatter = item => moment(item).format("MMM DD 'YY");
const dateTimeFormatter = item => moment(item).format("MMM DD 'YY hh:mm:ss a");
// const dateTimeFormat = "MMM 'YY";
// const dateFormatter = item => moment(item).format(dateTimeFormat);

const rowSelection = {

  onChange: (selectedRowKeys, selectedRows) => {
    localStorage.removeItem('studentArray')
    let studentArrayString=selectedRows
    localStorage.setItem("studentArray",JSON.stringify(studentArrayString))
  }
};
const convertToFormalHours = function (totalSeconds) {
  let negative;
  // Find a Negative number
  if (totalSeconds < 0) { negative = true } else { negative = false }
  if (negative) { totalSeconds = totalSeconds * -1; }
  var hours = Math.floor(totalSeconds / 3600);
  var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
  var seconds = totalSeconds - (hours * 3600) - (minutes * 60);
  // round seconds
  seconds = Math.round(seconds * 100) / 100
  var result = (hours < 10 ? "0" + hours : hours);
  result += ":" + (minutes < 10 ? "0" + minutes : minutes);
  result += ":" + (seconds < 10 ? "0" + seconds : seconds);
  if (negative) { return result = '-' + result } else { return result };
}

const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={650}>
    <ChartComponent data={resultSet.chartPivot()} cursor="pointer">
      {/* <XAxis dataKey="x" /> */}
      {(() => {
        return (
            <XAxis dataKey="x" fontSize="10px" angle={-45} sclaeToFit="true" verticalAnchor= "start" interval={0}
            textAnchor="end"/>
            // <YAxis axisLine={false} />
               )
      })()}
      {(() => {
        if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'hour') {
          return (
            <XAxis dataKey="x" tickFormatter={dateTimeFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'day') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'month') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else {
          return (
            <XAxis dataKey="x" />
          )
        }
      })()}
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend verticalAlign="bottom" wrapperStyle={{fontSize:"17px",bottom:"0px"}}/>
      <Tooltip cursor={false} />
    </ChartComponent>
  </ResponsiveContainer>
);

const CartesianChartOverall = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={350} className="overall_chart">
    <ChartComponent data={resultSet.chartPivot()} cursor="pointer">
      {/* <XAxis dataKey="x" /> */}
      {/* {(() => {
        return (
            <XAxis dataKey="x" fontSize="10px" angle={-45}
            textAnchor="end" />
               )
      })()} */}
      {(() => {
        if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'hour') {
          return (
            <XAxis dataKey="x" tickFormatter={dateTimeFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'day') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else if (resultSet.loadResponse.query.timeDimensions[0].granularity == 'month') {
          return (
            <XAxis dataKey="x" tickFormatter={dateDayFormatter} />
          )
        } else {
          return (
            <XAxis dataKey="x" />
          )
        }
      })()}
      <YAxis />
      <CartesianGrid />
      {children}
      <Legend verticalAlign="bottom" wrapperStyle={{fontSize:"17px"}}/>
      <Tooltip cursor={false} />
    </ChartComponent>
  </ResponsiveContainer>
);

// const colors = ["#9493DC", "#F7855E", "#BDC5CD", '#9A97A7', '#2A334A', "#6DAC4FFF", "#006B38FF", "#97BC62FF", "#2C5F2D", "#214a32", '#8cc63f', "#17703c", "#d5e4c0"];

const colors = ["#F9C098", "#E18C93", "#E2E3A1", "#9ED5EC", "#a929a5", '#9A97A7', '#2A334A', "#6DAC4FFF", "#006B38FF", "#97BC62FF", "#2C5F2D", "#214a32", '#8cc63f', "#17703c", "#d5e4c0"];
const backgroundColor = ['rgba(91, 143, 249, 0.9)', 'rgba(93, 216, 166, 0.9)', 'rgba(93, 112, 146, 0.9)', 'rgba(246, 190, 46, 0.9)']
const borderColor = ["#D58C56", "#C94041", "#BDC069"]

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;
};


const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};



const stackedChartData = resultSet => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }) =>
      yValuesArray.map(([yValues, m]) => ({
        x: resultSet.axisValuesString(xValues, ", "),
        color: resultSet.axisValuesString(yValues, ", "),
        measure: m && Number.parseFloat(m)
      }))
    )
    .reduce((a, b) => a.concat(b), []);
  return data;
}

const CustomizedLabel = createReactClass({

  render() {
    const { x, y, fill, value } = this.props;
    return <text
      x={x}
      y={y}

      fontSize='16'
      fontFamily='sans-serif'
      fill={fill}
      textAnchor="start">{value}%</text>
  }
});
const renderCustomizedLabel = (props) => {
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="10" fill="#000000" fontWeight="Bold" />;
};
const renderCustomizedLabelTop = (props) => {
  // props.value=Math.round(props.value);
  const { content, ...rest } = props;

  return <Label {...rest} fontSize="12" fill="#000000" fontWeight="Bold" />;
};
const TypeToChartComponent = {
  bar_horizontal: ({ resultSet }) => (

    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (

        <BarChart
          data={resultSet.seriesNames()}
          layout="vertical" barCategoryGap={1}
          margin={{ top: 0, right: 50, left: 0, bottom: 0 }}>
          <XAxis type="number" hide />
          <YAxis type="category" width={150} padding={{ left: 20 }} dataKey="name" />

          <Bar
            dataKey="value"
            fill="#323232"
            label={<CustomizedLabel />}
          />

        </BarChart>
      ))}
    </CartesianChart>
  ),
  line: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          strokeWidth={6}
          dataKey={series.key}
          // name={series.title}
          name={series.title. split(",",1).toString()}
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  bar: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={BarChart} >
      {localStorage.setItem("teacherName",resultSet.loadResponse.data[0]?.["TmSchoolHomework.teacher"])}
      {resultSet.seriesNames().map((series, i) => (
        <Bar
        
          layout="vertical"
          barSize={20}
          stroke={borderColor[i]}
          strokeWidth={1}
          key={series.key}
          stackId="a"
          //  horizontal={true}
          dataKey={series.key}
          name={series.title.replace(/^Work Order|^S Order/g, '')}
          // name={Math.round(series.title)}
          fill={colors[i]}
        >
          {/* <LabelList
                    position="top"
                    valueAccessor={entry => entry.D}
                    formatter={((value)=>
                      (value.value))}
                  /> */}
          {/* <Label position="center"  /> */}
          {/* <LabelList dataKey={series.key} position="inside" content={renderCustomizedLabel} /> */}
          <LabelList position="top" content={renderCustomizedLabelTop} />
        </Bar>
      ))}
    </CartesianChart>

  ),
  bar_overall: ({ resultSet }) => (
    <CartesianChartOverall resultSet={resultSet} ChartComponent={BarChart} >
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          layout="vertical"
          scroll={{ x: 100 }}
          barSize={20}
          stroke={borderColor[i]}
          strokeWidth={1}
          key={series.key}
          // stackId="a"
          //  horizontal={true}
          dataKey={series.key}
          // name={series.title.replace(/^Work Order|^S Order/g, '')}
          // name={Math.round(series.title)}
          name={series.title. split(",",1).toString()}
          fill={colors[i]}

        >
          {/* <LabelList
                    position="top"
                    valueAccessor={entry => entry.D}
                    formatter={((value)=>
                      (value.value))}
                  /> */}
          {/* <Label position="center"  /> */}
          
          {/* <LabelList dataKey={series.key} position="inside" content={renderCustomizedLabel} /> */}
          <LabelList position="top" content={renderCustomizedLabelTop} />
        </Bar>
      ))}
    </CartesianChartOverall>
  ),

  area: ({ resultSet }) => (
    <CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
          fill={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet }) => (
    <ResponsiveContainer width="100%" height={350}>
      {resultSet.seriesNames()[0] != undefined ? (<PieChart>
        <Pie
          isAnimationActive={false}
          data={resultSet.chartPivot()}
          nameKey="x"
          dataKey={resultSet.seriesNames()[0] == undefined ? (0) : (resultSet.seriesNames()[0].key)}
          // label
          label={(entry) => entry.name + ' :  ' + entry.value}
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {/* <Legend /> */}
        <Tooltip />
      </PieChart>) : (<Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: "100%"
        }}
      >
        <Col> <Statistic value={0} /> </Col> </Row>)}
    </ResponsiveContainer>
  ),
  circleProgress: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            //<Statistic value={resultSet.totalRow()[s.key]} />
            <Progress type="circle" percent={Math.round(resultSet.totalRow()[s.key])} />
          ))}
        </Col>
      </Row>
    );
  },
  strightProgress: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            //<Statistic value={resultSet.totalRow()[s.key]} />
            // <Progress type="circle" percent={30} />
            // <Progress percent={30} status='active'  />
            <Progress type="circle" className="ant-progress-circle1" percent={Math.round(resultSet.totalRow()[s.key])} />

            // <Progress percent={Math.round(resultSet.totalRow()[s.key])} />
          ))}
        </Col>
      </Row>
    );
  },
  number: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col>
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            value={resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]} 
            // value={(resultSet.totalRow()["TmSingleHwstudStatistics.averageScore"]||resultSet.totalRow()["AssessmentStatistics.totalAverage"] ||resultSet.totalRow()["Class.average"] ? resultSet.totalRow()[s.key]+'%':resultSet.totalRow()[s.key])} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            // value={resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]}
            value={resultSet.loadResponse.data[0]["classScore.overall_result"]}  
            // value={(resultSet.totalRow()["TmSingleHwstudStatistics.averageScore"]||resultSet.totalRow()["AssessmentStatistics.totalAverage"] ||resultSet.totalRow()["Class.average"] ? resultSet.totalRow()[s.key]+'%':resultSet.totalRow()[s.key])} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text_istudent: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            // value={resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]}
            value={resultSet.loadResponse.data[0]["TmSingleHwstudStatistics.Result"]}  
            // value={(resultSet.totalRow()["TmSingleHwstudStatistics.averageScore"]||resultSet.totalRow()["AssessmentStatistics.totalAverage"] ||resultSet.totalRow()["Class.average"] ? resultSet.totalRow()[s.key]+'%':resultSet.totalRow()[s.key])} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text_dig: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            // value={resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]}
            value={resultSet.loadResponse.data[0]["TmSingleHwstudStatistics.Diag_Student_Result"]}  
            // value={(resultSet.totalRow()["TmSingleHwstudStatistics.averageScore"]||resultSet.totalRow()["AssessmentStatistics.totalAverage"] ||resultSet.totalRow()["Class.average"] ? resultSet.totalRow()[s.key]+'%':resultSet.totalRow()[s.key])} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  domain_text_dign: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
        <Col style={{fontSize:"12px"}} className="domain-result">
          {resultSet.seriesNames().map((s) => (
            <Statistic 
            // value={resultSet.totalRow()[s.key]===null?"0":resultSet.totalRow()[s.key]}
            value={resultSet.loadResponse.data[0]["TmSchoolHomework.Diag_ClassScore"]}  
            // value={(resultSet.totalRow()["TmSingleHwstudStatistics.averageScore"]||resultSet.totalRow()["AssessmentStatistics.totalAverage"] ||resultSet.totalRow()["Class.average"] ? resultSet.totalRow()[s.key]+'%':resultSet.totalRow()[s.key])} 
            />
          ))}
        </Col>
      </Row>
    );
  },
  table_pivot_custom: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={false}
      scroll={{ x: 1200 }}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig)))}
      bordered />
  ), table: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig), resultSet.loadResponse.query.filters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  box_table: ({ resultSet, pivotConfig }) => (
    <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig)}
      dataSource={resultSet.tablePivot(pivotConfig)}
      bordered />
  ),
  chapter_overview_report: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmChapterOverview.levelId" && ea.dataIndex !== "TmChapterOverview.user_attempts" && ea.dataIndex !== "TmChapterOverviewNoGrp.levelId")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  chapter_summary_report: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmChapterSummary.levelId" && ea.dataIndex !== "TmChapterSummaryNoGrp.levelId")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  chapter_name: ({ resultSet }) => {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          height: '100%',
        }}
      >
       
          {resultSet.seriesNames().map((s) => (
            <div>{resultSet.loadResponse.data[0]["TmAllChapterList.categoryName"]}</div> 
          
          ))}
       
      </Row>
    );
  },

  student_overview_report: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmStudentOverview.tmStmktkStudentId" && ea.dataIndex !== "TmStudentOverview.tmStmktkStandardId" &&  ea.dataIndex !== "TmStudentOverviewNoGroup.tmStmktkStudentId" && ea.dataIndex !== "TmStudentOverviewNoGroup.tmStmktkStandardId")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  chapter_title: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
    pagination={false}
    columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmChapterSummary.studentId")}
    // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
    dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
    pass={resultSet.loadResponse.query.filters}
    bordered />
  },
  student_list_report: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmChapterOverview.studentId" && ea.dataIndex !== "TmChapterOverview.gradeId" && ea.dataIndex !== "TmChapterOverview.studentName" && ea.dataIndex !== "TmChapterOverview.user_attempts" && ea.dataIndex !== "TmChapterOverview.user_overall_percentage"  && ea.dataIndex !== "TmChapterOverviewNoGrp.studentId" && ea.dataIndex !== "TmChapterOverviewNoGrp.gradeId" && ea.dataIndex !== "TmChapterOverviewNoGrp.studentName" && ea.dataIndex !== "TmChapterOverviewNoGrp.user_attempts" && ea.dataIndex !== "TmChapterOverviewNoGrp.user_overall_percentage")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },

  intro_student_attempts: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmIntroAttempts.studentId" && ea.dataIndex !== "TmIntroAttempts.gradeId" && ea.dataIndex !== "TmIntroAttemptsNoGrp.studentId" && ea.dataIndex !== "TmIntroAttemptsNoGrp.gradeId")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  
  chapter_report: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmAllChapterList.categoryId" && ea.dataIndex !== "TmAllChapterListNoGroup.categoryId")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  task_report: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={rearrange(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmStudentMock.tmStmktkStudentId"))}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  table_individual: ({ resultSet, pivotConfig, parameters }) => {
    return <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.studentId")}
      // dataSource={addPercentage(individualStudent(assignDetail(resultSet.tablePivot(pivotConfig),parameters,pivotConfig)))}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
  },
  table_individual_adaptive: ({ resultSet, pivotConfig, parameters }) => {
    return (
      // "Selected Assignment is adaptive"
      <Table
      pagination={false}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.studentId")}
      dataSource={addPercentage(individualStudent(resultSet.tablePivot(pivotConfig),parameters,pivotConfig))}
      pass={resultSet.loadResponse.query.filters}
      bordered />
    )
  },
  table_assignment: ({ resultSet, pivotConfig, parameters }) => (
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidthHideColumn(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.shortTitle!==" " && ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(individualStudent(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions)))}
      bordered />
  ),
    table_assignment_all: ({ resultSet, pivotConfig,parameters, renderProps }) => {
      return <Table
        pagination={false}
        scroll={{ x: 500 }}
        className={reduceWidth(resultSet.tableColumns(pivotConfig))}
        columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.shortTitle!==" " && ea.dataIndex !== "TmSchoolHomework.groupId" && ea.dataIndex !== "TmSchoolHomework.assignmentId")}
        dataSource={individualAssignAll(resultSet.tablePivot(pivotConfig), parameters,"TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain")}
        bordered
      />
    },
  table_assignment_adaptive: ({ resultSet, pivotConfig, parameters }) => {
    return(
    // "adaptive - assignments"
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidthHideColumn(resultSet.tableColumns(pivotConfig))}
      columns={ascending(resultSet.tableColumns(pivotConfig).filter(ea => ea.shortTitle!==" " && ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" ))} //
      dataSource={addPercentage(dateFormat(individualStudent(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions)))}

      bordered />
    // )
  )},
  table_assignment_mail_page: ({ resultSet, pivotConfig, parameters }) => (
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidth(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  table_assignment_adaptive_mail_page: ({ resultSet, pivotConfig, parameters }) => (
    // "adaptive - assignments"
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidth(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  table_assignment_mail: ({ resultSet, pivotConfig, parameters }) => (
    <Table
      pagination={false}
      scroll={{ x: 100 }}
      className={increaseWidth(resultSet.tableColumns(pivotConfig))}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.statusOrder" && ea.dataIndex !== "TmSingleHwstudStatistics.studentId" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      dataSource={addPercentage(dateFormat(resultSet.tablePivot(pivotConfig), parameters, resultSet.loadResponse.query.timeDimensions))}
      bordered />
  ),
  table_teacher: ({ resultSet, pivotConfig }) => {
    return(
    <Table
      pagination={false}
      scroll={{ x: 1200 }}
      columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSchoolHomework.teacherId")}
      dataSource={resultSet.tablePivot(pivotConfig)}
      bordered />
    )
  },
  table_selection: ({ resultSet, pivotConfig }) => (
    <Table
      rowSelection={{
        ...rowSelection,
      }}
      pagination={true}
      scroll={{ x: 1200 }}
      // columns={changeColumn(resultSet.tableColumns(pivotConfig))}
      columns={changeColumn(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmSingleHwstudStatistics.studentId" ))}
      dataSource1={[
        {
          "key": 741,
          "TmStudentHomeworks.studentId": 741,
          "TmSingleHwstudStatistics.studentId": "1651",
          "TmStudentHomeworks.grpStudName": "1651 1651",
          "TmStudentHomeworks.studEmail": "1651"
        },
        {
          "key": 40552,
          "TmStudentHomeworks.studentId": 40552,
          "TmSingleHwstudStatistics.studentId": "78046",
          "TmStudentHomeworks.grpStudName": "78046 78046",
          "TmStudentHomeworks.studEmail": "78046"
        },
        {
          "key": 106105,
          "TmStudentHomeworks.studentId": 106105,
          "TmSingleHwstudStatistics.studentId": "NA",
          "TmStudentHomeworks.grpStudName": "NA",
          "TmStudentHomeworks.studEmail": "NA"
        }
      ]}
      dataSource={addKey(resultSet.tablePivot(pivotConfig))}
      bordered />
  ),
  table_pivot: ({ resultSet, pivotConfig, renderProps }) => {
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceWidth(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain")}
      bordered
    />
  },
  table_pivot_adaptive: ({ resultSet, pivotConfig, renderProps,parameters }) => {
    return (
    // "Selected Assignment is "+parameters[0]["AssignmentLabel"]
    <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceWidth(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain")}
      bordered
    />
    )
  },
  table_pivot_individual: ({ resultSet, pivotConfig, renderProps }) => {
    return <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
  },
  table_pivot_individual_adaptive: ({ resultSet, pivotConfig, renderProps }) => {
    return (
    // "individual adaptive"
    <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      columns={manipulateInput(resultSet.tableColumns(pivotConfig), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
    )
  },
     table_pivot_html: ({ resultSet,parameters,pivotConfig }) => {
      if(resultSet.loadResponse.data.length==0){
        return(
        <Table
        pagination={false}
        scroll={{ x: 500 }}
        className={reduceColumn(resultSet.tableColumns(pivotConfig))}
        // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
        columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmHwQnAnsStat.proficiency"), resultSet.tablePivot(pivotConfig), resultSet)}
        dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
        bordered
      />
        )
      }
      else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy
    // alert(viewByParam)

    // alert(aggregatedByParam)
    let strokeColor = '';
    
    removeRepeat(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
            // alert("chapt")
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        // alert("true")
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        // alert("false")
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
      }
  },

  table_pivot_html_skill: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy[0]
    let strokeColor = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },
  table_pivot_html_skill_domain: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    let strokeColor = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_skill_adap: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    // result =resultSet.loadResponse.data;
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    let strokeColor = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqPro.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
          distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'
      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },
  table_pivot_html_student: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmHwQnAnsStat.proficiency"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""
    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy[0]
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeat(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      var classScore=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },


  table_pivot_html_skill_student: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    var viewByParam=parameters[1].AggregatedBy[0]
    var aggregatedByParam=parameters[0].ViewBy[0]
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr>
        <td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var classScore=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },

  table_pivot_html_skill_student_domain: ({ resultSet,parameters,pivotConfig }) => {
    if(resultSet.loadResponse.data.length==0){
      return(
      <Table
      pagination={false}
      scroll={{ x: 500 }}
      className={reduceColumn(resultSet.tableColumns(pivotConfig))}
      // columns={resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "mHwQnAnsStat.proficiency" && ea.dataIndex !== "TmDifficulty.proficiency" && ea.dataIndex !== "TmSingleHwstudStatistics.assignmentId" && ea.dataIndex !=="TmSchoolHomework.assigId" && ea.dataIndex !=="TmHomeworkgroup.groupId")} //
      columns={manipulateInput(resultSet.tableColumns(pivotConfig).filter(ea => ea.dataIndex !== "TmHwQnAnsStat.skillName" && ea.dataIndex !== "TmHwQnAnsStat.skillStd" && ea.dataIndex !== "TmHwQnAnsStat.proficiency"), resultSet.tablePivot(pivotConfig), resultSet)}
      dataSource={removeRepeatt(resultSet.tablePivot(pivotConfig), "TmHwQnAnsStat.chapterName")}
      bordered
    />
      )
    }
    else{
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">
{columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr>
        <td>{runiq}</td>
    {columnUniques.map((cuniq,i)=>{
      
      var score=""
      var classScore=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
  {columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
  {columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })}
  </tr>
  )

})

}

  {/* ) */}

  </table>
    }
  },

  table_pivot_html_student_mail: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""
    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    var isClassScore=parameters[2].isClassScore
    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeat(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {isClassScore==false?
    columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )
    :
    columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )
  }
  </tr>
  <tr className="subHead">
  {isClassScore==false?
columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )
  :
  columnUniques.map((cuniq,i)=>
  uniqProStudentNotClass.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
        {isClassScore==false?
    columnUniques.map((cuniq,i)=>{
      
      var score=""
      var distQuestion=""
      var classScore=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })
  :
  columnUniques.map((cuniq,i)=>{
      
    var score=""
    var distQuestion=""
    // var classScore=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        //  classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         distQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(score && distQuestion){
      return(
      <><td>{score}</td><td>{distQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })
  }
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
      {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
  var dscore=""
  // var dclassScore=""
  var ddistQuestion=""
  dataProficiency.map((dataa,i)=>{
    dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'

    if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
       dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
      //  dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
       ddistQuestion=dataa["TmHWQs.distCount"];
    }
  })
  if(dscore && ddistQuestion){
    return(
    <><td>{dscore}</td><td>{ddistQuestion}</td></>
    )
  }
  else{
    return(
    <><td></td><td></td></>
    )
  }
})}
  </tr>
  )
})
:

rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
            {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
        oldChapt=runiq[0]
        
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
  oldChapt=runiq[0]
  
var tscore=""
// var tclassScore=""
var tdistQuestion=""

dataProficiency.map((dataa,i)=>{

dataa["TmHwQnAnsStat.averageScore"] >= 71?
strokeColor = '#92a055'
:
dataa["TmHwQnAnsStat.averageScore"] >= 50?
strokeColor = '#dc8147'
:
strokeColor = '#9e3433'

dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
strokeColor1 = '#92a055'
:
dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
strokeColor1 = '#dc8147'
:
strokeColor1 = '#9e3433'

if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.proficiency"]){
   tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
  //  tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
   tdistQuestion=dataa["TmHWQs.distCount"];
}
})
if(tscore && tdistQuestion){
return(
<><td>{tscore}</td><td>{tdistQuestion}</td></>
)
}
else{
return(
<><td></td><td></td></>
)
}
})}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },

  table_pivot_html_skill_student_mail: ({ resultSet,parameters }) => {
    columnUniques=[];
    rowUniques=[];
    dataProficiency=[];
    viewby=[];
    result=[];
    var oldChapt=""

    var viewByParam=parameters[1].AggregatedBy
    var aggregatedByParam=parameters[0].ViewBy
    var isClassScore=parameters[2].isClassScore

    // alert(aggregatedByParam)

    let strokeColor = '';
    let strokeColor1 = '';
    
    removeRepeatSkill(resultSet.loadResponse.data, "TmHwQnAnsStat.chapterName", "TmHwQnAnsStat.domain", "TmHwQnAnsStat.topicName",aggregatedByParam)
    return  <table id="customers">
  <tr>
    {viewby.map((view,i)=>
    <th rowspan="2">{view}</th>
  )}
    {isClassScore==false?
    columnUniques.map((cuniq,i)=>
        <th colspan="3">{cuniq[1]}</th>
    )
  :
  columnUniques.map((cuniq,i)=>
        <th colspan="2">{cuniq[1]}</th>
    )}
  </tr>
  <tr className="subHead">

  {isClassScore==false?
columnUniques.map((cuniq,i)=>
  uniqProStudent.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )
  :
  columnUniques.map((cuniq,i)=>
  uniqProStudentNotClass.map((uni,i)=>
  
    <td>{uni}</td>
  )
  )}
  </tr>
  {aggregatedByParam=="chapter"?
  rowUniques.map((runiq,i)=> {
    return(    
      <tr><td>{runiq}</td>
        {isClassScore==false?
    columnUniques.map((cuniq,i)=>{
      
      var score=""
      var classScore=""
      var distQuestion=""
      dataProficiency.map((dataa,i)=>{
        dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'
        if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
           score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
           classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
           distQuestion=dataa["TmHWQs.distCount"];
        }
      })
      if(score && distQuestion){
        return(
        <><td>{score}</td><td>{classScore}</td><td>{distQuestion}</td></>
        )
      }
      else{
        return(
        <><td></td><td></td><td></td></>
        )
      }
    })
  :
  columnUniques.map((cuniq,i)=>{
      
    var score=""
    // var classScore=""
    var distQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'
      if(runiq==dataa["TmHwQnAnsStat.chapterName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         score=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
        //  classScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         distQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(score && distQuestion){
      return(
      <><td>{score}</td><td>{distQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td></>
      )
    }
  })}
    </tr>
    )
  })
:
aggregatedByParam=="domain"?
rowUniques.map((runiq,i)=> {
  return(    
    <tr><td>{runiq}</td>
            {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
    var dscore=""
    var dclassScore=""
    var ddistQuestion=""
    dataProficiency.map((dataa,i)=>{
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         ddistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(dscore && ddistQuestion){
      return(
      <><td>{dscore}</td><td>{dclassScore}</td><td>{ddistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
  var dscore=""
  // var dclassScore=""
  var ddistQuestion=""
  dataProficiency.map((dataa,i)=>{
    dataa["TmHwQnAnsStat.averageScore"] >= 71?
    strokeColor = '#92a055'
  :
  dataa["TmHwQnAnsStat.averageScore"] >= 50?
    strokeColor = '#dc8147'
  :
    strokeColor = '#9e3433'

    dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
    strokeColor1 = '#92a055'
  :
  dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
    strokeColor1 = '#dc8147'
  :
    strokeColor1 = '#9e3433'

    if(runiq==dataa["TmHwQnAnsStat.domain"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
       dscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
      //  dclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
       ddistQuestion=dataa["TmHWQs.distCount"];
    }
  })
  if(dscore && ddistQuestion){
    return(
    <><td>{dscore}</td><td>{ddistQuestion}</td></>
    )
  }
  else{
    return(
    <><td></td><td></td></>
    )
  }
})}
  </tr>
  )
})
:
rowUniques.map((runiq,i)=> {
  return(    
    
    <tr>
      {oldChapt!=runiq[0]?
      <>
      <td rowspan={rowSpan[runiq[0]]}>{runiq[0]}</td>
    <td>{runiq[1]}</td>
    </>
    :
    <>
    {/* <td></td> */}
    <td>{runiq[1]}</td>
    </>
      }
                  {isClassScore==false?
  columnUniques.map((cuniq,i)=>{
   
        oldChapt=runiq[0]
    var tscore=""
    var tclassScore=""
    var tdistQuestion=""
    dataProficiency.map((dataa,i)=>{
      
      dataa["TmHwQnAnsStat.averageScore"] >= 71?
      strokeColor = '#92a055'
    :
    dataa["TmHwQnAnsStat.averageScore"] >= 50?
      strokeColor = '#dc8147'
    :
      strokeColor = '#9e3433'

      dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
      strokeColor1 = '#92a055'
    :
    dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
      strokeColor1 = '#dc8147'
    :
      strokeColor1 = '#9e3433'

      if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
         tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
         tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
         tdistQuestion=dataa["TmHWQs.distCount"];
      }
    })
    if(tscore && tdistQuestion){
      return(
      <><td>{tscore}</td><td>{tclassScore}</td><td>{tdistQuestion}</td></>
      )
    }
    else{
      return(
      <><td></td><td></td><td></td></>
      )
    }
  })
:
columnUniques.map((cuniq,i)=>{
   
  oldChapt=runiq[0]
var tscore=""
// var tclassScore=""
var tdistQuestion=""
dataProficiency.map((dataa,i)=>{

dataa["TmHwQnAnsStat.averageScore"] >= 71?
strokeColor = '#92a055'
:
dataa["TmHwQnAnsStat.averageScore"] >= 50?
strokeColor = '#dc8147'
:
strokeColor = '#9e3433'

dataa["AssesmentStatisticsQuestions.averageScore"] >= 71?
strokeColor1 = '#92a055'
:
dataa["AssesmentStatisticsQuestions.averageScore"] >= 50?
strokeColor1 = '#dc8147'
:
strokeColor1 = '#9e3433'

if(runiq[0]==dataa["TmHwQnAnsStat.chapterName"] && runiq[1]==dataa["TmHwQnAnsStat.topicName"] && cuniq[0]==dataa["TmHwQnAnsStat.skillStd"]){
   tscore=<Progress type="circle" percent={Math.round(dataa["TmHwQnAnsStat.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor}/>;
  //  tclassScore=<Progress type="circle" percent={Math.round(dataa["AssesmentStatisticsQuestions.averageScore"])} format={percent => `${percent}  %`} strokeColor={strokeColor1}/>;
   tdistQuestion=dataa["TmHWQs.distCount"];
}
})
if(tscore && tdistQuestion){
return(
<><td>{tscore}</td><td>{tdistQuestion}</td></>
)
}
else{
return(
<><td></td><td></td></>
)
}
})}
  </tr>
  )

})

}

  {/* ) */}

  </table>
  },
}

function dateFormat(comDate) {
  var completion = [];
  comDate.map((data, i) => {
    if (data["TmStudentHomeworks.completionDate"] != null) {
     var date= moment(data["TmStudentHomeworks.completionDate"]).format("DD-MM-YYYY")
      // alert("ananti")
      // var date=data["TmStudentHomeworks.completionDate"];
      data["TmStudentHomeworks.completionDate"] = date;
    }
    completion.push(data)
  })
  return completion;
}

function addKey(studentMailList) {
  //var percentData = [];
  studentMailList.map((data, i) => {
    data['key'] = i
  });
  return studentMailList;
}

function rearrange(data) {
  console.log(data,"datadata")
  const point = data[1]
  const Dataa = data
  data[1] = Dataa[2]
  data[2] = Dataa[3]
  data[3] = point
  return data
}

  function addPercentage(percent) {
  var percentData = [];

  percent.map((data, i) => {
    if (data["TmHwQnAnsStat.averageScore"] != null) {
      data["TmHwQnAnsStat.averageScore"] = data["TmHwQnAnsStat.averageScore"] + "%"
    }
    if (data["Class.average"] != null) {
      data["Class.average"] = data["Class.average"] + "%"
    }
    if (data["TmSingleHwstudStatistics.percentageString2"] != null) {
      var dataValue = data["TmSingleHwstudStatistics.percentageString2"];
      if (dataValue >= 71) {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='green'> {data["TmSingleHwstudStatistics.percentageString2"] + "%"} </Wrapper>
      }
      else if (dataValue >= 50) {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='orange'> {data["TmSingleHwstudStatistics.percentageString2"] + "%"} </Wrapper>
      }

      else if (data['TmSingleHwstudStatistics.statusOrder'] == '0') {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='grey'> NA  {/* data["TmStudentHomeworks.percentage"] + "%" */} </Wrapper>
      }


      else {
        data["TmSingleHwstudStatistics.percentageString2"] = <Wrapper type='yellow'> {data["TmSingleHwstudStatistics.percentageString2"] + "%"} </Wrapper>

      }
    }
    percentData.push(data)
  })
  return percentData;
}

// function assignDetail(individual,parameter,pivotConfig){
//   var res=[];
//   individual.map((ind, i) => {
//     pivotConfig.map((pivot, i) => {
//       if (ind["TmStudentCurrent.userId"]==pivot["TmStudentCurrent.userId"]) {
//         ind["TmStudentCurrent.completedAsg"]=pivot["TmStudentCurrent.completedAsg"]
//         ind["TmStudentCurrent.unattemptedAsg"]=pivot["TmStudentCurrent.unattemptedAsg"]
//         ind["TmStudentCurrent.studentAvg"]=pivot["TmStudentCurrent.studentAvg"]

//       }
//   res.push(ind)
//     })
// })
// return res;
// }

function individualAssignment(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    if (data["TmSchoolHomework.teacher"] != null) {
      // data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/
      // ${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].aterange[1]).format("YYYY-MM-DD")}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
          data["TmSchoolHomework.teacher"] = <Link to={`/assignment-teacher/0/${parameter[0].School}/${parameter[1].Class[0]}/0/${moment(parameter[2].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[2].Daterange[1]).format("YYYY-MM-DD")}/all/chapter/137571`}>{data["TmSchoolHomework.teacher"]}</Link>
    }
    linkData.push(data)
  })
  return linkData;
  }

function individualAssignAll(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    if (data["TmSchoolHomework.assignmentName"] != null) {
      // data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/
      // ${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].aterange[1]).format("YYYY-MM-DD")}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      if (data["AssessmentStatistics.average_Score"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/individual-assignment/${data['TmSchoolHomework.assignmentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/individual-assignment/${data['TmSchoolHomework.assignmentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      else{
          data["TmSchoolHomework.assignmentName"] = <Link to={`/individual-assignment/${data['TmSchoolHomework.assignmentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmSchoolHomework.groupId"]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
    }
    linkData.push(data)
  })
  return linkData;
  }

function individualStudent(individual,parameter) {

 
  var linkData = [];
  individual.map((data, i) => {
    if (data["TmStudentMock.tmStmktkStudentName"] != null) {
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmStudentMock.tmStmktkStudentName"] = <Link to={`/progress-report/${data['TmStudentMock.tmStmktkStudentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}`}>
        {data["TmStudentMock.tmStmktkStudentName"]} </Link>
      :
      data["TmStudentMock.tmStmktkStudentName"] = <Link to={`/progress-report/${data['TmStudentMock.tmStmktkStudentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}`}>
      {data["TmStudentMock.tmStmktkStudentName"]} </Link>
    }

    // alert(groupId)
    if (data["TmSingleHwstudStatistics.studentName"] != null) {
      if (data["TmSingleHwstudStatistics.Result"]) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/1`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
      }
      if (data["TmSingleHwstudStatistics.Diag_Student_Result"]) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/2`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
      }
      else{
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
    }
  }
    if (data["AdaptiveStudentReport.Student_Name"] != null) {
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["AdaptiveStudentReport.Student_Name"] = <Link to={`/student/${data['AdaptiveStudentReport.Student_Id']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>
        {data["AdaptiveStudentReport.Student_Name"]} </Link>
      :
      data["AdaptiveStudentReport.Student_Name"] = <Link to={`/student/${data['AdaptiveStudentReport.Student_Id']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/1`}>
      {data["AdaptiveStudentReport.Student_Name"]} </Link>
    }
   
    if (data["TmStudent.fullName"] != null) {
     //  data["TmStudent.fullName"] = <Link to={`/student/${data['TmStudent.fullName']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group[0]}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}`}>{data["TmStudent.fullName"]}</Link>
    }

    if (data["TmSchoolHomework.assignmentName"] != null) {
      // data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment/${data['TmSchoolHomework.assigId']}/
      // ${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].aterange[1]).format("YYYY-MM-DD")}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      if (data["TmSingleHwstudStatistics.Result"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      if (data["TmSingleHwstudStatistics.Overall_Diag_Result"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      if (data["TmSchoolHomework.Diag_ClassScore"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
        // groupId!="0"?
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
        :
        data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/2`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }
      else{
          data["TmSchoolHomework.assignmentName"] = <Link to={`/assignment-teacher/${data['TmSchoolHomework.assigId']}/${parameter[0].School}/${parameter[1].Class[0]}/${data["TmHomeworkgroup.groupId"]}/0/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>{data["TmSchoolHomework.assignmentName"]}</Link>
      }

    }

    /* *************** Chapter Report ************** */
     //console.log(parameter);
     if(parameter[0].School != null) {
      //console.log("parametersssss",parameter);
      if (data["TmAllChapterList.categoryName"] != null) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date" && parameter[7].StudentPlan == "1"?
        // groupId!="0"?
          data["TmAllChapterList.categoryName"] = <Link to={`/student-attempts/${data['TmAllChapterList.categoryId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${parameter[3]?.Daterange[0].format("YYYY-MM-DD")}/${parameter[3]?.Daterange[1].format("YYYY-MM-DD")}`}>
          {data["TmAllChapterList.categoryName"]} </Link>
        :
        data["TmAllChapterList.categoryName"] = <Link to={`/student-attempts/${data['TmAllChapterList.categoryId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/`}>
        {data["TmAllChapterList.categoryName"]} </Link>
      }

      if (data["TmAllChapterListNoGroup.categoryName"] != null) {
        
   //     alert
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date" && parameter[7].StudentPlan == "1"?
        // groupId!="0"?
          data["TmAllChapterListNoGroup.categoryName"] = <Link to={`/student-attempts/${data['TmAllChapterListNoGroup.categoryId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${parameter[3]?.Daterange[0].format("YYYY-MM-DD")}/${parameter[3]?.Daterange[1].format("YYYY-MM-DD")}`}>
          {data["TmAllChapterListNoGroup.categoryName"]} </Link>
        :
        data["TmAllChapterListNoGroup.categoryName"] = <Link to={`/student-attempts/${data['TmAllChapterListNoGroup.categoryId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}`}>
        {data["TmAllChapterListNoGroup.categoryName"]} </Link>
      }
  
    }

 
    /* *************** Chapter Report ************** */
    
    /* *************** Student Attempt Report ************** */
    if (data["TmChapterOverview.exceedExpectationChapter"] != null) {
          var start_date = new Date(parameter[7].Datebetween[0]);
          (start_date != "Invalid Date") ?
          // groupId!="0"?
            data["TmChapterOverview.exceedExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
            {data["TmChapterOverview.exceedExpectationChapter"]} </Link>
          :
          data["TmChapterOverview.exceedExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2]?.Group[0]}`}>
          {data["TmChapterOverview.exceedExpectationChapter"]} </Link>
    }

    if (data["TmChapterOverview.meetExpectationChapter"] != null) {
          var start_date = new Date(parameter[7].Datebetween[0]);
            (start_date != "Invalid Date") ?
          // groupId!="0"?
            data["TmChapterOverview.meetExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
            {data["TmChapterOverview.meetExpectationChapter"]} </Link>
          :
          data["TmChapterOverview.meetExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2]?.Group[0]}`}>
          {data["TmChapterOverview.meetExpectationChapter"]} </Link>
    }

    if (data["TmChapterOverview.partialExpectationChapter"] != null) {
          var start_date = new Date(parameter[7].Datebetween[0]);
            (start_date != "Invalid Date") ?
          // groupId!="0"?
            data["TmChapterOverview.partialExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
            {data["TmChapterOverview.partialExpectationChapter"]} </Link>
          :
          data["TmChapterOverview.partialExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2]?.Group[0]}`}>
          {data["TmChapterOverview.partialExpectationChapter"]} </Link>
    }

    if (data["TmChapterOverview.needExpectationChapter"] != null) {
        var start_date = new Date(parameter[7].Datebetween[0]);
          (start_date != "Invalid Date") ?
        // groupId!="0"?
          data["TmChapterOverview.needExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
          {data["TmChapterOverview.needExpectationChapter"]} </Link>
        :
        data["TmChapterOverview.needExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverview.studentId']}/${parameter[0].School}/${data['TmChapterOverview.gradeId']}/${parameter[2]?.Group[0]}`}>
        {data["TmChapterOverview.needExpectationChapter"]} </Link>
    }



    /* ************* No group chapter overview************* */
    if (data["TmChapterOverviewNoGrp.exceedExpectationChapter"] != null) {
          var start_date = new Date(parameter[7].Datebetween[0]);
          (start_date != "Invalid Date") ?
          // groupId!="0"?
            data["TmChapterOverviewNoGrp.exceedExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
            {data["TmChapterOverviewNoGrp.exceedExpectationChapter"]} </Link>
          :
          data["TmChapterOverviewNoGrp.exceedExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2]?.Group[0]}`}>
          {data["TmChapterOverviewNoGrp.exceedExpectationChapter"]} </Link>
    }

    if (data["TmChapterOverviewNoGrp.meetExpectationChapter"] != null) {
          var start_date = new Date(parameter[7].Datebetween[0]);
            (start_date != "Invalid Date") ?
          // groupId!="0"?
            data["TmChapterOverviewNoGrp.meetExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
            {data["TmChapterOverviewNoGrp.meetExpectationChapter"]} </Link>
          :
          data["TmChapterOverviewNoGrp.meetExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2]?.Group[0]}`}>
          {data["TmChapterOverviewNoGrp.meetExpectationChapter"]} </Link>
    }

    if (data["TmChapterOverviewNoGrp.partialExpectationChapter"] != null) {
          var start_date = new Date(parameter[7].Datebetween[0]);
            (start_date != "Invalid Date") ?
          // groupId!="0"?
            data["TmChapterOverviewNoGrp.partialExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
            {data["TmChapterOverviewNoGrp.partialExpectationChapter"]} </Link>
          :
          data["TmChapterOverviewNoGrp.partialExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2]?.Group[0]}`}>
          {data["TmChapterOverviewNoGrp.partialExpectationChapter"]} </Link>
    }

    if (data["TmChapterOverviewNoGrp.needExpectationChapter"] != null) {
        var start_date = new Date(parameter[7].Datebetween[0]);
          (start_date != "Invalid Date") ?
        // groupId!="0"?
          data["TmChapterOverviewNoGrp.needExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
          {data["TmChapterOverviewNoGrp.needExpectationChapter"]} </Link>
        :
        data["TmChapterOverviewNoGrp.needExpectationChapter"] = <Link to={`/progress-report/${data['TmChapterOverviewNoGrp.studentId']}/${parameter[0].School}/${data['TmChapterOverviewNoGrp.gradeId']}/${parameter[2]?.Group[0]}`}>
        {data["TmChapterOverviewNoGrp.needExpectationChapter"]} </Link>
    }

    /* *************** Student Attempt Report ************** */


     /* *************** Student Overview Report ************** */
     if (data["TmStudentOverview.tmStmktkStudentName"] != null) {
     
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date" && parameter[7].StudentPlan == "1"?
      // groupId!="0"?
        data["TmStudentOverview.tmStmktkStudentName"] = <Link to={`/progress-report/${data['TmStudentOverview.tmStmktkStudentId']}/${parameter[0].School}/${data['TmStudentOverview.tmStmktkStandardId']}/${parameter[2].Group}/${parameter[3]?.Daterange[0].format("YYYY-MM-DD")}/${parameter[3]?.Daterange[1].format("YYYY-MM-DD")}`}>
        {data["TmStudentOverview.tmStmktkStudentName"]} </Link>
      :
      data["TmStudentOverview.tmStmktkStudentName"] = <Link to={`/progress-report/${data['TmStudentOverview.tmStmktkStudentId']}/${parameter[0].School}/${data['TmStudentOverview.tmStmktkStandardId']}/${parameter[2]?.Group[0]}`}>
      {data["TmStudentOverview.tmStmktkStudentName"]} </Link>
    }

    if (data["TmStudentOverviewNoGroup.tmStmktkStudentName"] != null) {
     
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"  && parameter[7].StudentPlan == "1"?
      // groupId!="0"?
        data["TmStudentOverviewNoGroup.tmStmktkStudentName"] = <Link to={`/progress-report/${data['TmStudentOverviewNoGroup.tmStmktkStudentId']}/${parameter[0].School}/${data['TmStudentOverviewNoGroup.tmStmktkStandardId']}/${parameter[2].Group}/${parameter[3]?.Daterange[0].format("YYYY-MM-DD")}/${parameter[3]?.Daterange[1].format("YYYY-MM-DD")}`}>
        {data["TmStudentOverviewNoGroup.tmStmktkStudentName"]} </Link>
      :
      data["TmStudentOverviewNoGroup.tmStmktkStudentName"] = <Link to={`/progress-report/${data['TmStudentOverviewNoGroup.tmStmktkStudentId']}/${parameter[0].School}/${data['TmStudentOverviewNoGroup.tmStmktkStandardId']}/${parameter[2]?.Group[0]}`}>
      {data["TmStudentOverviewNoGroup.tmStmktkStudentName"]} </Link>
    }
    /* *************** Student Overview Report ************** */
   // alert('sssss');
   
    /* *************** Intro Student Overview Report ************** */
    if (data["TmIntroAttempts.studentName"] != null) {
      //alert('dddddddd');
      //console.log("aaaaaaaaaaaa",parameter[7].Datebetween[0]);

      var start_date = new Date(parameter[7].Datebetween[0]);
        (start_date != "Invalid Date") ?
      // groupId!="0"?
        data["TmIntroAttempts.studentName"] = <Link to={`/progress-report/${data['TmIntroAttempts.studentId']}/${parameter[0].School}/${data['TmIntroAttempts.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
        {data["TmIntroAttempts.studentName"]} </Link>
      :
      data["TmIntroAttempts.studentName"] = <Link to={`/progress-report/${data['TmIntroAttempts.studentId']}/${parameter[0]?.School}/${data['TmIntroAttempts.gradeId']}/${parameter[2]?.Group[0]}`}>
      {data["TmIntroAttempts.studentName"]} </Link>
    }
    if (data["TmIntroAttemptsNoGrp.studentName"] != null) {
      //alert('dddddddd');
      //console.log("aaaaaaaaaaaa",parameter[7].Datebetween[0]);

      var start_date = new Date(parameter[7].Datebetween[0]);
        (start_date != "Invalid Date") ?
      // groupId!="0"?
        data["TmIntroAttemptsNoGrp.studentName"] = <Link to={`/progress-report/${data['TmIntroAttemptsNoGrp.studentId']}/${parameter[0].School}/${data['TmIntroAttemptsNoGrp.gradeId']}/${parameter[2].Group}/${parameter[7].Datebetween[0]}/${parameter[7].Datebetween[1]}`}>
        {data["TmIntroAttemptsNoGrp.studentName"]} </Link>
      :
      data["TmIntroAttemptsNoGrp.studentName"] = <Link to={`/progress-report/${data['TmIntroAttemptsNoGrp.studentId']}/${parameter[0]?.School}/${data['TmIntroAttemptsNoGrp.gradeId']}/${parameter[2]?.Group[0]}`}>
      {data["TmIntroAttemptsNoGrp.studentName"]} </Link>
    }
    /* *************** Intro Student Overview Report ************** */

    linkData.push(data)
  })
  return linkData;
}

function individualStudentAdaptive(individual,parameter) {
  var linkData = [];
  individual.map((data, i) => {
    // alert(groupId)
    if (data["TmSingleHwstudStatistics.studentName"] != null) {
      if (data["TmSingleHwstudStatistics.Result"]) {
        (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}/1`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}/1`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
      }
      else{
      (moment(parameter[3]?.Daterange[0]).format("YYYY-MM-DD"))!="Invalid date"?
      // groupId!="0"?
        data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0].School}/${parameter[1].Class[0]}/${parameter[2].Group}/${moment(parameter[3].Daterange[0]).format("YYYY-MM-DD")}/${moment(parameter[3].Daterange[1]).format("YYYY-MM-DD")}/${parameter[4].Assignment[0]}/${parameter[5].ViewBy}/${parameter[6].AggregatedBy}`}>
        {data["TmSingleHwstudStatistics.studentName"]} </Link>
      :
      data["TmSingleHwstudStatistics.studentName"] = <Link to={`/student/${data['TmSingleHwstudStatistics.studentId']}/${parameter[0]?.School}/${parameter[1]?.Class[0]}/${parameter[2]?.Group[0]}/${parameter[4]?.Assignment[0]}/${parameter[5].ViewBy[0]}/${parameter[6]?.AggregatedBy[0]}`}>
      {data["TmSingleHwstudStatistics.studentName"]} </Link>
    }
  }

    linkData.push(data)
  })
  return linkData;
}

// function getGroup(){

//   const groupListOnLoad = [];
//   let access_token = cookie?.get('token'); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    
//     axios.get(`${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmStudentCurrent.groupId"],"timeDimensions":[],"order":[["TmStudentCurrent.groupId","asc"]],"measures":[],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${schoolId}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${classId}"]},{
//       "member": "TmStudentCurrent.userId","operator": "equals","values": ["${studentId}"]}]} `,
//       { 
//       headers: {
//           'Authorization': `${access_token}`
//         }
//       })
//         .then(res => {
          
//           const groupList = res.data.data;
//           groupList && groupList.map((x) => {
//             groupListOnLoad.push((x)["TmStudentCurrent.groupId"]);
//           })
//           return groupListOnLoad[0];
//       }).
//       catch(error => {
//       });
        
//     }
// }

// function hideLastColumn() {
//   return "tbl"
// }

function increaseWidth(column) {
  if (column.length >= 10) {
    return "assign_name"
  }
  if(column[1]["key"]=="TmSingleHwstudStatistics.percentageString2"){
    return "percentage_column_width"
  }
}

function increaseWidthHideColumn(column) {
  if (column.length >= 10) {
    return "assign_name"
  }
  if(column[1]["key"]=="TmSingleHwstudStatistics.percentageString2"){
    return "percentage_column_width"
  }
  // return "tbl"

}

function ascending(data) {
  let dataResult = [];
    data.map((dat,i)=>{
      if(dat['title'].includes('Qn ')){
        dataResult.push(dat['title'])
      }
    })  
  let result = dataResult.sort()
  let tableResult = [data[0],data[1]];
  result.map((res,j) => {
    data.map((dataa,i)=>{
      if(res == dataa["title"]){
        tableResult.push(dataa)
      }
    })
  })
  return tableResult
}

function reduceWidth(column) {
  //column.map((col, i) => {
  if (column.length === 3) {
    return "column_overall_chap"
  }
  if (column.length === 4) {
    return "column_overall_topic"
  }
  if (column.length === 5) {
    return "column_prof_chap"
  }
  if (column.length === 6) {
    return "column_prof_topic"
  }
}
function reduceColumn(column) {
  //column.map((col, i) => {
  if (column.length === 4) {
    return "indi_column_chap"
  }
  if (column.length === 5) {
    return "indi_column_topic"
  }
}

var rowData = [];
// function removeRepeat(data, input) {
//   var chapt = [];
//   data.map((dataa, i) => {   
//     var check = (chapt.indexOf(dataa[input]) > -1);
//     if (check) {
//       dataa[input] = "";
//     } else {
//       chapt.push(dataa[input]);
//     }
//     rowData.push(dataa);
//   })
//   return rowData;
// }




// function removeRepeat(data, input) {
//   rowData = [];
//   var chapt = [];
//   data.map((dataa, i) => {
//     // dataa[input1]=dataa[input1].append(dataa[input2])
//     // dataa[input2]=""
//     var check = (chapt.indexOf(dataa[input]) > -1);
//     if (check) {
//       dataa[input] = "";
//     } else {
//       chapt.push(dataa[input]);
//     }
//     rowData.push(dataa);
//   })
//   return rowData;
// }



function removeRepeat(data, input, domain, topic, parameter) {
  let data1=data;
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.domain"]])
          column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
          column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmHwQnAnsStat.chapterName"]])
          column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.domain"]])
              column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
              column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmHwQnAnsStat.chapterName"]])
              column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
            })
            viewby.push("Chapter")
          }
        // }

        // if(parameter=="skill"){
        //   if(data[0]?.hasOwnProperty(domain)){
        //         // alert("domain")
        //         viewby.push("Domain","Student Score","Distinct Questions")
        //         data.map((dataa,i)=>{
        //           row.push([dataa["TmHwQnAnsStat.domain"]])
        //           column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        //         })
        //       }
        //       else if(data[0]?.hasOwnProperty(topic)){
        //           // alert("topic")
        //         data.map((dataa,i)=>{
        //           row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
        //           column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        //         })
        //         // alert("true")
        //         viewby.push("Chapter","Topic","Student Score","Distinct Questions")
        //       }
        //       else{
        //         data.map((dataa,i)=>{
        //           // alert("chapter")
        //           row.push([dataa["TmHwQnAnsStat.chapterName"]])
        //           column.push([dataa["TmHwQnAnsStat.proficiency"],dataa["TmDifficulty.proficiency"]])
        //         })
        //         viewby.push("Chapter","Student Score","Distinct Questions")
        //       }
        //     }



          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });

      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    domainData.push(data[i])
    for(var j = 0, ln = columnUniques.length; j < ln; j++) {
      // alert("2st")
      if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
        // alert("3rd")
        // data.push(data["order"] = uniques1[j][3])
        data[i].order=columnUniques[j][2]

      }
    }
  }

  dataProficiency=[];

dataProficiency = data;



  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));





  //   rowData = [];
  // let rowDataChapt = [];
  // var dmain = [];
  // var chapt = [];
  // data.map((dataa, i) => {
  //   // dataa[input1]=dataa[input1].append(dataa[input2])
  //   // dataa[input2]=""
  //   var check = (dmain.indexOf(dataa[domain]) > -1);
  //   var checkChap = (chapt.indexOf(dataa[chapt]) > -1);
  //   if (check) {
  //     dataa[domain] = "";
  //   } else {
  //     dmain.push(dataa[domain]);
  //   }
  //   if (checkChap) {
  //     dataa[chapt] = "";
  //   } else {
  //     chapt.push(dataa[chapt]);
  //   }
  //   rowData.push(dataa);
  //   rowDataChapt.push(dataa);
  // })
  // return rowData, rowDataChapt;
}

function removeRepeatSkill(data, input, domain, topic, parameter) {
  // alert("skill")
  let data1=data;
  let row=[];
      let column=[];
    
      if(parameter=="all"){
  if(data[0]?.hasOwnProperty(domain)){
        // alert("domain")
        viewby.push("Domain","Student Score","Distinct Questions")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.domain"]])
          column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
        })
      }
      else if(data[0]?.hasOwnProperty(topic)){
          // alert("topic")
        data.map((dataa,i)=>{
          row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
          column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
        })
        // alert("true")
        viewby.push("Chapter","Topic","Student Score","Distinct Questions")
      }
      else{
        data.map((dataa,i)=>{
          // alert("chapter")
          row.push([dataa["TmHwQnAnsStat.chapterName"]])
          column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
        })
        viewby.push("Chapter","Student Score","Distinct Questions")
      }
    }

    // if(parameter=="proficiency"){
      if(data[0]?.hasOwnProperty(domain)){
            // alert("domain")
            viewby.push("Domain")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.domain"]])
              column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
            })
          }
          else if(data[0]?.hasOwnProperty(topic)){
              // alert("topic")
            data.map((dataa,i)=>{
              row.push([dataa["TmHwQnAnsStat.chapterName"],dataa["TmHwQnAnsStat.topicName"]])
              column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
            })
            // alert("true")
            viewby.push("Chapter","Topic")
          }
          else{
            data.map((dataa,i)=>{
              // alert("chapter")
              row.push([dataa["TmHwQnAnsStat.chapterName"]])
              column.push([dataa["TmHwQnAnsStat.skillStd"],dataa["TmHwQnAnsStat.skillName"]])
            })
            viewby.push("Chapter")
          }
      



          // let proficiencyIdName=[];
   
    // ---------------chapter topic ------------
    // var uniques = [];
      var itemsFound = {};
      for(var i = 0, l = row.length; i < l; i++) {
          var stringified = JSON.stringify(row[i]);
          if(itemsFound[stringified]) { continue; }
          rowUniques.push(row[i]);
          itemsFound[stringified] = true;
      }
  
    // ---------------proficiency id name ------------
      // var uniques1 = [];
      var itemsFound1 = {};
      for(var i = 0, l = column.length; i < l; i++) {
          var stringified1 = JSON.stringify(column[i]);
          if(itemsFound1[stringified1]) { continue; }
          columnUniques.push(column[i]);
          itemsFound1[stringified1] = true;
      }
      // uniques.sort((a, b) => a[0].localeCompare(b[0]));
      // uniques1.sort((a, b) => a[0].localeCompare(b[0]));

      // var items = ['réservé', 'premier', 'communiqué', 'café', 'adieu', 'éclair'];
      rowUniques.sort(function (a, b) {
        return a.toString().localeCompare(b); //using String.prototype.localCompare()
      });
      rowSpan={};
      rowUniques.map((rowUniqs,i)=>{
        if((rowUniqs[0] in rowSpan) == true){
          rowSpan[rowUniqs[0]]=rowSpan[rowUniqs[0]]+1
        }
        else{
          rowSpan[rowUniqs[0]]=1
        }
      })

      
    //   rowUniques = rowUniques.sort(function(a,b) {
    //     return a[0] - b[0];
    // });
  
    columnUniques = columnUniques.sort(function(a,b) {
      return a[0] - b[0];
  });
  
  
  for(var i = 0, l = columnUniques.length; i < l; i++) {
    columnUniques[i].push(i)
  }
  for(var i = 0, l = data.length; i < l; i++) {
    // alert("1st")
    domainData.push(data[i])
    // for(var j = 0, ln = columnUniques.length; j < ln; j++) {
    //   // alert("2st")
    //   if(data[i]["TmHwQnAnsStat.proficiency"]==columnUniques[j][0]){
    //     // alert("3rd")
    //     // data.push(data["order"] = uniques1[j][3])
    //     data[i].order=columnUniques[j][2]

    //   }
    // }
  }

  dataProficiency=[];

dataProficiency = data;



  let uniqueChapters = Array.from(new Set(row));
  let uniqueProficiency = Array.from(new Set(column));



}

function removeRepeatt(data, input, domain) {
  rowData = [];
  let rowDataChapt = [];
  var dmain = [];
  var chapt = [];
  data.map((dataa, i) => {
    // dataa[input1]=dataa[input1].append(dataa[input2])
    // dataa[input2]=""
    var check = (dmain.indexOf(dataa[domain]) > -1);
    var checkChap = (chapt.indexOf(dataa[input]) > -1);
    if (check) {
      dataa[domain] = "";
    } else {
      dmain.push(dataa[domain]);
    }
    if (checkChap) {
      // dataa[i-1] = "";
      rowData[i-1]["TmHwQnAnsStat.chapterName"]="";
      chapt.push(dataa[input]);
    } else {
      chapt.push(dataa[input]);
    }
    rowData.push(dataa);
    rowDataChapt.push(dataa);
  })
  return rowData, rowDataChapt;
  // return rowDataChapt;

}


function changeTitle(array) {
  var res = [];
  array.map((arr, i) => {

    if (arr.title == "Assignments Completed") {
      arr.title = "Ass. Completed"
    }

    if (arr.title == "Assignments Not Attempted") {
      arr.title = "Not Attempted"
    }
    if (arr.title == "Student Score") {
      arr.title = "Student Average"
    }
    if (arr.title == "Class Score") {
      arr.title = "Class Average"
    }
    if (arr.title == "Distinct Questions") {
      arr.title = "No Of Questions"
    }
    res.push(arr)
  })
  return res
}

function changeColumn(array) {
  var res = [];
  array.map((arr, i) => {

    if (arr.title == "  stu Student Email") {
      arr.title = "Student Email"
    }
    if (arr.title == "  Username"){
      arr.title = "Student Username"
    }

    
    res.push(arr)
  })
  return res
}
// function changeChildrenTitle(array) {
//   var res = [];
//   array.map((arr, i) => {
//   if(arr.key!="TmHwQnAnsStat.chapterName" &&arr.children ){
//     if (arr["children"][1]["title"] == "Distinct Questions") {
//       arr.children[1]["shortTitle"] = "#"
//     }
//   }
//     res.push(arr)
//   })
//   return res
// }

// function changeChildrenTitleIndividual(array) {
//   var res = [];
//   array.map((arr, i) => {
//   if(arr.key!="TmHwQnAnsStat.chapterName" &&arr.children ){
//     if (arr["children"][2]["shortTitle"] == "Distinct Questions ") {
//       arr.children[2]["shortTitle"] = "#"
//     }
//   }
//     res.push(arr)
//   })
//   return res
// }

function manipulateInput(array, jsonArray, resultSet) {

  let progressType = 'circle';

  // let arr1=array[1];
  // let arr2 = array[2];
  // let arr3 = array[3];
  // let arr4 = array[4];
  // let arr5 = array[5];
  // let arr6 = array[6];
  // let arr7 = array[7];

  if (resultSet.loadResponse.annotation?.dimensions['TmHwQnAnsStat.skillName']?.shortTitle != undefined || resultSet.loadResponse.annotation?.dimensions['TmHwQnAnsStat.proficiencyName']?.shortTitle != undefined) {
    progressType = 'circle';
    // if(array[1].key=="TmHwQnAnsStat.chapterName"){
    //   return width:100px;
    // }
    // if (array.length >= 8) {
    //   array[0] = array[0];
    //   array[1] = arr6;
    //   array[2] = arr1;
    //   array[3] = arr2;
    //   array[4] = arr3;
    //   array[5] = arr7;
    //   array[6] = arr4;
    //   array[7] = arr5;
    // }

  } else {
    progressType = '';
  }

  let i, key;
  var jsonArray = JSON.stringify(jsonArray);
  var jsonParsedArray = JSON.parse(jsonArray);
  var MaximumValue = 0;
  for (i = 0; i < jsonParsedArray.length; i++) {
    for (key in jsonParsedArray[i]) {
      if (jsonParsedArray[i].hasOwnProperty(key)) {
        if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1) && jsonParsedArray[i][key] > MaximumValue) {
          MaximumValue = jsonParsedArray[i][key];
        }

      }
    }
  }


  var res = [];
  {
    array.map((arr, i) => {
      arr.title = arr.shortTitle
      var key = arr.key;
      if (key) {

        if (key == 'TmHwQnAnsStat.averageScore') {
          arr.render = value => renderFunction(value, 'studentscore', value, '%', progressType)
        }
        else if (key.indexOf('.average') != -1 && key != 'TmHwQnAnsStat.averageScore') {
          arr.render = value => renderFunction(value, 'exception', value, '%', progressType)
        }

        else if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1)) {
          arr.render = value => renderFunction2(value) // renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
        }

        // else if ((key.indexOf('.noOfQuestions') != -1 || key.indexOf('.noOfDistQues') != -1)) {
        //   arr.render = value => renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
        // }
      }
      var chi = [];
      {
        arr.children &&
          arr.children.map((child, i) => {
            child.title = child.shortTitle;
            if (child.type == 'number' && child.format == 'percent') {
              child.render = value => renderFunction(value, 'exception', value, '%', progressType)
            }
            else
              if (child.type == 'number' && child.format == undefined) {
                child.render = value => renderFunction2(value) // => renderFunction(calculatePercentage(MaximumValue, value), 'active', value, ' ')
              }
            chi.push(child)
          })
        arr.children = chi;
      }
      res.push(arr)
    })
  }
  return res;
}

// function renderFunction(text, status, value, percentage, progressType) {
//   if (text) {
//     if(progressType=='circle') { 
//     return <Progress percent={text}  type='circle'  status={status} format={percent => `${Math.round(value)} ${percentage}`} />
//    } else {
//     return <Progress percent={text} status={status} format={percent => `${Math.round(value)} ${percentage}`} />
// }
//   } else {
//     return '-'
//   }
// }

function renderFunction2(text) {

  //var ValueDetail = '<p>'+ text  +"</p>";
  return <CenterWrapper type={text} />;

}

function renderFunction(text, status, value, percentage, progressType) {
  let strokeColor = '';
  let strokeColor2 = '';
  if (Math.round(value) >= 71) { // 71 - 100
    strokeColor = '#92a055';
  }
  else if (Math.round(value) >= 50) {  // 50 - 70  
    strokeColor = '#dc8147';
  }
  else {   // 1 - 49
    strokeColor = '#9e3433';
  }

  if (status == 'studentscore') {
    strokeColor2 = '#3B76AF';
  } else {
    strokeColor2 = '#b7bb5a';
  }

  // else {
  //   strokeColor = '#EFC761';
  // }

  if (text) {
    if (progressType == 'circle') {
      return <div className="textCenter"><Progress percent={text} type='circle' strokeColor={strokeColor} status={status} format={percent => `${Math.round(value)} ${percentage}`} /></div>
    } else {
      return <div className="textCenter"> <Progress percent={text} status={status} strokeColor={strokeColor2} format={percent => `${Math.round(value)} ${percentage}`} /></div>
    }
  } else {
    return <div className="textCenter">0</div>
    //return <Progress percent='0' status={status} strokeColor={strokeColor} format={percent => `${Math.round(value)} ${percentage}`} />
  }
}



//  return <Progress percent={text} type="circle" status={status} format={percent => `${Math.round(value)} ${percentage}`} />


let calculatePercentage = (maxNumber, currentNumber) => Math.round((currentNumber / maxNumber) * 100);


const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map(key => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = Component => ({ resultSet, error }) => (pivotConfig) => (parameters) =>
  (resultSet && <Component resultSet={resultSet} pivotConfig={pivotConfig}  parameters={parameters}/>) ||
  (error && error.toString()) || <Spin />;

const ChartRenderer = ({ vizState }) => {
  const { query, chartType, pivotConfig, parameters } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  let history = useHistory();
  if (renderProps.error && renderProps.error == 'Error: Invalid token') {
    // alert(renderProps.error);
    history.push('/home');
  }
  return component && renderChart(component)(renderProps)(pivotConfig)(parameters);
};

ChartRenderer.propTypes = {
  vizState: PropTypes.object,
 // cubejsApi: PropTypes.object
};
ChartRenderer.defaultProps = {
  vizState: {},
  // cubejsApi: null
};
export default ChartRenderer;
