import React, { PureComponent } from "react";
import TreeMap from "react-d3-treemap";
import "react-d3-treemap/dist/react.d3.treemap.css";
import {
  Spin,
  Row,
  Col,
  Layout,
  Menu,
  Breadcrumb,
  Select,
  Button,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Space,
} from "antd";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ChartRenderer from "../components/ChartTeacher";
import ChartRendererCustomised from "../components/ChartTeacher";
import { Table, Divider, Input } from "antd";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import axios from "axios";
import cookie from "js-cookie";
import moment from "moment";
import Dashboard from "../components/Dashboard";
import DashboardItem from "../components/DashboardItem";
import "antd/dist/antd.css";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ReactToPrint from "react-to-print";
import { Redirect } from "react-router";
import {
  baseUrlCube,
  newReportUrl,
  getUser,
  authenticate,
  isAuth,
  signout,
} from "../components/helpers";
import {
  MailOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterFilled,
  SolutionOutlined,
  FileDoneOutlined,
  FundOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  FormOutlined,
  LeftCircleOutlined,
  LoginOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;

class CustomerDashboardPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginUser: "",
      display: "none",
      collapsed: true,
      loader: false,
      individualAssignment: [props?.match?.params?.assignmentId],
      individualSchool: [props?.match?.params?.schoolId],
      individualClass: [props?.match?.params?.standardId],
      individualGroup: [props?.match?.params?.groupIdName],
      individualAggregated: [props?.match?.params?.aggregatedBy],
      individualviewBy: [props?.match?.params?.viewBy],
      schoolList: [],
      classList: [],
      groupList: [],
      assignmentList: [],
      dateRangeList: [
        moment().subtract(3, "months"),
        moment().startOf("second"),
      ],
      school: [""],
      selectedSchool: [],
      selectedSchoolID: "",
      selectedClassID: "",
      selectedGroupID: "",
      selectedAssignmentId: "",
      selectedClassValue: "",
      selectedGroupValue: "",
      selectedAssignmentValue: "",
      class: [""],
      selectedClass: [""],
      group: [""],
      selectedGroup: [""],
      selectedDateRange: ["", ""],
      selectedAssignment: [""],
      assignments: [""],
      assignmentsAll: [""],
      viewBy: ["all"],
      aggregateBy: ["chapter"],
      orderBy: [
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
      ],
      pivotConfig: {},
      ifAdaptive: 0,
      aggregateByAdaptive: ["domain"],
      teacherName: [],
    };
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  onChangeViweBy = (value) => {
    this.setState({ individualviewBy: "" });
    this.setState({
      viewBy: [value.value],
    });

    if (value.value == "proficiency") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.proficiencyName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.proficiencyName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }

    if (value.value == "skill") {
      this.setState({
        orderBy: [
          ["TmHwQnAnsStat.domain", "asc"],
          ["TmHwQnAnsStat.chapterName", "asc"],
          ["TmHwQnAnsStat.topicName", "asc"],
          ["TmHwQnAnsStat.skillName", "asc"],
        ],
      });

      this.setState({
        pivotConfig: {
          x: [
            "TmHwQnAnsStat.domain",
            "TmHwQnAnsStat.chapterName",
            "TmHwQnAnsStat.topicName",
          ],
          y: ["TmHwQnAnsStat.skillName", "measures"],
          fillMissingDates: true,
          joinDateRange: false,
        },
      });
    }
  };
  onChangeAggregateBy = (value) => {
    this.setState({ individualAggregated: "" });
    if (this.state.ifAdaptive == 1) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
    if (this.state.ifAdaptive == 2) {
      this.setState({
        aggregateBy: [value],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    } else {
      this.setState({
        aggregateBy: [value.toString()],
      });
      this.setState({
        aggregateByAdaptive: [value],
      });
    }
  };

  onChangeAssignment = (value) => {
    this.state.ifAdaptive = 0;
    if (value.value == "0") {
      this.setState({
        selectedAssignment: ["0"],
        selectedAssignmentValue: ["All"],
      });
    } else {
      this.setState({
        selectedAssignment: [value.value.toString()],
        selectedAssignmentValue: [value.label.toString()],
      });
      if (this.state.assignmentList?.length > 0) {
        this.state.assignmentList.map((alist, i) => {
          if (alist["TmSchoolHomework.assignmentId"] == value.value) {
            this.state.ifAdaptive = alist["TmSchoolHomework.if_adaptive"];
          }
        });
      }
      if (this.state.ifAdaptive == 1) {
        this.setState({ aggregateBy: "domain" });
        this.setState({ aggregateByAdaptive: "domain" });
      }
    }
    let access_token = cookie?.get("token");
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.totalTuCompleted","TmSchoolHomework.totalTuOpen","TmSchoolHomework.totalTuNotMarked"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
          "YYYY-MM-DD"
        )}","${this.state.dateRangeList[1].format(
          "YYYY-MM-DD"
        )}"]}],"order":[["TmSchoolHomework.teacher","asc"]],"dimensions":["TmSchoolHomework.teacher"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
          this.state.selectedSchool
        }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
          this.state.selectedClass
        }"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        res.data.data.map((resData, i) => {
          this.setState({ teacherName: resData["TmSchoolHomework.teacher"] });
        });
      });
  };

  onChangeSchool = (option) => {
    this.state.ifAdaptive = 0;

    this.setState({
      selectedSchool: [option.value.toString()],
      selectedSchoolID: option.value.toString(),
      selectedSchoolValue: option.children.toString(),
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${option.value}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const classList = res.data.data;

        this.onSchoolSelecteOnchange(res.data.data);
      })
      .catch((error) => {});
  };

  onSchoolSelecteOnchange = () => {
    let access_token = cookie?.get("token");

    /*************** Class Section  *************/
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${this.state.selectedSchool}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        this.setState({
          loader: true,
        });
        const classListtt = res.data.data;
        let classList = [];
        classListtt &&
          classListtt.map((x) => {
            if (x["TmStandard.standardId"] != null) {
              classList.push(x);
            }
          });
        let classId = classList[0]["TmStandard.className"];
        let className = classList[0]["TmStandard.className"];
        this.setState({ classList: classList });
        this.setState({
          selectedClassID: classId.toString(),
          selectedClassValue: className.toString(),
        });

        let classListOnLoad = [];
        classList &&
          classList.map((x) => {
            if (x["TmStandard.standardId"] != undefined) {
              classListOnLoad.push(x["TmStandard.standardId"]?.toString());
            }
          });
        this.setState({ selectedClass: [classListOnLoad[0]] });

        /*************** Group Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const groupList = res.data.data;

            this.setState({ groupList: groupList });
            const groupListOnLoad = [];
            groupList &&
              groupList.map((x) => {
                groupListOnLoad.push(
                  x["TmStudentCurrent.groupName"].toString()
                );
              });
            this.setState({
              selectedGroup: ["0"],
              selectedGroupID: ["0"],
              selectedGroupValue: ["All"],
            });
            // }
          })
          .catch((error) => {});
        /*************** Group Section  *************/

        /*************** Assignment Section  *************/

        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            const assignmentList = res.data.data;
            let assignmentId =
              assignmentList[0]["TmSchoolHomework.assignmentId"];
            let assignmentName =
              assignmentList[0]["TmSchoolHomework.assignmentName"];

            this.setState({ assignmentList: assignmentList });

            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            this.setState({
              selectedAssignment: [assignmentListOnLoad[0].toString()],
            });

            if (this.props?.match?.params?.AssignmentId) {
              this.setState({
                selectedAssignment: [
                  this.props?.match?.params?.AssignmentId.toString(),
                ],
                selectedAssignmentID: [
                  this.props?.match?.params?.AssignmentId.toString(),
                ],
                selectedAssignmentValue: [
                  this.props?.match?.params?.AssignmentValue.toString(),
                ],
              });
            } else {
              this.setState({
                selectedAssignment: ["0"],
                selectedAssignmentValue: ["All"],
              });
            }
            axios
              .get(
                `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.totalTuCompleted","TmSchoolHomework.totalTuOpen","TmSchoolHomework.totalTuNotMarked"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                  "YYYY-MM-DD"
                )}","${this.state.dateRangeList[1].format(
                  "YYYY-MM-DD"
                )}"]}],"order":[["TmSchoolHomework.teacher","asc"]],"dimensions":["TmSchoolHomework.teacher"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                  this.state.selectedSchool
                }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                  this.state.selectedClass
                }"]}]}`,
                {
                  headers: {
                    Authorization: `${access_token}`,
                  },
                }
              )
              .then((res) => {
                res.data.data.map((resData, i) => {
                  this.setState({
                    teacherName: resData["TmSchoolHomework.teacher"],
                  });
                });
              });
          })
          .catch((error) => {});

        /*************** Assignment Section  *************/
      })
      .catch((error) => {});

    /*************** Class Section  *************/
  };

  onChangeClass = (option) => {
    this.state.ifAdaptive = 0;
    this.state.selectedClass = [option.value.toString()];
    this.state.selectedClassValue = option.label.toString();
    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); // let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]} `,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const groupList = res.data.data;
        this.setState({ groupList: groupList });
        this.setState({
          selectedGroup: ["0"],
          selectedGroupId: ["0"],
          selectedGroupValue: ["0"],
        });
      })
      .catch((error) => {});
    /*************** Assignment Section  *************/
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
          "YYYY-MM-DD"
        )}","${this.state.dateRangeList[1].format(
          "YYYY-MM-DD"
        )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
          this.state.selectedSchool
        }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
          option.value
        }"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });

        const assignmentListOnLoad = [];
        assignmentList &&
          assignmentList.map((x) => {
            assignmentListOnLoad.push(
              x["TmSchoolHomework.assignmentId"].toString()
            );
          });
        this.setState({
          selectedAssignment: [assignmentListOnLoad[0].toString()],
        });

        if (this.props?.match?.params?.AssignmentId) {
          this.setState({
            selectedAssignment: [
              this.props?.match?.params?.AssignmentId.toString(),
            ],
            selectedAssignmentID: [
              this.props?.match?.params?.AssignmentId.toString(),
            ],
            selectedAssignmentValue: [
              this.props?.match?.params?.AssignmentValue.toString(),
            ],
          });
        } else {
          this.setState({
            selectedAssignment: ["0"],
            selectedAssignmentValue: ["All"],
          });
        }
        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.totalTuCompleted","TmSchoolHomework.totalTuOpen","TmSchoolHomework.totalTuNotMarked"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"order":[["TmSchoolHomework.teacher","asc"]],"dimensions":["TmSchoolHomework.teacher"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            res.data.data.map((resData, i) => {
              this.setState({
                teacherName: resData["TmSchoolHomework.teacher"],
              });
            });
          });
      })
      .catch((error) => {});

    /*************** Assignment Section  *************/
  };

  onChangeGroup = (option) => {
    this.state.ifAdaptive = 0;
    this.setState({
      selectedGroup: [option.value.toString()],
      selectedGroupValue: option.label.toString(),
    });
    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token");

    /*************** Assignment Section  *************/
    let assignmentListUrl = "";
    if (option.value != "0") {
      assignmentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
        "YYYY-MM-DD"
      )}","${this.state.dateRangeList[1].format(
        "YYYY-MM-DD"
      )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        option.value
      }"]}]}`;
    } else {
      assignmentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"order":[["TmSchoolHomework.assignmentName","asc"]],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
        "YYYY-MM-DD"
      )}","${this.state.dateRangeList[1].format(
        "YYYY-MM-DD"
      )}"]}],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"segments":["TmSchoolHomework.assgnClosed"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    }

    axios
      .get(`${assignmentListUrl}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });
        const assignmentListOnLoad = [];
        assignmentList &&
          assignmentList.map((x) => {
            assignmentListOnLoad.push(
              x["TmSchoolHomework.assignmentId"].toString()
            );
          });
        this.setState({
          selectedAssignment: [assignmentListOnLoad[0].toString()],
        });

        if (this.props?.match?.params?.AssignmentId) {
          this.setState({
            selectedAssignment: [
              this.props?.match?.params?.AssignmentId.toString(),
            ],
            selectedAssignmentID: [
              this.props?.match?.params?.AssignmentId.toString(),
            ],
            selectedAssignmentValue: [
              this.props?.match?.params?.AssignmentValue.toString(),
            ],
          });
        } else {
          this.setState({
            selectedAssignment: ["0"],
            selectedAssignmentValue: ["All"],
          });
        }
        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.totalTuCompleted","TmSchoolHomework.totalTuOpen","TmSchoolHomework.totalTuNotMarked"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"order":[["TmSchoolHomework.teacher","asc"]],"dimensions":["TmSchoolHomework.teacher"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            res.data.data.map((resData, i) => {
              this.setState({
                teacherName: resData["TmSchoolHomework.teacher"],
              });
            });
          });
      })
      .catch((error) => {});

    /*************** Assignment Section  *************/
  };
  onDateRangeChange_bk = (value, dateString) => {
    this.setState({
      dateRangeList: value,
    });

    let Query = {
      measures: ["TmSchoolGroups.classCount"],
      timeDimensions: [],
      order: { "TmSchoolGroups.classCount": "desc" },
      dimensions: ["TmSchool.schoolId", "TmSchool.schoolName"],
    };
    let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
          "YYYY-MM-DD"
        )}","${dateString[1].format(
          "YYYY-MM-DD"
        )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
          this.state.selectedSchool
        }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
          this.state.selectedClass
        }"]},{"member":"TmHomeworkgroup.groupId","operator":"equals","values":["${
          this.state.selectedGroup
        }"]}]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        const assignmentList = res.data.data;

        this.setState({ assignmentList: assignmentList });
      })
      .catch((error) => {});
  };

  onDateRangeChange = (value, dateString) => {
    this.state.ifAdaptive = 0;
    dateString = dateString?.toString().split(",");
    this.setState({ dateRangeList: value }); //[dateString.toString()]
    let groupQuery = "";
    if (dateString[0] != "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${
        this.state.selectedGroup
      }"]}]}`;
    } else if (dateString[0] != "" && this.state.selectedGroup == "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${value[0].format(
        "YYYY-MM-DD"
      )}","${value[1].format(
        "YYYY-MM-DD"
      )}"]}],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
        this.state.selectedSchool
      }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
        this.state.selectedClass
      }"]}]}`;
    } else if (dateString[0] == "" && this.state.selectedGroup != "0") {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]},{"member":"TmSchoolHomework.groupId","operator":"equals","values":["${this.state.selectedGroup}"]}]}`;
    } else {
      var dateRangeGroupAssignment = `cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"segments": ["TmSchoolHomework.assgnClosed"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate"}],"order":[[TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
    }
    let access_token = cookie?.get("token"); //let access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOjEsIlJvbGUiOiIxIn0.a44-3tvQJPdDPykJFW6N1aJKyaz3__Jju7UPV03dDLE';
    axios
      .get(`${baseUrlCube + dateRangeGroupAssignment}`, {
        headers: {
          Authorization: `${access_token}`,
        },
      })
      .then((res) => {
        const assignmentList = res.data.data;
        this.setState({ assignmentList: assignmentList });
        if (assignmentList.length > 0) {
          if (this.state.selectedGroup[0].toString() == "0") {
            this.setState({
              selectedAssignment: ["0"],
              selectedAssignmentValue: ["All"],
            });
          } else {
            const assignmentListOnLoad = [];
            assignmentList &&
              assignmentList.map((x) => {
                assignmentListOnLoad.push(
                  x["TmSchoolHomework.assignmentId"].toString()
                );
              });
            this.setState({
              selectedAssignment: ["0"],
              selectedAssignmentValue: ["All"],
            });
          }
        } else {
          this.setState({
            selectedAssignment: ["0"],
            selectedAssignmentValue: ["All"],
            assignmentList: null,
          });
        }
        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.totalTuCompleted","TmSchoolHomework.totalTuOpen","TmSchoolHomework.totalTuNotMarked"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
              "YYYY-MM-DD"
            )}","${this.state.dateRangeList[1].format(
              "YYYY-MM-DD"
            )}"]}],"order":[["TmSchoolHomework.teacher","asc"]],"dimensions":["TmSchoolHomework.teacher"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
              this.state.selectedSchool
            }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
              this.state.selectedClass
            }"]}]}`,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            res.data.data.map((resData, i) => {
              this.setState({
                teacherName: resData["TmSchoolHomework.teacher"],
              });
            });
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };

  componentDidMount() {
    let access_token = cookie?.get("token");
    this.setState({ loginUser: localStorage.getItem("username") });
    let isSchoolTrue = false;
    let isClassTrue = false;
    let isGroupTrue = false;
    let isAssignmentTrue = false;

    if (this.props?.match?.params?.sdateRange) {
      if (this.props?.match?.params?.sdateRange.toString() == "Invalid date") {
        this.setState({ dateRangeList: ["", ""] });
      } else {
        var inputstartdate = moment(this.props?.match?.params?.sdateRange);
        var inputenddate = moment(this.props?.match?.params?.edateRange);

        this.state.dateRangeList = [inputstartdate, inputenddate];
      }
    }

    if (this.props?.match?.params?.aggregatedBy) {
      this.state.aggregateBy = this.props?.match?.params?.aggregatedBy;
    }

    if (this.props?.match?.params?.viewBy) {
      this.state.viewBy = this.props?.match?.params?.viewBy;
    }
    if (this.props?.match?.params?.adaptive == 1) {
      this.state.ifAdaptive = 1;
    }

    if (this.props?.match?.params?.adaptive == 2) {
      this.state.ifAdaptive = 2;
    }

    axios
      .get(
        `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmSchool.count"],"timeDimensions": [],"order": [["TmSchool.schoolName","asc"]],"dimensions": ["TmSchool.schoolId","TmSchool.schoolName"]}`,
        {
          headers: {
            Authorization: `${access_token}`,
          },
        }
      )
      .then((res) => {
        isSchoolTrue = true;
        const schoolList = res.data.data;

        let schoolId = "";
        let schoolName = "";
        let schoolNameArray = [];

        if (this.props?.match?.params?.schoolId) {
          schoolId = this.props?.match?.params?.schoolId;
          schoolList &&
            schoolList.map((x) => {
              if (x["TmSchool.schoolId"] == schoolId) {
                schoolNameArray.push(x["TmSchool.schoolName"]);
              }
            });
          schoolName = schoolNameArray.toString();
        } else {
          schoolId = schoolList[0]["TmSchool.schoolId"];
          schoolName = schoolList[0]["TmSchool.schoolName"];
        }

        this.setState({
          schoolName: schoolName,
          selectedSchoolValue: schoolName,
        });

        this.setState({
          selectedSchool: [schoolId.toString()],
        });
        this.setState({ schoolList });
        if (this.props?.match?.params?.schoolId) {
          this.setState({
            selectedSchoolID: this.props?.match?.params?.schoolId.toString(),
          });
        } else {
          this.setState({
            selectedSchoolID: schoolId.toString(),
            selectedSchoolValue: schoolName.toString(),
          });
        }
        /*************** Class Section  *************/
        axios
          .get(
            `${baseUrlCube}cubejs-api/v1/load?query={"measures": ["TmStandard.count"],"timeDimensions": [],"order": [["TmStandard.standardId","asc"]],"dimensions": ["TmStandard.standardId","TmStandard.className"],"filters": [{"member": "TmSchoolHomework.schoolId","operator": "equals","values": ["${schoolId}"]}]} `,
            {
              headers: {
                Authorization: `${access_token}`,
              },
            }
          )
          .then((res) => {
            isClassTrue = true;
            const classListtt = res.data.data;
            let classList = [];
            classListtt &&
              classListtt.map((x) => {
                if (x["TmStandard.standardId"] != null) {
                  classList.push(x);
                }
              });

            this.setState({ classList: classList });

            let classListOnLoad = [];
            let classValue = [];
            classList &&
              classList.map((x) => {
                if (x["TmStandard.standardId"] != undefined) {
                  classListOnLoad.push(x["TmStandard.standardId"]?.toString());
                }
              });
            classList &&
              classList.map((x) => {
                if (
                  x["TmStandard.standardId"] == classListOnLoad[0].toString()
                ) {
                  classValue.push(x["TmStandard.className"]?.toString());
                }
              });
            this.setState({
              selectedClassValue: classValue[0].toString(),
            });

            if (this.props?.match?.params?.standardId) {
              let classId = this.props?.match?.params?.standardId.toString();
              let className = [];
              classList &&
                classList.map((x) => {
                  if (x["TmStandard.standardId"] == classId) {
                    className.push(x["TmStandard.className"]);
                  }
                });
              this.setState({
                selectedClassID: classId.toString(),
                selectedClassValue: className.toString(),
              });

              this.setState({
                selectedClass: [
                  this.props?.match?.params?.standardId.toString(),
                ],
                selectedClassId: classId,
                selectedClassValue: className,
              });
            } else {
              this.setState({ selectedClass: [classListOnLoad[0].toString()] });
            }
            /*************** Class Section  *************/
            /*************** Group Section  *************/

            let studentListUrl = "";

            if (
              this.props?.match?.params?.assignmentId &&
              this.props?.match?.params?.groupIdName == "0"
            ) {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"dimensions":["TmSchoolHomework.groupId","TmSchoolHomework.group"],"timeDimensions":[],"measures":[],"segments":[],"filters":[{"member":"TmSchoolHomework.assigId","operator":"equals","values":["${this.props?.match?.params?.assignmentId}"]}]}`;
            } else if (this.props?.match?.params?.standardId == "0") {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
            } else {
              studentListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":[],"timeDimensions":[],"order":[["TmStudentCurrent.groupName","asc"]],"dimensions":["TmStudentCurrent.groupId","TmStudentCurrent.groupName"],"segments":[],"filters":[{"member":"TmStudentCurrent.schoolId","operator":"equals","values":["${this.state.selectedSchool}"]},{"member":"TmStudentCurrent.standardId","operator":"equals","values":["${this.state.selectedClass}"]}]}`;
            }
            axios
              .get(`${studentListUrl}`, {
                headers: {
                  Authorization: `${access_token}`,
                },
              })
              .then((res) => {
                isGroupTrue = true;

                const groupList = res.data.data;

                this.setState({ groupList: groupList });
                const groupListOnLoad = [];
                groupList &&
                  groupList.map((x) => {
                    groupListOnLoad.push(
                      x["TmStudentCurrent.groupId"].toString()
                    );
                  });
                if (this.props?.match?.params?.groupIdName) {
                  if (this.props?.match?.params?.groupIdName == "0") {
                    let groupId = this.props?.match?.params?.groupIdName;
                    let groupName = [];
                    let groupIdName = [];
                    groupList &&
                      groupList.map((x) => {
                        if (x["TmStudentCurrent.groupId"] == groupId) {
                          groupName.push(x["TmStudentCurrent.groupName"]);
                        }
                      });
                    if (groupId == 0) {
                      groupName.push("ALL");
                      groupIdName.push("0");
                    }
                    this.setState({
                      selectedGroup: [groupListOnLoad[0]],
                      selectedGroupId: groupIdName,
                      selectedGroupValue: groupName,
                    });
                  } else if (this.props?.match?.params?.groupIdName != "0") {
                    let groupId = this.props?.match?.params?.groupIdName;
                    let groupName = [];
                    let groupIdName = [];
                    groupList &&
                      groupList.map((x) => {
                        if (x["TmStudentCurrent.groupId"] == groupId) {
                          groupName.push(x["TmStudentCurrent.groupName"]);
                        }
                      });
                    if (groupId == 0) {
                      groupName.push("ALL");
                      groupIdName.push("0");
                    }
                    this.setState({
                      selectedGroup: [this.props?.match?.params?.groupIdName],
                      selectedGroupId: groupIdName,
                      selectedGroupValue: groupName,
                    });
                  }
                } else {
                  this.setState({
                    selectedGroup: ["0"],
                    selectedGroupID: ["0"],
                    selectedGroupValue: ["All"],
                  });
                }

                /*************** Group Section  *************/
                /*************** Assignment Section  *************/
                let assignListUrl = "";
                if (
                  this.props?.match?.params?.studentId &&
                  (this.props?.match?.params?.groupIdName == "0" ||
                    this.props?.match?.params?.standardId == "0")
                ) {
                  assignListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                    "YYYY-MM-DD"
                  )}","${this.state.dateRangeList[1].format(
                    "YYYY-MM-DD"
                  )}"]}],"segments":["TmSchoolHomework.assgnClosed"],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                    this.state.selectedSchool
                  }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                    this.state.selectedClass
                  }"]}]}`;
                } else if (this.state.selectedGroup == "0") {
                  assignListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                    "YYYY-MM-DD"
                  )}","${this.state.dateRangeList[1].format(
                    "YYYY-MM-DD"
                  )}"]}],"segments":["TmSchoolHomework.assgnClosed"],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                    this.state.selectedSchool
                  }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                    this.state.selectedClass
                  }"]}]}`;
                } else if (this.props?.match?.params?.standardId == "0") {
                  assignListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                    "YYYY-MM-DD"
                  )}","${this.state.dateRangeList[1].format(
                    "YYYY-MM-DD"
                  )}"]}],"segments":["TmSchoolHomework.assgnClosed"],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                    this.state.selectedSchool
                  }"]},{"member":"TmHomeworkgroup.groupId","operator":"equals","values":["${
                    this.state.selectedGroup
                  }"]}]}`;
                } else {
                  assignListUrl = `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.count"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                    "YYYY-MM-DD"
                  )}","${this.state.dateRangeList[1].format(
                    "YYYY-MM-DD"
                  )}"]}],"segments":["TmSchoolHomework.assgnClosed"],"order":[["TmSchoolHomework.assignmentName","asc"]],"dimensions":["TmSchoolHomework.assignmentId","TmSchoolHomework.assignmentName","TmSchoolHomework.if_adaptive"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                    this.state.selectedSchool
                  }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                    this.state.selectedClass
                  }"]},{"member":"TmHomeworkgroup.groupId","operator":"equals","values":["${
                    this.state.selectedGroup
                  }"]}]}`;
                }
                axios
                  .get(`${assignListUrl}`, {
                    headers: {
                      Authorization: `${access_token}`,
                    },
                  })
                  .then((res) => {
                    isAssignmentTrue = true;

                    const assignmentList = res.data.data;
                    this.setState({ assignmentList: assignmentList });

                    const assignmentListOnLoad = [];
                    assignmentList &&
                      assignmentList.map((x) => {
                        assignmentListOnLoad.push(
                          assignmentList &&
                            assignmentList.map((x) => {
                              if (x["TmSchoolHomework.assignmentId"] != null) {
                                assignmentListOnLoad.push(x);
                              }
                            })
                        );
                      });
                    this.setState({ selectedAssignment: ["0"] });

                    if (this.props?.match?.params?.assignmentId) {
                      let assignmentId =
                        this.props?.match?.params?.assignmentId.toString();
                      let assignmentName = [];

                      assignmentList &&
                        assignmentList.map((x) => {
                          if (
                            x["TmSchoolHomework.assignmentId"] == assignmentId
                          ) {
                            this.state.ifAdaptive =
                              x["TmSchoolHomework.if_adaptive"];
                            assignmentName.push(
                              x["TmSchoolHomework.assignmentName"]
                            );
                          }
                        });

                      this.setState({
                        selectedAssignment: [
                          this.props?.match?.params?.assignmentId.toString(),
                        ],
                        selectedAssignmentID: [assignmentId.toString()],
                        selectedAssignmentValue: [assignmentName.toString()],
                      });
                      this.setState({
                        selectedAssignmentId: assignmentId,
                        selectedAssignmentValue: assignmentName,
                      });
                    } else {
                      this.setState({
                        selectedAssignment: ["0"],
                        selectedAssignmentValue: ["All"],
                      });
                    }
                    if (
                      isSchoolTrue &&
                      isClassTrue &&
                      isGroupTrue &&
                      isAssignmentTrue == true
                    ) {
                      this.setState({
                        loader: true,
                      });
                    }
                    axios
                      .get(
                        `${baseUrlCube}cubejs-api/v1/load?query={"measures":["TmSchoolHomework.totalTuCompleted","TmSchoolHomework.totalTuOpen","TmSchoolHomework.totalTuNotMarked"],"timeDimensions":[{"dimension":"TmSchoolHomework.publishDate","dateRange":["${this.state.dateRangeList[0].format(
                          "YYYY-MM-DD"
                        )}","${this.state.dateRangeList[1].format(
                          "YYYY-MM-DD"
                        )}"]}],"order":[["TmSchoolHomework.teacher","asc"]],"dimensions":["TmSchoolHomework.teacher"],"filters":[{"member":"TmSchoolHomework.schoolId","operator":"equals","values":["${
                          this.state.selectedSchool
                        }"]},{"member":"TmSchoolHomework.standardId","operator":"equals","values":["${
                          this.state.selectedClass
                        }"]}]}`,
                        {
                          headers: {
                            Authorization: `${access_token}`,
                          },
                        }
                      )
                      .then((res) => {
                        res.data.data.map((resData, i) => {
                          this.setState({
                            teacherName: resData["TmSchoolHomework.teacher"],
                          });
                        });
                      });
                  })
                  .catch((error) => {});
              })
              .catch((error) => {});
          })
          .catch((error) => {
            this.setState({
              loader: true,
            });
          });
      })
      .catch((error) => {});
  }
  render() {
    if (!isAuth()) {
      return <Redirect to="/home" />;
    }

    let width = 1024;
    let dateRangeArray = [];
    if (this.state.dateRangeList[0] == "") {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
      });
    } else {
      dateRangeArray.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }

    let dateRangeArrayClass = [];
    let dateRangeArrayBoxes = [];
    let dateRangeArrayQuestionAdaptive = [];
    let dateRangeArrayBoxesAdaptiveScore = [];

    if (this.state.dateRangeList[0] == "") {
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
      });
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
      });
    } else {
      dateRangeArrayQuestionAdaptive = [];
      dateRangeArrayQuestionAdaptive.push({
        dimension: "AdaptiveReport.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxes.push({
        dimension: "TmSchoolHomework.publishDate",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
      dateRangeArrayBoxesAdaptiveScore = [];
      dateRangeArrayBoxesAdaptiveScore.push({
        dimension: "classScore.Publish_Date",
        dateRange: [
          this.state.dateRangeList[0].format("YYYY-MM-DD"),
          this.state.dateRangeList[1].format("YYYY-MM-DD"),
        ],
      });
    }
    let dimensionArray = [];
    if (this.state.aggregateBy == "domain") {
      dimensionArray.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionArray.push("TmHwQnAnsStat.chapterName");
    } else if (this.state.aggregateBy == "topic") {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    } else {
      dimensionArray.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    if (this.state.viewBy == "proficiency") {
      this.state.orderBy = [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
        ["TmHwQnAnsStat.proficiencyName", "asc"],
      ];

      this.state.pivotConfig = {
        x: [
          "TmHwQnAnsStat.domain",
          "TmHwQnAnsStat.chapterName",
          "TmHwQnAnsStat.topicName",
        ],
        y: ["TmHwQnAnsStat.proficiencyName", "measures"],
        fillMissingDates: true,
        joinDateRange: false,
      };
    }

    if (this.state.viewBy == "skill") {
      this.state.orderBy = [
        ["TmHwQnAnsStat.domain", "asc"],
        ["TmHwQnAnsStat.chapterName", "asc"],
        ["TmHwQnAnsStat.topicName", "asc"],
        ["TmHwQnAnsStat.skillName", "asc"],
      ];

      this.state.pivotConfig = {
        x: [
          "TmHwQnAnsStat.domain",
          "TmHwQnAnsStat.chapterName",
          "TmHwQnAnsStat.topicName",
        ],
        y: ["TmHwQnAnsStat.skillName", "measures"],
        fillMissingDates: true,
        joinDateRange: false,
      };
    }

    if (this.state.viewBy == "skill") {
      dimensionArray.push("TmHwQnAnsStat.skillName");
    } else if (this.state.viewBy == "proficiency") {
      dimensionArray.push("TmHwQnAnsStat.proficiencyName");
    } else {
    }

    let chartFilterAdaptive = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptive.push({
        member: "AdaptiveReport.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let chartFilter = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.schoolId",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.standardId",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.groupId",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilter.push({
        member: "TmSchoolHomework.assigId",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    let dimensionDynamic = [
      "TmHwQnAnsStat.proficiency",
      "TmDifficulty.proficiency",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamic.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamic.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamic.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicSkill = [
      "TmHwQnAnsStat.skillName",
      "TmHwQnAnsStat.skillStd",
    ];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.domain");
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicSkill.push("TmHwQnAnsStat.chapterName");
    } else {
      dimensionDynamicSkill.push(
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let dimensionDynamicAdaptive = [];
    if (this.state.aggregateBy == "domain") {
      dimensionDynamicAdaptive.push(
        "AdaptiveReport.Domain_Name",
        "AdaptiveReport.Domain_result"
      );
    } else if (this.state.aggregateBy == "chapter") {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.skillName",
        "TmHwQnAnsStat.skillStd",
        "TmHwQnAnsStat.chapterName"
      );
    } else {
      dimensionDynamicAdaptive.push(
        "TmHwQnAnsStat.skillName",
        "TmHwQnAnsStat.skillStd",
        "TmHwQnAnsStat.chapterName",
        "TmHwQnAnsStat.topicName"
      );
    }

    let chartFilterAdaptivescore = [];

    if (this.state.selectedSchool[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.School_Id",
        operator: "equals",
        values: this.state.selectedSchool,
      });
    }

    if (this.state.selectedClass[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Standard_Id",
        operator: "equals",
        values: this.state.selectedClass,
      });
    }

    if (this.state.selectedGroup[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Group_Id",
        operator: "equals",
        values: this.state.selectedGroup,
      });
    }

    if (this.state.selectedAssignment[0]?.toString() != "0") {
      chartFilterAdaptivescore.push({
        member: "classScore.Assignment_Id",
        operator: "equals",
        values: this.state.selectedAssignment,
      });
    }

    const DashboardItemsTop = [
      {
        id: 1,
        name: "Students Usage Report",
        vizState: {
          query: {
            filters: chartFilter,
            dimensions: dimensionArray,
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmHwQnAnsStat.chapterName", "asc"]],
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
          },
          chartType: "table_pivot",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 1,
        name: "Students Usage Report",
        vizState: {
          query: {
            filters: chartFilter,
            dimensions: dimensionDynamic,
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmHwQnAnsStat.proficiency", "asc"]],
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
          },
          chartType: "table_pivot_html",
          parameters: [
            {
              ViewBy:
                this.state.individualAggregated[0] == undefined
                  ? this.state.aggregateBy
                  : this.state.individualAggregated,
            },
            {
              AggregatedBy: this.state.individualviewBy
                ? this.state.individualviewBy
                : this.state.viewBy,
            },
          ],
        },
      },
      {
        id: 1,
        name: "Students Usage Report",
        vizState: {
          query: {
            filters: chartFilter,
            dimensions: dimensionDynamicSkill,
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmHwQnAnsStat.skillStd", "asc"]],
            segments: [
              "TmSingleHwstudStatistics.completedAssgn",
              "TmSchoolHomework.assgnClosed",
            ],
            measures: ["TmHwQnAnsStat.averageScore", "TmHWQs.distCount"],
          },
          chartType: "table_pivot_html_skill_domain",
          parameters: [
            {
              ViewBy:
                this.state.individualAggregated[0] == undefined
                  ? this.state.aggregateBy
                  : this.state.individualAggregated,
            },
            {
              AggregatedBy: this.state.individualviewBy
                ? this.state.individualviewBy
                : this.state.viewBy,
            },
          ],
        },
      },
      {
        id: 2,
        name: "Students Report - Table View",
        vizState: {
          query: {
            dimensions: ["TmStudent.fullName"],
            timeDimensions: dateRangeArrayBoxes,
            order: [["TmStudent.fullName", "asc"]],
            measures: [
              "TmStudentHomeworks.completedAsg",
              "TmStudentHomeworks.unattemptedAsg",
              "TmHwQnAnsStat.averageScore",
            ],
            segments: ["TmSchoolHomework.assgnClosed"],
            filters: chartFilter,
          },
          chartType: "table",
        },
      },
      {
        id: 3,
        name: "Summary Info",
        vizState: {
          query: {
            measures: [
              "TmStudentHomeworks.completedAsg",
              "TmStudentHomeworks.unattemptedAsg",
              "TmHwQnAnsStat.averageScore",
            ],
            timeDimensions: [
              {
                dimension: "Class.completionDate",
                dateRange: this.state.dateRangeList,
              },
            ],
            order: {
              "TmStudentHomeworks.completedAsg": "desc",
            },
            dimensions: ["TmSchoolHomework.teacher", "TmHomeworkgroup.group"],
            filters: chartFilter,
            segments: ["TmStudentHomeworks.attAndNtAttp"],
          },
          chartType: "table",
        },
      },
      {
        id: 4,
        name: "Assignment Report 1",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.totalMarksScoredAssg"],
            timeDimensions: dateRangeArrayBoxes,
            order: [
              ["TmSingleHwstudStatistics.studentName", "asc"],
              ["TmSingleHwstudStatistics.statusOrder", "desc"],
              ["TmHwQnAnsStat.questId", "asc"],
            ],
            dimensions: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.percentageString2",
              "TmSingleHwstudStatistics.studentId",
              "TmSingleHwstudStatistics.statusOrder",
              "TmSingleHwstudStatistics.assignmentId",
              "TmHwQnAnsStat.questId",
            ],
            filters: chartFilter,
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "table_assignment",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
          pivotConfig: {
            x: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.percentageString2",
              "TmSingleHwstudStatistics.statusOrder",
            ],
            y: ["TmHwQnAnsStat.questId", "measures"],
            fillMissingDates: true,
            joinDateRange: false,
          },
        },
      },
      {
        id: 5,
        name: "Students Completed",
        vizState: {
          query: {
            measures: ["AssessmentStatistics.totalCompleted"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: [
              //"TmStudentHomeworks.attAndNtAttp"
              "TmSchoolHomework.assgnClosed",
            ],
          },
          chartType: "number",
        },
      },
      {
        id: 6,
        name: "Not Attempted",
        vizState: {
          query: {
            measures: ["AssessmentStatistics.notAttempted"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: [
              //"TmStudentHomeworks.attAndNtAttp"
              "TmSchoolHomework.assgnClosed",
            ],
          },
          chartType: "number",
        },
      },
      {
        id: 7,
        name: "Class Average",
        vizState: {
          query: {
            measures: ["AssessmentStatistics.totalAverage"],
            timeDimensions: dateRangeArrayBoxes,
            order: {},
            filters: chartFilter,
            dimensions: [],
            segments: [
              //"ClassAsgAvg.attAndNtAttp"
              "TmSchoolHomework.assgnClosed",
            ],
          },
          chartType: "number",
        },
      },
      {
        id: 1,
        name: "Students Usage Report 3",
        vizState: {
          query: {
            dimensions: dimensionDynamicAdaptive,
            timeDimensions: dateRangeArrayQuestionAdaptive,
            filters: chartFilterAdaptive,
            order: [["AdaptiveReport.Domain_Name", "asc"]],
          },
          chartType: "table_pivot_adaptive",
          pivotConfig: this.state.pivotConfig,
        },
      },
      {
        id: 4,
        name: "Assignment Report 2",
        vizState: {
          query: {
            measures: ["TmHwquestion.mark"],
            timeDimensions: dateRangeArrayBoxes,
            order: [
              ["TmSingleHwstudStatistics.studentName", "asc"],
              ["TmSingleHwstudStatistics.statusOrder", "desc"],
              ["TmHwquestion.questId", "asc"],
            ],
            dimensions: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.Result",
              "TmSingleHwstudStatistics.studentId",
              "TmSingleHwstudStatistics.statusOrder",
              "TmSingleHwstudStatistics.assignmentId",
              "TmHwquestion.questionId",
              "TmHwquestion.questId",
            ],
            filters: chartFilter,
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "table_assignment_adaptive",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateByAdaptive },
          ],
          pivotConfig: {
            x: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.statusOrder",
              "TmHwQnAnsStat.Result",
            ],
            y: ["TmHwquestion.questId", "TmHwquestion.questionId", "measures"],
            fillMissingDates: true,
            joinDateRange: false,
          },
        },
      },
      {
        id: 7,
        name: "Class Average",
        vizState: {
          query: {
            dimensions: ["classScore.overall_result"],
            timeDimensions: dateRangeArrayBoxesAdaptiveScore,
            order: { "classScore.overall_result": "asc" },
            filters: chartFilterAdaptivescore,
          },
          chartType: "domain_text",
        },
      },
      {
        id: 4,
        name: "Assignment Report 3",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.totalMarksScoredAssg"],
            timeDimensions: dateRangeArrayBoxes,
            order: [
              ["TmSingleHwstudStatistics.studentName", "asc"],
              ["TmSingleHwstudStatistics.statusOrder", "desc"],
              ["TmHwQnAnsStat.questId", "asc"],
            ],
            dimensions: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.Diag_Student_Result",
              "TmSingleHwstudStatistics.studentId",
              "TmSingleHwstudStatistics.statusOrder",
              "TmSingleHwstudStatistics.assignmentId",
              "TmHwQnAnsStat.questId",
            ],
            filters: chartFilter,
            segments: ["TmSchoolHomework.assgnClosed"],
          },
          chartType: "table_assignment",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
          pivotConfig: {
            x: [
              "TmSingleHwstudStatistics.studentName",
              "TmSingleHwstudStatistics.percentageString2",
              "TmSingleHwstudStatistics.statusOrder",
            ],
            y: ["TmHwQnAnsStat.questId", "measures"],
            fillMissingDates: true,
            joinDateRange: false,
          },
        },
      },
      {
        id: 5,
        name: "Class Score",
        vizState: {
          query: {
            dimensions: ["TmSchoolHomework.Diag_ClassScore"],
            timeDimensions: dateRangeArrayBoxes,
            order: { "TmSchoolHomework.Diag_ClassScore": "asc" },
            filters: chartFilter,
          },
          chartType: "domain_text_dign",
        },
      },
      {
        id: 4,
        name: "Assignment Report 4",
        vizState: {
          query: {
            segments: ["TmSchoolHomework.assgnClosed"],
            dimensions: [
              "TmSchoolHomework.assignmentId",
              "TmSchoolHomework.assignmentName",
              "TmSchoolHomework.teacher",
              "TmSchoolHomework.groupId",
              "TmSchoolHomework.asgGroupId",
              "AssessmentStatistics.studentsCompleted",
              "AssessmentStatistics.studentsNotAttempted",
              "AssessmentStatistics.average_Score",
            ],
            timeDimensions: dateRangeArrayBoxes,
            measures: [],
            order: { "TmSchoolHomework.assignmentName": "asc" },
            filters: chartFilter,
          },
          chartType: "table_assignment_all",
          parameters: [
            { School: this.state.selectedSchoolID },
            { Class: this.state.selectedClass },
            { Group: this.state.selectedGroup },
            { Daterange: this.state.dateRangeList },
            { Assignment: this.state.selectedAssignment },
            { ViewBy: this.state.viewBy },
            { AggregatedBy: this.state.aggregateBy },
          ],
        },
      },
    ];
    const DashboardItems = [
      {
        id: 1,
        name: "Number Chart",
        vizState: {
          query: {
            measures: ["TmHwQnAnsStat.noOfQuestions"],
            timeDimensions: [],
            order: {},
            dimensions: [],
            filters: [],
            limit: 10,
          },
          chartType: "number",
        },
      },
    ];
    const dashboardItem = (item) => (
      <React.Fragment>
        {item.isVisible && (
          <Col span={24} lg={12} key={item.id} style={{ marginBottom: "24px" }}>
            <DashboardItem title={item.name}>
              <ChartRenderer vizState={item.vizState} />
            </DashboardItem>
          </Col>
        )}
      </React.Fragment>
    );
    const dashboardItemTop = (item) => (
      <Col span={24} lg={6} key={item.id} style={{ marginBottom: "24px" }}>
        <DashboardItem title={item.name}>
          <ChartRendererCustomised vizState={item.vizState} />
        </DashboardItem>
      </Col>
    );

    const { collapsed } = this.state;
    let selectedSchoolID = this.state.selectedSchool[0];
    let selectedClassID = this.state.selectedClass[0];
    var classOption = [];
    const dateFormat = "DD-MM-YYYY";

    let allGroupList = [];
    let groupChildren = [];
    let classChildren = [];
    let allClassList = [];
    let assignmentChildren = [];
    let allAssignmentList = [];

    return (
      <React.Fragment>
        <Layout className="print_width" style={{ minHeight: "100vh" }}>
          <Sider
            width={230}
            collapsible
            collapsed={collapsed}
            onCollapse={this.onCollapse}
            className="side_menu site-layout-background"
          >
            <Menu theme="dark" mode="inline" className="nav_panel">
              <Menu.Item icon={<SolutionOutlined />} key="2">
                <Link
                  to="/teacher-usage"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Teachers Usage
                </Link>
              </Menu.Item>
              {/* <Menu.Item icon={<FileDoneOutlined />} key="13">
                  <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
              </Menu.Item> */}
              <Menu.Item icon={<TeamOutlined />} key="4">
                <Link to="/" className="sub-menu ant-menu-submenu-active1">
                  Class Usage
                </Link>
              </Menu.Item>
              <Menu.Item
                icon={<UserOutlined />}
                key="3"
                className="ant-menu-submenu "
              >
                <Link
                  to="/student"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Individual Student Report
                </Link>
              </Menu.Item>
              <Menu.Item icon={<FormOutlined />} key="1">
                <Link
                  to="/assignment"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Assignment Report
                </Link>
              </Menu.Item>
              <Menu.Item icon={<MailOutlined />} key="5">
                <Link
                  to="/send-email"
                  className="sub-menu ant-menu-submenu-active1"
                >
                  Send Email
                </Link>
              </Menu.Item>
             {/*  <Menu.Item icon={<FundProjectionScreenOutlined />} key="6">
                <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
              </Menu.Item> */}
              {/* {localStorage.getItem("userType") == 1 && (
                <>
                  <Menu.Item icon={<LoginOutlined />} key="7">
                    <Link
                      to="/login-report"
                      className="sub-menu ant-menu-submenu-active1"
                    >
                      Login Report
                    </Link>
                  </Menu.Item>
     
                  <Menu.Item icon={<LineChartOutlined />} key="9">
                    <Link
                      to="/task-analysis"
                      className="sub-menu ant-menu-submenu-active1"
                    >
                      Task Analysis
                    </Link>
                  </Menu.Item>
                  <Menu.Item icon={<FundOutlined />} key="10">
                    <Link
                      to="/question-analysis"
                      className="sub-menu ant-menu-submenu-active1"
                    >
                      Question Analysis
                    </Link>
                  </Menu.Item>
                </>
              )} */}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 0px 0px"}}>
            <Grid xs={12} sm={12} lg={12} className="header header-customized">
              <nav className="custom-nav">
                <button
                  className="nav-toggl"
                  onClick={() => {
                    this.setState({ mobNav: !this.state.mobNav });
                    console.log("The button is clicked", this.state.mobNav);
                  }}
                >
                  <MenuOutlinedIcon
                    className="icon-style-custom"
                    style={{
                      width: "20px",
                      height: "20px",
                      margin: "auto",
                      display: "flex",
                    }}
                  />
                </button>
                {this.state.mobNav && (
                  <ul className="custom-ul">
                    <Menu theme="dark" mode="inline" className="nav_panel">
                      <Menu.Item icon={<SolutionOutlined />} key="3">
                        <Link
                          to="/teacher-usage"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Teachers Usage
                        </Link>
                      </Menu.Item>
                     {/*  <Menu.Item icon={<FileDoneOutlined />} key="16">
                          <a href={`${newReportUrl}/attainment-report`}>Attainment Report</a>
                      </Menu.Item> */}
                      <Menu.Item icon={<TeamOutlined />} key="4">
                        <Link
                          to="/"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Class Usage
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        icon={<UserOutlined />}
                        key="2"
                        className="ant-menu-submenu "
                      >
                        <Link
                          to="/student"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Individual Student Report
                        </Link>
                      </Menu.Item>
                      <Menu.Item icon={<FormOutlined />} key="5">
                        <Link
                          to="/assignment"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Assignment Report
                        </Link>
                      </Menu.Item>
                      <Menu.Item icon={<MailOutlined />} key="6">
                        <Link
                          to="/send-email"
                          className="sub-menu ant-menu-submenu-active1"
                        >
                          Send Email
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item
                        icon={<FundProjectionScreenOutlined />}
                        key="1"
                      >
                        <a href={`${newReportUrl}/self-prep`}>Self Prep</a>
                      </Menu.Item> */}
                      {/* {localStorage.getItem("userType") == 1 && (
                        <>
                          <Menu.Item icon={<LoginOutlined />} key="7">
                            <Link
                              to="/login-report"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Login Report
                            </Link>
                          </Menu.Item>
                          
                          <Menu.Item icon={<LineChartOutlined />} key="9">
                            <Link
                              to="/task-analysis"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Task Analysis
                            </Link>
                          </Menu.Item>
                          <Menu.Item icon={<FundOutlined />} key="10">
                            <Link
                              to="/question-analysis"
                              className="sub-menu ant-menu-submenu-active1"
                            >
                              Question Analysis
                            </Link>
                          </Menu.Item>
                        </>
                      )} */}
                    </Menu>
                  </ul>
                )}
              </nav>
              <Grid
                xs={24}
                lg={24}
                container
                spacing={0}
                style={{ padding: 0 }}
                justify="left"
                alignItems="flex-start"
              >
                <Col span={8} className="logo_header">
                  <img class="logoWidth" src={logo} />
                </Col>
                <Col span={8} className="breadcrumb">
                  <center>
                    <h2>
                      <b>Assignment Report</b>
                    </h2>
                  </center>
                </Col>
                <Col span={8} className="user_detail">
                  <h5>
                    <p>
                      <span className="c1">Hello!,</span>{" "}
                      <span className="c2"> {this.state.loginUser} </span>
                      <br />
                      {this.state.selectedSchoolValue} <br />
                      <Link
                        className="logout_btn"
                        onClick={(event) => {
                          event.preventDefault();
                          signout(() => <Redirect to="/home" />);
                        }}
                      >
                        {" "}
                        Home{" "}
                      </Link>
                    </p>
                  </h5>
                </Col>
              </Grid>
            </Grid>
            <center>
              <h2>
                <b>
                  <div
                    style={{
                      display: this.state.display,
                      border: "2px solid black",
                      width: "30%",
                      height: "100px",
                      marginTop: "30px",
                      paddingTop: "30px",
                      backgroundColor: "white",
                    }}
                  >
                    One of the Data not provided
                  </div>
                </b>
              </h2>
            </center>
            <Content
              className="ant-layout-content site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {this.state.display == "none"
                ? !this.state.loader && <Spin />
                : this.setState({ display: "block" })}
              {this.state.loader && (
                <Dashboard>
                  <Grid
                    xs={10}
                    sm={10}
                    lg={10}
                    className="header printme-extra-content"
                  >
                    <Grid
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col span={8} className="logo_header_print">
                        <img class="logoWidth" src={logo} />
                      </Col>
                      <Col span={8} className="breadcrumb_print">
                        <center>
                          <h2>
                            <b>Assignment Report</b>
                          </h2>
                        </center>
                      </Col>
                      <Col span={8} className="user_detail_print">
                        <h5>
                          <p>
                            <span className="c1">School:</span>{" "}
                            <span className="c2">
                              {this.state.selectedSchoolValue}
                            </span>
                            <br />
                            <span className="c1">Assignment Name:</span>{" "}
                            <span className="c2">
                              {this.state.selectedAssignmentValue}
                            </span>
                            <br />
                            <span className="c1">Class:</span>{" "}
                            <span className="c2">
                              {this.state.selectedClassValue}
                            </span>
                            <br />
                            <span className="c1">Group:</span>{" "}
                            <span className="c2">
                              {this.state.selectedGroupValue == "0"
                                ? "All"
                                : this.state.selectedGroupValue}
                            </span>
                            <br />
                            <span className="c1">Teacher:</span>
                            <span className="c2">{this.state.teacherName}</span>
                            <br />
                          </p>
                        </h5>
                      </Col>
                    </Grid>
                  </Grid>
                  <Grid xs={10} sm={10} lg={10}>
                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      <Col
                        span={8}
                        key={DashboardItemsTop[6].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[6].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[6].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      <Col
                        span={8}
                        key={DashboardItemsTop[7].id}
                        className="topWidget"
                        id=""
                      >
                        <DashboardItem title={DashboardItemsTop[7].name}>
                          <ChartRendererCustomised
                            vizState={DashboardItemsTop[7].vizState}
                          />
                        </DashboardItem>
                      </Col>
                      {this.state.ifAdaptive == 1 ? (
                        <Col
                          span={8}
                          key={DashboardItemsTop[11].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[11].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[11].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.ifAdaptive == 2 ? (
                        <Col
                          span={8}
                          key={DashboardItemsTop[13].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[13].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[13].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={8}
                          key={DashboardItemsTop[8].id}
                          className="topWidget"
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[8].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[8].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                    </Grid>
                    <Grid
                      xs={12}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      {this.state.dateRangeList == undefined ? (
                        <h2 className="main-score-card-sub-title"> </h2>
                      ) : (
                        <h2 className="main-score-card-sub-title"></h2>
                      )}
                      <br />
                    </Grid>
                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      {this.state.ifAdaptive == "1" ? (
                        this.state.viewBy == "all" ? (
                          this.state.aggregateBy == "domain" ? (
                            <Col
                              span={24}
                              lg={24}
                              key={DashboardItemsTop[9].id}
                              className={
                                this.state.active === "" ? "active" : ""
                              }
                              id=""
                              onClick={this.onClickDashboardTop}
                            >
                              <DashboardItem title={DashboardItemsTop[9].name}>
                                <ChartRenderer
                                  vizState={DashboardItemsTop[9].vizState}
                                />
                              </DashboardItem>
                            </Col>
                          ) : (
                            <Col
                              span={24}
                              lg={24}
                              key={DashboardItemsTop[0].id}
                              className={
                                this.state.active === "" ? "active" : ""
                              }
                              id=""
                              onClick={this.onClickDashboardTop}
                            >
                              <DashboardItem title={DashboardItemsTop[0].name}>
                                <ChartRenderer
                                  vizState={DashboardItemsTop[0].vizState}
                                />
                              </DashboardItem>
                            </Col>
                          )
                        ) : this.state.viewBy == "proficiency" ? (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[1].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[1].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[1].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            key={DashboardItemsTop[2].id}
                            className={this.state.active === "" ? "active" : ""}
                            id=""
                            onClick={this.onClickDashboardTop}
                          >
                            <DashboardItem title={DashboardItemsTop[2].name}>
                              <ChartRenderer
                                vizState={DashboardItemsTop[2].vizState}
                              />
                            </DashboardItem>
                          </Col>
                        )
                      ) : this.state.viewBy == "all" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[0].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[0].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[0].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.viewBy == "proficiency" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[1].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[1].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[1].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[2].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[2].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[2].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                    </Grid>
                    <br />
                    <Grid
                      className="top-widgets"
                      xs={24}
                      lg={24}
                      container
                      spacing={0}
                      style={{ padding: 0 }}
                      justify="left"
                      alignItems="flex-start"
                    >
                      {this.state.selectedAssignment == 0 ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[14].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                          onClick={this.onClickDashboardTop}
                        >
                          <DashboardItem title={DashboardItemsTop[14].name}>
                            <ChartRenderer
                              vizState={DashboardItemsTop[14].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.ifAdaptive == "1" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[10].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[10].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[10].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : this.state.ifAdaptive == "2" ? (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[12].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[12].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[12].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      ) : (
                        <Col
                          span={24}
                          lg={24}
                          key={DashboardItemsTop[5].id}
                          className={this.state.active === "" ? "active" : ""}
                          id=""
                        >
                          <DashboardItem title={DashboardItemsTop[5].name}>
                            <ChartRendererCustomised
                              vizState={DashboardItemsTop[5].vizState}
                            />
                          </DashboardItem>
                        </Col>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    xs={2}
                    sm={2}
                    lg={2}
                    className="print_width_filter print_filters print_width_filter-cust2"
                  >
                    <Grid
                      container
                      justify="left"
                      alignItems="flex-start"
                      id="filterContainer-title"
                    >
                      <div
                        className="filter-bg white-bg print_btn_print"
                        style={{ float: "left" }}
                      >
                        <ReactToPrint
                          trigger={() => (
                            <button className="print_btn_web">
                              <PrinterFilled />
                              &nbsp;&nbsp;Print
                            </button>
                          )}
                          content={() => window.print()}
                        />
                      </div>
                      <DashboardItem title="Filters" className="ant-card-body">
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> School Name </strong>{" "}
                            </label>
                            <schoolValue
                              data={this.state.selectedSchool}
                            ></schoolValue>
                            <Select
                              showSearch
                              defaultActiveFirstOption={true}
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select school name"
                              optionFilterProp="children"
                              onChange={(value, option) =>
                                this.onChangeSchool(option)
                              }
                              labelInValue
                              value={{
                                value: this.state.selectedSchoolID?.toString(),
                              }}
                            >
                              {this.state.schoolList.map((x) => {
                                return (
                                  <Option
                                    key={x["TmSchool.schoolId"]}
                                    value={x["TmSchool.schoolId"]?.toString()}
                                  >
                                    {x["TmSchool.schoolName"]}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Class </strong>{" "}
                            </label>

                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value:
                                  typeof this.state.selectedClass[0] == "string"
                                    ? this.state.selectedClass[0]
                                    : this.state.selectedClass[0]?.toString(),
                              }}
                              placeholder="Select Class"
                              optionFilterProp="children"
                              onChange={this.onChangeClass}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              if({this.state.selectedClass.length > 0})
                              {this.state.classList &&
                                this.state.classList.map((x) => {
                                  classChildren.push(
                                    <Option
                                      value={x[
                                        "TmStandard.standardId"
                                      ]?.toString()}
                                    >
                                      {x["TmStandard.className"]}
                                    </Option>
                                  );
                                  allClassList.push(x["TmStandard.standardId"]);
                                })}
                              <Option value="0">All</Option>
                              {classChildren}
                            </Select>
                          </div>
                        </Col>{" "}
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Group </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              labelInValue
                              value={{
                                value: this.state.selectedGroup[0]?.toString(),
                              }}
                              style={{ width: "100%", paddingTop: 5 }}
                              placeholder="Select Group"
                              optionFilterProp="children"
                              onChange={this.onChangeGroup}
                            >
                              if({this.state.selectedGroup.length > 0}){" "}
                              {this.state.groupList &&
                                this.state.groupList.map((x) => {
                                  groupChildren.push(
                                    <Option key={x["TmStudentCurrent.groupId"]}>
                                      {x["TmStudentCurrent.groupName"]}
                                    </Option>
                                  );
                                  allGroupList.push(
                                    x["TmStudentCurrent.groupId"]
                                  );
                                })}
                              <Option value="0"> All </Option>
                              {groupChildren}
                            </Select>
                          </div>
                        </Col>
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Date Range </strong>{" "}
                            </label>
                            <RangePicker
                              style={{ top: 5 }}
                              onChange={this.onDateRangeChange}
                              value={
                                this.state.dateRangeList[0] != "0"
                                  ? [
                                      moment(
                                        this.state.dateRangeList[0],
                                        dateFormat
                                      ),
                                      moment(
                                        this.state.dateRangeList[1],
                                        dateFormat
                                      ),
                                    ]
                                  : ""
                              }
                              format={dateFormat}
                            />
                          </div>
                        </Col>
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ paddingBottom: 6, paddingTop: 5 }}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Assignments </strong>{" "}
                            </label>
                            <Select
                              showSearch
                              style={{ width: "100%", paddingTop: 5 }}
                              labelInValue
                              value={{
                                value:
                                  typeof this.state
                                    .selectedAssignmentValue[0] == "string"
                                    ? this.state.selectedAssignmentValue[0]
                                    : this.state.selectedAssignmentValue[0]?.toString(),
                              }}
                              placeholder="Select Assignments"
                              optionFilterProp="children"
                              onChange={this.onChangeAssignment}
                            >
                              if({this.state.selectedAssignment.length > 0}){" "}
                              {this.state.assignmentList &&
                                this.state.assignmentList.map((x) => {
                                  if (
                                    x["TmSchoolHomework.assignmentId"] != null
                                  ) {
                                    assignmentChildren.push(
                                      <Option
                                        key={x["TmSchoolHomework.assignmentId"]}
                                      >
                                        {x["TmSchoolHomework.assignmentName"]}
                                      </Option>
                                    );
                                    allAssignmentList.push(
                                      x["TmSchoolHomework.assignmentId"]
                                    );
                                  }
                                })}
                              <Option value="0"> All </Option>
                              {assignmentChildren}
                            </Select>
                          </div>
                        </Col>
                        <Col
                          span={24}
                          lg={24}
                          xs={24}
                          sm={24}
                          md={24}
                          style={{ paddingBottom: 6 }}
                          className="filterContainer"
                        >
                          <div className="filter-bg color-1 white-bg">
                            <label>
                              {" "}
                              <strong> Aggregate by</strong>{" "}
                            </label>
                            <Select
                              showSearch
                              // defaultValue={"all"}
                              labelInValue
                              defaultValue={{
                                value: this.state.viewBy.toString(),
                              }}
                              style={{ width: "100%" }}
                              placeholder="Select View by"
                              optionFilterProp="children"
                              onChange={this.onChangeViweBy}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              <Option value="all">Overall</Option>
                              <Option value="skill">Skill</Option>
                              <Option value="proficiency">Proficiency</Option>
                            </Select>
                          </div>
                        </Col>
                        {this.state.ifAdaptive == 0 ||
                        this.state.ifAdaptive == 2 ||
                        this.props?.match?.params?.viewBy != "domain" ? (
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ paddingBottom: 6 }}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> View by</strong>{" "}
                              </label>
                              <Select
                                showSearch
                                value={this.state.aggregateBy}
                                style={{ width: "100%" }}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          <Col
                            span={24}
                            lg={24}
                            xs={24}
                            sm={24}
                            md={24}
                            style={{ paddingBottom: 6 }}
                            className="filterContainer"
                          >
                            <div className="filter-bg color-1 white-bg">
                              <label>
                                {" "}
                                <strong> View by </strong>{" "}
                              </label>
                              <Select
                                showSearch
                                value={this.state.aggregateByAdaptive}
                                style={{ width: "100%", paddingTop: 5 }}
                                placeholder="Select Aggregate by"
                                optionFilterProp="children"
                                onChange={this.onChangeAggregateBy}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                <Option value="domain">Domain</Option>
                                <Option value="chapter">Chapter</Option>
                                <Option value="topic">Topic</Option>
                              </Select>
                            </div>
                          </Col>
                        )}
                      </DashboardItem>
                    </Grid>
                  </Grid>
                </Dashboard>
              )}
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

export default CustomerDashboardPage;
